import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import lottie from 'lottie-web';
import fullchep from '../jsons/fullcheprun3.json';
import chepface1 from '../jsons/chepface1.json';
import chepface2 from '../jsons/chepface2.json';
import chepface3 from '../jsons/chepface3.json';
import chepface4 from '../jsons/chepface4.json';
import chepface5 from '../jsons/chepface5.json';
import chepface6 from '../jsons/chepface6.json'
import chepface7 from '../jsons/chepface7.json'
import chepface8 from '../jsons/chepface8.json'
import secondanimation from '../jsons/distance.json';
import stoprun from '../jsons/stop.json';
import { Player } from '@lottiefiles/react-lottie-player';
import carrun from '../jsons/carrun.json';
import './phy.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingPage from "../LoadingPages/LoadingPage";
const Runner4 = () => {
  const rptref = useRef(null); // Reference for fullchep animation
  const faceref = useRef(null); // Reference for face animation
  const animationInstance = useRef(null);
  const stopAnimationInstance = useRef(null);

  const stopref = useRef(null);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  const animationInstance2 = useRef(null);
  const [playSpeed, setPlaySpeed] = useState(1);
  const [startimer, setstartimer] = useState(false);
  const [timeTaken, setTimeTaken] = useState(0);
  const [speed, setSpeed] = useState(1);
  const [distancea, setdistancea] = useState(false);

  const [faceCounter, setFaceCounter] = useState(0);
  const [faceHolder, setFaceHolder] = useState(chepface1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [runHolder, setRunHolder] = useState(carrun);
  const [dir, setDir] = useState(1); // Direction for face animation
  const [currentFrame, setCurrentFrame] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [stoper, setstoper] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [words, setwords] = useState("Speed");
  const [animationData, setAnimationData] = useState(carrun); // Load initial animation data

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


//may cause problems
useEffect(() => {
  if (animationInstance.current) {
   
  }
}, [animationInstance]);

useEffect(() => {
  let timerId;

  if (startimer) {
    timerId = setInterval(() => {
      setTimeTaken((prevTime) => prevTime + 1);
    }, 1000); // increment timeTaken every 1 second
  }

  return () => {
    clearInterval(timerId);
  };
}, [startimer, timeTaken]);

useEffect(() => {

  if(distancea){
    setRunHolder(secondanimation);

    setTimeout(() => {
      if (animationInstance.current) {
        animationInstance.current.setSpeed(1);

        animationInstance.current.play();
      }
    }, 500);
  }

}, [startimer, timeTaken]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4500);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
  
    if(speed<-1){
      setstoper(true);
    }

   
  }, [speed ]);
  
  useEffect(() => {
    if (speed > -1 && !isLoading && rptref.current) {
      animationInstance.current = lottie.loadAnimation({
        container: rptref.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: carrun,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet'
        },
      });
  
      const intervalId = setInterval(() => {
        if (animationInstance.current) {
          const frame = animationInstance.current.currentFrame;
          const duration = animationInstance.current.getDuration(true);
          const time = (frame / animationInstance.current.totalFrames) * duration;
          setCurrentFrame(frame);
        }
      }, 1000); // Update every 100ms
    }
  
    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy();
      }
    };
  }, [runHolder, isLoading]);
  
  useEffect(() => {
    if (speed === 0 && stopref.current && !isLoading) {
      stopAnimationInstance.current = lottie.loadAnimation({
        container: stopref.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: stoprun,
        rendererSettings: { preserveAspectRatio: 'xMidYMid meet' },
      });
    }
  }, [speed,isLoading]);
  
  
  useEffect(() => {
    if (!isLoading && faceref.current) {
      animationInstance2.current = lottie.loadAnimation({
        container: faceref.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: faceHolder,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet'
        }
      });
    }
  
    return () => {
      if (animationInstance2.current) {
        animationInstance2.current.destroy();
      }
    };
  }, [faceHolder, isLoading]); // Add isLoading as a dependency
  

  const playRun = () => {
    if (animationInstance.current) {
      animationInstance.current.play();
    }
  };

  const playFace = () => {
    if (animationInstance2.current) {
      animationInstance2.current.play();
    }
  };
  const playrun = () => {
    setTimeout(() => {
      if (animationInstance.current) {
        animationInstance.current.play();
      }
    }, 5);
  };

  const playit = () => {
    setTimeout(() => {
      if (animationInstance2.current) {
        animationInstance2.current.play();
      }
    }, 100);
  };

  const updateOpacityBasedOnSpeed = () => {
    const updatedAnimationData = JSON.parse(JSON.stringify(carrun));
  
    // Find the 'Car' layer and update its opacity
    const carLayer = updatedAnimationData.layers.find(layer => layer.nm === 'Car');
    if (carLayer) {
      // Set opacity: 0 if speed < 15, otherwise 100
      const opacityValue = speed < 15 ? 0 : 100; // Adjust threshold here
      carLayer.ks.o.k = opacityValue;
    }
  
    setAnimationData(updatedAnimationData); // Update state to trigger re-render
  };
  
  // Run updateOpacityBasedOnSpeed whenever 'speed' changes
  useEffect(() => {

    const updatedAnimationData = JSON.parse(JSON.stringify(animationData));
  
    // Find the 'Car' layer and update its opacity
    const carLayer = updatedAnimationData.layers.find(layer => layer.nm === 'Car');
    if (carLayer) {
      // Set opacity: 0 if speed < 15, otherwise 100
      const opacityValue = speed < 10 ? 0 : 100; // Adjust threshold here
      carLayer.ks.o.k = opacityValue;
    }
    if (animationInstance.current) {
      animationInstance.current.destroy();
      animationInstance.current = lottie.loadAnimation({
        container: rptref.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: updatedAnimationData, // Use the updated data
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet'
        }
      });
    }
  
   
    animationInstance.current.goToAndPlay(currentFrame, true);

  
    setAnimationData(updatedAnimationData);
  }, [speed]); // Add speed as a dependency here
  

  const handleSpeedDecrease = () => {
    setSpeed((prevSpeed) => {
      const newSpeed = prevSpeed - 1;
  
      
  
      return newSpeed;
    });
  
    setFaceCounter((prevCounter) => {
      const newCounter = prevCounter - 1;
      switch (newCounter) {
        case 0:
          setstoper(true);
          break;
        case 1:
          setFaceHolder(chepface1);
          playFace();
          break;
        case 2:
          setFaceHolder(chepface2);
          playFace();
          break;
        case 3:
          setFaceHolder(chepface3);
          playFace();
          break;
        case 4:
          setFaceHolder(chepface4);
          playFace();
          break;
        case 5:
          setFaceHolder(chepface5);
          playFace();
          break;
        default:
          break;
      }
      return newCounter;
    });
 
    setPlaySpeed((prevPlaySpeed) => Math.max(prevPlaySpeed - 0.5, 0.1));
  
    if (animationInstance.current) {
      if(playSpeed<1){

        setstoper(true);
        if(stopAnimationInstance.current){
          stopAnimationInstance.current.play();
        }
      }
      else {
        animationInstance.current.setSpeed(playSpeed - 0.5);

      }
    }

    
  
  };
  
  

  const handleSpeedIncrease = () => {
    if (!isPlaying) {
      animationInstance.current.play();
      animationInstance2.current.play();
      setIsPlaying(true);
      setstartimer(true);

    }

    setFaceCounter(prevCounter => {
      const newCounter = prevCounter + 1;
      if (newCounter >= 2 && newCounter <= 10) {
        switch (newCounter) {
          case 1:
          setFaceHolder(chepface1);
          playit();
          break;
          case 2:
            setFaceHolder(chepface2);
            playit();
            break;
          case 3:
            setFaceHolder(chepface3);
            playit();

            break;
          case 4:
            setFaceHolder(chepface4);
            playit();

            break;
          case 5:
            setFaceHolder(chepface5);
                      playit();

            break;
            case 6:
              setFaceHolder(chepface6);
                        playit();
  
              break;
              case 7:
                setFaceHolder(chepface7);
                          playit();
    
                break;
                case 8:
                setFaceHolder(chepface8);
                          playit();
    
                break;
          default:
            break;
        }
        playFace();
      }
      return newCounter;
    });

    setSpeed(prevSpeed => prevSpeed + 1);
    setPlaySpeed(prevPlaySpeed => prevPlaySpeed + 0.4);
    animationInstance.current.setSpeed(playSpeed + 0.4);
  };

  useEffect(() => {
    if (animationInstance2.current) {
      animationInstance2.current.setDirection(dir);
    }
  }, [dir]);

  const changeDirection = newDirection => {
    setDir(newDirection);
  };

  const reverseAnimation = () => {
    if (animationInstance2.current) {
      const totalFrames = animationInstance2.current.totalFrames;
      animationInstance2.current.goToAndStop(totalFrames - 1, true);
      animationInstance2.current.setDirection(-1);
      animationInstance2.current.play();
    }
  };

  return (
    <>
    {isMobile? (
 <div>

 <>

{isLoading ? (
<LoadingPage />
): (
<Container className="d-flex justify-content-center align-items-center">
<Row className="justify-content-center flex-wrap">
 <Col xs={12} md={8} lg={6} className="mb-4 d-flex justify-content-center">
   <Card style={{ width: '1000px', height: '1400px', textAlign: 'center' }}>
     <Card.Header style={{ backgroundColor: '#E0DBCF' }}>
       <h3>Activity: Chep Run</h3>
     </Card.Header>
     <Card.Body>
       <div style={{ display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems: 'center',userselect:'none' }}>
         <div ref={faceref} style={{ width: '500px', height: '500px', marginLeft: '320px', marginTop: '20px' }}></div>
   {speed<1 && (
     <div ref={stopref} style={{ width: '700px', height: '700px', marginLeft: '300px' }}></div>

   )}      
{speed>=1 && (
<div ref={rptref} style={{ width: '700px', height: '700px',marginLeft:'300px' }}></div>

)}


       </div>
     </Card.Body>
     <Card.Footer style={{ backgroundColor: '#E0DBCF' }}>
       <div style={{ height: '200px', fontFamily: 'helvetica', textAlign: 'center',fontSize:'18px' }}>
         <h2>{words}</h2>
         <div style={{ marginTop: '20px' }}>
           <Button variant="primary" onClick={handleSpeedDecrease}>Decrease Speed</Button>
           <Button variant="primary" onClick={handleSpeedIncrease} style={{ marginLeft: '100px' }}>Increase Speed</Button>

         </div>
         <div style={{ marginTop: '20px' }}>
         </div>
         <div style={{ marginTop: '10px' }}>
           Current speed: {speed} m/s
         </div>
         <div style={{ marginTop: '10px' }}>
         <h2>

    `Time taken: {timeTaken} seconds`
    
  
</h2>

         </div>
       </div>
     </Card.Footer>
   </Card>
 </Col>
</Row>
</Container>
)
}
</>
</div>
    ):(
      <div>

      <>
  
  {isLoading ? (
  <LoadingPage />
  ): (
    <Container className="d-flex justify-content-center align-items-center">
    <Row className="justify-content-center flex-wrap">
      <Col xs={12} md={8} lg={6} className="mb-4 d-flex justify-content-center">
        <Card style={{ width: '1700px', height: '700px', textAlign: 'center' }}>
          <Card.Header style={{ backgroundColor: '#E0DBCF' }}>
            <h3>Activity: Chep Run</h3>
          </Card.Header>
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',userselect:'none' }}>
              <div ref={faceref} style={{ width: '350px', height: '350px', marginRight: '20px' }}></div>
        {speed<1 && (
          <div ref={stopref} style={{ width: '100%', height: '400px' }}></div>
  
        )}      
    {speed>=1 && (
    <div ref={rptref} style={{ width: '100%', height: '400px' }}></div>
  
    )}
  
  
            </div>
          </Card.Body>
          <Card.Footer style={{ backgroundColor: '#E0DBCF' }}>
            <div style={{ height: '200px', fontFamily: 'helvetica', textAlign: 'center' }}>
            <h2>
  
    `Time taken: {timeTaken} seconds` 
    
  
</h2>
              <div style={{ marginTop: '20px' }}>
                <Button variant="primary" onClick={handleSpeedDecrease}>Decrease Speed</Button>
                <Button variant="primary" onClick={handleSpeedIncrease} style={{ marginLeft: '10px' }}>Increase Speed</Button>
  
              </div>
              <div style={{ marginTop: '20px' }}>
               
              </div>
              <div style={{ marginTop: '10px' }}>
                Current speed: {speed} m/s
              </div>
              <div style={{ marginTop: '10px' }}>
                Time taken: {timeTaken} s
              </div>
            </div>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  </Container>
  )
  }
  </>
  </div>
    )}
    </>

   

  );
};

export default Runner4;
