// src/Signup.js
import React, { useEffect, useRef, useState } from "react";
import { signup } from '../auth'; // Make sure this is the correct path
import { gsap } from "gsap";
import '../index.css';
import { axiosAction } from "../scistyles and comps/axiosAction";

const Signuptest = () => {

  const buttonref = useRef(null);
  const [fullName, setfullName] = useState('');
  const [username, setusername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setphone] = useState('');
  const [password, setPassword] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false); // State for error status

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await signup(fullName,username, email, password,phone, dateOfBirth);
      axiosAction.post('https://787a-137-63-145-153.ngrok-free.app/api/v1/auth/signin', fullName,username,email,password,phone,dateOfBirth)
      setMessage('Signup successful!');
      console.log('Signup successful!');
      setIsError(false); // Set error status to false
    } catch (error) {
      setMessage('Error during signup. Please try again.');
      console.error('Error during signup:', error); // Log error details
      setIsError(true); // Set error status to true
    }
  };
  
  const inputStyle = {
    margin: "9px",
    padding: "10px",
    width: "90%",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px",
    fontFamily: "Helvetica",
  };

  const buttonStyle = {
    margin: "10px",
    width:'300px',
    padding: "20px 20px",
    borderRadius: "50px",
    backgroundColor: "#8b0000",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "26px",
    position:'fixed',
    top:'280px',
    left:'50px'
  };

  
  const clickedbutton =  () => {
    gsap.to(buttonref.current, { scale: 0.8, duration:0.5, delay:0,ease:'power4.inOut' });
    gsap.to(buttonref.current, { backgroundColor: 'black', duration:0.5, delay:0,ease:'power4.inOut' });

  };

  return (
    <div className='signup'>
      <form style={{height:'700px',width:'360px',borderRadius:'30px'}}onSubmit={handleSignup}>
        <input type="text" placeholder="fullName" value={fullName} onChange={(e) => setfullName(e.target.value)}           
        style={inputStyle}
 />
     <input type="text" placeholder="username" value={username} onChange={(e) => setusername(e.target.value)}           
        style={inputStyle}
 />
  <input type="text" placeholder="phone" value={username} onChange={(e) => setphone(e.target.value)}           
        style={inputStyle}
 />
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} 
                  style={inputStyle}
                  />
        <input type="password" placeholder="Password(8char)" value={password} onChange={(e) => setPassword(e.target.value)}
                  style={inputStyle}
                  />
        <input type="date" placeholder="Date of Birth" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)}
                  style={inputStyle}
                  />
        <button ref={buttonref} onClick={clickedbutton} style={buttonStyle} type="submit">Sign Up</button>
      </form>
      {message && <div className={`message ${isError ? 'error' : ''}`}>{message}</div>}
    </div>
  );
};

export default Signuptest;