// Newton.js
import React, { useEffect, useRef, useState } from "react";
import gsap from 'gsap';
import jumper from '../jsons/jumper4.json';
import { Draggable } from 'gsap/Draggable';
import { Player } from '@lottiefiles/react-lottie-player';
import runlaunch from '../jsons/runlaunch.json';
import run1 from '../jsons/run1.json';
import run2 from '../jsons/run2.json';
import runend from '../jsons/runend.json';
import O from '../jsons/H.json';

gsap.registerPlugin(Draggable);

const Newton2 = () => {
  const blockRef = useRef(null); // Ref for the draggable block
  const lineRef = useRef(null);  // Ref for the ground line
  const animref = useRef(null);  // Ref for the Lottie animation
  const draggableInstanceRef = useRef([]); // Ref for storing the GSAP Draggable instances
  const [holder, setholder] = useState(runlaunch);
  const animref2 = useRef(null);  // Ref for the Lottie animation
  const [looper, setlooper] = useState(false);

  const blockRef2 = useRef(null); // Ref for the draggable block

  useEffect(() => {
    const initialX = 300; // Initial X position
    const initialY = 390; // Initial Y position
    
    // Set the initial position of the block
    gsap.set(blockRef.current, { x: initialX, y: initialY });
  

    //bloc draggable
    draggableInstanceRef.current[1] = Draggable.create(blockRef2.current, {
      type: "x,y",
      bounds: { top: 0, left: 0, right: window.innerWidth, bottom: 390 },
      onDragEnd: function() {
        moveBlockDown2();
      }
    });
    // Initialize Draggable on the block
    draggableInstanceRef.current[0] = Draggable.create(blockRef.current, {
      type: "x,y",
      bounds: {
        top: initialY - 10,
        bottom:  390   ,
        left: initialX - 10,
        right: initialX + 10
      },
      onDrag: function() {
        animref2.current.play();
      },
      onDragEnd: function() {

       
      setTimeout(() => {
        setholder(run1);
        setlooper(true);

        setTimeout(() => {
          setholder(run2);
        }, 100);

      }, 100);
        gsap.to(blockRef.current, {
          x: "+=1000", // Move up 10px relative to current position
          duration: 3.2,
          ease:'none',
          delay: 0.1,
          
            // Move block back to initial position after 1 second

                 
               

           
         
        });

        setTimeout(() => {
          setholder(runend);
          setlooper(false);
          setTimeout(() => {
            animref2.current.play();

          }, 100);
        }, 3100);
      }
    });
  }, []);
  
  const moveBlockDown2 = () => {
    const block = blockRef2.current;
    const line = lineRef.current;
    const block1 = blockRef.current;
    const checkCollision2 = (blockA, blockB) => {
      const rectA = blockA.getBoundingClientRect();
      const rectB = blockB.getBoundingClientRect();
      
      return !(
        rectA.right < rectB.left ||
        rectA.left > rectB.right ||
        rectA.bottom < rectB.top ||
        rectA.top > rectB.bottom
      );
    };
    const checkCollision = () => {
      const blockRect = block.getBoundingClientRect();
      const lineRect = line.getBoundingClientRect();
      const block1Rect = block1.getBoundingClientRect();
      
      if (blockRect.bottom + 1 >= lineRect.top && 
          blockRect.left < lineRect.right && 
          blockRect.right > lineRect.left) {
        return lineRect.top - blockRect.height;
      } 
      else if (blockRect.bottom + 1 >= block1Rect.top && 
               blockRect.left < block1Rect.right && 
               blockRect.right > block1Rect.left) {
        return block1Rect.top - blockRect.height;
      }

      return null;
    };
    
    const animateDown = () => {
      const collisionY = checkCollision();
      if (collisionY !== null) {
        gsap.to(block, { y: collisionY, duration: 0.1, ease: 'bounce.out' });
     //   animref.current.play();
      } else {
        gsap.to(block, { y: "+=40", duration: 0.05, onComplete: animateDown });
     //   animref.current.play();
      }
     
    };

    animateDown();
  };
  // Function to check for collision with the ground line
  const checkCollision = () => {
    const block = blockRef.current;
    const line = lineRef.current;

    const blockRect = block.getBoundingClientRect();
    const lineRect = line.getBoundingClientRect();

    if (
      blockRect.bottom + 1 >= lineRect.top &&
      blockRect.left < lineRect.right &&
      blockRect.right > lineRect.left
    ) {
      return lineRect.top - blockRect.height;
    }
    return null;
  };

  // Function to animate the block down with bounce effect
  const animateDown = () => {
    const collisionY = checkCollision();
    if (collisionY !== null) {
      // Stop movement at ground line
      gsap.to(blockRef.current, { y: collisionY, duration: 0.3, ease: "bounce.out" });
    } else {
      // Continue moving down if no collision
      gsap.to(blockRef.current, { y: -10, duration: 0.1, onComplete: animateDown });
    }
  };

  return (
    <div style={{ position: 'relative', height: '100vh', width: '100vw' }}>
      {/* Draggable block */}
    <>
    
   
     {looper?(
      <div
        ref={blockRef}
        style={{
          position: 'absolute',
          width: '200px',
          height: '200px',
        }}
      >
        <Player
          ref={animref2}
          src={holder}
          loop={true}
          autoplay={true}
          style={{ width: '250px', height: '250px' }}
        />
      </div>
     ):(
<div
        ref={blockRef}
        style={{
          position: 'absolute',
          width: '200px',
          height: '200px',
        }}
      >
        <Player
          ref={animref2}
          src={holder}
          loop={false}
          autoplay={false}
          keepLastFrame={true}
          style={{ width: '250px', height: '250px' }}
        />
      </div>
     )}
      </>
      
      <>
     
      {looper? (
 <div ref={blockRef2}  style={{ position: 'absolute', width: '100px', height: '100px' }}>
 <Player
     ref={animref}
     src={O}
     autoplay={true}
     loop={true}
     style={{ width: '100%', height: '100%' }} // Adjust Lottie size
   />
 </div>
      ):(
        <div ref={blockRef2}  style={{ position: 'absolute', width: '100px', height: '100px' }}>
        <Player
            ref={animref}
            src={O}
            autoplay={false}
            loop={false}
            keepLastFrame={true}
            style={{ width: '100%', height: '100%' }} // Adjust Lottie size
          />
        </div>

      )}
 </>
     
      {/* Ground line */}
      <div
        ref={lineRef}
        className="horizontal-line"
        style={{
          position: 'absolute',
          top: '630px', // Position the line 400px from the top of the container
          width: '100%',
          height: '4px',
          backgroundColor: 'black'
        }}
      />
    </div>
  );
};

export default Newton2;