import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";

const getRandomPosition = (maxWidth, maxHeight) => {
  return {
    x: Math.random() * maxWidth,
    y: Math.random() * maxHeight,
  };
};

const checkCollision = (follower, ball) => {
  const followerRect = follower.getBoundingClientRect();
  const ballRect = ball.getBoundingClientRect();

  return !(
    followerRect.top > ballRect.bottom ||
    followerRect.bottom < ballRect.top ||
    followerRect.left > ballRect.right ||
    followerRect.right < ballRect.left
  );
};

const MouseFollower = () => {
  const followerRef = useRef(null);
  const ballRefs = [useRef(null), useRef(null), useRef(null)];
  const containerRef = useRef(null);

  useEffect(() => {
    // Initialize positions of the balls
    const { clientWidth, clientHeight } = containerRef.current;
    ballRefs.forEach((ballRef) => {
      const { x, y } = getRandomPosition(clientWidth - 1010, clientHeight - 150);
      gsap.set(ballRef.current, { x, y, backgroundColor: "grey" });
    });

    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      gsap.to(followerRef.current, {
        x: clientX - 875,
        y: clientY - 75,
        duration: 0.2,
        ease: "power2.out",
      });

      ballRefs.forEach((ballRef) => {
        if (checkCollision(followerRef.current, ballRef.current)) {
          gsap.to(ballRef.current, { backgroundColor: "red" });
        } else {
          gsap.to(ballRef.current, { backgroundColor: "grey" });
        }
      });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{ position: "relative", width: "100vw", height: "100vh" }}
    >
      <div
        ref={followerRef}
        style={{
          position: "absolute",
          width: "150px",
          height: "150px",
          backgroundColor: "blue",
          borderRadius: "50%",
          pointerEvents: "none", // Ensures it doesn't block mouse events
        }}
      ></div>
      {ballRefs.map((ballRef, index) => (
        <div
          key={index}
          ref={ballRef}
          style={{
            position: "absolute",
            width: "150px",
            height: "150px",
            backgroundColor: "grey",
            borderRadius: "50%",
          }}
        ></div>
      ))}
    </div>
  );
};

export default MouseFollower;
