import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scisteps.css';
import { axiosAction } from './axiosAction';
// Validation schema using Yup
const schema = Yup.object().shape({
  name: Yup.string().when('isLogin', {
    is: false,  // Only required for sign-up
    then: Yup.string().required('Name is required'),
  }),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  dateOfBirth: Yup.date().when('isLogin', {
    is: false,  // Only required for sign-up
    then: Yup.date().required('Date of Birth is required'),
  }),
});

const SciStepsAuth = () => {
  const [isLogin, setIsLogin] = useState(true);  // Toggles between login and sign-up
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  // Easy-to-change colors
  const loginBackgroundColor = 'light';  // Change to any color
  const signupBackgroundColor = 'light';  // Change to any color
  const loginCardColor = 'light';  // Bootstrap color classes like 'light', 'primary', etc.
  const signupCardColor = 'primary';  // For the sign-up form

  const toggleForm = () => {
    setIsLogin(!isLogin);  // Switch between login and sign-up
  };


  const onSubmitSignup = async (data) => {
    console.log('Form Data:', data);
    // Handle form submission logic here
    const response = await axiosAction.post('https://e872-41-210-141-20.ngrok-free.app/api/v1/auth/signin', data);
    console.log('Response:', response);
  };
  
  const onSubmitLogin = async (data) => {
    console.log('something');
    console.log('Form Data:', data);
    // Handle form submission logic here
    const response = await axiosAction.post('https://5f5f-41-210-155-68.ngrok-free.app/api/v1/auth/login', data);
    console.log('Response:', response);
  };
  return (
    <Container 
      fluid 
      className="vh-100 d-flex justify-content-center align-items-center"
      style={{ backgroundColor: isLogin ? loginBackgroundColor : signupBackgroundColor }}  // Dynamic background color
    >
      <Row>
        <Col md={6} lg={4}>
          <Card 
            style={{ width: '500px' }} 
            className={`p-4 bg-${isLogin ? loginCardColor : signupCardColor}`}  // Dynamic card color
          >
            <h3 className={`text-center mb-4 ${isLogin ? 'text-dark' : 'text-white'}`}>
              {isLogin ? 'Login' : 'Sign Up'}
            </h3>
{isLogin && (
  <Form onSubmit={handleSubmit(onSubmitLogin)}>
    <Form.Group className="mb-3">
      <Form.Label>Username</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter username"
        {...register('username', { required: 'Username is required' })}  // Ensure this is registered correctly
      />
      {errors.username && <div className="text-danger">{errors.username.message}</div>}
    </Form.Group>
  
    <Form.Group className="mb-3">
      <Form.Label>Password</Form.Label>
      <Form.Control
        type="password"
        placeholder="Password"
        {...register('password', { required: 'Password is required' })}  // Ensure this is registered correctly
      />
      {errors.password && <div className="text-danger">{errors.password.message}</div>}
    </Form.Group>
  
    <Button variant="primary" type="submit" className="w-100 mb-3">
      Login
    </Button>
  </Form>
)}
        
{!isLogin && (
  <Form onSubmit={handleSubmit(onSubmitSignup)}>
  <Form.Group className="mb-3">
    <Form.Label>Full Name</Form.Label>
    <Form.Control
      type="text"
      placeholder="Enter your name"
      {...register('fullName', { required: 'Full Name is required' })}
    />
    {errors.fullName && <div className="text-danger">{errors.fullName.message}</div>}
  </Form.Group>

  <Form.Group className="mb-3">
    <Form.Label>Username</Form.Label>
    <Form.Control
      type="text"
      placeholder="Enter username"
      {...register('username', { required: 'Username is required' })}
    />
    {errors.username && <div className="text-danger">{errors.username.message}</div>}
  </Form.Group>

  <Form.Group className="mb-3">
    <Form.Label>Password</Form.Label>
    <Form.Control
      type="password"
      placeholder="Password"
      {...register('password', { required: 'Password is required' })}
    />
    {errors.password && <div className="text-danger">{errors.password.message}</div>}
  </Form.Group>

  <Form.Group className="mb-3">
    <Form.Label>Date of Birth</Form.Label>
    <Form.Control
      type="date"
      {...register('dateOfBirth', { required: 'Date of Birth is required' })}
    />
    {errors.dateOfBirth && <div className="text-danger">{errors.dateOfBirth.message}</div>}
  </Form.Group>

  <Button variant="primary" type="submit"  className="w-100 mb-3">
    Sign Up
  </Button>
</Form>
)}


            <div className="text-center">
              <Button variant="link" className={isLogin ? 'text-primary' : 'text-white'} onClick={toggleForm}>
                {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Login'}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SciStepsAuth;
