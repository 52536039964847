import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc,setDoc,collection,getDocs, updateDoc, arrayUnion,onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

// Your Firebase configuration object
const firebaseConfig = {
    apiKey: "AIzaSyBSzg-wtm8vLQPKVLzdtE8Xt2q46-IS2ZA",
    authDomain: "math-swipe.firebaseapp.com",
    projectId: "math-swipe",
    storageBucket: "math-swipe.appspot.com",
    messagingSenderId: "856595090752",
    appId: "1:856595090752:web:eb09ad893f56772dc6bea5",
    measurementId: "G-0VG70CBHH3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// below is what i changed
const db = getFirestore(app);
//const db = getDatabase(app);

const auth = getAuth(app);

export { app, db, auth, doc, getDoc, updateDoc, arrayUnion,onSnapshot,setDoc,getDocs,collection };
