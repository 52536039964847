// src/auth.js
import { auth, db } from './Firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, serverTimestamp, updateDoc, increment,getDoc } from 'firebase/firestore';

// Existing signup function
export const signup = async (name, email, password, dateOfBirth) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    const userDoc = {
      name,
      dateOfBirth,
      email,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      mEp: 0,
      mEf: 0,
      mDp: 0,
      mDf: 0,
      mKp: 0,
      mKf: 0,
      mEp2: 0,
      mEf2: 0,
      mDp2: 0,
      mDf2: 0,
      mKp2: 0,
      mKf2: 0,
      totalScore: 0,
      totalfailed: 0,
      comments: null,
    };
    await setDoc(doc(db, 'usersp', 'PHY'), userDoc);

    console.log('User signed up successfully', userDoc);
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const signup2 = async (name, email, password, dateOfBirth) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    const userDoc = {
      name,
      dateOfBirth,
      email,
      createdAt: serverTimestamp(),
      M1: 0,
      Mn: 0,
      totalScore: 0,
      Level1:0,
      comments:null,
      selection:null,
    };
    await setDoc(doc(db, 'users', user.uid), userDoc);

    console.log('User signed up successfully', userDoc);
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};


// Existing login function
export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    console.log('Login successful:', user);
    return true;
  } catch (error) {
    console.error('Error during login:', error);
    return false;
  }
};

// New updatemEp function
export const updatemEp = async () => {
  try {
    const docRef = doc(db, 'usersp', 'PHY');
    await updateDoc(docRef, {
      mEp: increment(1)
    });
    console.log('mEp incremented successfully');
  } catch (error) {
    console.error('Error incrementing mEp:', error);
  }
};

export const updatemEf = async () => {
  try {
    const docRef = doc(db, 'usersp', 'PHY');
    await updateDoc(docRef, {
      mEf: increment(1)
    });
    console.log('mEp incremented successfully');
  } catch (error) {
    console.error('Error incrementing mEp:', error);
  }
};

export const updatemDp = async () => {
  try {
    const docRef = doc(db, 'usersp', 'PHY');
    await updateDoc(docRef, {
      mDp: increment(1)
    });
    console.log('mEp incremented successfully');
  } catch (error) {
    console.error('Error incrementing mDp:', error);
  }
};

export const updatemDf = async () => {
  try {
    const docRef = doc(db, 'usersp', 'PHY');
    await updateDoc(docRef, {
      mDf: increment(1)
    });
    console.log('mEp incremented successfully');
  } catch (error) {
    console.error('Error incrementing mDp:', error);
  }
};
export const fetchmEpScore = async () => {
  try {
    const docRef = doc(db, 'usersp', 'PHY');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.mEp || 0; // Return mEp if exists, otherwise 0
    } else {
      console.log('No such document!');
      return 0;
    }
  } catch (error) {
    console.error('Error fetching mEp score:', error);
    return 0;
  }
};