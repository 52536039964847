import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MathIntro from './MathIntro';
import MathTraining from './MathTraining';
import MathLearning from "./MathLearning";
import MathLearning2 from "./MathLearning2";
import MathLearning3 from "./MathLearning3";
import Exp2 from "./Experiments/Exp2";
import Select from "./Select";
import Signup from "./Signup";
import Login from "./Login";
import UserList from "./Userlist";
import Scenario from "./Scenarios/Scenario";
import Generallession from "./LearningComponents/Generallesson";
import RotateDevice from './RotateDevice'; // Import the RotateDevice component
import './style.css';
import Matrix from "./LearningComponents/Matrix";
import Title from "./Title";
import MathTraining2 from "./MathTraining2";
import Teaching from "./Teaching";
import MyComponent from "./MyComponent";
import DashboardContainer from "./Dashboards/DashboardContainer";
import { QuestionProvider } from './PracticeComponents/QuestionContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notification from "./Notification";
import 'bootstrap/dist/css/bootstrap.min.css';
import Runner from "./PracticeComponents/Runner";
import Runner2 from "./PracticeComponents/Runner2";
import Runner3 from "./PracticeComponents/Runner3";
import Runner4 from "./PracticeComponents/Runner4";
import SignupPage from './Experiments/SignupPage';
import Launch from "./PracticeComponents/Launch";
import Physteps from "./Experiments/Physteps";
import Activity from "./Experiments/Activity";
import Logintest from "./Experiments/Logintest";
import Signuptest from "./Experiments/Signuptest";
import Subtopics from './Experiments/Subtopics';
import Tagofwar from "./PracticeComponents/TagofWar";
import GameRoom from './GameRoom';
import Lobby from './Lobby';
import ForcePush from "./PracticeComponents/ForcePush";
import Multiplayer from './Multiplayer';
import JoinGame from "./JoinGame";
import Scisteps from "./Scisteps";
import SubjectSelect from "./SubjectSelect";
import SwipeMatch from "./PracticeComponents/SwipeMatch";
import DistanceTracker from "./PracticeComponents/DistanceTracker";
import SciStepsAuth from "./scistyles and comps/SciStepsAuth";
import Physicsp1 from "./UNEB/Physicsp1";
import TopicsPage from './scistyles and comps/TopicsPage';
import ChemistryPracTopics from "./Chemix/ChemistryPracTopics";
import ChemistryTopics from "./Chemix/ChemistryTopics";
import MathTopics from "./LearningComponents/MathTopics";
import Cprac1 from "./Chemix/Cprac1";
import Cpra2 from "./Chemix/Cprac2";
import StemBlocs from "./Experiments/StemBlocs";
import Newton from "./Experiments/Newton";
import Newton2 from "./Experiments/Newton2";
import PeriodicTable from "./Chemix/PeriodicTable";
import DragLine from "./Experiments/DragLine";
import Newton3 from "./Experiments/Newton3";
import About from "./About";
import ProfilePage from "./Dashboards/ProfilePage";
//import Chem2 from './Chemix/Chem2';
//import Chem1 from "./Chemix/Chem1";
//const weeklyMessage = "This is the first version of Math Swipe!"; // Define the weekly message
import Elasticity1 from "./Experiments/Elasticity1";
function App() {



  return (
    <Router>
      <div className="App">
        <DashboardContainer />

        {/* ToastContainer for global notifications */}
        <ToastContainer />

        {/* <Notification message={weeklyMessage} /> */}
        

        <Routes>
          <Route path="/" element={<SubjectSelect  />} />
          <Route path="/mathintro" element={<MathIntro />} />

          <Route path="/mlearn" element={<MathLearning />} />
          <Route path="/mlearn2" element={<MathLearning2 />} />
          <Route path="/mlearn3" element={<MathLearning3 />} />
          <Route path="/runner" element={<Runner />} />
          <Route path="/mtrain" element={<MathTraining />} />
          <Route path="/mtrain2" element={<MathTraining2 />} />
          <Route path="/select" element={<Select />} />
          <Route path="/userlist" element={<UserList />} />
          <Route path="/scenario" element={<Scenario />} />
          <Route path="/general" element={<Generallession />} />
          <Route path="/matrix" element={<Matrix />} />
          <Route path="/title" element={<Title />} />
          <Route path="/teaching" element={<Teaching />} />
          <Route path="/myComponent" element={<MyComponent />} />
          <Route path="/exp2" element={<Exp2 />} />
          <Route path="/launch" element={<Launch />} />
          <Route path="/physteps" element={<Physteps />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/signtest" element={<Signuptest />} />
          <Route path="/logintest" element={<Logintest />} />
          <Route path="/tagofwar" element={<Tagofwar />} />
          <Route path="/multiplayer" element={<Multiplayer />} />
          <Route path="/forcepush" element={<ForcePush />} />
          <Route path="/scisteps" element={<Scisteps />} />
          <Route path="/subtopics" element={<Subtopics />} />
          <Route path="/game/:roomId" element={<GameRoom />} />
          <Route path="/joingame" element={<JoinGame />} />
          <Route path="/subjectselect" element={<SubjectSelect />} />
          <Route path="/swipematch" element={<SwipeMatch />} />
          <Route path="/distancetracker" element={<DistanceTracker />} />
          <Route path="/physicsp1" element={<Physicsp1 />} />
          <Route path="/topicspage" element={<TopicsPage />} />
          <Route path="/chemistrypractopics" element={<ChemistryPracTopics />} />
          <Route path="/chemistrytopics" element={<ChemistryTopics />} />
          <Route path="/mathtopics" element={<MathTopics />} />
          <Route path="/cprac1" element={<Cprac1 />} />
          <Route path="/cprac2" element={<Cpra2 />} />
          <Route path="/stemblocs" element={<StemBlocs />} />
          <Route path="/runner2" element={<Runner2 />} />
          <Route path="/newton" element={<Newton />} />
          <Route path="/periodictable" element={<PeriodicTable />} />
          <Route path="/newton2" element={<Newton2 />} />
          <Route path="/runner3" element={<Runner3 />} />
          <Route path="/runner4" element={<Runner4 />} />
          <Route path="/dragline" element={<DragLine />} />
          <Route path="/newton3" element={<Newton3 />} />
          <Route path="/about" element={<About />} />
          <Route path="/elasticity" element={<Elasticity1 />} />
          <Route path="/profilepage" element={<ProfilePage />} />

          <Route path="/signupPage" element={<SignupPage />} />
          <Route path="/signup" element={<Signup />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
