import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import { Draggable } from 'gsap/Draggable';
import { gsap } from 'gsap';
import spring from '../jsons/experiment assets/spring3.json';

const Elasticity = () => {
  const containerRef = useRef(null);
  const lottieInstance = useRef(null);
  const [playSpeed, setPlaySpeed] = useState(1);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [othervalue, setothervalue] = useState(50);

  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);

  const checkCollision = () => {
    const box1Rect = boxRef1.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    const isColliding = !(
      box1Rect.right < containerRect.left ||
      box1Rect.left > containerRect.right ||
      box1Rect.bottom < containerRect.top ||
      box1Rect.top > containerRect.bottom
    );

    return isColliding ? 1 : 0;
  };


  useEffect(() => {
    const box1Rect = boxRef1.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    const isColliding = !(
      box1Rect.right < containerRect.left ||
      box1Rect.left > containerRect.right ||
      box1Rect.bottom < containerRect.top ||
      box1Rect.top > containerRect.bottom
    );

    if(isColliding){

      const timerId = setTimeout(() => {
        setothervalue(prev => prev + 5);
        updateAnimationData(othervalue + 5);
      }, 500); // Speed of opponent pusher

      return () => clearTimeout(timerId);
    }
    
  }, [sliderValue,boxRef1,boxRef2,othervalue]);


  useEffect(() => {
    // Initialize Lottie animation
    lottieInstance.current = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: spring,
    });

    return () => {
      lottieInstance.current.destroy();
    };
  }, []);

  useEffect(() => {
    // Initialize GSAP Draggable for the boxes
    gsap.registerPlugin(Draggable);

    Draggable.create(boxRef1.current, {
      type: 'x,y',
      bounds: window, // Prevent dragging outside the viewport
     
    });

    Draggable.create(boxRef2.current, {
      type: 'x,y',
      bounds: window, // Prevent dragging outside the viewport
    });
  }, []);

  const handleSliderChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setSliderValue(newValue);
    updateAnimationData(newValue);
  };

  const updateAnimationData = (newValue) => {
    const updatedData = JSON.parse(JSON.stringify(spring)); // Clone animation data
    updatedData.layers.forEach((layer) => {
      if (layer.ef) {
        layer.ef.forEach((effect) => {
          if (effect.nm === 'Slider Control') {
            const sliderEffect = effect.ef.find((e) => e.nm === 'Slider');
            if (sliderEffect) {
              sliderEffect.v.k = newValue;
            }
          }
        });
      }
    });

    // Reinitialize the animation with the updated data
    if (lottieInstance.current) {
      lottieInstance.current.destroy();
    }
    lottieInstance.current = lottie.loadAnimation({
      container: containerRef.current,
      animationData: updatedData,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
      },
    });

    // Play the animation from the current frame
    if (newValue > 0) {
      lottieInstance.current.goToAndPlay(currentFrame, true);
    }
  };

  const boxStyle = {
    width: '200px',
    height: '100px',
    border: '2px solid #000',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
    cursor: 'grab',
    zIndex: 3,
  };

  return (
    <div>
      <h2>Elasticity Experiment</h2>
      <div
        ref={containerRef}
        style={{ width: '400px', height: '400px', margin: 'auto', border: '2px dashed #000' }}
      ></div>
      <div className="mb-3" style={{ userSelect: 'none' }}>
        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue}
          onChange={handleSliderChange}
          className="form-range"
        />
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px',userSelect:'none' }}>
          <div ref={boxRef1} style={boxStyle}>
            Box 1
          </div>
          <div ref={boxRef2} style={boxStyle}>
            Box 2
          </div>
        </div>
      </div>
    </div>
  );
};

export default Elasticity;
