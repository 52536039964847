import React from 'react';
import { Button } from 'react-bootstrap';
import './boot.css';

const CircularButton = ({ onClick }) => {
  return (
    <Button onClick={onClick} className="circular-button" variant="primary" size="lg">
      <span className="arrow-next"></span>
    </Button>
  );
};

export default CircularButton;
