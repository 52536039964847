import React, { useEffect, useRef, useState } from "react";
import crosses from '../jsons/crosses1.json';
import crosses2 from '../jsons/crosses2.json';
import crosses3 from '../jsons/crossesb.json';
import { gsap } from "gsap";
import { Draggable } from "gsap/all";
import wrong from '../jsons/wrongmove.json';
import divide from '../jsons/Division.json';
import '../index.css';
import sub from '../jsons/subtraction.json';
import { getFirestore, doc, updateDoc, increment } from 'firebase/firestore';
import { app } from '../Firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import bballf from '../jsons/bballf.json';
import bballp from '../jsons/bballpassed.json';
import bballf2 from '../jsons/bbfail2.json';
import bbp2 from '../jsons/bbp2.json';
import congs from '../jsons/congs.json';
import hint from '../jsons/hint2.json';
import hint1 from '../jsons/hinter.json';

import { Player } from "@lottiefiles/react-lottie-player";

gsap.registerPlugin(Draggable);

const Component11m = ({ variableValue, oneValue, fiveValue, sign1, sign2 ,finished ,sendDataToParent }) => {
  const xRef = useRef(null);
  const plusRef = useRef(null);
  const oneRef = useRef(null);
  const equalRef = useRef(null);
  const crossesref = useRef(null);
  const crosses2ref = useRef(null);
  const crosses3ref = useRef(null);
  const upperanimref = useRef(null);
  const bballref = useRef(null);
  const hintref = useRef(null);
  const hinterref = useRef(null);

  const db = getFirestore(app);
  const minusRef = useRef(null);
  const fiveRef = useRef(null);
  const lowref = useRef(null);
  const extraref = useRef(null);
 // const [finished, setFinished] = useState(false);

  const [M1, setM1] = useState(0);
  const [Mn, setMn] = useState(0);
  const [bball, setbball] = useState(null);
  const [showhint, setshowhint] = useState(false);
  
  const [hinters, sethinters] = useState(hint1);

  const [clones, setClones] = useState([]);
  const [cloneTopPosition, setCloneTopPosition] = useState(250); // Initial top position for the first clone

  const [showVariableValue, setShowVariableValue] = useState(true);
  const [showMinus, setShowMinus] = useState(false);
  const [showPlus, setShowPlus] = useState(true);
  const [onetilecolor, setonetilecolor] = useState('white');
  const [fivetilecolor, setfivetilecolor] = useState('white');
  const [low, setlow] = useState(wrong);
  const [ans, setAns] = useState(wrong);
  const [upperanimations, setupperanimations] = useState(null);
  const [Showvariable, setvariable] = useState(true);
  const [uM1, setuM1] = useState(null);
  const [uMn, setuMn] = useState(null);



  const auth = getAuth();
const currentUser = auth.currentUser;
const currentUserId = currentUser.uid;

  const incrementM1 = () => {
    setM1(prevM1 => {
      const newM1 = prevM1 + 1;
      updateM1InFirestore(newM1).then(() => {
        // code to be executed after the update is complete
      }).catch(error => {
        console.error('Error updating M1:', error);
      });
      return newM1;
    });

  };

  const decreaseM1 = () => {
    setM1(prevM1 => {
      const newM1 = prevM1 - 1;
      decreaseM1InFirestore(newM1).then(() => {
        // code to be executed after the update is complete
      }).catch(error => {
        console.error('Error updating M1:', error);
      });
      return newM1;
    });

  };


  const incrementuM1 = () => {
    setuM1(uM1 => {
      
      const newuM1 = uM1 + 1;
  //    onUpdateUM1(newuM1); // Call the callback prop
      return newuM1;
    });
  };

  const playhinter = () => {
   
    if(hinterref.current){
      hinterref.current.play();
    }

  };


  const enablehint = () => {
  
    decreaseM1();
    setshowhint(true);
   // gsap.to(one, { backgroundColor: 'blue', duration: 1 });
    setTimeout(() => {
playhinter();

}, 500);

setTimeout(() => {
  setshowhint(false);
}, 5000);


  };


  const incrementuMn = () => {
    setuMn(uMn => {
      const newuMn = uMn + 1;
    //  onUpdateUMn(newuMn); // Call the callback prop
      return newuMn;
    });
  };

  const updateM1InFirestore = async (newM1Value) => {
  try {
    const userDocRef = doc(db, 'users', currentUserId);
    await updateDoc(userDocRef, { M1: increment(1) });
    console.log('M1 updated successfully');
  } catch (error) {
    console.error('Error updating M1:', error);
  }
};
const decreaseM1InFirestore = async (newM1Value) => {
  try {
    const userDocRef = doc(db, 'users', currentUserId);
    await updateDoc(userDocRef, { M1: increment(-1) });
    console.log('M1 updated successfully');
  } catch (error) {
    console.error('Error updating M1:', error);
  }
};

  

  const incrementMn = () => {
    setMn(prevMn => {
      const newMn = prevMn + 1;
      updateMnInFirestore(newMn).then(() => {
        // code to be executed after the update is complete
      }).catch(error => {
        console.error('Error updating Mn:', error);
      });
      return newMn;
    });
  };
  
  const playbball = () => {
    setTimeout(() => {
      if (bballref.current) {
        bballref.current.play();
       // setbball(bballf); // or setbball(bballp)
      }
    }, 500);
  };
  
  const playwrongbball = () => {
   // setbball(bballf);
    setTimeout(() => {
      if (bballref.current) {
        bballref.current.play();
       // setbball(bballf); // or setbball(bballp)
      }
    }, 500);
  };
  const updateMnInFirestore = async (newMnValue) => {
    try {
      const userDocRef = doc(db, 'users', currentUserId);
      await updateDoc(userDocRef, { Mn: increment(1) });
      console.log('Mn updated successfully');
    } catch (error) {
      console.error('Error updating Mn:', error);
    }
  };
  


  const handleWrongMove = () => {
    setbball(bballf);
    playbball();
  };
  
  const handleWrongMove2 = () => {
    setbball(bballf);
    playbball();
  };
  const handleCorrectMove = () => {
    setbball(bballp);
    playbball();
  };
  const handleCorrectMove2 = () => {
    setbball(bballp);
    playbball();
  };
  const playhint = () => {
   
setTimeout(() => {
  if(hintref.current){
    hintref.current.play();
  }
}, 2500);

  

  };
  


  useEffect(() => {



    const one = oneRef.current;
    const equal = equalRef.current;
    const five = fiveRef.current;
    const extra = extraref.current;

    const minus = minusRef.current;
    const x = xRef.current;
    const plus = plusRef.current;
    const fivePosition = five.getBoundingClientRect().right;
    const equalPosition = equal.getBoundingClientRect().left;

    if (variableValue === 1) {
      setShowVariableValue(false);
    }

    const draggableOneInstance = Draggable.create(one, {
      type: "x",
      edgeResistance: 0.65,
     
      onDragEnd: function() {
        const onePosition = this.target.getBoundingClientRect().right;

        if (onePosition > fivePosition) {
          setTimeout(() => {
            cloneit();
          }, 1200);
          incrementM1();
          incrementuM1();
          setAns(sub);
          handleCorrectMove();
          gsap.to(upperanimref.current, { opacity: 100, duration: 0.5 });
          const place1 = extra.offsetLeft - one.offsetLeft;
          gsap.to(one, { x: place1, duration: 1.5 });
          gsap.to(one, { color: 'white', duration: 2 });
          gsap.to(one, { backgroundColor: 'black', duration: 2 });
          gsap.to(five, { color: 'white', duration: 2 });
          gsap.to(five, { backgroundColor: 'black', duration: 2 });
          gsap.to(minus, { color: 'white', duration: 2 });
          gsap.to(minus, { backgroundColor: 'black', duration: 2 });
          gsap.to(x, { x: 90, duration: 1.5 });
          gsap.to(minus, { opacity: 100, duration: 1, delay: 0.3 });
          gsap.to(plus, { opacity: 0, duration: 0.5, delay: 0.2 });

          gsap.to(one, { opacity: 0, duration: 0.5, delay: 2 });
          gsap.to(minus, { opacity: 0, duration: 0.5, delay: 2 });
          gsap.to(five, { color: 'black', duration: 1, delay: 3 });
          gsap.to(five, { backgroundColor: 'white', duration: 1, delay: 3 });
          if(variableValue===1){
finished='true';

          }
          setTimeout(() => {
            
            if (sign2 === '+') {
              const newValue = +fiveValue + +oneValue; // Convert strings to numbers
              fiveRef.current.textContent = newValue;
              
            } else if (sign2 === '-') {
              const newValue = +fiveValue - +oneValue; // Convert strings to numbers
              fiveRef.current.textContent = newValue;
             // setCompleted(true);

            }
          }, 2300);
        } else {
          handleWrongMove2();
          incrementMn();
          incrementuMn();
          setAns(wrong);
          setbball(bballf);
          playbball();
          const placeorigin = one.offsetLeft - one.offsetLeft;
          gsap.to(one, { x: placeorigin, duration: 0.5 });
          gsap.to(upperanimref.current, { opacity: 100, duration: 0.5 });
          gsap.to(upperanimref.current, { opacity: 0, duration: 0.5, delay: 1.5 });
        }

        const handleDoubleClick = () => {
        
          incrementM1();
          incrementuM1();
          if (onePosition > fivePosition) {
            if( variableValue>1){
              handleCorrectMove2();
              setbball(bballp);
              setAns(divide);
              setlow(congs);
              gsap.to(x, { backgroundColor: "black", duration: 0.5 });
              gsap.to(x, { color: "white", duration: 0.5 });
              gsap.to(x, { color: "black", duration: 0.5, delay: 0.5 });
              gsap.to(x, { backgroundColor: "", duration: 0.5, delay: 0.5 });
              console.log("double clicked");
              const xValueElement = document.createElement("div");
              xValueElement.textContent = variableValue;
              xValueElement.style.position = "absolute";
              xValueElement.style.padding = "10px";
              xValueElement.style.border = "1px solid #585757";
              xValueElement.style.color = "#585757";
              xValueElement.style.fontSize = "100";
              xValueElement.style.zIndex = "5";
              xValueElement.style.top = "420px";
              xValueElement.style.left = `${x.getBoundingClientRect().left + 15}px`;
              xValueElement.style.cursor = "move";
              document.body.appendChild(xValueElement);
  
              const draggableXValueInstance = Draggable.create(xValueElement, {
                type: "x",
                edgeResistance: 0.65,
                onDragEnd: function () {
                  const xValue = parseFloat(this.target.textContent);
                  const xValuePosition = this.target.getBoundingClientRect().left;
                  const newone = fiveRef.current.textContent;
                  if (xValuePosition > equalPosition) {
                    setTimeout(() => {
                      cloneit();
                    }, 1000);

                    gsap.to(xValueElement, { x: 102, duration: 1 });
                    handleCorrectMove();
                    setTimeout(() => {
                      const new2 = newone / xValue;
                      fiveRef.current.textContent = new2;
                      gsap.to(xValueElement, { opacity: 0, duration: 0.3, ease: "power2.inOut" });
                    }, 2000);
  
                    setTimeout(() => {
                      if (lowref.current) {
                        lowref.current.play();
                      }
                    }, 2500);
  
                    setTimeout(() => {
                      setShowVariableValue(false);
                    }, 2500);
  
                    setTimeout(() => {
                      criscross();
                    }, 300);
                    setTimeout(() => {
                      criscross2();
                    }, 1000);
  
                    this.disable();
                  } else {
                    handleWrongMove2();
                    incrementMn();
                    setAns(wrong);
                    setbball(bballf);
                    playbball();
                  }
                },
              });

            }
           
          } else {
            incrementMn();
            incrementuMn();
          }
        };
        x.addEventListener("dblclick", handleDoubleClick);

        return () => {
          x.removeEventListener("dblclick", handleDoubleClick);
        };
      },
    });

    const draggablexInstance = Draggable.create(x, {
      type: "x",
      edgeResistance: 0.65,
     
      onDragEnd: function() {
        const onePosition = this.target.getBoundingClientRect().right;

     
          const placeorigin2 = x.offsetLeft - x.offsetLeft;
          gsap.to(x, { x: placeorigin2, duration: 0.5 });
       //   gsap.to(upperanimref.current, { opacity: 100, duration: 0.5 });
        //  gsap.to(upperanimref.current, { opacity: 0, duration: 0.5, delay: 1.5 });
        setAns(wrong);
        playhint();
       // setbball(bballf);
       if(bball===bballf){
        playwrongbball();
        setTimeout(() => {
          setbball(bballf2);
        }, 3000);
console.log('first fail');
       }
       else{
       // setbball(bballf);
        setTimeout(() => {
          playwrongbball();
        }, 500);
        setTimeout(() => {
          setbball(bballf);
        }, 3000);
        console.log('second fail');

       }

     
      },
    });

    const draggablefiveInstance = Draggable.create(five, {
      type: "x",
      edgeResistance: 0.65,
     
      onDragEnd: function() {
        const onePosition = this.target.getBoundingClientRect().right;

     
          const placeorigin3 = five.offsetLeft - five.offsetLeft;
          gsap.to(five, { x: placeorigin3, duration: 0.5 });
       //   gsap.to(upperanimref.current, { opacity: 100, duration: 0.5 });
        //  gsap.to(upperanimref.current, { opacity: 0, duration: 0.5, delay: 1.5 });
     
        playhint();
        if(bball===bballf){
          playwrongbball();
          setTimeout(() => {
            setbball(bballf2);
          }, 3000);
  console.log('first fail');
         }
         else{
         // setbball(bballf);
          setTimeout(() => {
            playwrongbball();
          }, 500);
          setTimeout(() => {
            setbball(bballf);
          }, 3000);
          console.log('second fail');
  
         }     
      },
    });


    if(showhint){

      gsap.to(one, { backgroundColor: 'yellow', duration: 1 });
      gsap.to(five, { backgroundColor: '#0083FF', duration: 1 });

      gsap.to(one, { backgroundColor: 'white', duration: 1,delay:5 });
      gsap.to(five, { backgroundColor: 'white', duration: 1,delay:5 });
    }

  }, [bball,showhint]);


  useEffect(() => {
    setbball(bballf); // or setbball(bballp)
  }, [bballref]);
  
   useEffect(() => {
  

    if(variableValue<2){
      setShowVariableValue(false);
    }

   }, []);

  const crossposition = {
    position: 'absolute',
    width: '80px',
    height: '80px',
    bottom: '-30px',
    left: '240px',
    zIndex: 2
  };

  const crossposition2 = {
    position: 'absolute',
    width: '80px',
    height: '80px',
    bottom: '32px',
    left: '245px',
    zIndex: 2
  };

  const numbersposition = {
    padding: "10px",
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    border: "1px solid #000",
    userSelect: 'none',
    margin: "5px"
  };

  const criscross = () => {
    if (crossesref.current) {
      crossesref.current.play();
    }
  };

  const criscross2 = () => {
    if (crosses2ref.current) {
      crosses2ref.current.play();
    }
  };

  const criscross3 = () => {
    if (crosses3ref.current) {
      crosses3ref.current.play();
    }
  };

  const cloneit = () => {
    console.log("cloning");
    let addclone = document.getElementById("cont1");
    let mainone = document.getElementById("main");

    let clone = addclone.cloneNode(true);
    clone.id = 'theclone';
    clone.style.position = "fixed";
    clone.style.top = `${cloneTopPosition}px`; // update top position
   // setCloneTopPosition(prevPosition => prevPosition ); // increment for the next clone
    clone.style.left='1010px';

    // Access the previous clone and adjust its position
    if (clones.length > 0) {
        if(clones.length===1){
            const previousClone = clones[clones.length - 1];
            previousClone.style.top = `${parseInt(previousClone.style.top) + 170}px`;
        }
        else if(clones.length===2){
            const previousClone = clones[clones.length - 1];
            previousClone.style.top = `${parseInt(previousClone.style.top) + 270}px`;
            const previousClone2 = clones[clones.length - 2];
          

            previousClone2.style.top = `${parseInt(previousClone2.style.top) + 70}px`;
        }
        else if(clones.length===3){
            const previousClone = clones[clones.length - 1];
          

            previousClone.style.top = `${parseInt(previousClone.style.top) + 70}px`;
        }
        else if(clones.length===4){
            const previousClone = clones[clones.length - 1];
            previousClone.style.top = `${parseInt(previousClone.style.top) + 70}px`;
        }
        else if(clones.length===5){
            const previousClone = clones[clones.length - 1];
            previousClone.style.top = `${parseInt(previousClone.style.top) + 70}px`;
        }
        
    }

    // Prepend the clone to the main container
    mainone.insertBefore(clone, mainone.firstChild);

    // Update the clones state
    setClones([...clones, clone]);
}
const sendData = () => {
  const data = true;
  sendDataToParent(data);
};

  return (
    <div id= 'main' className="practicearea">
      
      <div id= 'cont1' className="values" style={{ left:'10px', position: 'fixed', display: "flex", fontSize: "30px", alignItems: 'center', padding: '30px', userSelect: 'none' }}>
        <div ref={xRef} style={{ ...numbersposition }}>
          {showVariableValue && (
            <span style={{ userSelect: 'none' }}>{variableValue}</span>
          )}
          x</div>
        <div ref={plusRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', color: 'blue', userSelect: 'none', margin: "5px" }}>
          {sign1}</div>
        <div ref={oneRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', border: "1px solid #000", userSelect: 'none', backgroundColor: onetilecolor, margin: "5px" }}>
          {oneValue}</div>
        <div ref={equalRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', color: 'blue', userSelect: 'none', margin: "5px" }}>
          =</div>
        <div>
          <Player style={{ ...crossposition }} ref={crossesref} autoplay={false} loop={false} src={crosses} />
        </div>
        <div>
          <Player style={{ ...crossposition2 }} ref={crosses2ref} autoplay={false} loop={false} src={crosses2} />
        </div>
        <div  ref={fiveRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', border: "1px solid #000", backgroundColor: fivetilecolor, userSelect: 'none', margin: "5px" }}>
          {fiveValue}
        </div>
        <div ref={minusRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', border: "1px solid #0178E9", userSelect: 'none', color: 'blue', margin: "5px", opacity: 0 }}>
          {sign2}</div>
        <div ref={extraref} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', border: "1px solid #black", userSelect: 'none', color: 'black', margin: "5px", opacity: 0 }}>
          5</div>
      </div>
      <div style={{width:'400px',height:'400px',position:'fixed',left:'10px',top:'56px'}}>
      <Player ref={bballref} autoplay={false} loop={false} keepLastFrame={true} src={bball}   // or setbball(bballp)
 />

      </div>
      <div style={{zIndex:5,scale:'0.3',position:'fixed',left:'750px',top:'365px'}}>
        <div onClick={enablehint}>
        <Player ref={hintref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={hint}   // or setbball(bballp)
 />
        </div>
            {showhint && (
              <div style={{position:'fixed',fontFamily:'helvetica',fontSize:'100px',left:'400px',bottom:'40px',color:'black', scale:'2' // Added CSS property to prevent wrapping
            }}>
               
               <Player ref={hinterref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={hinters}   // or setbball(bballp)
 />
                 </div>
            )}

      </div>
    </div>
  );
};
export default Component11m;
