import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import onemeter from '../jsons/1m.json';
import twometers from '../jsons/2m.json';
import threemetres from '../jsons/1.json';
import pointeight from '../jsons/0.json';
import pointfive from '../jsons/point5.json';
import lottie from 'lottie-web';
import { Player } from '@lottiefiles/react-lottie-player';
import gsap from 'gsap';

const Runner3 = () => {
    const [runHolder, setRunHolder] = useState(pointfive);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const animationInstance = useRef(null);
    const [tracker, setTracker] = useState(1);
    const [speed, setSpeed] = useState(1);

    const raceref = useRef(null); // Reference for face animation

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    
      
      const handleSpeedIncrease = () => {
     
      if(tracker===1){
        setRunHolder(pointeight);
        setTracker(2);

      }
      else if (tracker===2){
        setRunHolder(onemeter);
        setTracker(3);

      }
      else if (tracker===3){
        setRunHolder(twometers);

      }
    
    };

    const handleSpeedDecrease = () => {
     
        if(tracker===1){
          setRunHolder(pointfive);
          setTracker(2);
  
        }
        else if (tracker===2){
          setRunHolder(pointeight);
          setTracker(3);
        }
        else if (tracker===3){
          setRunHolder(onemeter);
        }
        
      
      };

    return (
      
        <>
        {isMobile?(
<div>

</div>
        ):(
<div>
<Container className="d-flex justify-content-center align-items-center">
<Row className="justify-content-center flex-wrap">
 <Col xs={12} md={8} lg={6} className="mb-4 d-flex justify-content-center">
   <Card style={{ width: '1300px', height: '500px', textAlign: 'center' }}>
     <Card.Header style={{ backgroundColor: '#E0DBCF' }}>
       <h3>Speed means how far you've travelled in 1 second</h3>
     </Card.Header>
     <Card.Body>
       <div style={{ userselect:'none' }}>
  
<div ref={raceref} style={{ width: '300px', height: '400px',marginLeft:'100px' }}>
<Player
          ref={raceref}
          src={runHolder}
          loop={true}
          autoplay={true}
        />
</div>


       </div>
     </Card.Body>
     <Card.Footer style={{ backgroundColor: '#E0DBCF' }}>
       <div style={{ height: '200px', fontFamily: 'helvetica', textAlign: 'center',fontSize:'18px' }}>
         <div style={{ marginTop: '20px' }}>
           <Button variant="primary" onClick={handleSpeedDecrease}>Decrease Speed</Button>
           <Button variant="primary" onClick={handleSpeedIncrease} style={{ marginLeft: '100px' }}>Increase Speed</Button>

         </div>
         <div style={{ marginTop: '20px' }}>
         </div>
         <div style={{ marginTop: '10px' }}>
           Current speed: {speed} m/s
         </div>
         <div style={{ marginTop: '10px' }}>
         <h2>

    
  
</h2>

         </div>
       </div>
     </Card.Footer>
   </Card>
 </Col>
</Row>
</Container>
</div>
        )}
        </>
    );


}

export default Runner3;