import React, { useEffect, useRef, useState } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import wait from './jsons/wait.json';
import next from './jsons/goback.json';
import './MathLearning.css';
import { gsap } from "gsap";
import Component1 from "./PracticeComponents/Component1";
import Component1m from "./PracticeComponents/Component1m";

import Component11 from "./PracticeComponents/Component11";
import mob1 from './jsons/mob1.json';
import Component3 from "./PracticeComponents/Component3";
import Component5 from "./PracticeComponents/Component5";
import crosses from './jsons/crosses1.json';
import crosses2 from './jsons/crosses2.json';
import crosses3 from './jsons/crosses1.json';
import task1 from './jsons/task1.json';
import welldone from './jsons/welldone.json';
import anequation from './jsons/anequation.json';
import variable from './jsons/variables.json';
import constants from './jsons/constants.json';
import congs from './jsons/congs.json';
import wrong from './jsons/wrongmove.json';
import Unit1 from "./LearningComponents/Unit1";
import Unit12 from "./LearningComponents/Unit12";
import Unit2 from "./LearningComponents/Unit2";
import Unit3 from "./LearningComponents/Unit3";
import Unit32 from "./LearningComponents/Unit32";
import nextbutton from './jsons/nextbtnlong.json';
import backbutton from './jsons/goback.json';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import mswipe from './jsons/mssswipe1.json';
import ok from './jsons/ok.json';
import not from './jsons/not.json';
import msound from './jsons/swipesound2.mp3'
import finalsound from './jsons/ssound2.mp3';
import likemath from './jsons/likemath.json';
import likemathsound from './jsons/likemath.mp3';
import yes from './jsons/yes.json';
import no from './jsons/no.json';
import branches from'./jsons/made of branches.json';
import branchesound from'./jsons/branches.mp3';
import algebra from'./jsons/definitionofalgebra.json';
import algebrasound from'./jsons/algebrais.mp3';
import variabldefsound from'./jsons/variabledefinition.mp3';
import definitionvariable from './jsons/definitionvariable.json';
import wave from './jsons/wav2.mp3';
import n2 from'./jsons/2n.json';
import n2sound from'./jsons/2n.mp3';
import equation from './jsons/equation.json';
import solvingx from './jsons/solvingx.json';
import solvingxsound from './jsons/solvingx.mp3';
import equationsound from './jsons/equationsound.mp3';
import sides from './jsons/sides.json';
import sidesounds from './jsons/sidesounds.mp3';
import takeright from './jsons/takeright.json';
import takerightsounds from './jsons/takeright.mp3';
import utry from './jsons/utry.json';
import utrysounds from './jsons/utry.mp3';
import drag1 from './jsons/drag1.mp3';
import tryquestion from './jsons/tryquestion.mp3';
import yis1 from './jsons/yis1.json';
import yis1sound from './jsons/yis1.mp3';
import div2 from './jsons/div2.json';
import div2sound from './jsons/div2.mp3';
import utryn from './jsons/utryn.json';
import utrynsound from './jsons/utryn.mp3';
import doubleclicking from './jsons/doubleclicking.mp3';
import rpt from './jsons/rpt.json';
import sorry from './jsons/sorry.json';
import sorrysound from './jsons/sorry.mp3';
import dressup from './jsons/dressup.json';
import favourite from './jsons/pickfavorites.json';
import somethingsound from './jsons/somethingelse.mp3';
import dress1 from './jsons/dressup.json';
import dressoption1 from './jsons/dressed1.json';
import dressoption2 from './jsons/dressed2.json';
import two from './jsons/02.json';
import { Card } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import one from './jsons/01.json';
import science from './jsons/scienceicon.json';
import business from './jsons/businessicon.json';
import pickone from './jsons/pickone.mp3';
import pick1 from './jsons/pickone.json';
import clothes from './jsons/clothes.json';
import shoes from './jsons/shoespicked.json';
import eorm from './jsons/eorm.mp3';
import shoeshirt from './jsons/shoeorshirt.mp3';
import mechanics from './jsons/mechanics.json';
import electricity from './jsons/electricity.json';
import sc1 from './jsons/sc1.json';
import sc1sound from './jsons/sc1.mp3';
import tutorial1 from './jsons/tutorial1.json';
import tutorial1sound from './jsons/tsound.mp3';
import mobswipe1 from './jsons/mobswipe1.json';
import mobswipe2 from './jsons/mobswipe2.json';
import mobswipe3 from './jsons/mobswipe3.json';
import mobswipe4 from './jsons/mobswipe4.json';
import mobswipe5 from './jsons/mobswipe5.json';
import Unit2m from "./LearningComponents/Unit2m";
import Component11m from "./PracticeComponents/Component11m";
import Unit3m from "./LearningComponents/Unit3m";
import CircularButton from "./bootsrap elements/CircularButton";
import CircularButtonback from "./bootsrap elements/CircularButtonback";
import Component10 from "./PracticeComponents/Component10";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc,updateDoc,onSnapshot } from 'firebase/firestore';
import ProgressBar from './LoadingPages/ProgressBar';

const auth = getAuth();
const db = getFirestore();

const MathLearning = () =>{
  const navigate = useNavigate();


    const tag3ref = useRef(null);
    const audioref = useRef(null);
    const newref = useRef(null);
    const newref2 = useRef(null);
    const newref3 = useRef(null);
    const newrefm = useRef(null);
    const equalRef = useRef(null);
    const m3ref = useRef(null);
    const nextRef = useRef(null);
    const next2Ref = useRef(null);
    const doneref = useRef(null);
    const minusref = useRef(null);
    const negateref = useRef(null);
    const scrollRef = useRef(null);
    const windowref = useRef(null);
    const okref = useRef(null);
    const okref2 = useRef(null);
    const audioref3 = useRef(null);
    const [buttonmain, setbuttonmain] = useState(nextbutton);
    const [dresscounter, setdresscounter] = useState(1);
    const [change, setchange] = useState(one);
    const [scdiv, setscdiv] = useState(false);
    const [userUID, setUserUID] = useState(null); // Store the user UID
    const [progressvalue, setprogressvalue] = useState(0);

    const rptref = useRef(null);
    const faveref = useRef(null);
    const dressref = useRef(null);
    const oneref = useRef(null);
    const leftref = useRef(null);
    const rightref = useRef(null);

    const notref = useRef(null);
    const crosses2ref = useRef(crosses2);
    const narratorref = useRef(null);
    const upperanimref = useRef(task1);
    const nextbuttonref = useRef(null);
    const backbuttonref = useRef(backbutton);
    const [unit, setUnit] = useState(1); // State to track the visibility of the minus box
    const [counter, setcounter] = useState(1); // State to track the visibility of the minus box
    const [unloggedscore, setunloggedscore] = useState(0);
    const [unloggedfail, setunloggedfail] = useState(0);
    const [setside2, setsetside2] = useState(false); // State to track the visibility of the minus box
    
    const [isfun, setisfun] = useState(false);
    const [options, setoptions] = useState(false); // State to track the visibility of the minus box
    const [faveoptions, setfaveoptions] = useState(false); // State to track the visibility of the minus box
    const [redpill, setredpill] = useState(business);
    const [bluepill, setbluepill] = useState(science);
    const [playingm, setplayingm] = useState(mobswipe1);

    const [playing, setplaying] = useState(mswipe);
    const [sounding, setsounding] = useState(msound);
    const [answers, setanswers] = useState(false);
    const [firstplace, setfirstplace] = useState(ok);
    const [secondplace, setsecondplace] = useState(not);
    const [secondtile, setsecondtile] = useState(solvingx);
    const [bottomtile, setbottomtile] = useState(equation);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const crossesref = useRef(crosses);
    const crosses3ref = useRef(crosses);
   
   

    useEffect(() =>{
if(answers){
  gsap.to(okref2.current, { opacity: 100, duration:1, delay:1 });
  //gsap.to(notref.current, { visibility: 'hidden', duration:1, delay:1 });
}
    
    },[counter]);
  
   
    
    useEffect(() => {
      if (counter >18) {
        navigate('/mlearn');
      }
    }, [counter, navigate]);

    useEffect(() => {
      console.log('counter is', counter);
    }, [counter]);
    
    const thebottomslower = {
        flex: 1,
        position: "relative",
        background:"white",
        height: "418px",
        padding:"0px",
        width: "908px",
        marginBottom: "150px",
        marginTop: "110px",
        display:"flex",
        marginLeft: "340px",
        zindex:2 
      }; 
      
      const funstuff = () =>{
        setanswers(false);
        setisfun(true);
        setplaying(sorry);
        setsounding(sorrysound);
        setTimeout(() => {
  if (newref.current) {
    newref.current.play();
  }
  if (audioref.current) {
    audioref.current.play();
  }
}, 100); 
      }
      const repeatanimation = () => {
        if (newref.current) {
          newref.current.stop();  // Reset the animation
          newref.current.play();  // Play the animation from the start
        }
        if (audioref.current) {
          
          audioref.current.play();  // Play the audio
        }
       // setcounter(counter - 1);
        playnarrator();
        playsound();
      };
      
      const goback = () => {
        // Decrement the counter first
        setcounter((prevCounter) => {
          const newCounter = prevCounter - 1;
      
          // Set the states based on the updated counter
          if (newCounter === 2) {
           
              setplaying(mob1);

         
           
            setsounding(msound);
          } else if (newCounter === 3) {
            setplaying(branches);
            setsounding(branchesound);
          } else if (newCounter === 4) {
            setplaying(likemath);
            setsounding(likemathsound);
            setfirstplace(yes);
            setsecondplace(no);
          } else if (newCounter === 5) {
            setplaying(algebra);
            setsounding(algebrasound);
          } else if (newCounter === 6) {
            setplaying(definitionvariable);
            setsounding(variabldefsound);
          } else if (newCounter === 7) {
            setsounding(equationsound);
          } else if (newCounter === 8) {
            setbottomtile(sides);
            setsounding(sidesounds);
            setsetside2(true);
          } else if (newCounter === 9) {
            setbottomtile(takeright);
            setsounding(takerightsounds);
          } else if (newCounter === 10) {
            setbottomtile(utry);
            setsounding(utrysounds);
          } else if (newCounter === 11) {
            setsounding(drag1);
          } else if (newCounter === 12) {
            setsounding(tryquestion);
          } else if (newCounter === 13) {
            setplaying(yis1);
            setsounding(yis1sound);
          } else if (newCounter === 14) {
            setplaying(div2);
            setsounding(div2sound);
          } else if (newCounter === 15) {
            setplaying(utryn);
            setsounding(utrynsound);
          } else if (newCounter === 16) {
            setsounding(doubleclicking);
          }
      
          return newCounter;
        });
      
        // Stop the current animation and sound
        if (newref.current) {
          newref.current.stop();
        }
        if (audioref.current) {
          audioref.current.pause();
          audioref.current.currentTime = 0;
        }
      
        // Play the new animation and sound after state updates
        setTimeout(() => {
          if (newref.current) {
            newref.current.play();
          }
          if (audioref.current) {
            audioref.current.play();
          }
        }, 100); // Slight delay to ensure state updates before playing
      };
      
      
      const updateselect = async (selectiontype) => {
        try {
            const response = await fetch('/api/update-m1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ selection: selectiontype }),
            });

            if (!response.ok) {
                throw new Error('Failed to update M1 in the database');
            }

            const data = await response.json();
            console.log('selection updated successfully:', data);
        } catch (error) {
            console.error('Error updating selection in the database:', error);
        }
    };
      

      const placeanswers = () =>{
        //gsap.to(okref2.current, { visibility: 'visible', duration:1, delay:1 });
        //gsap.to(notref.current, { visibility: 'hidden', duration:1, delay:1 });

        console.log('supposed to be showing')

      }
      
      const playnextscene = () =>{
        //setcounter(counter+1);
       // placeanswers()
       setprogressvalue(progressvalue+5);

        setTimeout(() => {
          setanswers(false)
        }, 1000);
      
      

       
if(counter===1){
  setcounter(counter+1);

  setTimeout(() => {
    setanswers(true);
  }, 3500);
  console.log('the counter is on ', counter);

}
if(counter===3){
  setcounter(counter+1);
  setanswers(false);
  setplaying(branches);
  console.log('the counter is on ', counter);

  

  setTimeout(() => {
    setsounding(branchesound)

  }, 500);
  setTimeout(() => {
    if(audioref.current){
      audioref.current.play();
    }
  }, 600);
}
else if(counter===2){
  setcounter(counter+1);
  console.log('the counter is on ',counter);

  setsounding(likemathsound);
  setplaying(likemath);
  setTimeout(() => {
    setfirstplace(yes);
    setsecondplace(no);
  }, 1200);
  console.log('the counter is on ', counter);


        setTimeout(() => {
          setanswers(true);
        }, 2500);
}
else if(counter===4){
  setcounter(counter+1);
  setplaying(algebra);
  setsounding(algebrasound);
  console.log('the counter is on ',counter);
}
else if(counter===5){
  
  setcounter(counter+1);
  setplaying(definitionvariable);
  setsounding(variabldefsound);
  console.log('the counter is on ', counter);
  setTimeout(() => {
    if(newref2.current){
      newref2.current.play();
    }
    setsounding(solvingxsound);

  }, 10000);

}
else if(counter===6){
  
  setcounter(counter+1);

  setsounding(equationsound);
  console.log('the counter is on ',counter);
}
else if(counter===7){
  console.log('7 counter is on ');
  setbottomtile(sides)
  setsounding(sidesounds)
  setsetside2(true);
  setcounter(counter+1);

}
 if(counter===8){
  setcounter(counter+1);
  console.log('8 counter is on ');
  setbottomtile(takeright)
  setsounding(takerightsounds)

}
if(counter===9){
  setcounter(counter+1);
  console.log('9 counter is on ');
  setbottomtile(utry)
  setsounding(utrysounds)

}
if(counter===10){
  setcounter(counter+1);
  console.log('10 counter is on ');
  setTimeout(() => {
    setsounding(drag1)

  }, 2000);

}
if(counter===11){
  setcounter(counter+1);
  console.log('10 counter is on ');
     setsounding(tryquestion)
 

}
if(counter===12){
  setcounter(counter+1);
  console.log('12 counter is on '); 
  setplaying(yis1);
  setsounding(yis1sound)

}
if(counter===13){
  setcounter(counter+1);
  console.log('13 counter is on '); 
  setplaying(div2);
  setsounding(div2sound)

}
if(counter===14){
  setcounter(counter+1);
  console.log('14 counter is on '); 
  setplaying(utryn);
  setsounding(utrynsound)

}
if(counter===15){
  setcounter(counter+1);
  console.log('15 counter is on '); 
  setsounding(doubleclicking);

}
if(counter===16){
  setcounter(counter+1);
  console.log('16 counter is on '); 

}
if(counter===17){
  setcounter(counter+1);
  console.log('17 counter is on '); 

}if(counter===18){
  setcounter(counter+1);
  console.log('18 counter is on '); 

}
if(counter===19){
  
  setcounter(counter+1);
  console.log('18 counter is on ');

}

else{
  console.log('failed');
}

setTimeout(() => {
  if(newref.current){
    newref.current.play();
  }
 
  
}, 1000);
setTimeout(() => {
  if(audioref.current){
    audioref.current.play();        }
}, 10);

      }
      const playnextscenem = () =>{
    

       
if(counter===1){
  setcounter(counter+1);

  setTimeout(() => {
    setanswers(true);
  }, 3500);
  console.log('the counter is on ', counter);

}
if(counter===3){
  setcounter(counter+1);
  setanswers(false);
  setplayingm(mobswipe3);
  console.log('the counter is on ', counter);

  

  setTimeout(() => {
    setsounding(branchesound)

  }, 500);
  setTimeout(() => {
    if(audioref.current){
      audioref.current.play();
    }
  }, 600);
}
else if(counter===2){
  setcounter(counter+1);
  console.log('the counter is on ',counter);

  setsounding(likemathsound);
  setplayingm(mobswipe2);
  setTimeout(() => {
    setfirstplace(yes);
    setsecondplace(no);
  }, 1200);
  console.log('the counter is on ', counter);


        setTimeout(() => {
          setanswers(true);
        }, 2500);
}
else if(counter===4){
  setcounter(counter+1);
  setplayingm(mobswipe4);
  setsounding(algebrasound);
  console.log('the counter is on ',counter);
}
else if(counter===5){
  
  setcounter(counter+1);
  setplayingm(mobswipe5);
  setsounding(variabldefsound);
  console.log('the counter is on ', counter);
  setTimeout(() => {
    if(newref2.current){
      newref2.current.play();
    }
    setsounding(solvingxsound);

  }, 10000);

}
else if(counter===6){
  
  setcounter(counter+1);

  setsounding(equationsound);
  console.log('the counter is on ',counter);
}
else if(counter===7){
  console.log('7 counter is on ');
  setbottomtile(sides)
  setsounding(sidesounds)
  setsetside2(true);
  setcounter(counter+1);

}
 if(counter===8){
  setcounter(counter+1);
  console.log('8 counter is on ');
  setbottomtile(takeright)
  setsounding(takerightsounds)

}
if(counter===9){
  setcounter(counter+1);
  console.log('9 counter is on ');
  setbottomtile(utry)
  setsounding(utrysounds)

}
if(counter===10){
  setcounter(counter+1);
  console.log('10 counter is on ');
  setTimeout(() => {
    setsounding(drag1)

  }, 2000);

}
if(counter===11){
  setcounter(counter+1);
  console.log('10 counter is on ');
     setsounding(tryquestion)
 

}
if(counter===12){
  setcounter(counter+1);
  console.log('12 counter is on '); 
  setplaying(yis1);
  setsounding(yis1sound)

}
if(counter===13){
  setcounter(counter+1);
  console.log('13 counter is on '); 
  setplaying(div2);
  setsounding(div2sound)

}
if(counter===14){
  setcounter(counter+1);
  console.log('14 counter is on '); 
  setplaying(utryn);
  setsounding(utrynsound)

}
if(counter===15){
  setcounter(counter+1);
  console.log('15 counter is on '); 
  setsounding(doubleclicking);

}
if(counter===16){
  setcounter(counter+1);
  console.log('16 counter is on '); 

}
if(counter===17){
  setcounter(counter+1);
  console.log('17 counter is on '); 

}if(counter===18){
  setcounter(counter+1);
  console.log('18 counter is on '); 

}if(counter===19){
  navigate('/mlearn3');

}

else{
  console.log('failed');
  navigate('/mlearn3');

}

setTimeout(() => {
  if(newref.current){
    newref.current.play();
  }
 
  
}, 1000);
setTimeout(() => {
  if(audioref.current){
    audioref.current.play();        }
}, 10);

      }
      
      const playsound = () =>{

        if(audioref.current){
          audioref.current.play()
        }
      }
      const playright = () =>{

        if(rightref.current){
          rightref.current.play()
        }
      }
      const playleft = () =>{

        if(leftref.current){
          leftref.current.play()
        }
      }

      setTimeout(() => {
        
      }, 500);
      const playnarrator = () =>{

        if(newref.current){
          newref.current.play()
        }
      }
      const playnarratorm = () =>{
setTimeout(() => {
  if(newrefm.current){
    newrefm.current.play()
  }
}, 500);
        
      }

      const next = () => {
        if(counter>18){
          navigate('/mlearn');

        }
        console.log('next clicked');
//wait scene 
if (!isDisabled) {
  setbuttonmain(wait);
  if(nextbuttonref.current){
    nextbuttonref.current.play();
  }
  console.log("Button clicked");
  setIsDisabled(true);
  setTimeout(() => {
    setIsDisabled(false);
    setbuttonmain(nextbutton);

  }, 2500);
} 





        setfaveoptions(false);
        console.log('the counter is on ', {counter});
        setoptions(false);
        setisfun(false);
          if(audioref3.current){
            audioref3.current.play();
          }
      playnarrator();
      playnarratorm();

      if(newref3.current){
        newref3.current.play();
      }

      
        playnextscene();

     

        if (unit === 50) {
            navigate('/select');
        } else {
            setUnit(unit + 1);
        }
    }
    const handleAudioLoad = () => {
      if (audioref.current) {
        if(counter>1){
          audioref.current.play().catch((error) => {
            console.error("Audio play interrupted:", error);
          });
        }
       
      }
    };
    const handleAudioLoad3 = () => {
      if (audioref3.current) {
        if(counter>1){
          audioref3.current.play().catch((error) => {
            console.error("Audio play interrupted:", error);
          });
        }
       
      }
    };
    const handleAnimationLoad = () => {
      if (newref.current) {
        newref.current.play().catch((error) => {
          console.error("Animation play interrupted:", error);
        });
      }
    };
    const dressoptions = () => {
     setoptions(true);
     setisfun(false);
     console.log('options');

    };
    const favestart = () => {
      setfaveoptions(true);
      setoptions(false);
      setanswers(false);
      setisfun(false);
      setTimeout(() => {
        setsounding(pickone);
        setplaying(pick1);
      }, 500);
      setTimeout(() => {
        if(newref.current){
          newref.current.play()
        }
      }, 800);
     };
    
     const businesset = () => {
      try{
      updateselect('business');

      }
      catch(error){
        console.error('Error updating select :', error);

      }

      if(rightref.current){
        rightref.current.play();
      }

      setTimeout(() => {
        setredpill(clothes);
        setbluepill(shoes);
      }, 3600);

      setTimeout(() => {
        setsounding(shoeshirt);


      }, 1000);
     console.log('business set')
    }


    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
  };



    const scienceset = () => {
      if(leftref.current){
        leftref.current.play();
      }
      setTimeout(() => {
        setredpill(mechanics);
        setbluepill(electricity);
      }, 3600);
     
      console.log('science set')
      setTimeout(() => {
        setsounding(eorm);

      }, 1000);

     }

    const playoption1 = () => {
if (dresscounter===1)
{
  setplaying(dressoption1);
}   
else if(dresscounter===2) {
  setchange(two);

  setplaying(dressoption2);

}  



      setTimeout(() => {
        if(newref.current){
          newref.current.play();
          setdresscounter(dresscounter+1);
        }
        if(oneref.current){
          oneref.current.play();
        }
      }, 500);
     };
     const buttonStyle = {
      margin: "10px",
      padding: "20px 20px",
      borderRadius: "50px",
      backgroundColor: "#061FBE",
      color: "white",
      border: "none",
      cursor: "pointer",
      fontFamily: "Helvetica",
      fontWeight: "bold",
      fontSize: "26px"
    };
return(
<div>

   {isMobile? (
    <div style={{display: "flex",background:"white",overflow:"hidden", position:'relative'}}>
         <div style={{ 
    position: 'fixed', 
    top: 0, 
    left: 0, 
    width: '100%', 
    height: '30px', 
    backgroundColor: '#262B4D', 
    padding: '3px', 
    zIndex: 1000, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center' 

    }}> 
    <Link to ='../test'>

    <div style={{ color:"#FFFFFF",userSelect:'none' }}>
    <h4>
MATH SWIPE
    </h4>
    </div>
    </Link>
    </div>
   
   <div>
    <Player>
    <audio ref={audioref} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
    </Player>
   </div>
   <div>
    <Player>
    <audio ref={audioref3} src={wave} type="audio/mp3" onLoadedData={handleAudioLoad3}/>

    </Player>
   </div>
    
    <div ref={windowref} style={{ position:"fixed",  marginRight: '10px',height:"100vh",width:"100vw",backgroundColor:"#ffeca1" }}>
    
   


 <div  style={{ position:'fixed',top:'15px',left:'120px', scale:'1.3', alignContent:'center',justifyContent:"center",zindex:1,borderRadius:'15px'}}>
   

 
  
</div>



  
    
   
   
 
      </div>

     
       {counter===11 && (
        <div>
<Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
<Row className="justify-content-center flex-wrap">
<Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
<Card style={{ width: 'auto' }}>

<Card.Body className="text-center">

 <div>
<Unit2m value1={1} value2={2}/> 

 </div>
 </Card.Body>
 </Card>
 </Col>
 </Row>
 </Container>


 </div>
      )}
       {counter===12 && (
        <div>
     <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
     <Row className="justify-content-center flex-wrap">
     <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
    
     <Card style={{ width: 'auto',marginBottom:'500px' }}>
    

<div >
<Component1m variableValue={1} oneValue={2} fiveValue={3} sign1={'-'} sign2={'+'}/> 
 </div>



 </Card>
 </Col>
 </Row>
 </Container>

        </div>
       
 
 
      )}
          {counter>12 && counter<16 && (
            <div>
<Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
<Row className="justify-content-center flex-wrap">
<Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
<Card style={{ width: 'auto' }}>
<Card.Header>
<div style={{ width: '200px',marginTop:'8px',marginLeft:'5px' }}> 
<ProgressBar progress={progressvalue} label="Completed"/>

</div>
</Card.Header>
<Card.Body className="text-center">

               <div ref={narratorref} >

<Player ref={newref} loop={false} autoplay={false} keepLastFrame={true} src={playing} />
 </div>
 </Card.Body>
 </Card>
 </Col>
 </Row>
 </Container>


              </div>
     
 
      )}
         {counter===16 && (
          <div>
                 <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
                 <Row className="justify-content-center flex-wrap">
                 <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
                 <Card style={{ width: 'auto' }}>
                 <Card.Body className="text-center">

<div >
<Unit3m value1={3} value2={6}/> 
 </div>

 </Card.Body>
 </Card>
 </Col>
 </Row>
 </Container>

          </div>
 

      )}
        {counter===17 && (
 <div >

<Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
                 <Row className="justify-content-center flex-wrap">
                 <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
                 <Card style={{ width: 'auto' }}>
                 <Card.Body className="text-center">

<div >
<Unit3m value1={2} value2={8}/> 
 </div>

 </Card.Body>
 </Card>
 </Col>
 </Row>
 </Container> </div>

      )}
       {counter===18 && (
 <div >

<Component10 variableValue={2} oneValue={1} fiveValue={3} sign1={'-'} sign2={'+'}/> 
 </div>

      )}
        {counter===19 && (
 <div >

<Component10 variableValue={3} oneValue={2} fiveValue={11} sign1={'+'} sign2={'-'}/> 
 </div>

      )}
     
    
     
    
       {counter<11 &&  (
<div>
<Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
  <Row className="justify-content-center flex-wrap">
    <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
      <Card style={{ width: 'auto' }}>
      <Card.Header>
<div style={{ width: '200px',marginTop:'8px',marginLeft:'5px' }}> 
<ProgressBar progress={progressvalue} label="Completed"/>

</div>
</Card.Header>
        <Card.Body className="text-center">
          <div> 

          <Player
            ref={newref}
            autoplay={false}
            loop={false}
            keepLastFrame={true}
            hover={false}
            src={playing}
            aspectRatio="3:4"
            style={{ width: '350px', height: '250px' }}
          />
                    </div>

        </Card.Body>
      </Card>
    </Col>

    {scdiv && (
      <Col xs={12} className="d-flex justify-content-center">
        <div style={{ position: 'relative', zIndex: 10 }}>
          <Unit32 style={{ zIndex: 10 }} value1={5} value2={50000} />
        </div>
      </Col>
    )}

    {answers && (
      <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
        <Card style={{ width: 'auto' }}>
          <Card.Footer>
            <div style={{ display: 'flex' }}>
              <div ref={okref2} onClick={playnextscene}>
                <Player ref={okref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={firstplace} style={{ width: '180px', height: '70px' }} />
              </div>
              <div onClick={funstuff} ref={notref}>
                <Player ref={notref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={secondplace} style={{ width: '180px', height: '70px' }} />
              </div>
            </div>
          </Card.Footer>
        </Card>
      </Col>
    )}

    {isfun && (
      <Col xs={12} className="mb-4 d-flex justify-content-center">
        <div style={{display:'flex'}}>
          <div ref={okref2} onClick={dressoptions} style={{ zIndex: 3, transform: 'scale(1.2)', opacity: '100', marginBottom: '20px' }}>
            <Player ref={dressref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={dress1} />
          </div>
          <div onClick={favestart} ref={notref} style={{ transform: 'scale(1.2)' }}>
            <Player ref={faveref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={favourite} />
          </div>
        </div>
      </Col>
    )}


{options && (
        <Col xs={12} className="mb-4 d-flex justify-content-center">
  <Card style={{ width: 'auto' }}>
  <Card.Footer>
 <div onClick={playoption1} >
      <Player style={{width:'150px',}} ref={oneref} autoplay={false} loop={false} keepLastFrame={true}  src={change} />

   </div>
   </Card.Footer>
   </Card>
   </Col>

)}  

{faveoptions && (
          <Col xs={12} className="mb-4 d-flex justify-content-center">
  <Card style={{ width: 'auto' }}>
  <Card.Footer>

   <div style={{display:'flex'}}>
<div onClick={scienceset}  >
      <Player  ref={leftref} autoplay={false} loop={false} keepLastFrame={true}   src={bluepill} />

   </div>
    <div onClick={businesset}  >
    <Player  ref={rightref} autoplay={false} loop={false} keepLastFrame={true}    src={redpill} />

 </div>
   </div>
   </Card.Footer>
   </Card>
   </Col>


)} 

    {counter > 5 && counter < 11 && (
      <Col xs={10} className="mb-5 d-flex justify-content-center">
        <div style={{ marginBottom: '10px',height:'45px' }}>
          <Player ref={newref2} loop={false} autoplay={false} keepLastFrame={true} src={secondtile} />
        </div>
      </Col>
    )}

    {counter > 6 && counter < 11 && (
      <Col xs={10} className="mb-10 d-flex justify-content-center" >
        <div >
          <Player ref={newref3} loop={false} autoplay={true} keepLastFrame={true} src={bottomtile} />
        </div>
      </Col>
    )}
  </Row>
</Container>

</div>
     )}
    
        {scdiv && (
          <div>
            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
            <Row className="justify-content-center flex-wrap">
            <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
            <Card style={{ width: 'auto' }}>
            <Card.Body className="text-center">

 <div >
 <Unit32 style={{zindex:10}} value1={5} value2={50000} />

</div>

</Card.Body>
</Card>
</Col>
</Row>
</Container>

</div>
     )}
<div>

<div>

</div>



<Container fluid className="fixed-bottom bg-light p-3">

<Row >

  <Col xs={12} md={4} lg={5}  >
    <Card style={{ width: 'auto' }}>
      <Card.Footer className="d-flex justify-content-between">
           
            <CircularButtonback onClick={goback}>


            </CircularButtonback>
            <div onClick={repeatanimation} style={{ cursor: 'pointer' }}>
                <Player
                  ref={rptref}
                  autoplay={false}
                  loop={false}
                  keepLastFrame={true}
                  hover={true}
                  src={rpt}
                  style={{ width: '100px', height: '70px' }}
                />
              </div>    
              
                      <CircularButton className='ml-auto' onClick={next}>
              
              </CircularButton>
      </Card.Footer>
    </Card>
  </Col>



  
</Row>
</Container>
</div>

     

  



   
   
   

    </div>
   ): (
<div style={{display: "flex",background:"white",overflow:"hidden", position:'relative'}}>
         <div style={{ 
    position: 'fixed', 
    top: 0, 
    left: 0, 
    width: '100%', 
    height: '20px', 
    backgroundColor: '#000E75', 
    padding: '3px', 
    zIndex: 1000, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center' 

    }}> 
    <Link to ='../test'>

    <div style={{ color:"#FFFFFF",userSelect:'none' }}>
    <h4>
MATH SWIPE
    </h4>
    </div>
    </Link>
    </div>
   
   <div>
    <Player>
    <audio ref={audioref} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
    </Player>
   </div>
   <div>
    <Player>
    <audio ref={audioref3} src={wave} type="audio/mp3" onLoadedData={handleAudioLoad3}/>

    </Player>
   </div>
    
    <div ref={windowref} style={{ position:"fixed",  marginRight: '10px',height:"100vh",width:"100vw",backgroundColor:"#E7E5D4" }}>
    
   


 <div  >
   
{answers && (
 <div>
<div ref={okref2} onClick={playnextscene} style={{zindex:3,scale:'1.2', position:'fixed', left:'650px',top:'80px',opacity:'100'}}>
<Player ref={okref} autoplay={false} loop={false} keepLastFrame={true} hover={true}  src={firstplace} />

</div>
<div onClick={funstuff} ref={notref} style={{scale:'1.2', position:'fixed', left:'650px',top:'200px'}}>
<Player ref={notref} autoplay={false} loop={false} keepLastFrame={true} hover={true}  src={secondplace} />

</div>  
 </div>
)}
 
  
  
</div>



  
    
   
   
 
      </div>

      {counter>5 &&counter<11 && (
 <div style={{zindex:7, width:'400px',height:'300px', position:'fixed',left:'1100px',bottom:'405px'}}>
 <Player ref={newref2} loop={false} autoplay={false} keepLastFrame={true} src={secondtile} />
 
 </div>
      )}
        {counter>6 && counter<11 && (
 <div style={{zindex:4, width:'400px',height:'300px',position:'fixed',left:'1100px',bottom:'205px'}}>
 <Player ref={newref3} loop={false} autoplay={true} keepLastFrame={true} src={bottomtile} />
 
 </div>
      )}
       {counter===11 && (
 <div style={{zindex:7, position:'fixed',left:'700px',bottom:'400px'}}>

<Unit2 value1={1} value2={2}/> 
 </div>

      )}
       {counter===12 && (
 <div style={{zindex:7, position:'fixed',left:'700px',bottom:'400px'}}>

<Component11 variableValue={1} oneValue={2} fiveValue={3} sign1={'-'} sign2={'+'}/> 
 </div>
 
      )}
          {counter>12 && counter<16 && (
            
      <div ref={narratorref} style={{zindex:7, scale:'0.6',position:'fixed',left:'227px',bottom:'-28px'}}>

<div style={{ width: '200px',marginTop:'8px',marginLeft:'5px' }}> 
<ProgressBar progress={progressvalue} label="Completed"/>

</div>

<Player ref={newref} loop={false} autoplay={false} keepLastFrame={true} src={playing} />
 </div>
 
      )}
         {counter===16 && (
 <div style={{zindex:7, position:'fixed',left:'700px',bottom:'400px'}}>

<Unit3 value1={3} value2={6}/> 
 </div>

      )}
        {counter===17 && (
 <div style={{zindex:7, position:'fixed',left:'700px',bottom:'400px'}}>

<Unit3 value1={2} value2={8}/> 
 </div>

      )}
       {counter===18 && (
 <div style={{zindex:7, position:'fixed',left:'900px',bottom:'400px'}}>

<Component11 variableValue={2} oneValue={1} fiveValue={3} sign1={'-'} sign2={'+'}/> 
 </div>

      )}
        {counter===19 && (
 <div style={{zindex:7, position:'fixed',left:'700px',bottom:'400px'}}>

<Component11 variableValue={3} oneValue={2} fiveValue={11} sign1={'+'} sign2={'-'}/> 
 </div>

      )}
     
      
     
    
       {counter<11 &&  (
      <div style={{position:'fixed',left:'500px'}} >
<Container style={{ minHeight: '100vh' }}>
<Row >
  {/* Card 1 */}
  <Col xs={10} md={4} lg={5}  >
    <Card style={{ width: '600px',marginRight:'600px',marginTop:'100px' }}>
      <Card.Body >
        <Player
        ref={newref}
          autoplay={false}
          loop={false}
          keepLastFrame={true}
          hover={false}
          src={playing}
          aspectRatio="3:4"
          style={{
            width:'500px',height:'500px'
           }}
        />
       
      </Card.Body>
    </Card>
  </Col>

  {scdiv && (
      <Col xs={12} className="d-flex justify-content-center">
        <div style={{ position: 'relative', zIndex: 10 }}>
          <Unit32 style={{ zIndex: 10 }} value1={5} value2={50000} />
        </div>
      </Col>
    )}

    {answers && (
      <Col xs={12} md={4} lg={5} >
        <Card style={{ width: '200px',height:'250px',marginTop:'250px',marginLeft:'100px' }}>
          <Card.Footer>
            <div style={{ display: 'flex' ,flexDirection:'column' }}>
              <div ref={okref2} onClick={playnextscene}>
                <Player ref={okref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={firstplace} style={{ width: '180px', height: '100px' }} />
              </div>
              <div onClick={funstuff} ref={notref}>
                <Player ref={notref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={secondplace} style={{ width: '180px', height: '100px' }} />
              </div>
            </div>
          </Card.Footer>
        </Card>
      </Col>
    )}

    {isfun && (
      <Col xs={12} md={4} lg={5} >
        <Card style={{ width: '200px',height:'250px',marginTop:'250px',marginLeft:'100px' }}>

        <div style={{display:'flex',flexDirection:'column'}}>
          <div ref={okref2} onClick={dressoptions} style={{ zIndex: 3,  opacity: '100', marginBottom: '20px' }}>
            <Player ref={dressref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={dress1} />
          </div>
          <div onClick={favestart} ref={notref}>
            <Player ref={faveref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={favourite} />
          </div>
        </div>
        </Card>

      </Col>
    )}

{options && (
      <Col xs={12} md={4} lg={5} >
        <Card style={{ width: '300px',height:'250px',marginTop:'250px',marginLeft:'100px' }}>
        <Card.Footer>

 <div onClick={playoption1} >
      <Player style={{width:'200px'}}ref={oneref} autoplay={false} loop={false} keepLastFrame={true}  src={change} />

   </div>
   </Card.Footer>

   </Card>

   </Col>

)} 
 {faveoptions && (
        <Col xs={12} md={4} lg={5} >
        <Card style={{ width: '300px',marginTop:'150px',marginLeft:'100px' }}>
        <Card.Footer>

   <div style={{display:'flex',flexDirection:'column'}}>
<div onClick={scienceset}  >
      <Player style={{height:'200px',}} onClick={scienceset} ref={leftref} autoplay={false} loop={false} keepLastFrame={true}   src={bluepill} />

   </div>
    <div onClick={businesset} >
    <Player style={{height:'200px',}} onClick={businesset} ref={rightref} autoplay={false} loop={false} keepLastFrame={true}    src={redpill} />

 </div>
   </div>
   </Card.Footer>
   </Card>
   </Col>

)} 
  
</Row>
</Container>
</div>
     )}
    
        {scdiv && (
 <div style={{zindex:10, position:'fixed',left:'1000px',bottom:'200px'}}>
 <Unit32 style={{zindex:10}} value1={5} value2={50000} />

</div>
     )}

    
{counter>18? (
 <div style={{ position:'fixed',zindex:7,left:'900px',bottom:'10px' }} className="nextb2">
  <Link to='/mlearn3'>
 <Player ref={nextbuttonref} autoplay={true} loop={true} keepLastFrame={true} hover={true}  src={buttonmain} />
 </Link>
 </div>
):(

  <div style={{ position:'fixed',zindex:7,left:'1000px',bottom:'10px' , pointerEvents: isDisabled ? 'none' : 'auto', cursor: isDisabled ? 'default' : 'pointer' }}onClick={next} className="nextb2">

<Player ref={nextbuttonref} autoplay={true} loop={true} keepLastFrame={true} hover={true}  src={buttonmain} />
</div>
)}
     
   

  

<div onClick={goback} style={{position:'fixed',left:'400px',zIndex:7}} className="nextb3">
   <Player ref={backbuttonref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={backbutton} />

   </div>

   
   <div onClick={repeatanimation} style={{position:'fixed',left:'740px',top:'530px', scale:'0.5'}} >
   <Player ref={rptref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={rpt} />

   </div>
   

    </div>

   )}



  
  </div>

    
);
};

export default MathLearning;