import React from 'react';
import { Button } from 'react-bootstrap';
import './boot.css';

const CircularButtonback = ({ onClick }) => {
  return (
    <Button onClick={onClick} className="circular-button-back" variant="primary" size="lg">
      <span className="arrow-back"></span>
    </Button>
  );
};

export default CircularButtonback;
