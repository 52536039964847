import React, { useEffect, useRef, useState } from "react";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import desktop from '../jsons/desktopview.json';
import mobile from '../jsons/mobileview.json';
import './scenario.css';
import scene1mob from '../jsons/scene1mobile.json';
import scene1desktop from '../jsons/scene1desktop.json';
import scene2desktop from '../jsons/scene2desktop.json';
import scene3desktop from '../jsons/scene3desktop.json';

import scene2mobile from '../jsons/scene2mobile.json';
import next from '../jsons/nextbtn.json';
import option1 from '../jsons/option1.json';
import option2 from '../jsons/option2.json';
import option3 from '../jsons/option3.json';

import sound1 from './scenarioaudio.mp3';
import sound2 from './swipesound.mp3';
import finalsound from './ssound2.mp3';
import sound3 from '../jsons/wave.mp3';
import startsc from '../jsons/startpagesc.json';
import startbtn from '../jsons/startbutton.json';
import science from '../jsons/scienceicon.json';
import business from '../jsons/businessicon.json';

import gsap from "gsap";

const Scenario = () => {
    const mobileref = useRef(null);
    const desktopref = useRef(null);
    const nextbuttonref = useRef(null);
    const optionsref = useRef(null);
    const audioRef = useRef(null);
    const introref = useRef(null);
    const startref = useRef(null);
    const businessref = useRef(null);
    
    const scienceref = useRef(null);
    const sideboard = useRef(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [mobileanimation, setmobileanimation] = useState(scene1mob);
    const [desktopanimation, setdesktopanimation] = useState(scene1desktop);
    const [audioholder, setAudioholder] = useState(sound1);
    const [titlesequence, settitlesequence] = useState(true);



    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };
    const scene3play = () => {
        setdesktopanimation(scene3desktop);
    };
    useEffect(() => {

        

    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const hovernext = () => {
if(nextbuttonref.current){
    nextbuttonref.current.play();
}
    };

    const startengines = () => {
       if(audioRef.current){
        (audioRef.current.play());
       }
       gsap.to(businessref.current,{opacity:'100',duration:1,delay:0.2})
       gsap.to(scienceref.current,{opacity:'100',duration:1,delay:0.2})

            };
            
            const startscenario = () => {
                settitlesequence(false);
                if(audioRef.current){
                    (audioRef.current.play());
                   }            }
            const nextscene = () => {
                if(audioRef.current){
                 (audioRef.current.play());
                }
                 setdesktopanimation(scene2desktop);
                 setmobileanimation(scene2mobile);
                 gsap.to(sideboard.current,{opacity:'100',duration:2})
                
                     };



    return(
        <div className='scenariobody' style={{overflow:"hidden",height:'97vh'}}>
           
            <div className="header-container" style={{position:'fixed',alignContent:'center', backgroundColor:'#0C64F3', color:"white",userSelect:'none',fontFamily:'helvetica',userSelect:'none',fontWeight:'bolder',fontSize:'20px' }}>

      
MATH SWIPE
    </div>
    <div>

    <Player >
<audio ref={audioRef} src={finalsound} type="audio/mp3"/>
</Player>

    </div>
    {titlesequence && (
        <div>

<div  onClick={startengines} style={{position:'fixed',zIndex:2, scale:'0.5',top:'350px',left:'1000px'}}>
<Player ref={startref} autoplay={true} loop={false} src={startbtn} keepLastFrame={true} hover={true} >
</Player>
</div>

            <div style={{zIndex:1, position:'fixed',top:'40px',left:'-180px'}}>
            <Player ref={introref} autoplay={true} loop={false} src={startsc} keepLastFrame={true} />
            </div>

<div onClick={startscenario} ref={businessref} style={{zIndex:2, position:'fixed',top:'-10px',opacity:'0'}} >
<Player ref={introref} autoplay={false} loop={false} src={business} keepLastFrame={true}hover={true} />

</div>
<div  ref={scienceref} style={{zIndex:2, position:'fixed',top:'-10px',left:'700px',opacity:'0'}} >
<Player ref={introref} autoplay={false} loop={false} src={science} keepLastFrame={true} hover={true}/>

</div>
<div></div>

        </div>
    )}
   {!titlesequence && (
 <div>
 {isMobile ? (
             <div className="mobile-container">
                 <div>
                 <Player ref={mobileref} autoplay={true} loop={false} src={mobileanimation} keepLastFrame={true} />
                 </div>
                 <div onClick={nextscene} onMouseEnter={hovernext} style={{position:'fixed', scale:'0.7',bottom:'10px', zIndex:1}}>
                 <Player ref={nextbuttonref} autoplay={true} loop={false} src={next} keepLastFrame={true} />
                 </div>

             </div>
         ) : (
             <div>
<div style={{position:'absolute', scale:'0.5',left:'-480px',top:'-120px', border: "5px solid #5B7093", zIndex:1}}>
<Player
autoplay={true}
ref={desktopref}
loop={false}
src={desktopanimation}
keepLastFrame={true}
//onLoopComplete={pausevideo}
>

</Player>

             </div>
             <div style={{display:'flex'}}>
             <div ref={sideboard} style={{border: "5px solid #5B7093", position:'fixed',fontFamily:'helvetica',fontSize:'23px',color:'#111F4E',backgroundColor:'white',top:'133px',left:'910px',width:'650px',height:'400px',opacity:'0'}}>
                <span style={{left:'70px',top:'10px',position:'absolute'}}>
                  If Jane has 5000/=, How many books can she buy?
                 </span> 
                 <span onClick={scene3play}>
                 <Player onClick={scene3play} style={{scale:'0.5',bottom:'255px',position:'fixed',left:'900px'}} ref={optionsref} autoplay={false} loop={false} src={option1} keepLastFrame={true} hover={true} />

                 </span>
                 <span >
                 <Player style={{scale:'0.5',bottom:'255px',position:'fixed',right:'-30px'}} ref={optionsref} autoplay={false} loop={false} src={option2} keepLastFrame={true} hover={true} />
                 </span>
                 <span >
                 <Player style={{scale:'0.5',bottom:'105px',position:'fixed',right:'90px'}} ref={optionsref} autoplay={false} loop={false} src={option3} keepLastFrame={true} hover={true} />
                 </span>
             </div>

             </div>
             <div onClick={nextscene} onMouseEnter={hovernext} style={{scale:'0.25', position:'fixed',bottom:'5px',right:'-100px',zIndex:2}}>
                 <Player ref={nextbuttonref} autoplay={true} loop={false} src={next}   />
                 </div>

             </div>
            
         )}      
             </div>
   )}
   
          
        </div>
    );
};

export default Scenario;
