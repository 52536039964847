import { Link } from 'react-router-dom';
import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import Component1 from './PracticeComponents/Component1';
import bg from './jsons/bga.json';
import bg2 from './jsons/yellowpage.json';
import Calculator2 from './generatecomponents/Calculator2';
import Component5 from './PracticeComponents/Component5';
import Component6 from './PracticeComponents/Component6';
import Component8 from './PracticeComponents/Component8';
import SignOut from './Signout';
import './PracticeComponents/Component.css';
import './auth.css';
import { updateScore } from './auth'; // Import the updateScore function
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc,updateDoc,onSnapshot } from 'firebase/firestore';
import failedlevel1 from './jsons/failedlevel1.json';
import passedlevel1 from './jsons/passedlevel1.json';
import questiontag from './jsons/qn1.json';
import nextbutton from './jsons/nextbtn.json';
import back from './jsons/goback.json';
import tryagain from './jsons/wrongmove.json';
import correct from './jsons/correct1.json';
import Signup from './Signup';
import Login from './Login';
import bikescene2 from './jsons/bike2scene.json';
import initialscene from './jsons/bike1scene.json';
import initialsceneaudio from './jsons/bikesound1.mp3';
import { Card,Container, Row, Col } from 'react-bootstrap';

import { Draggable } from "gsap/all";
import QuadraticExample2 from './PracticeComponents/QuadraticExample2';
import QuadraticExample21 from './PracticeComponents/QuadraticExample21';

import CircularButton from './bootsrap elements/CircularButton';

gsap.registerPlugin(Draggable);

const auth = getAuth();
const db = getFirestore();
//const currentUser = auth.currentUser;
//const currentUserId = currentUser.uid;

const MathTraining2 = () => {



    const bgref = useRef(null);
    const Aref = useRef(null);
    const Vref = useRef(null);
    const Sref = useRef(null);
    const kevinacc = useRef(null);
    const kevindist = useRef(null);
    const kevinspeed = useRef(null);
    const Mikeacc = useRef(null);
    const mikedist = useRef(null);
    const mikespeed = useRef(null);
    
    const audioref = useRef(null);


    const variable3Ref = useRef(null);
   
    const [dataFromChild, setDataFromChild] = useState('');

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [scene, setscene] = useState(initialscene);

    const [valuesfilled, setvaluesfilled] = useState(false);
    const [initialsetup, setinitialsetup] = useState(true);
    const [sounding, setsounding] = useState(initialsceneaudio);

    const [Svariable, setSvariable] = useState("");
    const [Avariable, setAvariable] = useState("");
    const [Vvariable, setVvariable] = useState("");
    
    const [kevinaccvariable, setkevinaccvariable] = useState(1);
    const [kevindistvariable, setkevindistvariable] = useState(260);
    const [kevinspeedvariable, setkevinspeedvariable] = useState(5);
    const [triggerquad, setriggerquad] = useState(false);

    const [Mikeaccvariable, setMikeaccvariable] = useState(2);
    const [mikedistvariable, setmikedistvariable] = useState(260);
    const [mikespeedvariable, setmikespeedvariable] = useState(2);
    const [checkA, setcheckA] = useState(false);
    const [checkV, setcheckV] = useState(false);
    const [checkS, setcheckS] = useState(false);
    
    const [timetaken, settimetaken] = useState("");

    const questionref = useRef(null);
    const answerref = useRef(null);
  

    const verdictref = useRef(null);

    const nextbtnref = useRef(null);

    const [questionno, setquestionno] = useState(1);
    const [authenticate, setauthenticate] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const [M1, setM1] = useState(0);
    const [username, setUsername] = useState('');
    const [score, setscore] = useState('');
    const [fail, setfail] = useState('');
    const [unloggedscore, setunloggedscore] = useState(0);
    const [unloggedfail, setunloggedfail] = useState(0);
    const [userUID, setUserUID] = useState(null); // Store the user UID
    const [level, setLevel] = useState('level1');
    const [verdict, setverdict] = useState(false);  
    const [passorfail, setpassorfail] = useState(passedlevel1);

   

useEffect(()=> {
    const fetchUserData = async (user) => {
        try {
            console.log('Fetching user data for UID:', user.uid); // Debugging line
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (userDoc.exists()) {
                //setscore(userDoc.data().M1);
                if(userDoc.data().Level1 >0){
                    setLevel('level2')
                }
              
                console.log('Level 2 achieved', userDoc.data().name); // Debugging line
            } else {
                console.error('Error with levels');
            }
        } catch (error) {
            console.error('offline, cant fetch level:', error);
           
        }
    };
})

const checkCollision = (draggable, target) => {
    const draggableRect = draggable.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    return !(
        draggableRect.top > targetRect.bottom ||
        draggableRect.bottom < targetRect.top ||
        draggableRect.left > targetRect.right ||
        draggableRect.right < targetRect.left
    );
};  
useEffect(()=> {
    const fetchUserData = async (user) => {
        try {
            console.log('Fetching user data for UID:', user.uid); // Debugging line
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (!userDoc.exists()) {
                //setscore(userDoc.data().M1);
            }
        } catch (error) {

            
        }
    };
})

    useEffect(() => {
        const fetchUserData = async (user) => {
            try {
                console.log('Fetching user data for UID:', user.uid); // Debugging line
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    //setscore(userDoc.data().M1);
                    setfail(userDoc.data().Mn);

                    setUsername(userDoc.data().name);
                    setUserUID(user.uid); // Set the user UID
                    console.log('Username fetched:', userDoc.data().name); // Debugging line
                } else {
                    console.error('No such document!');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setUsername('new user'); // Set default username if there's an error
                console.log('unlogged score',unloggedscore);
                //here is where I want it
                setscore(unloggedscore);
                setfail(unloggedfail);
            }
        };

        onAuthStateChanged(auth, (user) => {
            if (user) {
                setauthenticate(false);
                fetchUserData(user);
            } else {
                setauthenticate(true);
            }
        });
    }, [unloggedscore, unloggedfail]);
    
    useEffect(() => {
        let unsubscribe;
        if (userUID) {
            const userDocRef = doc(db, 'users', userUID);
            unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    setscore(docSnapshot.data().M1 || 0);
                    setfail(docSnapshot.data().Mn || 0);
                } else {
                    console.error('No such document!');
                }
            });
        }
    
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [userUID]);

 
    useEffect(() => {

        if(Avariable===1 && Svariable===5 && Vvariable===260){
            setriggerquad(true);
            console.log.apply("set in motion")
            setvaluesfilled(true);
         }
         

    }, []);
 

    const handleSignOut = (signedout) => {
        if (signedout) {
            setauthenticate(true);
        }
    };


    const updatelevel1 = async (score) => {
        try {
          const userDocRef = doc(db, 'users', userUID);
          await updateDoc(userDocRef, { Level1: score });
          console.log('Level1 updated successfully');
        } catch (error) {
          console.error('Error updating Level1:', error);
        }
      };

    const handleLoginSuccess = () => {
        setauthenticate(false);
    };

    const handleSignupSuccess = () => {
        setShowSignup(false);
        setShowLogin(true);
    };
    
 
    const incrementM1 = () => {
        setM1(prevM1 => prevM1 + 1);
        updateM1InDatabase(M1);
    };

    const updateM1InDatabase = async (newM1Value) => {
        try {
            const response = await fetch('/api/update-m1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ M1: newM1Value }),
            });

            if (!response.ok) {
                throw new Error('Failed to update M1 in the database');
            }

            const data = await response.json();
            console.log('M1 updated successfully:', data);
        } catch (error) {
            console.error('Error updating M1 in the database:', error);
        }
    };

    const tileposition = {
        padding: "10px 15px",
        borderRadius: "10px",
        //backgroundColor: "white",
       // color: "white",
        border: "1px solid #000",
      //  cursor: "pointer",
        fontFamily: "Helvetica",
        fontWeight: "bold",
        fontSize: "20px"
      };

      const handleAudioLoad = () => {
        if (audioref.current) {
        
            audioref.current.play().catch((error) => {
              console.error("Audio play interrupted:", error);
            });
          
         
        }
      };
      
      const next = () => {
      setscene(bikescene2);
      setTimeout(() => {
        setinitialsetup(false);

      }, 1500);

      };

      useEffect(() => {
     
        setTimeout(() => {
            const kd = kevindist.current;
            const ks = kevinspeed.current;
            const ka = kevinacc.current;
     
         
     
           const draggablexInstancekd = Draggable.create(kd, {
             type: "x,y",
             edgeResistance: 0.65,
            
             onDragEnd: function() {
                 const kdleft= kd.offsetLeft;
                 const kdpos = kd.getBoundingClientRect().right;
                 const blackposition = Sref.current.getBoundingClientRect().right;
                 const blueposition = Vref.current.getBoundingClientRect().right;
                 const redposition = Aref.current.getBoundingClientRect().right;
                 const mdElement = kd; // Reference to your draggable element
                 const targetElements = [Vref.current, Sref.current, Aref.current];
     
                 targetElements.forEach(target => {
                     if (checkCollision(mdElement, target)) {
                         // Collision detected
                         if (target === Vref.current) {
     
                             const placeoriginx = kd.offsetLeft - kd.offsetLeft;
                             const placeoriginy = kd.offsetTop - kd.offsetTop;
                             gsap.to(kd, { x: placeoriginx,y:placeoriginy, duration: 0.5 });
     
                         } else if (target === Sref.current) {
                             setSvariable(kevindistvariable);
                             setcheckS(true);
                         } else if (target === Aref.current) {
     
                             const placeoriginx = kd.offsetLeft - kd.offsetLeft;
                             const placeoriginy = kd.offsetTop - kd.offsetTop;
                             gsap.to(kd, { x: placeoriginx,y:placeoriginy, duration: 0.5 });
     
                         }
                     }    else{
                         const placeoriginx = kd.offsetLeft - kd.offsetLeft;
                         const placeoriginy = kd.offsetTop - kd.offsetTop;
         
                         gsap.to(kd, { x: placeoriginx,y:placeoriginy, duration: 0.5 });
         
                     }
     
     
                 });
     
                
                
     
              },
           });
           const draggablexInstanceks = Draggable.create(ks, {
             type: "x,y",
             edgeResistance: 0.65,
            
             onDragEnd: function() {
                 const ksleft= ks.offsetLeft;
                 const kspos = ks.getBoundingClientRect().right;
                 const blackposition = Sref.current.getBoundingClientRect().right;
                 const blueposition = Vref.current.getBoundingClientRect().right;
                 const redposition = Aref.current.getBoundingClientRect().right;
                 const mdElement = ks; // Reference to your draggable element
                 const targetElements = [Vref.current, Sref.current, Aref.current];
     
                 targetElements.forEach(target => {
                     if (checkCollision(mdElement, target)) {
                         // Collision detected
                         if (target === Vref.current) {
     
                            setVvariable(kevinspeedvariable);
                            setcheckV(true);
                         } else if (target === Sref.current) {
     
                            const placeoriginx = ka.offsetLeft - ka.offsetLeft;
                            const placeoriginy = ka.offsetTop - ka.offsetTop;
            
                            gsap.to(ks, { x: placeoriginx,y:placeoriginy, duration: 0.5 });
         
                         } else if (target === Aref.current) {
     
                             const placeoriginx = ka.offsetLeft - ka.offsetLeft;
                             const placeoriginy = ka.offsetTop - ka.offsetTop;
             
                             gsap.to(ks, { x: placeoriginx,y:placeoriginy, duration: 0.5 });
     
                         }
                     }    else{
                         const placeoriginx = ka.offsetLeft - ka.offsetLeft;
                     const placeoriginy = ka.offsetTop - ka.offsetTop;
     
                     gsap.to(ks, { x: placeoriginx,y:placeoriginy, duration: 0.5 });
         
                     }
     
     
                 });
     
     
     
     
               
     
              },
           });
           const draggablexInstanceka = Draggable.create(ka, {
             type: "x,y",
             edgeResistance: 0.65,
            
             onDragEnd: function() {
                 const kaleft= ka.offsetLeft;
                 const kapos = ka.getBoundingClientRect().right;
                 const blackposition = Sref.current.getBoundingClientRect().right;
                 const blueposition = Vref.current.getBoundingClientRect().right;
                 const redposition = Aref.current.getBoundingClientRect().right;
     
                 const mdElement = ka; // Reference to your draggable element
                 const targetElements = [Vref.current, Sref.current, Aref.current];
     
                 targetElements.forEach(target => {
                     if (checkCollision(mdElement, target)) {
                         // Collision detected
                         if (target === Vref.current) {
     
                             const placeoriginx = ka.offsetLeft - ka.offsetLeft;
                             const placeoriginy = ka.offsetTop - ka.offsetTop;
             
                             gsap.to(ka, { x: placeoriginx,y:placeoriginy, duration: 0.5 });
     
                         } else if (target === Sref.current) {
     
                             const placeoriginx = ka.offsetLeft - ka.offsetLeft;
                             const placeoriginy = ka.offsetTop - ka.offsetTop;
             
                             gsap.to(ka, { x: placeoriginx,y:placeoriginy, duration: 0.5 });
     
                         } else if (target === Aref.current) {
     
setAvariable(kevinaccvariable);    
setcheckA(true); 
                         }
                     }    else{
                         const placeoriginx = ka.offsetLeft - ka.offsetLeft;
                         const placeoriginy = ka.offsetTop - ka.offsetTop;
         
                         gsap.to(ka, { x: placeoriginx,y:placeoriginy, duration: 0.5 });
         
                     }
     
     
                 });
     
     if(checkA && checkS && checkV){
        setvaluesfilled(true);
     }
     if(Avariable===1 && Svariable===5 && Vvariable===260){
        console.log('filled')
        setvaluesfilled(true);
     }
     
              },
           });
     
     
        }, 500);
     


    },[mikedist,mikespeed,Mikeacc,kevinspeed,kevinacc,kevindist,Mikeaccvariable,mikedistvariable,mikespeedvariable,kevinaccvariable,kevindistvariable,kevinspeedvariable,Svariable,Avariable,Vvariable]);


    const buttonStyle2 = {
        margin: "10px",
        padding: "20px 20px",
        borderRadius: "50px",
        backgroundColor: "#8b0000",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontFamily: "Helvetica",
        fontWeight: "bold",
        fontSize: "26px"
      };
    const buttonStyle = {
        margin: "10px",
        padding: "20px 20px",
        borderRadius: "50px",
        backgroundColor: "#061FBE",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontFamily: "Helvetica",
        fontWeight: "bold",
        fontSize: "26px"
      };

      const handleDataFromChild = (data) => {
        setDataFromChild(data);
        if(data>0 && data<20){
settimetaken(data);
        }
      
      };
      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    return (
        <div>
            {isMobile? (
                <div style={{userSelect:'none'}}>
            {authenticate && (
                <div className='auth-overlay'>
                    {!showLogin && !showSignup && (
                        <div>
                            <button style={{...buttonStyle}} onClick={() => setShowLogin(true)}>Login</button>
                            <button style={{...buttonStyle2}} onClick={() => setShowSignup(true)}>Signup</button>
                        </div>
                    )}
                    {showSignup && <Signup onSignupSuccess={handleSignupSuccess} />}
                    {showLogin && <Login onLoginSuccess={handleLoginSuccess} />}
                </div>
            )}



            {!authenticate && (
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '10px' }}>


                       

    <div>



    </div>



<div>

<div >
    <div style={{position:'absolute',marginLeft:'100px',zIndex:5,marginTop:'1400px'}}>
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh',marginRight:'160vw', }}>
    <Row className="justify-content-center flex-wrap">
    <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
    <Card style={{ width: '340px' }}>
    <Card.Body className="text-center">

    <div style={{display:'flex',flexDirection:'column',}}>
<div ref={kevinacc} style={{  ...tileposition,zIndex:5,backgroundColor:'maroon',color:'white',width:'300px' }} >
      
      <span style={{   padding: "10px" }}>
          {kevinaccvariable + 'm/s2'}
          </span>
      </div>

      <div ref={kevinspeed} style={{  ...tileposition,zIndex:5,backgroundColor:'darkblue',color:'white',width:'300px'  }} >
      
      <span style={{   padding: "10px" }}>
          {kevinspeedvariable+'m/s'}
          </span>
      </div>
      <div ref={kevindist} style={{  ...tileposition,zIndex:5,backgroundColor:'black',color:'white',width:'300px'  }} >
      
      <span style={{   padding: "10px" }}>
          {kevindistvariable+'m'}
          </span>
      </div>
</div>
</Card.Body>
</Card>
</Col>
</Row>
</Container>

    </div>
    <div style={{position:'absolute'}}>
<Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '300vh',marginRight:'10vw', marginBottom: '100vh' }}>
    <Row className="justify-content-center flex-wrap">
    <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
    <Card style={{ width: '1200px', }}>
    <Card.Body className="text-center">
    <div>

                        <Player ref={bgref} autoplay={true} loop={false} keepLastFrame={true} src={scene} />
                    </div>
                    </Card.Body>

                    </Card>
                    </Col>
                    </Row>
                    </Container>
</div>

    <div id='finalpos' style={{position:'absolute',marginTop:'1050px',marginLeft:'1000px'}}>
<Container className ="d-flex justify-content-center align-items-center"
style={{ minHeight: '270vh' }}
>
<Row className="justify-content-center  flex-wrap">
    <Col>
    <Card style={{ }}>
    <Card.Body className="d-flex justify-content-between">
<div style={{fontsize:'20px',fontFamily:'helvetica',fontWeight:'bold'}}>
    Time taken = {timetaken}
</div>
</Card.Body>
</Card>

    </Col>
                           <Col xs={10} md={4} lg={5}  >
                           <Card style={{width:'80px' ,marginRight:"100px"}}>
                           <Card.Footer className="d-flex justify-content-between">

                   <div onClick={next}>
    <CircularButton/>
    </div>   
    </Card.Footer>
    </Card>
    </Col>
    </Row >
    </Container>          
    </div>



                    </div>        

                    <div>
                        <div style={{display:'flex',flexWrap:'wrap',}}>
                        <div style={{}}>
                            
                        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '450vh',marginLeft:'5vw', }}>
                        <Row className="justify-content-center flex-wrap">
                        <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
                        <Card style={{ width: '1350px' }}>
                        <Card.Body className="text-center">
{
    
}
                           
                            {checkA && checkS && checkV?  (
        
       
           
               <div style={{zIndex:5,marginTop:'100px',fontFamily:'helvetica'}}>
               <QuadraticExample21 a={Avariable} b={Vvariable} c={Svariable*-1} start={1} sendDataToParent={handleDataFromChild} />
                </div>
             ):
             (
                <div style={{  display: "flex",fontSize: "20px", alignItems: 'center', padding: '30px', userSelect: 'none' }}>
                              
            <div  style={{  ...tileposition,backgroundColor:'maroon',color:'white' }} ref={Aref}>

                <span style={{   padding: "10px" }}>
                    {Avariable}
                    </span>
                </div>

                   <div style={{ ...tileposition }} >
                       <span> t</span>
                       <span style={{}}> 2</span>

                   </div>
                   <div style={{backgroundColor:'white',fontWeight:'bold', padding: "10px", color: 'blue', userSelect: 'none', margin: "5px" }} >+</div>
                   <div style={{...tileposition,backgroundColor:'darkblue',color:'white'}} id='this' ref={Vref} >
                   <span style={{   padding: "10px" }}>
                    {Vvariable}
                    </span>

                    </div>       
                    <div style={{...tileposition}} id='this'  >
                   t

                    </div>                                
                     <div id='this' ref={variable3Ref}>
                       <span style={{ backgroundColor:'white', padding: "10px" }}>-</span>
                   </div>
                   <div style={{...tileposition,backgroundColor:'black',color:'white'}} id='this' ref={Sref} >
                   <span style={{   padding: "10px" }}>
                    {Svariable}
                    </span>

                    </div> 
                    <div style={{...tileposition}} id='this' >
                  =

                    </div> 
                    <div style={{...tileposition}} id='this'  >
                 0

                    </div> 
                
               </div> 
             )
            
            }  
                            </Card.Body>
                            </Card>
                            </Col>
                            </Row>
                            </Container>

                        </div>

                        </div>


                    </div>
    </div>
   
                
   <div>
    <Player>
    <audio ref={audioref} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
    </Player>
   </div>
              
                  
                </div>
            )}
        </div>
            ): (
                <div style={{userSelect:'none',height:'100vw'}}>
            {authenticate && (
                <div className='auth-overlay'>
                    {!showLogin && !showSignup && (
                        <div>
                            <button style={{...buttonStyle}} onClick={() => setShowLogin(true)}>Login</button>
                            <button style={{...buttonStyle2}} onClick={() => setShowSignup(true)}>Signup</button>
                        </div>
                    )}
                    {showSignup && <Signup onSignupSuccess={handleSignupSuccess} />}
                    {showLogin && <Login onLoginSuccess={handleLoginSuccess} />}
                </div>
            )}



            {!authenticate && (
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '10px' }}>


                       

    <div>



    </div>



<div>

<div >
    <div style={{position:'absolute',marginLeft:'400px',zIndex:5}}>
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh',marginRight:'100vw', }}>
    <Row className="justify-content-center flex-wrap">
    <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
    <Card style={{ width: '500px' }}>
    <Card.Body className="text-center">

    <div style={{display:'flex',flexDirection:'column',}}>
<div ref={kevinacc} style={{  ...tileposition,zIndex:5,backgroundColor:'maroon',color:'white' }} >
      
      <span style={{   padding: "10px" }}>
          {kevinaccvariable + 'm/s2'}
          </span>
      </div>

      <div ref={kevinspeed} style={{  ...tileposition,zIndex:5,backgroundColor:'darkblue',color:'white' }} >
      
      <span style={{   padding: "10px" }}>
          {kevinspeedvariable+'m/s'}
          </span>
      </div>
      <div ref={kevindist} style={{  ...tileposition,zIndex:5,backgroundColor:'black',color:'white' }} >
      
      <span style={{   padding: "10px" }}>
          {kevindistvariable+'m'}
          </span>
      </div>
</div>
</Card.Body>
</Card>
</Col>
</Row>
</Container>

    </div>
    <div style={{position:'absolute'}}>
<Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh',marginRight:'10vw', marginBottom: '100vh' }}>
    <Row className="justify-content-center flex-wrap">
    <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
    <Card style={{ width: '1200px', }}>
    <Card.Body className="text-center">
    <div>

                        <Player ref={bgref} autoplay={true} loop={false} keepLastFrame={true} src={scene} />
                    </div>
                    </Card.Body>

                    </Card>
                    </Col>
                    </Row>
                    </Container>
</div>

    <div id='finalpos' style={{position:'absolute',marginTop:'250px',marginLeft:'1000px'}}>
<Container className ="d-flex justify-content-center align-items-center"
style={{ minHeight: '100vh' }}
>
<Row className="justify-content-center  flex-wrap">
    <Col>
    <Card style={{ }}>
    <Card.Body className="d-flex justify-content-between">
<div style={{fontsize:'20px',fontFamily:'helvetica',fontWeight:'bold'}}>
    Time taken = {timetaken}
</div>
</Card.Body>
</Card>

    </Col>
                           <Col xs={10} md={4} lg={5}  >
                           <Card style={{width:'80px' ,marginRight:"100px"}}>
                           <Card.Footer className="d-flex justify-content-between">

                   <div onClick={next}>
    <CircularButton/>
    </div>   
    </Card.Footer>
    </Card>
    </Col>
    </Row >
    </Container>          
    </div>



                    </div>        

                    <div>
                        <div style={{display:'flex',flexWrap:'wrap',}}>
                        <div style={{}}>
                            
                        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '110vh',marginLeft:'35vw', }}>
                        <Row className="justify-content-center flex-wrap">
                        <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
                        <Card style={{ width: '1350px' }}>
                        <Card.Body className="text-center">
{
    
}
                           
                            {checkA && checkS && checkV?  (
        
       
           
               <div style={{zIndex:5,marginTop:'100px',fontFamily:'helvetica'}}>
               <QuadraticExample21 a={Avariable} b={Vvariable} c={Svariable*-1} start={1} sendDataToParent={handleDataFromChild} />
                </div>
             ):
             (
                <div style={{  display: "flex",fontSize: "20px", alignItems: 'center', padding: '30px', userSelect: 'none' }}>
                              
            <div  style={{  ...tileposition,backgroundColor:'maroon',color:'white' }} ref={Aref}>

                <span style={{   padding: "10px" }}>
                    {Avariable}
                    </span>
                </div>

                   <div style={{ ...tileposition }} >
                       <span> t</span>
                       <span style={{}}> 2</span>

                   </div>
                   <div style={{backgroundColor:'white',fontWeight:'bold', padding: "10px", color: 'blue', userSelect: 'none', margin: "5px" }} >+</div>
                   <div style={{...tileposition,backgroundColor:'darkblue',color:'white'}} id='this' ref={Vref} >
                   <span style={{   padding: "10px" }}>
                    {Vvariable}
                    </span>

                    </div>       
                    <div style={{...tileposition}} id='this'  >
                   t

                    </div>                                
                     <div id='this' ref={variable3Ref}>
                       <span style={{ backgroundColor:'white', padding: "10px" }}>-</span>
                   </div>
                   <div style={{...tileposition,backgroundColor:'black',color:'white'}} id='this' ref={Sref} >
                   <span style={{   padding: "10px" }}>
                    {Svariable}
                    </span>

                    </div> 
                    <div style={{...tileposition}} id='this' >
                  =

                    </div> 
                    <div style={{...tileposition}} id='this'  >
                 0

                    </div> 
                
               </div> 
             )
            
            }  
                            </Card.Body>
                            </Card>
                            </Col>
                            </Row>
                            </Container>

                        </div>

                        </div>


                    </div>
    </div>
   
                
   <div>
    <Player>
    <audio ref={audioref} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
    </Player>
   </div>
              
                  
                </div>
            )}
        </div>
            )}
 
        </div>
       
    );
};

export default MathTraining2;
