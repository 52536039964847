import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { axiosAction } from "../scistyles and comps/axiosAction";
import { signup } from '../auth'; // Make sure this is the correct path


const SignupPage = () => {
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');

  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const buttonRef = useRef();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const ax = await axiosAction.post('https://787a-137-63-145-153.ngrok-free.app/api/v1/auth/signin', {
        fullName,
        username,
        email,
        password,
        phone,
        dateOfBirth,
      });
      
      setMessage('Signup successful!');
      console.log('Signup successful!');
      console.log(ax);

      setIsError(false);
    } catch (error) {
      setMessage('Error during signup. Please try again.');
      console.error('Error during signup:', error);
      setIsError(true);
    }
  };

  const inputStyle = {
    width: '100%',
    margin: '10px 0',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  };

  const buttonStyle = {
    width: '100%',
    padding: '10px',
    marginTop: '20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">Signup</h2>
      <form style={{ height: '700px', width: '360px', borderRadius: '30px' }} onSubmit={handleSignup}>
        <input
          type="text"
          name="fullName"
          placeholder="Full Name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          style={inputStyle}
        />
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={inputStyle}
        />
        <input
          type="text"
          name="phone"
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          style={inputStyle}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}
        />
        <input
          type="password"
          name="password"
          placeholder="Password (8 characters)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}
        />
        <input
          type="date"
          name="dateOfBirth"
          placeholder="Date of Birth"
          value={dateOfBirth}
          onChange={(e) => setDateOfBirth(e.target.value)}
          style={inputStyle}
        />
        <button ref={buttonRef} style={buttonStyle} type="submit">
          Sign Up
        </button>
        {message && (
          <div className={`alert mt-3 ${isError ? 'alert-danger' : 'alert-success'}`}>
            {message}
          </div>
        )}
      </form>
    </div>
  );
};

export default SignupPage;
