import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import goback from '../jsons/goback.json';

// Register the custom GSAP effect
gsap.registerEffect({
  name: "calchover",
  effect: (targets, config) => {
    return gsap.to(targets, { backgroundColor: config.backgroundColor, color: config.color, duration: config.duration });
  },
  defaults: { duration: 0.1, backgroundColor: "white", color: "#373839" }, // defaults for the config object
  extendTimeline: true,
});

const Calculator = ({ onSelect }) => {
  const onesRef = useRef(null);
  const twosRef = useRef(null);
  const threeRef = useRef(null);
  const fourRef = useRef(null);
  const fiveRef = useRef(null);
  const sixRef = useRef(null);
  const sevenRef = useRef(null);
  const eightRef = useRef(null);
  const nineRef = useRef(null);
  const [calcactive, setCalcactive] = useState(true);

  const refs = [onesRef, twosRef, threeRef, fourRef, fiveRef, sixRef, sevenRef, eightRef, nineRef];

  useEffect(() => {
    refs.forEach(ref => {
      if (ref.current) {
        ref.current.addEventListener("mouseenter", () => {
          gsap.effects.calchover(ref.current);
        });

        ref.current.addEventListener("mouseleave", () => {
          gsap.to(ref.current, { backgroundColor: "#373839", color: "white", duration: 0.3 });
        });
      }
    });

    return () => {
      refs.forEach(ref => {
        if (ref.current) {
          ref.current.removeEventListener("mouseenter", () => {
            gsap.effects.calchover(ref.current);
          });

          ref.current.removeEventListener("mouseleave", () => {
            gsap.to(ref.current, { backgroundColor: "#373839", color: "white", duration: 0.3 });
          });
        }
      });
    };
  }, [refs]);

  const calcposition = {
    margin: "10px",
    left: "-20px",
    borderRadius: "10px",
    backgroundColor:"#373839",
    color: "white",
    border: "1px solid #204861",
    fontFamily: "Helvetica",
    fontWeight:"bold"
  };

  const select = (value) => {
    if (onSelect) {
      onSelect(value);
    }
  };

  return (
    <div>
      {calcactive && (
        <div style={{ display: "flex", fontSize: "30px", alignItems: 'center', padding: '30px', userSelect: 'none', left: '-120px', position: 'relative', top: '5px',zIndex:5 }}>
        <div onClick={() => select(1)} ref={onesRef} style={{ ...calcposition, padding: "10px" }}>1</div>
        <div onClick={() => select(2)} ref={twosRef} style={{ ...calcposition, padding: "10px" }}>2</div>
        <div onClick={() => select(3)} ref={threeRef} style={{ ...calcposition, padding: "10px" }}>3</div>
        <div onClick={() => select(4)} ref={fourRef} style={{ ...calcposition, padding: "10px" }}>4</div>
        <div onClick={() => select(5)} ref={fiveRef} style={{ ...calcposition, padding: "10px" }}>5</div>
        <div onClick={() => select(6)} ref={sixRef} style={{ ...calcposition, padding: "10px" }}>6</div>
        <div onClick={() => select(7)} ref={sevenRef} style={{ ...calcposition, padding: "10px" }}>7</div>
        <div onClick={() => select(8)} ref={eightRef} style={{ ...calcposition, padding: "10px" }}>8</div>
        <div onClick={() => select(9)} ref={nineRef} style={{ ...calcposition, padding: "10px" }}>9</div>
      </div>
      )}
      
    </div>
   
  );
};

export default Calculator;
