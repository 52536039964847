import React, { useState, useRef, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import stable1 from '../jsons/stable1.json';
import unstable1 from '../jsons/unstable1.json';

const Sim3 = ({ sendDataToParent}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const animation1Ref = useRef(null);
  const animation2Ref = useRef(null);

  const handleAnimationClick = () => {
    setIsPlaying(true);
    sendData();

    setTimeout(() => {
        if(animation1Ref.current){
            animation1Ref.current.play();
        }
        if(animation2Ref.current){
            animation2Ref.current.play();

        }
    }, 500);
  };
  const handleAnimationClick2 = () => {
    setIsPlaying(true);
    setTimeout(() => {
        if(animation1Ref.current){
            animation1Ref.current.play();
        }
        if(animation2Ref.current){
            animation2Ref.current.play();

        }
    }, 500);
  };
  const sendData = () => {
    const data = true;
    sendDataToParent(data);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
     <div  onClick={() => handleAnimationClick()} >
     <Player
        ref={animation1Ref}
        src={stable1}
        loop={false}
        keepLastFrame={true}

        autoplay={false}
        style={{ width: '200px', height: '200px' }}
       
      />
     </div>
      {/* 1px Border between animations */}
      <div style={{ width: '1px', height: '140px', backgroundColor: 'black' }}></div>
     <div  onClick={() => handleAnimationClick2()} >
     <Player
        ref={animation2Ref}
        src={unstable1}
        loop={false}
        keepLastFrame={true}
        autoplay={false}
        style={{ width: '200px', height: '200px' }}
        onClick={() => handleAnimationClick()}
      />
    </div>
    
    </div>
  );
};

export default Sim3;
