import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

const Sim4 = ({ sendDataToParent }) => {
  const upperBoxRef = useRef(null);
  const lowerBoxRef = useRef(null);
  const redBoxRefs = useRef([]);
  const [boxColors, setBoxColors] = useState(Array(4).fill('red'));

  useEffect(() => {
    // GSAP Animation
    gsap.fromTo(upperBoxRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
    gsap.fromTo(lowerBoxRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });

    redBoxRefs.current.forEach((box, index) => {
      gsap.fromTo(box, { opacity: 0 }, { opacity: 1, duration: 1, delay: index * 0.2 });
    });
  }, []);

  const sendData = () => {
    const data = true;
    sendDataToParent(data);
  };

  // Handle click event for red boxes
  const handleBoxClick = (index) => {
    setBoxColors(prevColors =>
      prevColors.map((color, i) =>
        i === index ? 'black' : color
      )
    );

    // If the last red box is clicked, trigger sendData
    if (index === 2) {
      sendData();
    }
  };

  // Define styles for the boxes
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    upperBox: {
      width: '200px',
      height: '50px',
      backgroundColor: 'yellow',
      border: '2px solid black',
      position: 'relative',
    },
    lowerBox: {
      width: '50px',
      height: '200px',
      backgroundColor: 'lightblue',
      border: '2px solid black',
      position: 'relative',
    },
    redBox: {
      width: '30px',
      height: '30px',
      backgroundColor: 'red',
      position: 'absolute',
      cursor: 'pointer',
    },
  };

  // Red box positions on the edges of upper and lower boxes
  const redBoxPositions = [
    { top: '50%', left: '0', transform: 'translateY(-50%)' },       // Upper box - left edge center
    { top: '50%', right: '0', transform: 'translateY(-70%)' },      // Upper box - right edge center
    { top: '0', left: '50%', transform: 'translateX(-50%)' },       // Lower box - top edge center
    { bottom: '0', left: '50%', transform: 'translateX(-50%)' },    // Lower box - bottom edge center
  ];

  return (
    <div style={styles.container}>
      <div ref={upperBoxRef} style={styles.upperBox}>
        {redBoxPositions.slice(0, 2).map((position, index) => (
          <div
            key={index}
            ref={el => (redBoxRefs.current[index] = el)}
            style={{ ...styles.redBox, ...position, backgroundColor: boxColors[index] }}
            onClick={() => handleBoxClick(index)}
          ></div>
        ))}
      </div>
      <div ref={lowerBoxRef} style={styles.lowerBox}>
        {redBoxPositions.slice(2, 4).map((position, index) => (
          <div
            key={index + 2}
            ref={el => (redBoxRefs.current[index + 2] = el)}
            style={{ ...styles.redBox, ...position, backgroundColor: boxColors[index + 2] }}
            onClick={() => handleBoxClick(index + 2)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Sim4;
