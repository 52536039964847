import React, { useRef, useEffect,useState } from "react";
import './StemBlocs.css';
import gsap from 'gsap';
import { Draggable } from 'gsap/Draggable';
import H from '../jsons/O.json';
import { Player } from '@lottiefiles/react-lottie-player';
import O from '../jsons/H.json';
import water from '../jsons/elements/water.json';

gsap.registerPlugin(Draggable);

const StemBlocs = () => {
  const lineRef = useRef(null);
  const blockRef = useRef(null); // Ref for the first draggable block
  const blockRef2 = useRef(null); // Ref for the second block
  const blockRef3 = useRef(null); // Ref for the third block
  const animref3 = useRef(null); // Ref for the third block
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const animref = useRef(null); // Ref for the third block
  const animref2 = useRef(null); // Ref for the third block
  const [hold, sethold] = useState(H);
  const [waterswitch, setwaterswitch] = useState(false);

  const draggableInstanceRef = useRef([]); // Ref for storing the GSAP Draggable instances

  useEffect(() => {
    const lineYPos = isMobile ? 300 : 600; // Adjust line position for mobile
    lineRef.current.style.top = `${lineYPos}px`;
    gsap.to(blockRef2.current, { y: 500, x: 400, duration: 0 });
    gsap.to(blockRef.current, { y: 500, x: 700, duration: 0 });
    gsap.to(blockRef3.current, { y: 500, x: 200, duration: 0 });

    gsap.to(blockRef2.current, { backgroundColor: 'blue', duration: 0 });
    gsap.to(blockRef2.current, { backgroundColor: 'black', duration: 0 });

    // Initialize Draggable on the first block
    draggableInstanceRef.current[0] = Draggable.create(blockRef.current, {
      type: "x,y",
      bounds: { top: 0, left: 0, right: window.innerWidth, bottom: lineYPos },
      onDragEnd: function() {
        moveBlockDown();
        animref.current.play();

      }
    });

    // Initialize Draggable on the second block
    draggableInstanceRef.current[1] = Draggable.create(blockRef2.current, {
      type: "x,y",
      bounds: { top: 0, left: 0, right: window.innerWidth, bottom: lineYPos },
      onDragEnd: function() {
        moveBlockDown2();
      }
    });

    draggableInstanceRef.current[1] = Draggable.create(blockRef3.current, {
      type: "x,y",
      bounds: { top: 0, left: 0, right: window.innerWidth, bottom: lineYPos },
      onDragEnd: function() {
        moveBlockDown3();
      }
    });
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    const checkCollision3 = (blockA, blockB) => {
      const rectA = blockA.getBoundingClientRect();
      const rectB = blockB.getBoundingClientRect();
      
      return !(
        rectA.right < rectB.left ||
        rectA.left > rectB.right ||
        rectA.bottom < rectB.top ||
        rectA.top > rectB.bottom
      );
    };
   if(waterswitch){

    if (checkCollision3(blockRef2.current, blockRef.current)) {
      sethold(water);
      setTimeout(() => {
        if(animref3.current){
          animref3.current.play();
          gsap.to(blockRef2.current, { opacity: "0", duration: 0.3, ease:'power2.inOut'  });

        }
      }, 100);
      
      gsap.to(blockRef2.current, { opacity: "0", duration: 0.3, ease:'power2.inOut'  });
    }
  

     
   }

  }, [waterswitch,blockRef,blockRef2,blockRef3]);

  const moveBlockDown = () => {
    const block = blockRef.current;
    const line = lineRef.current;
    const block2 = blockRef2.current;

    const checkCollision = () => {
      const blockRect = block.getBoundingClientRect();
      const lineRect = line.getBoundingClientRect();
      const block2Rect = block2.getBoundingClientRect();
      
      if (blockRect.bottom + 1 >= lineRect.top && 
          blockRect.left < lineRect.right && 
          blockRect.right > lineRect.left) {
        return lineRect.top - blockRect.height;
      } 
      else if (blockRect.bottom + 1 >= block2Rect.top && 
               blockRect.left < block2Rect.right && 
               blockRect.right > block2Rect.left) {
        return block2Rect.top - blockRect.height;
      }

      return null;
    };
    
    const animateDown = () => {
      const collisionY = checkCollision();
      if (collisionY !== null) {
        gsap.to(block, { y: collisionY, duration: 0.1, ease: 'bounce.out' });
      } else {
        gsap.to(block, { y: "+=40", duration: 0.05, onComplete: animateDown });
      }
    };

    animateDown();
  };

  const moveBlockDown2 = () => {
    const block = blockRef2.current;
    const line = lineRef.current;
    const block1 = blockRef.current;
    const checkCollision2 = (blockA, blockB) => {
      const rectA = blockA.getBoundingClientRect();
      const rectB = blockB.getBoundingClientRect();
      
      return !(
        rectA.right < rectB.left ||
        rectA.left > rectB.right ||
        rectA.bottom < rectB.top ||
        rectA.top > rectB.bottom
      );
    };
    const checkCollision = () => {
      const blockRect = block.getBoundingClientRect();
      const lineRect = line.getBoundingClientRect();
      const block1Rect = block1.getBoundingClientRect();
      
      if (blockRect.bottom + 1 >= lineRect.top && 
          blockRect.left < lineRect.right && 
          blockRect.right > lineRect.left) {
        return lineRect.top - blockRect.height;
      } 
      else if (blockRect.bottom + 1 >= block1Rect.top && 
               blockRect.left < block1Rect.right && 
               blockRect.right > block1Rect.left) {
        return block1Rect.top - blockRect.height;
      }

      return null;
    };
    
    const animateDown = () => {
      const collisionY = checkCollision();
      if (collisionY !== null) {
        gsap.to(block, { y: collisionY, duration: 0.1, ease: 'bounce.out' });
     //   animref.current.play();
      } else {
        gsap.to(block, { y: "+=40", duration: 0.05, onComplete: animateDown });
     //   animref.current.play();
      }
      if (checkCollision2(block, blockRef3.current)) {
        // Play the Lottie animation if there's a collision
       animref.current.play();
       animref2.current.play();
       gsap.to(blockRef3.current, { opacity: "0", duration: 0.3, ease:'power2.inOut'  });
        setwaterswitch(true);
      
      }
    };

    animateDown();
  };


  const moveBlockDown3 = () => {
    const block = blockRef3.current;
    const line = lineRef.current;
    const block1 = blockRef.current;

    const checkCollision2 = (blockA, blockB) => {
      const rectA = blockA.getBoundingClientRect();
      const rectB = blockB.getBoundingClientRect();
      
      return !(
        rectA.right < rectB.left ||
        rectA.left > rectB.right ||
        rectA.bottom < rectB.top ||
        rectA.top > rectB.bottom
      );
    };

    
    const checkCollision = () => {
      const blockRect = block.getBoundingClientRect();
      const lineRect = line.getBoundingClientRect();
      const block1Rect = block1.getBoundingClientRect();
      
      if (blockRect.bottom + 1 >= lineRect.top && 
          blockRect.left < lineRect.right && 
          blockRect.right > lineRect.left) {
        return lineRect.top - blockRect.height;
      } 
      else if (blockRect.bottom + 1 >= block1Rect.top && 
               blockRect.left < block1Rect.right && 
               blockRect.right > block1Rect.left) {
        return block1Rect.top - blockRect.height;
      }

      return null;
    };
    
    const animateDown = () => {
      const collisionY = checkCollision();
      if (collisionY !== null) {
        gsap.to(block, { y: collisionY, duration: 0.1, ease: 'bounce.out' });

      } else {
        gsap.to(block, { y: "+=40", duration: 0.05, onComplete: animateDown });
      //  animref.current.play();

      }
      if (checkCollision2(block, blockRef2.current)) {
        // Play the Lottie animation if there's a collision
        animref.current.play();
         animref2.current.play();
         gsap.to(blockRef3.current, { opacity: "0", duration: 0.3, ease:'power2.inOut'  });
        setwaterswitch(true);
         
      }
     
     
    };

    animateDown();
  };
  return (
    <div style={{display:'flex',}}>
<div ref={blockRef} style={{ position: 'absolute', marginRight:'400px', width: isMobile ? '70px' : '100px', height: isMobile ? '70px' : '100px' }}>
<Player
          ref={animref3}
          src={hold}
          autoplay={false}
          loop={false}
          keepLastFrame={true}
          style={{ width: '100%', height: '100%' }} // Adjust Lottie size
        />
      </div>
     
  
      <div ref={blockRef2} style={{ position: 'absolute', width: isMobile ? '70px' : '100px', height: isMobile ? '70px' : '100px' }}>
      <Player
          ref={animref}
          src={O}
          autoplay={false}
          loop={false}
          keepLastFrame={true}
        />
      </div>
      <div ref={blockRef3} style={{ position: 'absolute', width: isMobile ? '70px' : '100px', height: isMobile ? '70px' : '100px' }}>
      <Player
          ref={animref2}
          src={O}
          loop={false}
          keepLastFrame={true}
          autoplay={false}

        />
      </div>
      <div ref={lineRef} className="horizontal-line" style={{ position: 'fixed', width: '3000px',marginTop:'70px', height: '4px', backgroundColor: 'black' }} />
      </div>
  );
};

export default StemBlocs;
