import React from 'react';
import { ProgressBar as BootstrapProgressBar } from 'react-bootstrap';

const ProgressBar = ({ progress, label }) => {
  const getVariant = (progress) => {
    if (progress < 20) return 'danger';
    if (progress < 50) return 'primary'; // blue
    if (progress < 70) return 'warning'; // yellow
    return 'success'; // green
  };

  return (
    <div style={{display:'flex',alignItems:'center'}}>
      <div style={{padding:'5px'}}>
        {label}
      </div>
      <div style={{ width: '100%', margin: '0 auto', fontSize:'10px' }}>
        <BootstrapProgressBar 
          now={progress} 
          label={`${progress}%`} 
          variant={getVariant(progress)}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
