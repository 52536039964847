// src/components/Login.js
import React, { useEffect, useRef, useState } from "react";
import { login } from './auth';
import './index.css';
import { gsap } from "gsap";

const Login = ({  }) => {
  const buttonref = useRef(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    const success = await login(email, password);
    if (success) {
      setLoginFailed(false);

    } else {
      setLoginFailed(true);
    }
  };
  const buttonStyle = {
    margin: "10px",
    width:'200px',
    padding: "20px 20px",
    borderRadius: "50px",
    backgroundColor: "#061FBE",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "26px",
    position:'fixed',
    top:'200px',
    left:'30px'
  };
  const inputStyle = {
    margin: "9px",
    padding: "10px",
    width: "90%",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px",
    fontFamily: "Helvetica",

  };
  const clickbutton =  () => {
    gsap.to(buttonref.current, { scale: 0.9, duration:0.5, delay:0,ease:'power4.inOut' });
    gsap.to(buttonref.current, { backgroundColor: 'black', duration:0.5, delay:0,ease:'power4.inOut' });

  };
  
  const hoverbutton =  () => {
    gsap.to(buttonref.current, { scale: 1.1, duration:0.5, delay:0,ease:'power4.inOut' });
    gsap.to(buttonref.current, { backgroundColor: '#001075', duration:0.5, delay:0,ease:'power4.inOut' });

  };
  const leavebutton =  () => {
    gsap.to(buttonref.current, { scale: 1, duration:0.5, delay:0,ease:'power4.inOut' });
   gsap.to(buttonref.current, { backgroundColor: '061FBE', duration:0.5, delay:0,ease:'power4.inOut' });

  };
  return (
    <div className='login2'>
      <form   onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}

        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}

        />
        <button ref={buttonref} onMouseEnter={hoverbutton} onMouseLeave={leavebutton} onClick={clickbutton} style={buttonStyle} type="submit">Login</button>
      </form>
      {loginFailed && (
        <div className="error-message">
          Login failed. Please check your email and password.
        </div>
      )}
    </div>
  );
};

export default Login;
