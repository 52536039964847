import React, { useEffect, useRef, useState } from "react";
import { auth } from './Firebase'; // Import only auth from your Firebase file
import { signOut } from 'firebase/auth'; // Import signOut from firebase/auth
import signoutl from './jsons/signout.json';
import { Player } from '@lottiefiles/react-lottie-player';

const SignOut = () => {
    const buttonref = useRef(null);

    const [signedout, setsignedout] = useState(false);
    const signoutref = useRef(null);

    const handleSignOut = () => {
        signOut(auth)
            .then(() => {
                setsignedout(true);
                console.log('User signed out successfully');
            })
            .catch((error) => {
                console.error('Error signing out:', error);
            });
    };

    const buttonStyle = {
        width:'120px',
        height:'50px',
        padding: "20px 20px",
        borderRadius: "20px",
        backgroundColor: "black",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontFamily: "Helvetica",
        fontWeight: "bold",
        fontSize: "15px",
        position:'relative',
        top:'-110px',
        left:'10px'
    };
    
    return (
        <div>
            <div>
                <button ref={buttonref} onClick={handleSignOut} style={buttonStyle} type="submit">
                    Sign out
                </button>
            </div>
        </div>
    );
};

export default SignOut;
