import { Player } from "@lottiefiles/react-lottie-player";
import Squarert from "./Squarert";
import Liketerms from "./Liketerms";
import MouseFollower from "./MouseFollower";

const Generallession = () => {

    return(
<div>
    <div style={{position:'fixed', left:'800px'}}>


<MouseFollower />
</div>
</div>
    );
};

export default Generallession;