import React, { useState,useRef,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import avatar1 from './jsons/avatarboy.json';
import avatar2 from './jsons/avatargirl.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import TagofWar from './PracticeComponents/TagofWar';

const JoinGame = () => {
  const [role, setRole] = useState(null);
  const navigate = useNavigate();
  const rptref = useRef(null);
  const rptref2 = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleJoin = (side) => {
    setRole(side);
  };
  const handlehover = () => {
    if(rptref.current){
      rptref.current.play();
    }
  };
  const handleleave = () => {
    if(rptref.current){
      rptref.current.stop();
    }
  };
  const handlehover2 = () => {
    if(rptref2.current){
      rptref2.current.play();
    }
  };
  const handleleave2 = () => {
    if(rptref2.current){
      rptref2.current.stop();
    }
  };
  return (
    <>
   
   {isMobile ? (
  <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
    <>
      {role === null ? (
        <div className="d-flex flex-column">
          {/* Left Side Card - Babirye */}
          <div className="card my-3" style={{ width: '18rem' }}>
            <div className="card-header text-center">
              <h1>Barigye</h1>
            </div>
            <div onMouseEnter={handlehover} onMouseLeave={handleleave} onClick={() => handleJoin(1)}>
              <Player
                ref={rptref}
                autoplay={false}
                loop={false}
                src={avatar1}
                keepLastFrame={true}
                style={{ height: '250px', width: '250px' }}
              />
            </div>
            <div className="card-body">
              {/* Additional content here */}
            </div>
            <div className="card-footer text-center">
              <div>Power Level: 20 {/* Fill Power Level */}</div>
              <div>Workrate: 1 {/* Fill Workrate */}</div>
            </div>
          </div>

          {/* Right Side Card - Barigye */}
          <div className="card my-3" style={{ width: '18rem' }}>
            <div className="card-header text-center">
              <h1>Babirye</h1>
            </div>
            <div onMouseEnter={handlehover2} onMouseLeave={handleleave2} onClick={() => handleJoin(2)}>
              <Player
                ref={rptref2}
                keepLastFrame={true}
                autoplay={false}
                loop={false}
                src={avatar2}
                style={{ height: '250px', width: '250px' }}
              />
            </div>
            <div className="card-body">
              {/* Additional content here */}
            </div>
            <div className="card-footer text-center">
              <div>Power Level: 10 {/* Fill Power Level */}</div>
              <div>Workrate: 2 {/* Fill Workrate */}</div>
            </div>
          </div>
        </div>
      ) : (
        <TagofWar role={role} />
      )}
    </>
  </div>
):(
      <div>

      <>
      {role===null? (
        <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="d-flex">
          {/* Left Side Card - Babirye */}
          <div className="card mx-5" style={{ width: '18rem' }}>
            <div className="card-header text-center">
            <h1>
            Barigye
  
            </h1>
            </div>
            <div onMouseEnter={handlehover} onMouseLeave={handleleave} onClick={() => handleJoin(1)}>
            <Player
            ref={rptref}
              autoplay ={false}
              loop={false}
              src={avatar1}
              keepLastFrame={true}
              style={{ height: '250px', width: '250px' }}
            />
            </div>
            
            <div className="card-body">
              {/* You can add more content here if needed */}
            </div>
            <div className="card-footer text-center">
              <div>Power Level: 20 {/* Fill Power Level */}</div>
              <div>Workrate:1 {/* Fill Workrate */}</div>
             
            </div>
          </div>
  
          {/* Right Side Card - Barigye */}
          <div className="card mx-5" style={{ width: '18rem' }}>
            <div className="card-header text-center">
            <h1>
            Babirye
  
            </h1>          </div>
            <div  onMouseEnter={handlehover2} onMouseLeave={handleleave2} onClick={() => handleJoin(2)}>
            <Player
               ref={rptref2}
               keepLastFrame={true}
  
               autoplay ={false}
               loop={false}
              src={avatar2}
              style={{ height: '250px', width: '250px' }}
            />
            </div>
          
            <div className="card-body">
              {/* You can add more content here if needed */}
            </div>
            <div  className="card-footer text-center">
              <div>Power Level: 10 {/* Fill Power Level */}</div>
              <div>Workrate: 2 {/* Fill Workrate */}</div>
            
            </div>
          </div>
        </div>
      </div>
      ):(
  
        <TagofWar role={role} />
      )}
      </>
      </div>
    )}
    </>
  );
};

export default JoinGame;
