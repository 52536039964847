import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PhysicsHeader from './PhysicsHeader'; // Adjust the path if necessary
import { Link } from 'react-router-dom';

const Scisteps = () => {
  // This function will handle the Timer click from the PhysicsHeader
  const handleTimerClick = () => {
    console.log("Timer clicked!");
    // Add any logic to handle the Timer functionality
  };

  return (
    <div>
      <PhysicsHeader onTimerClick={handleTimerClick} />

      <Container style={{ marginTop: '100px' }}> {/* Adjusted margin to leave space for the fixed header */}
        <Row className="justify-content-center">
          <Col md={4}>
            <Card style={{ textAlign: 'center', padding: '30px',height:'200px' }}>
              <Card.Body>
              <Link to="/subjectselect" style={{ textDecoration: 'none', width: '100%' }}>

                <Card.Title>Learn</Card.Title>
                </Link>

              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card style={{ textAlign: 'center', padding: '30px',height:'200px' }}>
              <Card.Body>
                <Card.Title>Play and Practice</Card.Title>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card style={{ textAlign: 'center', padding: '30px',height:'200px' }}>
              <Card.Body>
                <Card.Title>Study</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Scisteps;
