import React, { useEffect, useRef, useState } from "react";
import { Card, Dropdown, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ProgressBar from '../LoadingPages/ProgressBar';
import gsap from 'gsap';
import PhysicsHeader from "../PhysicsHeader";
import math from '../svgs/math.svg';
import algebrahighlight from '../jsons/scenes/algebra/algebrascene.json';
import { Player } from "@lottiefiles/react-lottie-player";
import geometry from '../svgs/geometry.svg';
import trig from '../svgs/trig.svg';
import bar from '../svgs/bar.svg';
import calculus from '../svgs/calculus.svg';

const MathTopics = () => {
  const animationRef = useRef(null);
    const [topics, setTopics] = useState([
        {
            title: '  01 Algebra',
            progress: 50,
            svg:math,
            availability:0,
            description:'  Variables and their relationships. USES - calculating profit, losses,etc',
            subtopics: [
              {
                title: ' Equations',
                progress: 100,
                availability:0,
                description:' Deals with Basic equations',
                activities: ['Basic'],
            },
                {
                    title: ' Quadratic Equations',
                    description:' Deals with quadratic equations.',
                    progress: 40,
                    availability:0,
                    activities: ['Quadratic'],
                },
                {
                    title: 'Test ',
                    description:'try out this test to ',
                    progress: 0,
                    availability:1,
                    activities: ['Scenario'],
                },
            ],
        },
        {
            title: ' 02 Geometry',
            description:' Study of how energy flows through materials',
            progress: 20,
            svg:geometry,
            availability:0,
            subtopics: [
                {
                    title: ' Points, Lines and planes',
                    description:'Equilibrium is about balance that results in rest of an object',
                    progress: 0,
                    availability:0,
                    activities: ['A15 Coulombs law', 'A16 Electric field Intensity'],
                },
                {
                    title: ' Triangles and quadrilaterals',
                    description:'Equilibrium is about balance that results in rest of an object',
                    progress: 0,
                    availability:0,
                    activities: ['A17 Series', 'A18 Parallel','A19 combination Circuits'],
                },
                {
                  title: ' Circles',
                  progress: 0,
                  availability:0,
                  description:'Equilibrium is about balance that results in rest of an object',
                  activities: ['A20 Ammeters', 'A21 Voltmeters','A22 Ohmeters'],
              },
              {
                title: 'Test - Algebra ',
                description:'try out this test to ',
                progress: 0,
                availability:1,
                activities: ['Scenario'],
            },
            ],
        },{
          title: ' 03 Trigonometry',
          progress: 0,
          svg:trig,
          availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
          subtopics: [
              {
                  title: ' Triangles and Angles',
                  progress: 0,
                  availability:0,
                  activities: ['A23 Laws of reflection', 'A24 Total Internal Reflection'],
              },
              {
                  title: ' Graphs of trigonometric functions',
                  progress: 0,
                  availability:0,
                  activities: ['A25 Types of Lenses', 'A26 Lens equations','A27 optical Instruments'],
              },
              {
                title: 'Test - Trigonometry ',
                description:'try out this test to ',
                progress: 0,
                availability:1,
                activities: ['Scenario'],
            },
          ],
      },
      {
        title: ' 05 Statistics',
        progress: 20,
        svg:bar,
        availability:0,
        description:'Equilibrium is about balance that results in rest of an object',
        subtopics: [
            {
                title: ' Data analysis',
                description:'Equilibrium is about balance that results in rest of an object',
                progress: 0,
                availability:0,
                activities: ['A28 Mechanical', 'A29 Electromagnetic','A30 Longitudinal','A31 transverse'],
            },
            {
                title: 'Probability',
                description:'Equilibrium is about balance that results in rest of an object',
                progress: 0,
                availability:0,
                activities: ['A32 Speed', 'A33Frequency','A34 Wavelength', 'A35 Intensity'],
            },
            {
              title: 'Superposition & Interference',
              description:'Equilibrium is about balance that results in rest of an object',
              progress: 0,
              availability:0,
              activities: ['A36 Principle of superposition', 'A37 interferance Patterns'],
          },
          {
            title: 'Test - Statistics ',
            description:'try out this test to ',
            progress: 0,
            availability:1,
            activities: ['Scenario'],
        },
        ],
    }, 
{
  title: '06 Coordinate Geometry',
  progress: 20,
  svg:geometry,
  availability:0,
  description:'Equilibrium is about balance that results in rest of an object',
  subtopics: [
      {
          title: 'Catesian Coordinates',
          progress: 0,
          description:'Equilibrium is about balance that results in rest of an object',
          availability:0,
          activities: ['A37 Conduction', 'A38 Convection', 'A39 Radiation'],
      },
      {
          title: 'Transformations',
          progress: 0,
          availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
          activities: ['A40 Temperature', 'A41 Heat Capacity','A42 Specific Heat Capacity'],
      },
      {
        title: 'Test - Coordinate Geometry ',
        description:'try out this test to ',
        progress: 0,
        availability:1,
        activities: ['Scenario'],
    },
      
  ],
},  
{
  title: ' 07 Calculus',
  progress: 20,
  svg:calculus,
  availability:0,
  subtopics: [
      {
          title: 'Differentiation',
          progress: 0,
          availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
          activities: ['A47 Magnetic Field Lines', 'A48 Magnetic Flux'],
      },
      {
          title: 'Integration',
          progress: 0,
          availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
          activities: ['A49 Faradays Law', 'A50 Lens Law'],
      },
     
  ],
},
    
    ]);

    const [editingIndex, setEditingIndex] = useState(null);
    const [editingSubtopic, setEditingSubtopic] = useState({ topicIndex: null, subtopicIndex: null });
    const [newTitle, setNewTitle] = useState('');
    const progressValue = 50;
    const progressValue2 = 20;
    const progressValue3 = 10;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isRevealed, setIsRevealed] = useState(false);
    const [revealedDiv2, setRevealedDiv2] = useState(false);
    const [revealedDiv1, setRevealedDiv1] = useState(false);
    const [isDiv1Expanded, setIsDiv1Expanded] = useState(false);
    const [isDiv2Expanded, setIsDiv2Expanded] = useState(false);
    const [isDiv2Clicked, setDiv2Clicked] = useState(false);
    const [viewMode, setViewMode] = useState('topics'); // 'topics', 'subtopics', 'activities'
    const [currentTopic, setCurrentTopic] = useState('01 Algebra');
    const [currentSubtopic, setCurrentSubtopic] = useState(' ');
    const [currentdesc, setcurrentdesc] = useState('Variables and their relationships ');
    const [currentProgress, setcurrentProgress] = useState(progressValue);

    const [showsubtopic, setshowsubtopic] = useState(false);

    const handleToggleSubtopics = (index, topicTitle,topicDescription,topicprogress) => {
      setCurrentTopic(topicTitle);
      setCurrentSubtopic(''); // Reset subtopic when a new topic is selected
      setcurrentdesc(topicDescription);
      setcurrentProgress(topicprogress);
      setshowsubtopic(false);

      const subtopicElement = document.getElementById(`subtopics-${index}`);
      const isOpen = subtopicElement.style.height !== '0px';
  
      gsap.to(subtopicElement, {
          height: isOpen ? 0 : 'auto',
          opacity: isOpen ? 0 : 1,
          duration: 0.3,
      });
  };
  
  const handleToggleActivities = (topicIndex, subIndex, subtopicTitle,subtopicdescription,subtopicprogress,svgicon) => {
    setshowsubtopic(true);
    setCurrentSubtopic(subtopicTitle);
    setcurrentdesc(subtopicdescription);
setcurrentProgress(subtopicprogress);
    const activityElement = document.getElementById(`activities-${topicIndex}-${subIndex}`);
    const isOpen = activityElement.style.height !== '0px';

    gsap.to(activityElement, {
        height: isOpen ? 0 : 'auto',
        opacity: isOpen ? 0 : 1,
        duration: 0.3,
    });
};

const updateTopicProgress = (topicIndex, newProgress) => {
  setTopics(prevTopics => {
    // Create a copy of the topics array
    const updatedTopics = [...prevTopics];
    
    // Update the progress for the specific topic
    updatedTopics[topicIndex] = {
      ...updatedTopics[topicIndex], // Copy the existing topic
      progress: newProgress          // Set the new progress value
    };
    
    // Return the updated array to update state
    return updatedTopics;
  });
};

    const handleSelectViewMode = (mode) => {
      setViewMode(mode);
    };
  
    // Render functions based on viewMode
    const renderTopics = () => (
      topics.map((topic, index) => (
        <div key={`topic-${index}`}>
          {/* Topic Div */}
          <div
            style={{
              display: 'flex',
              border: '1px solid #ccc',
              padding: '10px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              marginBottom: '10px',
              fontWeight: 'bold',
              backgroundColor: topic.availability === 0 ? '#FFFFFF' : '#DDEFFD',  // Check for availability and set color
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => handleToggleSubtopics(index, topic.title,topic.description,topic.progress,topic.svg)}
          >
            <img
      src={topic.svg}
      alt="Lightning Icon"
      style={{ width: '50px', height: '50px', marginRight: '8px' }}
    />
            <span>{topic.title}</span>
            
            <ProgressBar progress={topic.progress} label="Progress:" />
            </div>
    
          {/* Subtopics */}
          <div
            id={`subtopics-${index}`}
            style={{
              height: 0,
              overflow: 'hidden',
              opacity: 0,
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '20px',
              fontSize: '0.9em',
            }}
          >
            {topic.subtopics.map((subtopic, subIndex) => (
              <div key={`subtopic-${index}-${subIndex}`}>
                {/* Subtopic Div */}
                <div
                  style={{
                    display: 'flex',
                    border: '0.5px solid #ccc',
                    padding: '8px',
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                    backgroundColor: subtopic.availability === 0 ? '#FFFFFF' : '#FBF6CD',  // Check for availability and set color                    marginBottom: '5px',
                    cursor: 'pointer',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => handleToggleActivities(index, subIndex, subtopic.title,subtopic.description,subtopic.progress)}
                >
                
                    <span>{subtopic.title}</span>
                
                  
                </div>
    
                {/* Activities */}
                <div
                  id={`activities-${index}-${subIndex}`}
                  style={{
                    height: 0,
                    overflow: 'hidden',
                    opacity: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '40px',
                    fontSize: '0.8em',
                  }}
                >
                  {subtopic.activities.map((activity, activityIndex) => (
                     <div
                     key={`activity-${index}-${subIndex}-${activityIndex}`}
                     style={{ marginBottom: '5px', padding: '5px 0' }}
                   >
                  { activity==='Basic' &&(
                       <Link to="/mlearn" style={{ textDecoration: 'none', color: 'inherit' }}>
                         {activity}
                       </Link>
                     )}
                  { activity==='Quadratic' &&(
                       <Link to="/mlearn2" style={{ textDecoration: 'none', color: 'inherit' }}>
                         {activity}
                       </Link>
                     )}
                  { activity==='Scenario' &&(
                       <Link to="/mlearn3" style={{ textDecoration: 'none', color: 'inherit' }}>
                         {activity}
                       </Link>
                     )}
                     {activityIndex > 1 && <span>{activity}</span>}
                   </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))
    );
    
    const renderSubtopics = () => (
      topics.map((topic, index) => (
        <div key={`topic-${index}`}>
          {topic.subtopics.map((subtopic, subIndex) => (
            <div key={`subtopic-${index}-${subIndex}`}>
              {/* Subtopic Div */}
              <div
                style={{
                  display: 'flex',
                  border: '0.5px solid #ccc',
                  padding: '8px',
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#FBF6CD',
                  marginBottom: '5px',
                  cursor: 'pointer',
                  justifyContent: 'space-between',
                }}
                onClick={() => handleToggleActivities(index, subIndex, subtopic.title)}
              >
                <span>{subtopic.title}</span>
              </div>
    
              {/* Activities */}
              <div
                id={`activities-${index}-${subIndex}`}
                style={{
                  height: 0,
                  overflow: 'hidden',
                  opacity: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '40px',
                  fontSize: '0.8em',
                }}
              >
                {subtopic.activities.map((activity, activityIndex) => (
                 <div
                 key={`activity-${index}-${subIndex}-${activityIndex}`}
                 style={{ marginBottom: '5px', padding: '5px 0' }}
               >
                 { activity==='Basic' && (
                   <Link to="/mlearn" style={{ textDecoration: 'none', color: 'inherit' }}>
                     {activity}
                   </Link>
                 )}
                  { activity==='Quadratic' &&(
                   <Link to="/mlearn2" style={{ textDecoration: 'none', color: 'inherit' }}>
                     {activity}
                   </Link>
                 )}
                { activity==='Scenario' &&(
                   <Link to="/mlearn3" style={{ textDecoration: 'none', color: 'inherit' }}>
                     {activity}
                   </Link>
                 )}
                 {activityIndex > 1 && <span>{activity}</span>}
               </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))
    );
    
    const renderActivities = () => (
      topics.flatMap((topic, index) => 
        topic.subtopics.flatMap((subtopic, subIndex) => 
          subtopic.activities.map((activity, activityIndex) => (
            <div key={`activity-${index}-${subIndex}-${activityIndex}`} style={{ marginBottom: '5px', padding: '5px 0' }}>
              <Link to={`/activity`} style={{ textDecoration: 'none', color: 'blue' }}>
                {activity}
              </Link>
            </div>
          ))
        )
      )
    );
    
    
    
   

    return (
        <>
            {isMobile ? (
                <div>
                    <div style={{ userSelect: 'none' }}>
                        {/* Fixed Header Card */}
                      <PhysicsHeader />


                        <div style={{ position: 'fixed',marginBottom:'90px',  right: '150px', zIndex: 30 }}>
              <Dropdown  style={{top:'320px'}} onSelect={handleSelectViewMode}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  View Mode
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="topics">Topics</Dropdown.Item>
                  <Dropdown.Item eventKey="subtopics">Subtopics</Dropdown.Item>
                  <Dropdown.Item eventKey="activities">Activities</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

                        <div className="fixed-card" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '100px' }}>
                            {/* Fixed Card on top */}
                            <Card style={{
                                width: '85%',
                                position: 'fixed', top: '12%',
                                maxWidth: '400px',
                                marginLeft: '30px',
                                marginBottom: '20px',
                                paddingBottom:'10px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                border: '2px solid #000',
                                zIndex:5
                            }}>
                                <Card.Header style={{ backgroundColor: '#f8f9fa' }}>
                                <h6>Current Progress</h6>
</Card.Header>
                                  <Card.Body>
        <h1> Topic: {currentTopic}</h1>
        <h4 style={{fontWeight:'normal'}}>
          {showsubtopic && (
           <span>
           SubTopic:
           </span> 
            
            )}
          {currentSubtopic || ' '}</h4>
        <h6 style={{fontWeight:'normal', color:'#3B3B3B'}}>
          <span style={{fontWeight:'bold', color:'#1D1D1D'}}>
          Summary: 
          </span>
          <span style={{padding:'5px'}}>
          {currentdesc}
            </span>
            <div>
            <Player
        ref={animationRef}
        src={algebrahighlight}
        loop={true}
        autoplay={true}
        style={{width:'200px',height:'50px'}}

      />

            </div>
         </h6>        <ProgressBar progress={currentProgress} label="Progress:" />
        <Button variant="primary">
        {currentSubtopic.includes("Quadratic Equations") ? (
        <Link to="/mlearn2" style={{ color: "white", textDecoration: "none" }}>
          Start
        </Link>
      ) : currentSubtopic.includes("Equations") ? (
        <Link to="/mlearn" style={{ color: "white", textDecoration: "none" }}>
          Start
        </Link>
      ) : (
        <span>Start</span>
      )}
</Button>
    </Card.Body>
                            </Card>

                            {/* Scrolling Card on the Right */}
                            <Card style={{
                                width: '85%',
                                position:'relative',
                                marginTop:'300px',
                                maxWidth: '700px',
                                marginLeft: '30px',
                                padding: '20px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                border: '2px solid #000',
                            }}>
                              <Card.Body>
  <h4>Contents</h4>

  {/* Three Boxes for Subtopics */}
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
    
    {/* Box 1: Dynamic Content Based on viewMode */}
    <div style={{ width: '50px', fontWeight: 'bold' }}></div>
    <div style={{ flex: 1 }}>
      {/* Render content based on viewMode */}
      {viewMode === 'topics' && renderTopics()}
      {viewMode === 'subtopics' && renderSubtopics()}
      {viewMode === 'activities' && renderActivities()}
    </div>

  
  </div>
</Card.Body>

                            </Card>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div style={{ userSelect: 'none' }}>
                        {/* Fixed Header Card */}
                        <PhysicsHeader />

                        <div style={{ position: 'fixed', top: '50px', right: '150px', zIndex: 1001 }}>
              <Dropdown onSelect={handleSelectViewMode}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  View Mode
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={renderTopics} eventKey="topics">Topics</Dropdown.Item>
                  <Dropdown.Item onClick={renderSubtopics} eventKey="subtopics">Subtopics</Dropdown.Item>
                  <Dropdown.Item onClick={renderActivities} eventKey="activities">Activities</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
                        <div style={{ display: 'flex', position: 'relative', marginTop: '100px' }}>
                            {/* Fixed Card on the Left */}
                            <Card style={{
                                width: '500px',
                                height: '300px',
                                position: 'fixed',
                                top: '100px',
                                left: '120px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                border: '2px solid #000',
                                zIndex: 1000,
                            }}>
                                <Card.Body>
        <h6>Current Progress</h6>
        <h1 style={{fontWeight:'bolder'}}> Topic: {currentTopic}</h1>
        <h4 style={{fontWeight:'normal'}}>
          {showsubtopic && (
           <span>
           SubTopic:
           </span> 
            
            )}
          {currentSubtopic || ' '}</h4>        <h6 style={{fontWeight:'normal', color:'#3B3B3B'}}>
          <span style={{fontWeight:'bold', color:'#1D1D1D'}}>
          Summary: 
          </span>
          <span style={{padding:'5px'}}>
          {currentdesc}
            </span>
         </h6>
         <div style={{alignContent:'end',justifyContent:'left',left:'200px'}}>
            <Player
        ref={animationRef}
        src={algebrahighlight}
        loop={true}
        autoplay={true}
        style={{width:'200px',height:'50px'}}
      />

            </div>
        <ProgressBar progress={currentProgress} label="Progress:" />
        <Button variant="primary">
        {currentSubtopic.includes("Quadratic Equations") ? (
        <Link to="/mlearn2" style={{ color: "white", textDecoration: "none" }}>
          Start
        </Link>
      ) : currentSubtopic.includes("Equations") ? (
        <Link to="/mlearn" style={{ color: "white", textDecoration: "none" }}>
          Start
        </Link>
      ) : (
        <span>Start</span>
      )}
</Button>
    </Card.Body>
                            </Card>

                            {/* Scrolling Card on the Right */}
                            <div style={{ marginLeft: '640px' }}>
                                <Card style={{
                                    height: '1500px',
                                    width: '700px',
                                    padding: '20px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    border: '2px solid #000',
                                }}>
                                 <Card.Body>
  <h4>Topics</h4>

  {/* Three Boxes for Subtopics */}
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
    
    {/* Box 1: Dynamic Content Based on viewMode */}
    <div style={{ width: '50px', fontWeight: 'bold' }}></div>
    <div style={{ flex: 1 }}>
      {/* Render content based on viewMode */}
      {viewMode === 'topics' && renderTopics()}
      {viewMode === 'subtopics' && renderSubtopics()}
      {viewMode === 'activities' && renderActivities()}
    </div>

    

   
  </div>
</Card.Body>

                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MathTopics;
