import axios from 'axios';

export class axiosAction {
    static async get(url) {
        const response = await axios.get(url);
        return response.data;
    }
    static async post(url, data) {
        const response = await axios.post(url, data);
        return response.data;
    }
}