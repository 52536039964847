import React, { useRef,useState,useEffect } from "react";
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link component
import linear from '../jsons/linearmotion.json';
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingPage from '../LoadingPages/LoadingPage';

const Physteps = () => {
    const introref = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    const nextPageRoute = () => {
        return <Link to="/subtopics" />;
      };

      useEffect(() => {
        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 4500);
    
        return () => clearTimeout(timer);
      }, []);

    return (
        <>



    <div>
    {/* Fixed Header Card */}
    <Card style={{
        position: 'fixed',
        top: 0,
        width: '100%',
        height: '80px',
        textAlign: 'center',
        zIndex: 1000, // Ensures it stays on top
        color: 'black',
        fontFamily: 'helvetica',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f8f9fa'
    }}>
        <h2>Physics Steps</h2>
    </Card>

    {/* Container with margin-top to avoid overlap */}
    <Container className="d-flex justify-content-center align-items-center" style={{ marginTop: '100px' }}>
        <Row className="justify-content-center align-items-center flex-column">
            {/* Topics Card */}
            <Col xs={12} className="mb-4">
                <Card style={{
                    textAlign: 'center',
                    backgroundColor: '#f8f9fa',
                    padding: '20px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Reduced shadow
                    border: '2px solid #ccc' // Increased stroke
                }}>
                    <Card.Body>
                        <h4>Topics</h4>
                    </Card.Body>
                </Card>
            </Col>

            {/* Flex Row for Cards */}
            <Col xs={12} className="d-flex justify-content-around flex-wrap">
                {/* Linear Motion Card */}
                <Link to="/subtopics" style={{ textDecoration: 'none' }}> {/* Wrap card in Link */}
                    <Card style={{
                        textAlign: 'center',
                        backgroundColor: '#f8f9fa',
                        padding: '20px',
                        width: '300px',
                        height: '300px',
                        margin: '10px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Reduced shadow
                        border: '2px solid #ccc' // Increased stroke
                    }}>
                        <Card.Body>
                            <h4>Linear Motion</h4>
                            <Player ref={introref} autoplay={true} loop={true} src={linear} style={{ width: '100%', height: '200px' }} />
                        </Card.Body>
                    </Card>
                </Link>

                {/* Refraction Card */}
                <Card style={{
                    textAlign: 'center',
                    backgroundColor: '#f8f9fa',
                    padding: '20px',
                    width: '300px',
                    height: '300px',
                    margin: '10px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Reduced shadow
                    border: '2px solid #ccc' // Increased stroke
                }}>
                    <Card.Body>
                        <h4>Refraction</h4>
                    </Card.Body>
                </Card>

                {/* Electricity Card */}
                <Card style={{
                    textAlign: 'center',
                    backgroundColor: '#f8f9fa',
                    padding: '20px',
                    width: '300px',
                    height: '300px',
                    margin: '10px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Reduced shadow
                    border: '2px solid #ccc' // Increased stroke
                }}>
                    <Card.Body>
                        <h4>Electricity</h4>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
</div>

    </>


    );
};

export default Physteps;
