import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { FaUserCircle } from "react-icons/fa";
import "./profilepage.css";
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import PhysicsHeader from "../PhysicsHeader";
const auth = getAuth();
const db = getFirestore();
// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const ProfilePage = () => {
  const [username, setUsername] = useState("Username");
  const [userUID, setUserUID] = useState(null);
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [authenticate, setAuthenticate] = useState(true);
  // Simulated database data
  const [userPerformance, setUserPerformance] = useState({
    Math: 80,
    Physics: 70,
    Chemistry: 60,
  });

  // Prepare data for the chart
  const chartData = {
    labels: ["Math", "Physics", "Chemistry"],
    datasets: [
      {
        label: "Performance (%)",
        data: Object.values(userPerformance),
        backgroundColor: ["#007bff", "#28a745", "#ffc107"],
        borderColor: ["#0056b3", "#1e7e34", "#d39e00"],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.raw}%`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
  };
  useEffect(() => {
    const fetchUserData = async (user) => {
      try {
        const userDoc = await getDoc(doc(db, 'usersp', 'PHY'));
        if (userDoc.exists()) {
          setUsername(userDoc.data().name);
          setUserUID('PHY');
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUsername('Guest');
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticate(false);
        fetchUserData(user);
      } else {
        setAuthenticate(true);
      }
    });
  }, []);

 
  return (
    <Container className="profile-page-container mt-5">
      <Row>
        {/* User Profile Card */}
        <Col md={4} className="mb-4">
          <Card className="text-center">
            <Card.Header className="profile-card-header">
              <h4>{username}</h4>
            </Card.Header>
            <Card.Body>
              <FaUserCircle size={100} className="user-icon" />
            </Card.Body>
            <Card.Footer>
              <h6>Rank: #5</h6>
            </Card.Footer>
          </Card>
        </Col>

        {/* Performance Section */}
        <Col md={8}>
          <Card className="performance-card">
            <Card.Header className="performance-card-header">
              <h5>User Performance</h5>
            </Card.Header>
            <Card.Body>
              <Bar data={chartData} options={chartOptions} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePage;
