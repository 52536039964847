import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/all";
import lottie from "lottie-web";
import ex1 from '../jsons/colorbar.json';

const Exp2 = () => {
  const [animation, setAnimation] = useState(null);
  const animationRef = useRef(null);

  useEffect(() => {
 
  }, []);

  const handleMouseEnter = () => {
  };

  const handleMouseLeave = () => {

  };

  return (
    <div>
      <div
        ref={animationRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};

export default Exp2;
