// NotificationIcon.js
import React from 'react';
import { FaBell } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationIcon = ({ message }) => {
  const handleIconClick = () => {
    toast.info(message, {
        position: 'top-center', // Directly use the string value for the position
        autoClose: 5000, // 5 seconds
    });
  };

  return (
    <div className="notification-icon" onClick={handleIconClick} style={{ cursor: 'pointer' }}>
      <FaBell size={24} />
    </div>
  );
};

export default NotificationIcon;
