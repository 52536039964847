import React, { useEffect, useRef, useState } from "react";
import { Card, Dropdown, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ProgressBar from '../LoadingPages/ProgressBar';
import gsap from 'gsap';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc,updateDoc,onSnapshot } from 'firebase/firestore';
import Signup from '../Signup';
import Login from '../Login';
import PhysicsHeader from "../PhysicsHeader";
import { fetchmEpScore } from '../authp';
import lightning from '../svgs/lightning.svg';
import bulb from '../svgs/light-bulb.svg';
//import mechanics from '../svgs/gears.svg';
import temperature from '../jsons/svgs/temperature.svg';
import magnet from '../svgs/magnet.svg';
import matter from '../svgs/matter.svg';
import mechanics from '../jsons/svgs/arm-lever.svg';
import missionImage from '../jsons/svgs/science.svg';

import emoticon from '../jsons/svgs/emoticon.svg';

import measure from '../svgs/measure.svg';

import waves from '../svgs/sound-waves.svg';

const auth = getAuth();
const db = getFirestore();
const Subtopics = () => {
  const [userUID, setUserUID] = useState(null); // Store the user UID
  const [authenticate, setauthenticate] = useState(true);
  const [logged, setlogged] = useState(true);
  const [mEpScore, setMEpScore] = useState(0);
  const [mDpScore, setMDpScore] = useState(0);
  const [mKpScore, setMKpScore] = useState(0);

  const [mEpfail, setMEpfail] = useState(0);
  const [mDpfail, setMDpfail] = useState(0);
  const [mKpfail, setMKpfail] = useState(0);

  useEffect(() => {
    let unsubscribe;

    if (userUID) {
      const userDocRef = doc(db, 'usersp', 'PHY');
      unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          console.error('show marks');

          setMEpScore(docSnapshot.data().mEp || 0);
          setMDpScore(docSnapshot.data().mDp || 0);
          setMKpScore(docSnapshot.data().mKp || 0);

          setMEpfail(docSnapshot.data().mEf || 0);
          setMDpfail(docSnapshot.data().mDf || 0);
          setMKpfail(docSnapshot.data().mKf || 0);
         // setMarks(mep+mdp+mkp);
        //  setfail(docSnapshot.data().Mn || 0);
        } else {
          console.error('failed to show marks');
        }
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userUID,mEpScore]);
 


    const [topics, setTopics] = useState([
      
        {
            title: ' 01 Mechanics',
            progress: 50,
            svg:mechanics,
            availability:0,
            description:'  Learn and see how things move, respond to forces, and interact with each other.',
            subtopics: [
              {
                title: '02 Equilibrium',
                progress: 90,
                availability:1,
                description:' Equilibrium is about balance that results in rest of an object.',
                activities: ['Center of Gravity', 'A02 Conditions for Equilibrium', 'Couples'],
                score: 1  ,
                failed:1,
            },
                {
                    title: '03 Dynamics',
                    description:' Dynamics is the branch of mechanics that studies the forces and torques that cause motion and changes in motion.',
                    progress: 10,
                    availability:1,
                    activities: ['Forces & Moments', '04 Newtons laws', 'A05 Momentum','06 Energy','A07 Work',' A08 Power'],
                    score: 2,
                    failed:2,

                },
                {
                    title: '04 Kinematics',
                    description:'Kinematics is the branch of mechanics that deals with the motion of objects without considering the forces that cause this motion.',
                    progress: 20,
                    availability:0,
                    activities: ['Motion','10 Distance','11 Displacement','A12 speed','13 Linear Motion', 'A14 Non-linear motion',''],
                    score: 3,
                    failed:3,


                },
            ],
        },
        {
            title: '05 Electricity',
            description:' Study of how energy flows through materials',
            progress: 0,
            lotti:mechanics,
            svg:lightning,
            availability:0,
            subtopics: [
                {
                    title: ' Electric Charges & Fields',
                    description:'Electric charge is a fundamental property of matter that causes it to experience a force when placed in an electromagnetic field.',
                    progress: 0,
                    availability:0,
                    activities: ['A15 Coulombs law', 'A16 Electric field Intensity'],
                },
                {
                    title: ' Electric Circuits',
                    description:'An electric circuit is a closed loop or pathway that allows electric charges to flow.',
                    progress: 0,
                    availability:0,
                    activities: ['A17 Series', 'A18 Parallel','A19 combination Circuits'],
                },
                {
                  title: ' Electric Measurements',
                  progress: 0,
                  availability:0,
                  description:'Electric measurements involve quantifying electrical quantities such as voltage, current, resistance, and power in an electrical circuit. ',
                  activities: ['A20 Ammeters', 'A21 Voltmeters','A22 Ohmeters'],
              },
            ],
        },{
          title: '06 Light & Optics',
          progress: 0,
          svg:bulb,
          availability:0,
          description:'Light is a form of electromagnetic radiation that is visible to the human eye. Optics is the branch of physics that studies the behavior and properties of light, including its interactions with matter and the design of optical instruments.',
          subtopics: [
              {
                  title: ' Reflection & Refraction',
                  progress: 0,
                  availability:0,
                  description:'Reflection is the bouncing back of light (or other waves) when it encounters a surface or interface. Refraction is the bending of light as it passes from one medium to another, caused by a change in its speed.',
                  activities: ['A23 Laws of reflection', 'A24 Total Internal Reflection'],
              },
              {
                  title: ' Lenses and optic',
                  progress: 0,
                  availability:0,
                  description:'Lenses are transparent optical devices that refract light to focus or diverge it, playing a crucial role in various optical systems, including eyeglasses, cameras, microscopes, and telescopes. ',

                  activities: ['A25 Types of Lenses', 'A26 Lens equations','A27 optical Instruments'],
              },
          ],
      },
      {
        title: '07 Sound & Waves',
        progress: 0,
        availability:0,
        svg:waves,
        description:'Sound is a type of mechanical wave that propagates through a medium (such as air, water, or solids) as a result of vibrations.  Waves are disturbances that transfer energy from one location to another without the transfer of matter.',
        subtopics: [
            {
                title: ' Types of Waves',
                description:'Mechanical and Electromagnetic',
                progress: 0,
                availability:0,
                activities: ['A28 Mechanical', 'A29 Electromagnetic','A30 Longitudinal','A31 transverse'],
            },
            {
                title: 'SoundWaves',
                description:'Sound is a type of mechanical wave that propagates through a medium (such as air, water, or solids) as a result of vibrations.',
                progress: 0,
                availability:0,
                activities: ['A32 Speed', 'A33Frequency','A34 Wavelength', 'A35 Intensity'],
            },
            {
              title: 'Superposition & Interference',
              description:'Equilibrium is about balance that results in rest of an object',
              progress: 0,
              availability:0,
              activities: ['A36 Principle of superposition', 'A37 interferance Patterns'],
          },
        ],
    }, 
{
  title: '08 Heat & Thermodynamics',
  progress: 20,
  svg:temperature,
  availability:0,
  description:'Heat is a form of energy that is transferred between systems or bodies due to a temperature difference.',
  subtopics: [
      {
          title: 'Heat Transfer',
          progress: 0,
          description:'Heat Transfer is the process by which thermal energy moves from one physical system to another, driven by temperature differences. Understanding heat transfer is essential in various fields, including engineering, meteorology, and environmental science, as it governs how energy is exchanged in systems.',
          availability:0,
          activities: ['A37 Conduction', 'A38 Convection', 'A39 Radiation'],
      },
      {
          title: 'Thermodynamic Systems',
          progress: 0,
          availability:0,
          description:' Thermodynamic Systems are specific portions of matter or space selected for analysis in thermodynamics a branch of physics that studies heat, work, temperature and energy transfer.',
          activities: ['A40 Temperature', 'A41 Heat Capacity','A42 Specific Heat Capacity'],
      },
      {
        title: 'Laws of Thermodynamics',
        progress: 0,
        availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
        activities: ['A43 Zeroth', 'A44 First','A45 Second','A46 ThirdLaw'],
    },
  ],
},  
{
  title: '09 Magnetism & ElectroMagnetism',
  progress: 0,
  svg:magnet,
  availability:0,
  description:'Magnetism is a physical phenomenon produced by the motion of electric charge, which results in attractive and repulsive forces between objects',
  subtopics: [
      {
          title: 'Magnetic Fields',
          progress: 0,
          availability:0,
          description:'Magnetic Fields are regions around a magnetic material or a moving electric charge within which magnetic forces can be observed.',
          activities: ['A47 Magnetic Field Lines', 'A48 Magnetic Flux'],
      },
      {
          title: 'Electro-magnetic Induction',
          progress: 0,
          availability:0,
          description:'Electromagnetic Induction is the process by which a changing magnetic field within a closed loop of wire induces an electromotive force (EMF) and, consequently, an electric current in the wire. ',
          activities: ['A49 Faradays Law', 'A50 Lens Law'],
      },
      {
        title: 'Alternating Current (AC)',
        progress: 0,
        availability:0,
          description:'Alternating Current (AC) is a type of electrical current in which the flow of electric charge periodically reverses direction.',
        activities: ['Activity 1', 'Activity 2'],
    },
  ],
},
{
  title: '10 Measurement & Units',
  progress: 0,
  svg:missionImage,

  svg:measure,
  availability:0,
  subtopics: [
      {
          title: 'Fundamental & Derived Quantities',
          progress: 0,
          availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
          activities: ['Activity 1', 'Activity 2'],
      },
      {
          title: 'Units & Dimensions',
          progress: 0,
          availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
          activities: ['Activity 1', 'Activity 2'],
      },
      {
        title: 'Measurement Errors',
        progress: 0,
        availability:0,
        description:'Equilibrium is about balance that results in rest of an object',
        activities: ['Activity 1', 'Activity 2'],
    },
    {
      title: 'Significant Figures',
      progress: 0,
      availability:0,
      description:'Equilibrium is about balance that results in rest of an object',
      activities: ['Activity 1', 'Activity 2'],
  },
  ],
},
{
title: '11 Properties of Matter',
progress: 0,
svg:matter,
description:' These properties help us understand and characterize different substances and their behavior in various conditions.',
availability:0,
subtopics: [
    {
        title: 'Density & Pressure',
        progress: 0,
        availability:0,
        description:'Density is a physical property of matter defined as the mass of an object per unit volume.',
        activities: ['Activity 1', 'Activity 2'],
    },
    {
        title: 'Temperature & Thermometry',
        progress: 0,
        availability:0,
        description:'Temperature is a fundamental physical quantity that measures the average kinetic energy of the particles in a substance.',
        activities: ['Activity 1', 'Activity 2'],
    },
    {
      title: 'Thermal Expansion',
      progress: 0,
      availability:0,
      description:'Thermal expansion is the tendency of matter to change its shape, area, and volume in response to changes in temperature.',
      activities: ['Activity 1', 'Activity 2'],
  },
  {
    title: 'Elasiticity and Hookes Law',
    progress: 0,
    availability:0,
    description:'Elasticity refers to the capacity of a material to undergo deformation (stretching, compressing, bending, etc.) when subjected to external forces and to return to its original shape once those forces are removed.',
    activities: ['Activity 1', 'Activity 2'],
},
],
},     
    ]);

    const [editingIndex, setEditingIndex] = useState(null);
    const [editingSubtopic, setEditingSubtopic] = useState({ topicIndex: null, subtopicIndex: null });
    const [newTitle, setNewTitle] = useState('');
    const progressValue = 50;
    const progressValue2 = 20;
    const progressValue3 = 10;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isRevealed, setIsRevealed] = useState(false);
    const [revealedDiv2, setRevealedDiv2] = useState(false);
    const [revealedDiv1, setRevealedDiv1] = useState(false);
    const [isDiv1Expanded, setIsDiv1Expanded] = useState(false);
    const [isDiv2Expanded, setIsDiv2Expanded] = useState(false);
    const [isDiv2Clicked, setDiv2Clicked] = useState(false);
    const [viewMode, setViewMode] = useState('topics'); // 'topics', 'subtopics', 'activities'
    const [currentTopic, setCurrentTopic] = useState('Mechanics');
    const [currentSubtopic, setCurrentSubtopic] = useState(' ');
    const [currentdesc, setcurrentdesc] = useState('Learn and see how things move, respond to forces, and interact with each other ');
    const [currentProgress, setcurrentProgress] = useState(progressValue);
    const [svgicon, setsvgicon] = useState(mechanics);

    const [showsubtopic, setshowsubtopic] = useState(false);
    const [Username, setUsername] = useState('New user');
    const [Marks, setMarks] = useState('___');

    const handleToggleSubtopics = (index, topicTitle,topicDescription,topicprogress,svgicon) => {
      setCurrentTopic(topicTitle);
      setsvgicon(svgicon)
      setCurrentSubtopic(''); // Reset subtopic when a new topic is selected
      setcurrentdesc(topicDescription);
      setcurrentProgress(topicprogress);
      setshowsubtopic(false);

      const subtopicElement = document.getElementById(`subtopics-${index}`);
      const isOpen = subtopicElement.style.height !== '0px';
  
      gsap.to(subtopicElement, {
          height: isOpen ? 0 : 'auto',
          opacity: isOpen ? 0 : 1,
          duration: 0.3,
      });
  };
  
  const handleToggleActivities = (topicIndex, subIndex, subtopicTitle,subtopicdescription,subtopicprogress) => {
    setshowsubtopic(true);
    setCurrentSubtopic(subtopicTitle);
    setcurrentdesc(subtopicdescription);
setcurrentProgress(subtopicprogress);
    const activityElement = document.getElementById(`activities-${topicIndex}-${subIndex}`);
    const isOpen = activityElement.style.height !== '0px';

    gsap.to(activityElement, {
        height: isOpen ? 0 : 'auto',
        opacity: isOpen ? 0 : 1,
        duration: 0.3,
    });
};

const updateTopicProgress = (topicIndex, newProgress) => {
  setTopics(prevTopics => {
    // Create a copy of the topics array
    const updatedTopics = [...prevTopics];
    
    // Update the progress for the specific topic
    updatedTopics[topicIndex] = {
      ...updatedTopics[topicIndex], // Copy the existing topic
      progress: newProgress          // Set the new progress value
    };
    
    // Return the updated array to update state
    return updatedTopics;
  });
};

    const handleSelectViewMode = (mode) => {
      setViewMode(mode);
    };
  
    // Render functions based on viewMode
    const renderTopics = () => (
      topics.map((topic, index) => (
        <div key={`topic-${index}`}>
          {/* Topic Div */}
          <div
            style={{
              display: 'flex',
              border: '1px solid #ccc',
              padding: '10px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              marginBottom: '10px',
              fontWeight: 'bold',
              backgroundColor: topic.availability === 0 ? '#FFFFFF' : '#DDEFFD',  // Check for availability and set color
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => handleToggleSubtopics(index, topic.title,topic.description,topic.progress,topic.svg)}
          >
              <img
      src={topic.svg}
      alt="Lightning Icon"
      style={{ width: '20px', height: '20px', marginRight: '8px' }}
    />
            <span>{topic.title}</span>
            
            <ProgressBar progress={topic.progress} label="Progress:" />
           
            </div>
    
          {/* Subtopics */}
          <div
            id={`subtopics-${index}`}
            style={{
              height: 0,
              overflow: 'hidden',
              opacity: 0,
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '20px',
              fontSize: '0.9em',
            }}
          >
            {topic.subtopics.map((subtopic, subIndex) => (
              <div key={`subtopic-${index}-${subIndex}`}>
                {/* Subtopic Div */}
                <div
                  style={{
                    display: 'flex',
                    border: '0.5px solid #ccc',
                    padding: '8px',
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                    backgroundColor: subtopic.availability === 0 ? '#FFFFFF' : '#FBF6CD',  // Check for availability and set color                    marginBottom: '5px',
                    cursor: 'pointer',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => handleToggleActivities(index, subIndex, subtopic.title,subtopic.description,subtopic.progress)}
                >
                
                    <span>{subtopic.title}</span>
                
                    <span>
  Score: {
    subtopic.score === 1
      ? mEpScore
      : subtopic.score === 2
      ? mDpScore
      : subtopic.score === 3
      ? mKpScore
      : subtopic.score // This handles other values or defaults to the score if it’s different from 0, 1, or 2
  }
</span>
<span>
  Failed: {
    subtopic.failed === 1
      ? mEpfail
      : subtopic.failed === 2
      ? mDpfail
      : subtopic.failed === 3
      ? mKpfail
      : subtopic.failed // This handles other values or defaults to the score if it’s different from 0, 1, or 2
  }
</span>
                </div>
    
                {/* Activities */}
                <div
                  id={`activities-${index}-${subIndex}`}
                  style={{
                    height: 0,
                    overflow: 'hidden',
                    opacity: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '40px',
                    fontSize: '0.8em',
                  }}
                >
                 {subtopic.activities.map((activity, activityIndex) => (
                     <div
                     key={`activity-${index}-${subIndex}-${activityIndex}`}
                     style={{ padding: '5px 0' }}
                   >
                  { activity==='Center of Gravity' &&(
                       <Link to="/physicsp1" >
                         {activity}
                       </Link>
                     )}
                  { activity==='Motion' &&(
                       <Link to="/activity" >
                         {activity}
                       </Link>
                     )}
                  { activity==='Scenario' &&(
                       <Link to="/mlearn3">
                         {activity}
                       </Link>
                     )}
                     {activityIndex > 1 && <span>{activity}</span>}
                   </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))
    );
    
    const renderSubtopics = () => (
      topics.map((topic, index) => (
        <div key={`topic-${index}`}>
          {topic.subtopics.map((subtopic, subIndex) => (
            <div key={`subtopic-${index}-${subIndex}`}>
              {/* Subtopic Div */}
              <div
                style={{
                  display: 'flex',
                  border: '0.5px solid #ccc',
                  padding: '8px',
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#FBF6CD',
                  marginBottom: '5px',
                  cursor: 'pointer',
                  justifyContent: 'space-between',
                }}
                onClick={() => handleToggleActivities(index, subIndex, subtopic.title)}
              >
                <span>{subtopic.title}</span>
                Score:{subtopic.score}
              </div>
    
              {/* Activities */}
              <div
                id={`activities-${index}-${subIndex}`}
                style={{
                  height: 0,
                  overflow: 'hidden',
                  opacity: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '40px',
                  fontSize: '0.8em',
                }}
              >
                {subtopic.activities.map((activity, activityIndex) => (
                    <div
                    key={`activity-${index}-${subIndex}-${activityIndex}`}
                    style={{ marginBottom: '5px', padding: '5px 0' }}
                  >
                  
                  
                    {activityIndex > 1 && <span>{activity}</span>}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))
    );
    
    const renderActivities = () => (
      topics.flatMap((topic, index) => 
        topic.subtopics.flatMap((subtopic, subIndex) => 
          subtopic.activities.map((activity, activityIndex) => (
            <div key={`activity-${index}-${subIndex}-${activityIndex}`} style={{ marginBottom: '5px', padding: '5px 0' }}>
              <Link to={`/activity`} style={{ textDecoration: 'none', color: 'blue' }}>
                {activity}
              </Link>
            </div>
          ))
        )
      )
    );
  


    useEffect(() => {
      const fetchUserData = async (user) => {
          try {
              console.log('Fetching user data for UID:',  'PHY'); // Debugging line
              const userDoc = await getDoc(doc(db, 'usersp', 'PHY'));
              if (userDoc.exists()) {
                  //setscore(userDoc.data().M1);

                  setUsername(userDoc.data().name);
                  setUserUID(user.uid); // Set the user UID
                  console.log('Username fetched:', userDoc.data().name);
                  console.log('mEp fetched:', userDoc.data().mEp);
                setMEpScore(userDoc.data().mEp);
                setMDpScore(userDoc.data().mDp);
                setMKpScore(userDoc.data().mKp);

                   // Debugging line
              } else {
                  console.error('No such document!');
              }
          } catch (error) {
              console.error('Error fetching user data:', error);
              setUsername('new person'); // Set default username if there's an error
              //here is where I want it
            
          }
      };

      onAuthStateChanged(auth, (user) => {
          if (user) {
              setauthenticate(false);
              fetchUserData(user);
          } else {
              setauthenticate(true);
          }
      });
  }, [ ]);
  
    
   

    return (
        <>
            {isMobile ? (
                <div>
                    <div style={{ userSelect: 'none' }}>
                        {/* Fixed Header Card */}
                        <Card style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '80px',
                            textAlign: 'center',
                            zIndex: 1000,
                            color: 'black',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#f8f9fa',
                            borderBottom: '2px solid #ccc'
                        }}>
                            <h2 style={{ alignContent:'end', fontSize: 'clamp(1.5rem, 2.5vw, 2rem)' }}>{Username}</h2>
                        </Card>


                        <div style={{ position: 'fixed',marginBottom:'70px',  right: '150px', zIndex: 30 }}>
              <Dropdown  style={{top:'310px'}} onSelect={handleSelectViewMode}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  View Mode
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="topics">Topics</Dropdown.Item>
                  <Dropdown.Item eventKey="subtopics">Subtopics</Dropdown.Item>
                  <Dropdown.Item eventKey="activities">Activities</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

                        <div className="fixed-card" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '100px' }}>
                            {/* Fixed Card on top */}
                            <Card style={{
                                width: '85%',
                                position: 'fixed', top: '12%',
                                maxWidth: '400px',
                                marginLeft: '30px',
                                marginBottom: '20px',
                                paddingBottom:'10px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                border: '2px solid #000',
                                zIndex:5
                            }}>
                                <Card.Header style={{ backgroundColor: '#f8f9fa' }}>
                                <h6>Current Progress</h6>
</Card.Header>
                                  <Card.Body>
                                  <img
      src={svgicon}
      alt="Lightning Icon"
      style={{ width: '40px', height: '40px', marginRight: '8px' }}
    />
        <h1> Topic: {currentTopic}</h1>
        <h4 style={{fontWeight:'normal'}}>
          {showsubtopic && (
           <span>
           SubTopic:
           </span> 
            
            )}
          {currentSubtopic || ' '}</h4>
        <h6 style={{fontWeight:'normal', color:'#3B3B3B'}}>
          <span style={{fontWeight:'bold', color:'#1D1D1D'}}>
          Summary: 
          </span>
          <span style={{padding:'5px'}}>
          {currentdesc}
            </span>
         </h6>        <ProgressBar progress={currentProgress} label="Progress:" />
        <Button variant="primary">
  {currentSubtopic.includes('Equilibrium') ? (
    <Link to="/physicsp1" style={{ color: 'white', textDecoration: 'none' }}>
            Start

    </Link>
  ) : currentSubtopic.includes('Dynamics') ? (
    <Link to="/activity" style={{ color: 'white', textDecoration: 'none' }}>
      Start
    </Link>
  ): currentSubtopic.includes('Kinematics') ? (
<Link to="/runner" style={{ color: 'white', textDecoration: 'none' }}>
      Start
    </Link>
  ): (
    <span>Start</span> // Fallback for other conditions
  )}
</Button>
    </Card.Body>
                            </Card>

                            {/* Scrolling Card on the Right */}
                            <Card style={{
                                width: '85%',
                                position:'relative',
                                marginTop:'300px',
                                maxWidth: '700px',
                                marginLeft: '30px',
                                padding: '20px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                border: '2px solid #000',
                            }}>
                              <Card.Body>
  <h4>Topics</h4>

  {/* Three Boxes for Subtopics */}
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
    
    {/* Box 1: Dynamic Content Based on viewMode */}
    <div style={{ width: '50px', fontWeight: 'bold' }}></div>
    <div style={{ flex: 1 }}>
      {/* Render content based on viewMode */}
      {viewMode === 'topics' && renderTopics()}
      {viewMode === 'subtopics' && renderSubtopics()}
      {viewMode === 'activities' && renderActivities()}
    </div>

  
  </div>
</Card.Body>

                            </Card>
                        </div>
                    </div>
                </div>
            ) : (
                <div>

 <div style={{ userSelect: 'none' }}>
 {/* Fixed Header Card */}
<PhysicsHeader />
 <div style={{ position: 'fixed', top: '50px', right: '150px', zIndex: 1001 }}>
<Dropdown onSelect={handleSelectViewMode}>
<Dropdown.Toggle variant="secondary" id="dropdown-basic">
View Mode
</Dropdown.Toggle>

<Dropdown.Menu>
<Dropdown.Item onClick={renderTopics} eventKey="topics">Topics</Dropdown.Item>
<Dropdown.Item onClick={renderSubtopics} eventKey="subtopics">Subtopics</Dropdown.Item>
<Dropdown.Item onClick={renderActivities} eventKey="activities">Activities</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
</div>
 <div style={{ display: 'flex', position: 'relative', marginTop: '100px' }}>
     {/* Fixed Card on the Left */}
     <Card style={{
         width: '500px',
         height: '300px',
         position: 'fixed',
         top: '110px',
         left: '120px',
         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
         border: '2px solid #000',
         zIndex: 1000,
     }}>
         <Card.Body>
<h6>Current Progress</h6>
<img
      src={svgicon}
      alt="Lightning Icon"
      style={{ width: '50px', height: '50px', marginRight: '8px' }}
    />
<h1 style={{fontWeight:'bolder'}}> Topic: {currentTopic}</h1>
<h4 style={{fontWeight:'normal'}}>
{showsubtopic && (
<span>
SubTopic:
</span> 

)}
{currentSubtopic || ' '}</h4>        <h6 style={{fontWeight:'normal', color:'#3B3B3B'}}>
<span style={{fontWeight:'bold', color:'#1D1D1D'}}>
Summary: 
</span>
<span style={{padding:'5px'}}>
{currentdesc}
</span>
</h6>

<ProgressBar progress={currentProgress} label="Progress:" />
<Button variant="primary">
{currentSubtopic.includes('Dynamics') ? (
<Link to="/activity" style={{ color: 'white', textDecoration: 'none' }}>
Start
</Link>
) : currentSubtopic.includes('Kinematics') ? (
<Link to="/runner" style={{ color: 'white', textDecoration: 'none' }}>
Start

</Link>
) : currentSubtopic.includes('Equilibrium') ?(
 <Link to="/physicsp1" style={{ color: 'white', textDecoration: 'none' }}>
Start
</Link> 
): (
<span>Start...</span> // Fallback for other conditions
)}
</Button>
</Card.Body>
     </Card>

     {/* Scrolling Card on the Right */}
     <div style={{ marginLeft: '640px' }}>
         <Card style={{
             height: '1400px',
             width: '700px',
             top:'10px',
             padding: '20px',
             boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
             border: '2px solid #000',
         }}>
          <Card.Body>
<h4>Topics</h4>

{/* Three Boxes for Subtopics */}
<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

{/* Box 1: Dynamic Content Based on viewMode */}
<div style={{ width: '50px', fontWeight: 'bold' }}></div>
<div style={{ flex: 1 }}>
{/* Render content based on viewMode */}
{viewMode === 'topics' && renderTopics()}
{viewMode === 'subtopics' && renderSubtopics()}
{viewMode === 'activities' && renderActivities()}
</div>




</div>
</Card.Body>

         </Card>
     </div>
 </div>
</div>

                
                   
                </div>
            )}
        </>
    );
};

export default Subtopics;
