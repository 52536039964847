import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

const Sim2 = ({ sendDataToParent }) => {
  const blueBoxRef = useRef(null);
  const redBoxRefs = useRef([]);
  const [boxColors, setBoxColors] = useState(Array(5).fill('red'));

  useEffect(() => {
    // GSAP Animation (Optional)
    gsap.fromTo(blueBoxRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
    redBoxRefs.current.forEach((box, index) => {
      gsap.fromTo(box, { opacity: 0 }, { opacity: 1, duration: 1, delay: index * 0.2 });
    });
  }, []);

  const sendData = () => {
    const data = true;
    sendDataToParent(data);
  };

  // Handle click event for red boxes
  const handleBoxClick = (index) => {
    setBoxColors(prevColors =>
      prevColors.map((color, i) =>
        i === index ? (index === 4 ? 'green' : 'black') : color // center box turns green, others turn black
      )
    );

    // If the center box (index 4) turns green, call sendData()
    if (index === 4) {
      sendData();
    }
  };

  // Define styles for the boxes
  const styles = {
    blueBox: {
      width: '200px',
      height: '200px',
      backgroundColor: 'yellow', // Changed to yellow
      border: '2px solid black', // Added black border
      position: 'relative',
    },
    redBox: {
      width: '30px',
      height: '30px',
      backgroundColor: 'red',
      position: 'absolute',
      cursor: 'pointer', // Add pointer cursor for clickable effect
    },
    cardBody: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '400px',
    },
  };

  // Red box positions
  const redBoxPositions = [
    { top: '0', left: '0' }, // top-left
    { top: '0', right: '0' }, // top-right
    { bottom: '0', left: '0' }, // bottom-left
    { bottom: '0', right: '0' }, // bottom-right
    { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }, // center
  ];

  return (
    <div ref={blueBoxRef} style={styles.blueBox}>
      {redBoxPositions.map((position, index) => (
        <div
          key={index}
          ref={el => (redBoxRefs.current[index] = el)}
          style={{ ...styles.redBox, ...position, backgroundColor: boxColors[index] }} // Set dynamic background color
          onClick={() => handleBoxClick(index)} // Add onClick event
        ></div>
      ))}
    </div>
  );
};

export default Sim2;
