import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/all";
import wrong from '../jsons/wrongmove.json';
import divide from '../jsons/Division.json';
import '../index.css';
import sub from '../jsons/subtraction.json';
import { getFirestore, doc, updateDoc, increment } from 'firebase/firestore';
import { app } from '../Firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useGesture } from 'react-use-gesture';
import congs from '../jsons/congs.json';
import { Player } from "@lottiefiles/react-lottie-player";
import sqrt from '../jsons/sqrt.json';

const Component8 = ({ n1 }) => {
  const n1Ref = useRef(null);
  const xref = useRef(null);
  const sqrtref = useRef(null);
  
  const playsqrt = useRef(null);
  const playsqrt2 = useRef(null);

  const sqrtref2 = useRef(null);

  const [longPress, setLongPress] = useState(false);
  const longPressDuration = 500; // 500ms (half a second)
  let pressTimeout = null;

  const handleMouseDown = () => {
    pressTimeout = setTimeout(() => {
      setLongPress(true);
      gsap.to(xref.current, { scale: 1.4,duration: 0.4, ease: "power2.inOut" });
      console.log('long pressed');
      gsap.to(sqrtref.current, { scale:0.5, y:-50, duration:0.3 });
      gsap.to(sqrtref2.current, { scale:0.5, y:-50, duration:0.3 });

      if(playsqrt.current){
        playsqrt.current.play();
      }
      if(playsqrt2.current){
        playsqrt2.current.play();
      }
    }, longPressDuration);

setTimeout(() => {
    gsap.to(sqrtref.current, { opacity:0,  duration:0.5 });
    gsap.to(sqrtref2.current, {  opacity:0,  duration:0.5 });
    n1Ref.current.textContent = Math.sqrt(n1)
    //toggle span
    gsap.to(xref.current.querySelector('span'), { opacity: 0, duration: 0.5 });

}, 2000);

  };

  const handleMouseUp = () => {
    clearTimeout(pressTimeout);
  };
  const hovercharacter = () => {
    gsap.to(xref.current, { color: 'red' });
};

const leavecharacter = () => {
    gsap.to(xref.current, { color: 'black' });
};

useEffect(() => {
    const sqrt1position = xref.current.getBoundingClientRect().top;
    const sqrt2position = xref.current.offsetLeft;
    const sqrt3position = xref.current.offset;

    gsap.to(sqrtref.current, { x: sqrt2position-130,
     y:100, duration:0 });

     gsap.to(sqrtref2.current, { x: sqrt2position+20,
        y:100, duration:0 });
})


  return (
    <div style={{top:'350px', position:'fixed'}}>
         <div ref={sqrtref} style={{zIndex:1,position:'fixed' }}>
        <Player  ref={playsqrt} autoplay={false} keepLastFrame={true} loop={false} src={sqrt} />
        </div>
        <div ref={sqrtref2} style={{zIndex:1,position:'fixed' }}>
        <Player  ref={playsqrt2} autoplay={false} keepLastFrame={true} loop={false} src={sqrt} />
        </div>
      <div style={{ display: 'flex',zIndex:3, userSelect:'none',fontFamily: 'helvetica', fontSize: '40px',cursor: 'pointer' }}>
        <div ref={xref} style={{margin:'20px'}} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseEnter={hovercharacter} onMouseLeave={leavecharacter}> 
          x <span style={{position:'relative',fontSize:'15px', bottom:'20px'}}>2</span>
        </div>
        <div style={{margin:'20px'}}> = </div>
        <div ref={n1Ref} style={{margin:'20px'}} > {n1} </div>
      </div>
    
    </div>
  );
};

export default Component8;
