import React, { useEffect, useRef, useState } from "react";
import sticky from './jsons/sticynote.json';
import yellowsticky from './jsons/yellowsticynote.json';
import './sticky.css';
import { gsap } from "gsap";
import { Link } from 'react-router-dom';
import Calculator from "./generatecomponents/Calculator";
import Calculator2 from './generatecomponents/Calculator2';
import attempt from './jsons/text1.json';
import generate from './jsons/start.json';
import hand1 from './jsons/palm1.json';
import hand2 from './jsons/h2.json';
import hand3 from './jsons/h3.json';
import hand4 from './jsons/h4.json';
import nxt from './jsons/nextbtn.json';
import wait from './jsons/wait.json';
import folded from './jsons/held.json';
import cross1 from './jsons/xross1.json';
import cross5 from './jsons/xbare.json';
import home from './jsons/home.json';
import questionbtn from './jsons/questionsbutton.json';
import crosses3 from './jsons/crossesb.json';
import backbutton from './jsons/goback.json';
import { Player } from "@lottiefiles/react-lottie-player";
import ReactDOM from 'react-dom';
import Variables from "./generatecomponents/Variables";
import Operators from "./generatecomponents/Operators";
import genquest from './jsons/genquest.json';
import genquad from './jsons/genquad.json';
import basice from './jsons/basiceqn.json';
import quade from './jsons/quadeqn.json';
import QuadraticExample from './PracticeComponents/QuadraticExample';
import QuadraticExamplem2 from "./PracticeComponents/QuadraticExamplem2";
import { Card,Container, Row, Col } from 'react-bootstrap';
const Select = () => {
    const oneRef = useRef(null);
    const bgref = useRef(null);
    const attemptref = useRef(null);
    const signrightref = useRef(null);
    const backbuttonref = useRef(null);
    const genref = useRef(null);

    const sp1 = useRef(null);
    const usables = useRef(null);

    const stickyref = useRef(null);
    const homeref = useRef(null);

    const handref2 = useRef(null);
    const newvalueref = useRef(null); 
    const mirror4ref = useRef(null); 
    
    const stepsref = useRef(null); 
    const mirror3ref = useRef(null); // Reference for the newly created element
    const mirror2ref = useRef(null); // Reference for the newly created element
    const mirror1ref = useRef(null); // Reference for the newly created element
    const box4ref = useRef(null); // Reference for the newly created element
    const nextbuttonref = useRef(null); // Reference for the newly created element
    const rightnumref = useRef(null);
    const generateref = useRef(null);
    const crosses5ref = useRef(null);
    const crosses6ref = useRef(null);
    const crosses4ref = useRef(null);
    const crossesref = useRef(null);
    const xrossesref = useRef(cross1);
    const xrosses5ref = useRef(null);

    
    const crosses2ref = useRef(null);
    const crosses3ref = useRef(null);
    const variableRef = useRef(null);
    const xref = useRef(null);
    const variable2Ref = useRef(null);
    const span1ref = useRef(null);
    const span22ref = useRef(null);

    const span2ref = useRef(null);

    const variable3Ref = useRef(null);
    const handref = useRef(null);
    const sign2ref = useRef(null);
    const sign1ref = useRef(null);
    const variref = useRef(null);
    const handPlayerRef = useRef(null);

    const [hand, setHand] = useState(hand1);
    const [hh, sethh] = useState(hand3);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const [nextbuttonno, setnextbuttonno] = useState(0);
    
    const [operator, setoperator] = useState("__");
    const [operator2, setoperator2] = useState("__");
    
    const [showstart, setshowstart] = useState(false);
    const [neg, setneg] = useState(false);
    const [variable, setVariable] = useState("");
    const [variable2, setVariable2] = useState("");
    const [variable3, setVariable3] = useState("");
    const [calcactive, setCalcactive] = useState(true);
    const [calcactive2, setCalcactive2] = useState(true);

    const [nextbutton, setnextbutton] = useState(nxt);
    const [navigate, setnavigate] = useState(false);
    
    const [vvariable, setvvariable] = useState("__");
    const [quadratic, setquadratic] = useState(false);

    const [buttonmain, setbuttonmain] = useState(nxt);
    const [isDisabled, setIsDisabled] = useState(false);
    const [startgenerator2, setgenerator2] = useState(false);
    const [startgenerators, setstartgenerators] = useState(true);

    const [startgenerator, setgenerator] = useState(false);
    const [clickCounter, setClickCounter] = useState(0);
    const [clickCounter2, setClickCounter2] = useState(0);
    const [clickCounter3, setClickCounter3] = useState(0);
    const [clickCounter4, setClickCounter4] = useState(0);

    
    const [showVariableValue, setshowVariableValue] = useState(true);
    const [divisor, setdivisor] = useState(true);
    const [a, setA] = useState(null);
    const [b, setB] = useState(null);
    const [c, setC] = useState(null);
    const [A2, setA2] = useState(null);
    const [B2, setB2] = useState(null);
    const [C2, setC2] = useState(null);
    const [op1, setop1] = useState(null);
    const [op2, setop2] = useState(null);
    const [op3, setop3] = useState(null);
    const [A3, setA3] = useState('');
    const [B3, setB3] = useState('');
    const [C3, setC3] = useState('');
    
    const [q, setQ] = useState(null);
    const [w, setW] = useState(null);
    const [e, setE] = useState(null);

    const [j, setJ] = useState(null);
    const [k, setK] = useState(null);
    const [l, setL] = useState(null);

    const [sign1, setsign1] = useState('+');
    const [sign2, setsign2] = useState('-');
    const [ismirror, setismirror] = useState(0);

    
    const [boxes, setBoxes] = useState([]);
    const [boxCounter, setBoxCounter] = useState(0);

    const [boxStyles, setBoxStyles] = useState({
        position: 'relative',
        left: '0px',
        color: '#183243',
        fontSize: '20px',
        opacity:'100'
    });
    const [boxStyles2, setBoxStyles2] = useState({
        position: 'absolute',
        left: '-40px',
        top:'360px',
        color: '#183243',
        fontSize: '20px',
        opacity:'00'
    });
    

    const boxRefs = useRef([]);



    useEffect(() => {
        if (bgref.current) {
            bgref.current.play();
            console.log('bgred');
        }
      
    }, []);

    useEffect(() => {
        if (!calcactive) {

            boxRefs.current.forEach(ref => {
                if (ref) {
                    gsap.to(ref, { opacity: 0, duration: 1,delay:2 });
                }
            });

            console.log('calc removed');
            const variable2Rect = variable2Ref.current.getBoundingClientRect();
            const place = rightnumref.current.offsetLeft - variable2Ref.current.offsetLeft;
            const place2 = variable2Ref.current.offsetLeft - variableRef.current.offsetLeft;
            const place3 = variable2Ref.current.offsetLeft - xref.current.offsetLeft;

            // Add a new box
            setBoxes(prevBoxes => [{ variable, variable2, variable3 }, ...prevBoxes]);

           
          
            // THE BREAK
            if(nextbuttonno===1){
                console.log("button at 1");
                setTimeout(() => {
                
                    setismirror(ismirror+1);
                    gsap.to(mirror1ref.current, { opacity: 100, duration: 2,delay:2  });
                   //initial
                    gsap.to(mirror1ref.current, { y: 0, duration: 0  });
                    gsap.to(mirror1ref.current, { y: 65, duration: 2,delay:0.8  });
                    
    
                   
    
                }, 100);


                setTimeout(() => {
                    setHand(folded);
                }, 100);

                if(op1==='+'){
                    setop3('-');
                }
                else if(op1 ==='-'){
                    setop3('+');
                }
               
                gsap.to(signrightref.current, { opacity: 100, duration: 2, delay: 2 });
                gsap.to(sign1ref.current, { opacity: 0, duration: 0.2, delay: 1 });
                gsap.to(variableRef.current, { x: place2 - 58, duration: 2, delay: 1 });
                gsap.to(xref.current, { x: place3, duration: 2, delay: 1 });
    
                gsap.to(variable2Ref.current, { x: place+12, duration: 2, delay: 0.5 });
               
            }
           

            if(nextbuttonno===2){

                console.log("button at 2");
                setJ(variableRef.current.textContent);
                setK(variable3Ref.current.textContent);
                setL(variable2Ref.current.textContent);
                //color switch
                gsap.to(span1ref.current, { backgroundColor: 'black', duration: 0.5 });
                    gsap.to(span2ref.current, { backgroundColor: 'black', duration: 0.5 });
                gsap.to(variable2Ref.current, { color: 'white', duration: 0.5 });
                    gsap.to(variable3Ref.current, { color: 'white', duration: 0.5 });
                    
                    gsap.to(signrightref.current, { backgroundColor: 'black', duration: 0.5 });
                    gsap.to(signrightref.current, { color: 'white', duration: 0.5 });
                    //reverse
                    gsap.to(span1ref.current, { backgroundColor: 'white', duration: 0.5,delay:2 });
                    gsap.to(span2ref.current, { backgroundColor: 'white', duration: 0.5,delay:2 });
                gsap.to(variable2Ref.current, { color: 'black', duration: 0.5 ,delay:2});
                    gsap.to(variable3Ref.current, { color: 'black', duration: 0.5,delay:2 });
                    gsap.to(signrightref.current, { backgroundColor: 'white', duration: 0.5,delay:2 });
                    gsap.to(signrightref.current, { color: 'black', duration: 0.5 ,delay:2});
            

                    if(isMobile){

                        setTimeout(() => {
                            gsap.to(mirror2ref.current, { opacity: 100, duration: 2, delay:1  });
                            //initial
                              gsap.to(mirror2ref.current, { y:60, duration: 0 });
                  
                          gsap.to(mirror1ref.current, { y: -10, duration: 2  });
        
                            if(op3==='-'){
                                const newsub = variable3 - variable2;
                                setVariable3(newsub);
        
                            }else if(op3==='+'){
                                const newsub = +variable3 + +variable2;
                                setVariable3(newsub);
        
                            }
                            
                            const getem = document.getElementById("this");
                            getem.style.backgroundColor = "red";
            
                         //   gsap.to(variable2Ref.current, { color: 'red', duration: 0.5, delay: 1 });
                
            
                        }, 2000);
                  

                    }
                    else{
                        setTimeout(() => {
                            gsap.to(mirror2ref.current, { opacity: 100, duration: 2, delay:1  });
                            //initial
                              gsap.to(mirror2ref.current, { y:160, duration: 0 });
                  
                            gsap.to(mirror1ref.current, { y: -10, duration: 2  });
        
                            if(op3==='-'){
                                const newsub = variable3 - variable2;
                                setVariable3(newsub);
        
                            }else if(op3==='+'){
                                const newsub = +variable3 + +variable2;
                                setVariable3(newsub);
        
                            }
                            
                            const getem = document.getElementById("this");
                            getem.style.backgroundColor = "red";
            
                         //   gsap.to(variable2Ref.current, { color: 'red', duration: 0.5, delay: 1 });
                
            
                        }, 2000);
                  
                    }
            
            
                   
          
          
                gsap.to(variable2Ref.current, { opacity: 0, duration: 0.5 ,delay:2});
                gsap.to(signrightref.current, { opacity: 0, duration: 0.5,delay:2 });

                setTimeout(() => {
    
                   
                }, 2000);
                setTimeout(() => {
                    
                    
                    const newsub = variable3 - variable2;
                    
                    gsap.to(boxRefs.current, {opacity: 0, duration: 1 });
                    
                    // Animate the element's position after 9 seconds
               
                }, 8000);
                
               
            }
           
else if(nextbuttonno===3){
    if(handref2.current){
        handref2.current.play();

           

    }
    setQ(variableRef.current.textContent);
    setW(variable2Ref.current.textContent);
    setE(variable3Ref.current.textContent);

    if(isMobile){
        gsap.to(mirror1ref.current, {y:-20 , duration: 1 ,delay:1});
        gsap.to(mirror2ref.current, {y:+10 , duration: 1 ,delay:1});
        gsap.to(mirror3ref.current, {y:170 , duration: 0});
        gsap.to(mirror3ref.current, {opacity:100 , duration: 1 ,delay:1});
    }
    else{
        gsap.to(mirror1ref.current, {y:-70 , duration: 1 ,delay:1});
        gsap.to(mirror2ref.current, {y:100 , duration: 1 ,delay:1});
        gsap.to(mirror3ref.current, {y:170 , duration: 0});
        gsap.to(mirror3ref.current, {opacity:100 , duration: 1 ,delay:1});
    }
  

    // Create Player component for hand animation
    setTimeout(() => {
        const xValueElement = document.createElement("div");
        xValueElement.textContent = variable; // Set the text content to the value of 'x'
        xValueElement.style.padding = "10px";
        xValueElement.style.color = "#005BD9";
        xValueElement.style.fontSize = "30px";
        xValueElement.style.fontFamily = "Helvetica";
        xValueElement.style.fontWeight = "bolder";

        xValueElement.style.opacity = "100";
        xValueElement.style.zIndex = "5";
        xValueElement.className = "dividing";
        xValueElement.style.cursor = "move";

        document.body.appendChild(xValueElement);

        // Assign the reference to the newly created element
        newvalueref.current = xValueElement;
        const handPlayer = document.createElement("div");
        xValueElement.appendChild(handPlayer);
        ReactDOM.render(<Player ref={handPlayerRef}  className='das'  autoplay={true} loop={false} src={hh} hover={false} />, handPlayer);
        
       
      
        


    }, 2000);
 

}
else if(nextbuttonno===4){
    setnextbutton(questionbtn);
    setnavigate(true);
    const temp = variable2-variable3;
    sethh(hand4);
    const one1 = variable3Ref.current ? variable3Ref.current.textContent : 0;
    const two2 = variableRef.current ? variableRef.current.textContent : 1;
    console.log(two2,one1);

    setTimeout(() => {
        criscross();
        criscross5(); 
    }, 2000);
    setTimeout(() => {
       
       
          setVariable3(one1/two2);
          gsap.to(crossesref.current, {opacity:100 , duration: 1});
          gsap.to(crosses5ref.current, {opacity:100 , duration: 1});
          gsap.to(crosses5ref.current, {opacity:0 , duration: 1, delay:0 });
          gsap.to(crossesref.current, {opacity:0 , duration: 1 , delay:0 });
          gsap.to(variableRef.current, {opacity:0 , duration: 1 ,delay:0});
          
    }, 5000);
    
    const pp = variable3Ref.current.offsetLeft - variableRef.current.offsetLeft;
    gsap.to(newvalueref.current, {x:pp-100 , duration: 1 ,delay:1});
    gsap.to(newvalueref.current, {opacity:0 , duration: 1 ,delay:3});
    gsap.to(crossesref, {opacity:0 , duration: 1 ,delay:4});

}
           

        }

    }, [calcactive, variable, variable2,newvalueref,mirror1ref,mirror2ref,mirror3ref,nextbuttonno,crossesref,crosses2ref]);

    useEffect(() => {
        if(nextbuttonno ===2){
            if (boxes.length > 0) {
                // Get the reference to the newly added boxes
               // const newBoxesRef = boxesRef.current.children.slice(-1)[0];
                
                // Animate the new boxes
                gsap.to(boxRefs.current, { y: 100, duration: 0 });
                gsap.to(boxRefs.current, {opacity: 0, duration: 0 });
                gsap.to(boxRefs.current, { y: -100, duration: 1,delay:1 });
                gsap.to(boxRefs.current, {opacity: 0, duration: 0,delay:2 });
      
              }
        }
        // Check if there are new boxes
       
      }, [boxes,boxRefs]);
  
      
      const startanswer = () => {
        setCalcactive(false);
        gsap.to(variable2Ref.current.querySelector('span'), { opacity: 100, duration: 1.5 });

    };
    const callquadratic =()=>{
        setCalcactive2(false);
        setTimeout(() => {
            setquadratic(true);

        }, 500);
        


    }
    const generator = () => {
        setstartgenerators(false)
        setgenerator(true);
        //here
        setTimeout(() => {
           gsap.to(variable2Ref.current.querySelector('span'), { opacity: 0, duration: 0 });

        }, 1500);

    };
    const generator2 = () => {
        setstartgenerators(false)
        setgenerator2(true);        
        //here
        setTimeout(() => {
        //    gsap.to(variable2Ref.current.querySelector('span'), { opacity: 0, duration: 0 });

        }, 1500);

    };
    const addnextno = () => {
        setnextbuttonno(nextbuttonno + 1) ;
        if (!isDisabled) {
            setbuttonmain(wait);
            console.log("Button clicked");
            setIsDisabled(true);
            setTimeout(() => {
              setIsDisabled(false);
              setbuttonmain(nextbutton);

            }, 2000);
          } 
      };
    

    const handleSelect = (value) => {
        if (clickCounter === 0) {
           // setVariable('');
            setVariable(prevVariable => prevVariable + value);

          
            setA(value);
        } else if (clickCounter === 2 && clickCounter3===0) {
            setVariable2(prevVariable => prevVariable + value);
            setB(value);
            setClickCounter2(1);

        } 
        if(clickCounter===2 && clickCounter2===1 && clickCounter3===1){
            setVariable3(prevVariable => prevVariable + value);
            setC(value);
            setshowstart(true)
        }

        
    };

    const handleSelect2 = (value) => {
        if (clickCounter4 === 0) {
           // setVariable('');
           // setVariable(prevVariable => prevVariable + value);

          
           // const A3 = value;
           //setA3(value);
            setClickCounter4(1);
            setVariable(value);
            setA2(value);

        } else if (clickCounter4 === 2 ) {
          //  const B3 = value;
            if(neg){
                const n = -1*value;
                setVariable2(value);
                setB2(n);
            }
            setClickCounter4(3);
            setVariable2(value);
            setB2(value);

        } 
        if(clickCounter4===4 ){
          //  const C3 = value;

          if(neg){
            const b = -1*value;
            setC2(b);
            setVariable3(value);
            setneg(false);
          }
          else{
            setClickCounter4(5);
            setVariable3(value);
            setC2(value);
          }
            
            setTimeout(() => {
                setshowstart(true);
                console.log('values are,', A2,B2,C2);
            }, 500);
        }

        
    };




    const handlevariables = (value) => {
        setClickCounter(1);
        setvvariable(value);
    }
    
    const handleoperators = (value) => {
        
        if(clickCounter===1){
            setClickCounter(2);
            setoperator(value);
            setop1(value);
        }
        else if(clickCounter===2 && clickCounter2===0){
            setoperator(value);

        }
        if(clickCounter===2 && clickCounter2===1){
  //          setClickCounter(4);
            setoperator2(value);
            setClickCounter3(1);
            setop2(value);

        }
        
    }
    
    const handleoperators2 = (value) => {
        
        if(clickCounter4===1){
            setClickCounter4(2);
            setoperator(value);
            if(value==='-'){
                setneg(true);
            }
            else {
                setneg(false);
            }
           
            
        }
        else if(clickCounter4===3){
            setClickCounter4(4);
            setoperator2(value);
            if(value==='-'){
                setneg(true);
            }
            else {
                setneg(false);
            }
           
        }
      
        
    }



    const updateBoxStyles = (newStyles) => {
        setBoxStyles(prevStyles => ({
            ...prevStyles,
            ...newStyles
        }));
    };

    const fontss = {
        fontFamily: "Helvetica",
        fontWeight: "bold",
    };

    const duplicateStyles = {
        color: '#183243',
        fontSize: '20px'
    };
    const criscross = ()=> {

        if(xrossesref.current){
          xrossesref.current.play();
        }
      }
      const criscross5 = ()=> {
    
        if(xrosses5ref.current){
          xrosses5ref.current.play();
        }
      }
      const genstop = ()=> {
    
        if(generateref.current){
            generateref.current.stop();
        }
      }
     
      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

      
 
      
    return (
        <div>
            {isMobile? (
 <div style={{ position:'fixed',backgroundColor:'#FFD98E'}} >
      
 <div  style={{color:'#e0b075',position:'fixed',left:'800px',top:'150px',zIndex:4, fontFamily:'helvetica',fontSize:'30px',fontWeight:'bolder'}}>
MATH SWIPE
</div>


<div style={{position:'fixed',top:'10px',left:'80px',width:'100px',height:'100px',zIndex:4}}>
  <Link to ='/'>

<Player ref={homeref} autoplay={false} loop={false} src={home} hover={true} />
</Link>




</div>

{startgenerator && (
         
                             
         <div>
              <Container fluid className="fixed-top bg-light p-3">
              <Row >
              <Col xs={12} md={4} lg={5}  >
              <Card style={{ width: 'auto' ,}}>
              <Card.Header className="d-flex justify-content-between">
                {calcactive? (
                    
    <div style={{width:'300px',height:'100px'}}>
    <Player ref={generateref} autoplay={false} loop={false} src={genquest} hover={true} keepLastFrame={true} />  
    </div>
                ):(
<div ref={stepsref}>
<div ref={mirror3ref}  style={{ display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0'}} >
                             <div style={{ padding: "10px"}} className="box1">
                             {q}
                             </div>
                              <div style={{ padding: "10px", backgroundColor:'white'}} className="boxx">
                                 y                   
                             </div>
                           
                             <div style={{ padding: "10px"}} className="assignbox1">
                             =
                             </div>
                           
                             <div style={{color:'#005BD9', padding: "10px",fontWeight:'bolder'}} className="box4">
                             {e}

                             </div>
                          
                             <div style={{ color:'#005BD9',padding: "10px"}}>
                         Step2
                     </div>


</div>

<div ref={mirror2ref}  style={{ display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0', position:'absolute'}} >
                             <div style={{ padding: "10px"}} >
                             {j}
                             </div>
                              <div style={{ padding: "10px"}} >
                                 y                   
                             </div>
                           
                             <div style={{ padding: "10px"}} >
                             =
                             </div>
                             <div  style={{ padding: "10px"}} >
                             {k}

                             </div>
                             <div style={{color:'#005BD9', fontWeight:'bolder', padding: "10px"}} >
                             {op3}

                             </div>
                             <div style={{color:'#005BD9', fontWeight:'bolder',padding: "10px"}}>
                             {l}

                             </div>
                             <div style={{ padding: "10px",color:'#005BD9'}}>
                         Step1
                     </div>


</div>



<div ref={mirror1ref}   style={{display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0'}}  >
                             <div style={{ padding: "10px"}} >
                             {a}
                             </div>
                              <div style={{ padding: "10px"}} >
                                 y                   
                             </div>
                             <div style={{ padding: "10px"}}>
                                 {op1}
                             </div>
                             <div style={{ padding: "10px",}}  >
                             {b}

                             </div>
                             <div style={{ padding: "10px"}}  >
                             {op2}
                             </div>
                             <div style={{ padding: "10px"}} ref={box4ref}>
                             {c}

                             </div>
                             <div style={{ padding: "10px",color:'#005BD9'}}>
                             question
                             </div>

</div>
 </div>  

                )}
          
             
             </Card.Header>
             <Card.Body>
             <div style={{}} >
             
             <div style={{ ...fontss,  display: "flex",fontSize: "20px", alignItems: 'center', padding: '30px', userSelect: 'none' }}>
               
          <div  style={{ ...fontss, border: "1px solid #000", padding: "10px",zIndex:3,backgroundColor:'white' }} ref={variableRef}>
          <Player ref={handref2} style={{ position: 'absolute', width: '150px', height: '150px',  zIndex: 3,opacity:'100' }} autoplay={false} loop={false} src={hand2} hover={false} />

              <span style={{ ...fontss,  padding: "10px" }}>
                  {variable}
                  </span>
              </div>

                 <div style={{ ...fontss,backgroundColor:'white', border: "1px solid red", padding: "10px", color: 'red',backgroundColor:'white' }} ref={xref}>{vvariable}</div>
                 <div style={{ ...fontss,backgroundColor:'white',border: "1px solid blue", padding: "10px", color: 'blue', userSelect: 'none', margin: "5px" }} ref={sign1ref}>{operator}</div>
                 <div id='this' ref={variable2Ref} style={{ position: 'relative',zIndex:3 }}>
                    <span ref={sp1} className="h1">
                    <Player ref={handref} style={{ position: 'absolute', width: '150px', height: '150px', top: '10px', zIndex: 3 }} autoplay={false} loop={false} src={hand} hover={false} />

                    </span>
                     <span ref={span2ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>{variable2}</span>
                 </div>       
                 <div style={{ ...fontss, padding: "10px", color: 'blue', userSelect: 'none', margin: "5px" }}>{operator2}</div>
                 <div id='this' ref={variable3Ref}>
                     <span ref={span1ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>{variable3}</span>
                 </div>
                 <div style={{ ...fontss,backgroundColor:'white', border: "1px solid #3900A8", color: '#3900A8', padding: "10px", opacity: "0" }} ref={signrightref}>{op3}</div>
                 <div style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px", opacity: "0" }} ref={rightnumref}>__</div>
                 <div ref={sign2ref} style={{ ...fontss, padding: "10px", border: "1px solid #0178E9", userSelect: 'none', color: 'blue', margin: "5px", opacity: 0 }}>{op3}</div>
                 <div style={{ ...fontss, padding: "10px", border: "1px solid black", userSelect: 'none', color: 'black', margin: "5px", opacity: 0 }}>5</div>
                
             </div>
            
         </div>
             </Card.Body>
             </Card>
             </Col>
  
             </Row>
</Container>
{calcactive && (
    <div>
                          <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
  <Row className="justify-content-center flex-wrap">
  <Col xs={10} md={4} lg={5} className="mb-4 d-flex justify-content-center">
  <Card style={{ width: 'auto',marginTop:'300px',height:'400px' }}>
  <Card.Body className="text-center" >

                     <div style={{width:'300px',height:'70px'}}>
                        
                       <Variables onSelect={handlevariables} style={{height:'50px',width:'200px'}} />    

                        </div> 
                    <div style={{width:'300px',height:'200px'}}>

                         <Calculator2 onSelect={handleSelect}  />
                    </div>
                         <div style={{marginBottom:'50px'}} >

                         <Operators onSelect={handleoperators} style={{height:'50px',width:'200px',marginBottom:'50px'}} />
                         </div>

                         </Card.Body>
                         
                         </Card>
                         </Col>
                         </Row>
                         </Container>           

                      {showstart && (
                        <div>
<Container fluid className="fixed-bottom bg-light p-3">

<Row >
<Col xs={12} md={4} lg={5}  >
<Card style={{ width: 'auto',marginTop:'5px' ,height:'90px'}}>
<Card.Footer className="d-flex justify-content-between">
    <div onClick={startanswer}>
    <Player ref={generateref} autoplay={true} loop={false} src={generate} hover={true} keepLastFrame={true} style={{height:'100px',width:'250px'}} />

    </div>
</Card.Footer>
</Card>
      </Col>
      </Row>
                         </Container>           

                            </div>
                      )}
                   </div>
                      
                 

                 )}
            




<div  className='cris' ref={crossesref} style={{ opacity:100, position:'fixed', zIndex:3}}>
<Player ref={xrossesref} style={{  zIndex: 3 ,opacity:'100' }} autoplay={false} loop={false} keepLastFrame={true} src={cross1} hover={false} />

</div>
<div className='cris2' ref={crosses5ref} style={{opacity:100, position:'fixed', zIndex:3}}>
<Player ref={xrosses5ref} style={{  zIndex: 3, opacity:'100' }} autoplay={false} loop={false} keepLastFrame={true} src={cross5} hover={false} />



</div>
{!calcactive && (
    <div>
            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
            <Row className="justify-content-center flex-wrap">
            <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
            <Card style={{ width: 'auto',marginTop:'300px' }}>
            <Card.Body className="text-center">

<div  onClick={addnextno} style={{  pointerEvents: isDisabled ? 'none' : 'auto', cursor: isDisabled ? 'default' : 'pointer'  }}>

<Player style={{width:'300px',height:'100px'}} ref={nextbuttonref} autoplay={true} loop={true} src={buttonmain} keepLastFrame={true} hover={true} />

</div>
</Card.Body>
</Card>
</Col>
</Row>
</Container>

</div>
)}

{ismirror===3 && (
                         <div style={{display:'flex'}} ref={mirror3ref}className="box ">
                             <div style={{ display:'flex',flexDirection:'column'}}>
                             <div>
                             <div className="box1">
                             {variable}
                             </div>

                              <div className="boxx">
                                 y                   
                             </div>
                           
                             <div className="assignbox1">
                             =
                             </div>
                             <div className="box4">
                             {variable3}
                             </div>
                             </div>
                             <div>
                             <div>
                             <div className="box1">
                             {divisor}
                             </div>

                              <div className="boxx">
                                 __                  
                             </div>
                           
                             <div className="assignbox1">
                                 __
                             </div>
                             <div className="box4">
                             {divisor}
                             </div>
                             </div>
                             </div>
                             </div>
                             

                           


</div>
  )}
           
</div>


     )}

     {startgenerators && (
              <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>

<Row className="justify-content-center flex-wrap">
<Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
<Card style={{ width: 'auto', marginRight:'100px' }}>
<Card.Body className="text-center">

             <div >
                 <Player ref={attemptref} autoplay={false} loop={false} src={attempt} hover={true} />
             </div>

             
             </Card.Body>
             </Card>
             </Col>
             <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
             <Card style={{ width: 'auto' }}>
             <Card.Body className="text-center">
             <div onMouseLeave={genstop} onClick={generator} >
                 <Player ref={generateref} autoplay={false} loop={false} src={basice} hover={true} keepLastFrame={true} style={{marginLeft:'10px',height:'100px',width:'200px'}}/>
             </div>


             <div  onMouseLeave={genstop} onClick={generator2} >
                 <Player ref={generateref} autoplay={false} loop={false} src={quade} hover={true} keepLastFrame={true} style={{marginLeft:'10px',height:'100px',width:'300px'}}  />
             </div>
             </Card.Body>
             </Card>
             </Col>
             </Row>
             </Container>

        
     )}



    
     {startgenerator2 && (
         
                        
         <div>
  {calcactive2 && (
            <div>
                          <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
                          <Row className="justify-content-center flex-wrap">
                          <Col xs={10} md={4} lg={5} className="mb-4 d-flex justify-content-center">
                          <Card style={{ width: 'auto',marginTop:'300px',height:'350px' }}>
                          <Card.Body className="text-center" >

                                    <div ref={usables}  >
                                     
                                      <div style={{width:'300px',height:'200px'}} >
          
                                        <Calculator2 onSelect={handleSelect2} />
                                        </div>
                                        <div style={{marginBottom:'50px'}}  >
          
                                        <Operators onSelect={handleoperators2} />
                                        </div>
                                        </div>
                                        </Card.Body>
                                        </Card>
                                        </Col>

                                        </Row>
                                        </Container>           

                                        {showstart && (
<div>
<Container fluid className="fixed-bottom bg-light p-3">
<Row >
<Col xs={12} md={4} lg={5}  >
<Card style={{ width: 'auto',marginTop:'5px' ,height:'90px'}}>
<Card.Footer className="d-flex justify-content-between">

              <div onMouseLeave={genstop} onClick={callquadratic} >
              <Player ref={generateref} autoplay={true} loop={false} src={generate} hover={true} keepLastFrame={true} />
          </div>
          </Card.Footer>
          </Card>
          </Col>
      </Row>
                         </Container>  
          </div>

                                        )}
                                  
                                     
                                    </div>
          
                                )}
                 {calcactive2 && (

<div >
<Container fluid className="fixed-top bg-light p-3">
<Row >
<Col xs={12} md={4} lg={5}  >
<Card style={{ width: 'auto' }}>
<Card.Header className="d-flex justify-content-between">
<div >
          
          <Player ref={generateref} autoplay={false} loop={false} src={genquad} hover={true} keepLastFrame={true} />
          
          
          </div>

</Card.Header>
<Card.Body>
<div style={{ ...fontss, display: "flex", fontSize: "15px", alignItems: 'center', padding: '10px', userSelect: 'none' }}>

<div  style={{ ...fontss, border: "1px solid #000", padding: "10px",zIndex:3,backgroundColor:'white' }} ref={variableRef}>

<div style={{ ...fontss, padding:'10px' }}>
{variable}
</div>
</div>

<div style={{ ...fontss,backgroundColor:'white', border: "1px solid red", padding: "10px", color: 'red',backgroundColor:'white' }} ref={xref}>
 <span>
     y
 </span>
 <span style={{fontSize:'15px'}}>
     2
 </span>
 </div>
<div style={{ ...fontss,backgroundColor:'white',border: "1px solid blue", padding: "10px", color: 'blue', userSelect: 'none', margin: "5px" }} ref={sign1ref}>{operator}</div>
<div id='this' ref={variable2Ref} >

 <span ref={span22ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>{variable2}</span>
</div>  
<div id='this' ref={variable3Ref}>
 <span ref={span1ref} style={{ ...fontss,backgroundColor:'white',color:'red', border: "1px solid #000", padding: "10px" }}>y</span>
</div>     
<div style={{ ...fontss, padding: "10px", color: 'blue', userSelect: 'none', margin: "5px" }}>{operator2}</div>
<div id='this' ref={variable3Ref}>
 <span ref={span1ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>{variable3}</span>
</div>
<div id='this' ref={variable3Ref}>
 <span ref={span1ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>=</span>
</div>
<div id='this' ref={variable3Ref}>
 <span ref={span1ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>0</span>
</div>

<div style={{ ...fontss,backgroundColor:'white', border: "1px solid #3900A8", color: '#3900A8', padding: "10px", opacity: "0" }} ref={signrightref}>{op3}</div>
<div style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px", opacity: "0" }} ref={rightnumref}>__</div>
<div ref={sign2ref} style={{ ...fontss, padding: "10px", border: "1px solid #0178E9", userSelect: 'none', color: 'blue', margin: "5px", opacity: 0 }}>{op3}</div>
<div style={{ ...fontss, padding: "10px", border: "1px solid black", userSelect: 'none', color: 'black', margin: "5px", opacity: 0 }}>5</div>

</div>
</Card.Body>
</Card>
</Col>
</Row>
</Container>

</div>
                 )}
            


<div className="steps" ref={stepsref}>
<div ref={mirror3ref} className="box23 " style={{ display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0', position:'absolute'}} >
                             <div style={{ padding: "10px"}} className="box1">
                             {q}
                             </div>
                              <div style={{ padding: "10px", backgroundColor:'white'}} className="boxx">
                                 y                   
                             </div>
                           
                             <div style={{ padding: "10px"}} className="assignbox1">
                             =
                             </div>
                           
                             <div style={{color:'#005BD9', padding: "10px",fontWeight:'bolder'}} className="box4">
                             {e}

                             </div>
                          
                             <div style={{ color:'#005BD9',padding: "10px"}}>
                         Step2
                     </div>


</div>

<div ref={mirror2ref} className="box23 " style={{ display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0', position:'absolute'}} >
                             <div style={{ padding: "10px"}} className="box1">
                             {j}
                             </div>
                              <div style={{ padding: "10px"}} className="boxx">
                                 y                   
                             </div>
                           
                             <div style={{ padding: "10px"}} className="assignbox1">
                             =
                             </div>
                             <div  style={{ padding: "10px"}} className="box4">
                             {k}

                             </div>
                             <div style={{color:'#005BD9', fontWeight:'bolder', padding: "10px"}} className="box4">
                             {op3}

                             </div>
                             <div style={{color:'#005BD9', fontWeight:'bolder',padding: "10px"}} className="box3">
                             {l}

                             </div>
                             <div style={{ padding: "10px",color:'#005BD9'}}>
                         Step1
                     </div>


</div>



<div ref={mirror1ref}  className="box2" style={{display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0',position:'absolute' }}  >
                             <div style={{ padding: "10px"}} className="box1">
                             {a}
                             </div>
                              <div style={{ padding: "10px"}} className="boxx">
                                 y                   
                             </div>
                             <div style={{ padding: "10px"}}>
                                 {op1}
                             </div>
                             <div style={{ padding: "10px",}}  className="box3">
                             {b}

                             </div>
                             <div style={{ padding: "10px"}}  className="assignbox1">
                             {op2}
                             </div>
                             <div style={{ padding: "10px"}} ref={box4ref} className="box4">
                             {c}

                             </div>
                             <div style={{ padding: "10px",color:'#005BD9'}}>
                             question
                             </div>

</div>
 </div>  


<div  className='cris' ref={crossesref} style={{ opacity:100, position:'fixed', zIndex:3}}>
<Player ref={xrossesref} style={{  zIndex: 3 ,opacity:'100' }} autoplay={false} loop={false} keepLastFrame={true} src={cross1} hover={false} />

</div>
<div className='cris2' ref={crosses5ref} style={{opacity:100, position:'fixed', zIndex:3}}>
<Player ref={xrosses5ref} style={{  zIndex: 3, opacity:'100' }} autoplay={false} loop={false} keepLastFrame={true} src={cross5} hover={false} />



</div>
{!calcactive && (
<div className="nexton" onClick={addnextno} style={{position:'fixed',top:'275px',left:'950px',zIndex:3,  pointerEvents: isDisabled ? 'none' : 'auto', cursor: isDisabled ? 'default' : 'pointer'  }}>
{navigate? (
<Link to='/mtrain'>
<Player style={{width:'300px',height:'300px'}} ref={nextbuttonref} autoplay={true} loop={true} src={buttonmain} keepLastFrame={true} hover={true} />
</Link>
):
(
<Player style={{width:'300px',height:'300px'}} ref={nextbuttonref} autoplay={true} loop={true} src={buttonmain} keepLastFrame={true} hover={true} />

)
}

</div>
)}

{ismirror===3 && (
                         <div style={{display:'flex'}} ref={mirror3ref}className="box ">
                             <div style={{ display:'flex',flexDirection:'column'}}>
                             <div>
                             <div className="box1">
                             {variable}
                             </div>

                              <div className="boxx">
                                 y                   
                             </div>
                           
                             <div className="assignbox1">
                             =
                             </div>
                             <div className="box4">
                             {variable3}
                             </div>
                             </div>
                             <div>
                             <div>
                             <div className="box1">
                             {divisor}
                             </div>

                              <div className="boxx">
                                 __                  
                             </div>
                           
                             <div className="assignbox1">
                                 __
                             </div>
                             <div className="box4">
                             {divisor}
                             </div>
                             </div>
                             </div>
                             </div>
                             

                           


</div>
  )}

{quadratic && (
                 <div style={{fontFamily:'helvetica'}}>
                     <QuadraticExamplem2 a={A2} b={B2} c={C2} />
                      </div>
              )}   


           
</div>


     )}






     
 </div>
            ): (
                <div style={{ backgroundColor:'#FFD98E'}}className="mainn">
      
                <div  style={{color:'#e0b075',position:'fixed',left:'800px',top:'150px',zIndex:4, fontFamily:'helvetica',fontSize:'30px',fontWeight:'bolder'}}>
          MATH SWIPE
              </div>
              
            
              <div style={{position:'fixed',top:'100px',left:'600px',width:'100px',height:'100px',zIndex:4}}>
                 <Link to ='/'>
          
              <Player ref={homeref} autoplay={false} loop={false} src={home} hover={true} />
              </Link>
              {startgenerator && calcactive && (
          <div style={{zIndex:5,scale:'0.7',position:'fixed',left:'720px',top:'180px'}}>
          
          <Player ref={generateref} autoplay={false} loop={false} src={genquest} hover={true} keepLastFrame={true} />
          
          
          </div>
          
          
          )}
          
          {startgenerator2 && calcactive2 && (
          <div style={{zIndex:5,scale:'1.1',position:'fixed',left:'720px',top:'180px'}}>
          
          <Player ref={generateref} autoplay={false} loop={false} src={genquad} hover={true} keepLastFrame={true} />
          
          
          </div>
          
          
          )}
          
          
              </div>
                    {startgenerators && (
                        <div position='fixed'>
                            <div className="attempt">
                                <Player ref={attemptref} autoplay={false} loop={false} src={attempt} hover={true} />
                            </div>
          
                            <div style={{position:'fixed',top:'450px',scale:'1.5'}} onMouseLeave={genstop} onClick={generator} className="generate">
                                <Player ref={generateref} autoplay={false} loop={false} src={basice} hover={true} keepLastFrame={true} />
                            </div>
          
          
                            <div style={{position:'fixed',top:'650px',scale:'2.2',left:'830px'}} onMouseLeave={genstop} onClick={generator2} className="generate">
                                <Player ref={generateref} autoplay={false} loop={false} src={quade} hover={true} keepLastFrame={true} />
                            </div>
          
                        </div>
                    )}
          
          
          
                    {startgenerator && (
                        
                                            
                        <div>
          {calcactive && (
                                    <div ref={usables}style={{zIndex:5,position:"fixed",top:'180px',left:'680px'}} className="calculator" >
                                      <span style={{top:'-110px',position:'relative',left:'-350px'}}>
                                      <Variables onSelect={handlevariables} />    
                                      </span>
                                      <span style={{top:'115px',position:'relative',left:'-750px'}}>
          
                                        <Calculator2 onSelect={handleSelect} />
                                        </span>
                                        <span style={{top:'-240px',position:'relative',left:'-880px'}}>
          
                                        <Operators onSelect={handleoperators} />
                                        </span>
                                        {showstart && (
              <span style={{position:'fixed',top:'530px'}} onMouseLeave={genstop} onClick={startanswer} className="generate">
              <Player ref={generateref} autoplay={true} loop={false} src={generate} hover={true} keepLastFrame={true} />
          </span>
                                        )}
                                  
                                     
                                    </div>
                                    
                                   
          
                                )}
                            <div className="box">
                            
                                <div style={{ ...fontss, display: "flex",position:'absolute',top:'45px', fontSize: "30px", alignItems: 'center', padding: '30px', userSelect: 'none' }}>
                                  
                             <div  style={{ ...fontss, border: "1px solid #000", padding: "10px",zIndex:3,backgroundColor:'white' }} ref={variableRef}>
                             <Player ref={handref2} style={{ position: 'absolute', width: '150px', height: '150px', top: '50px', zIndex: 3,opacity:'100' }} autoplay={false} loop={false} src={hand2} hover={false} />
          
                                 <span style={{ ...fontss,  padding: "10px" }}>
                                     {variable}
                                     </span>
                                 </div>
               
                                    <div style={{ ...fontss,backgroundColor:'white', border: "1px solid red", padding: "10px", color: 'red',backgroundColor:'white' }} ref={xref}>{vvariable}</div>
                                    <div style={{ ...fontss,backgroundColor:'white',border: "1px solid blue", padding: "10px", color: 'blue', userSelect: 'none', margin: "5px" }} ref={sign1ref}>{operator}</div>
                                    <div id='this' ref={variable2Ref} style={{ position: 'relative',zIndex:3 }}>
                                       <span ref={sp1} className="h1">
                                       <Player ref={handref} style={{ position: 'absolute', width: '150px', height: '150px', top: '10px', zIndex: 3 }} autoplay={false} loop={false} src={hand} hover={false} />
          
                                       </span>
                                        <span ref={span2ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>{variable2}</span>
                                    </div>       
                                    <div style={{ ...fontss, padding: "10px", color: 'blue', userSelect: 'none', margin: "5px" }}>{operator2}</div>
                                    <div id='this' ref={variable3Ref}>
                                        <span ref={span1ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>{variable3}</span>
                                    </div>
                                    <div style={{ ...fontss,backgroundColor:'white', border: "1px solid #3900A8", color: '#3900A8', padding: "10px", opacity: "0" }} ref={signrightref}>{op3}</div>
                                    <div style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px", opacity: "0" }} ref={rightnumref}>__</div>
                                    <div ref={sign2ref} style={{ ...fontss, padding: "10px", border: "1px solid #0178E9", userSelect: 'none', color: 'blue', margin: "5px", opacity: 0 }}>{op3}</div>
                                    <div style={{ ...fontss, padding: "10px", border: "1px solid black", userSelect: 'none', color: 'black', margin: "5px", opacity: 0 }}>5</div>
                                   
                                </div>
                               
                            </div>
          
          
            <div className="steps" ref={stepsref}>
            <div ref={mirror1ref}  className="box2" style={{display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0',position:'absolute' }}  >
                                            <div style={{ padding: "10px"}} className="box1">
                                            {a}
                                            </div>
                                             <div style={{ padding: "10px"}} className="boxx">
                                                y                   
                                            </div>
                                            <div style={{ padding: "10px"}}>
                                                {op1}
                                            </div>
                                            <div style={{ padding: "10px",}}  className="box3">
                                            {b}
          
                                            </div>
                                            <div style={{ padding: "10px"}}  className="assignbox1">
                                            {op2}
                                            </div>
                                            <div style={{ padding: "10px"}} ref={box4ref} className="box4">
                                            {c}
            
                                            </div>
                                            <div style={{ padding: "10px",color:'#005BD9'}}>
                                            question
                                            </div>
          
          </div>
          <div ref={mirror2ref} className="box23 " style={{ display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0', position:'absolute'}} >
                                            <div style={{ padding: "10px"}} className="box1">
                                            {j}
                                            </div>
                                             <div style={{ padding: "10px"}} className="boxx">
                                                y                   
                                            </div>
                                          
                                            <div style={{ padding: "10px"}} className="assignbox1">
                                            =
                                            </div>
                                            <div  style={{ padding: "10px"}} className="box4">
                                            {k}
          
                                            </div>
                                            <div style={{color:'#005BD9', fontWeight:'bolder', padding: "10px"}} className="box4">
                                            {op3}
          
                                            </div>
                                            <div style={{color:'#005BD9', fontWeight:'bolder',padding: "10px"}} className="box3">
                                            {l}
          
                                            </div>
                                            <div style={{ padding: "10px",color:'#005BD9'}}>
                                        Step1
                                    </div>
          
          
          </div>
            <div ref={mirror3ref} className="box23 " style={{ display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0', position:'absolute'}} >
                                            <div style={{ padding: "10px"}} className="box1">
                                            {q}
                                            </div>
                                             <div style={{ padding: "10px", backgroundColor:'white'}} className="boxx">
                                                y                   
                                            </div>
                                          
                                            <div style={{ padding: "10px"}} className="assignbox1">
                                            =
                                            </div>
                                          
                                            <div style={{color:'#005BD9', padding: "10px",fontWeight:'bolder'}} className="box4">
                                            {e}
          
                                            </div>
                                         
                                            <div style={{ color:'#005BD9',padding: "10px"}}>
                                        Step2
                                    </div>
          
          
          </div>
          
        
          
          
          
          
                </div>  
          
          
          <div  className='cris' ref={crossesref} style={{ opacity:100, position:'fixed', zIndex:3}}>
          <Player ref={xrossesref} style={{  zIndex: 3 ,opacity:'100' }} autoplay={false} loop={false} keepLastFrame={true} src={cross1} hover={false} />
          
          </div>
          <div className='cris2' ref={crosses5ref} style={{opacity:100, position:'fixed', zIndex:3}}>
          <Player ref={xrosses5ref} style={{  zIndex: 3, opacity:'100' }} autoplay={false} loop={false} keepLastFrame={true} src={cross5} hover={false} />
          
          
          
          </div>
          {!calcactive && (
            <div className="nexton" onClick={addnextno} style={{position:'fixed',top:'275px',left:'950px',zIndex:3,  pointerEvents: isDisabled ? 'none' : 'auto', cursor: isDisabled ? 'default' : 'pointer'  }}>
          {navigate? (
            <Link to='/mtrain'>
            <Player style={{width:'300px',height:'300px'}} ref={nextbuttonref} autoplay={true} loop={true} src={buttonmain} keepLastFrame={true} hover={true} />
            </Link>
          ):
          (
            <Player style={{width:'300px',height:'300px'}} ref={nextbuttonref} autoplay={true} loop={true} src={buttonmain} keepLastFrame={true} hover={true} />
          
          )
          }
          
          </div>
          )}
          
              {ismirror===3 && (
                                        <div style={{display:'flex'}} ref={mirror3ref}className="box ">
                                            <div style={{ display:'flex',flexDirection:'column'}}>
                                            <div>
                                            <div className="box1">
                                            {variable}
                                            </div>
          
                                             <div className="boxx">
                                                y                   
                                            </div>
                                          
                                            <div className="assignbox1">
                                            =
                                            </div>
                                            <div className="box4">
                                            {variable3}
                                            </div>
                                            </div>
                                            <div>
                                            <div>
                                            <div className="box1">
                                            {divisor}
                                            </div>
          
                                             <div className="boxx">
                                                __                  
                                            </div>
                                          
                                            <div className="assignbox1">
                                                __
                                            </div>
                                            <div className="box4">
                                            {divisor}
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            
          
                                          
          
          
          </div>
                 )}
                          
             </div>
          
             
                    )}
          
                    {startgenerator2 && (
                        
                                       
                        <div>
          {calcactive2 && (
            <div>
                          <Container className="d-flex justify-content-center align-items-right" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
                          <Row >
                          <Col xs={10} md={4} lg={5} >
                          <Card style={{ width: '500px',marginTop:'100px',marginRight:'1200px',height:'500px' }}>
                          <Card.Body className="text-center" >

                                    <div ref={usables}  >
                                     
                                      <div style={{width:'600px',height:'300px'}} >
          
                                        <Calculator2 onSelect={handleSelect2} />
                                        </div>
                                        
                                        <div style={{marginBottom:'50px',marginLeft:'200px'}}  >
          
                                        <Operators onSelect={handleoperators2} />
                                        </div>
                                        </div>
                                        </Card.Body>
                                        </Card>
                                        </Col>

                                        </Row>
                                        </Container>           

                                        {showstart && (
<div>
<Container fluid className="fixed-bottom bg-light p-3">
<Row >
<Col xs={12} md={4} lg={5}  >
<Card style={{ width: '300px',marginTop:'5px' ,marginLeft:'500px',height:'80px'}}>
<Card.Footer className="d-flex justify-content-between">

              <div style={{width:'300px',height:"100px"}} onMouseLeave={genstop} onClick={callquadratic} >
              <Player ref={generateref} autoplay={true} loop={false} src={generate} hover={true} keepLastFrame={true} />
          </div>
          </Card.Footer>
          </Card>
          </Col>
      </Row>
                         </Container>  
          </div>

                                        )}
                                  
                                     
                                    </div>
          
                                )}
                                {calcactive2 && (
          
          <div className="box">
                            
          <div style={{ ...fontss, display: "flex",position:'absolute',top:'45px', fontSize: "30px", alignItems: 'center', padding: '30px', userSelect: 'none' }}>
          
          <div  style={{ ...fontss, border: "1px solid #000", padding: "10px",zIndex:3,backgroundColor:'white' }} ref={variableRef}>
          <Player ref={handref2} style={{ position: 'absolute', width: '150px', height: '150px', top: '50px', zIndex: 3,opacity:'100' }} autoplay={false} loop={false} src={hand2} hover={false} />
          
          <span style={{ ...fontss,  padding: "10px" }}>
             {variable}
             </span>
          </div>
          
            <div style={{ ...fontss,backgroundColor:'white', border: "1px solid red", padding: "10px", color: 'red',backgroundColor:'white' }} ref={xref}>
                <span>
                    y
                </span>
                <span style={{fontSize:'20px'}}>
                    2
                </span>
                </div>
            <div style={{ ...fontss,backgroundColor:'white',border: "1px solid blue", padding: "10px", color: 'blue', userSelect: 'none', margin: "5px" }} ref={sign1ref}>{operator}</div>
            <div id='this' ref={variable2Ref} style={{ position: 'relative',zIndex:3 }}>
               <span ref={sp1} className="h1">
               <Player ref={handref} style={{ position: 'absolute', width: '150px', height: '150px', top: '10px', zIndex: 3 }} autoplay={false} loop={false} src={hand} hover={false} />
          
               </span>
                <span ref={span2ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>{variable2}</span>
            </div>  
            <div id='this' ref={variable3Ref}>
                <span ref={span1ref} style={{ ...fontss,backgroundColor:'white',color:'red', border: "1px solid #000", padding: "10px" }}>y</span>
            </div>     
            <div style={{ ...fontss, padding: "10px", color: 'blue', userSelect: 'none', margin: "5px" }}>{operator2}</div>
            <div id='this' ref={variable3Ref}>
                <span ref={span1ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>{variable3}</span>
            </div>
            <div id='this' ref={variable3Ref}>
                <span ref={span1ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>=</span>
            </div>
            <div id='this' ref={variable3Ref}>
                <span ref={span1ref} style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px" }}>0</span>
            </div>
            
            <div style={{ ...fontss,backgroundColor:'white', border: "1px solid #3900A8", color: '#3900A8', padding: "10px", opacity: "0" }} ref={signrightref}>{op3}</div>
            <div style={{ ...fontss,backgroundColor:'white', border: "1px solid #000", padding: "10px", opacity: "0" }} ref={rightnumref}>__</div>
            <div ref={sign2ref} style={{ ...fontss, padding: "10px", border: "1px solid #0178E9", userSelect: 'none', color: 'blue', margin: "5px", opacity: 0 }}>{op3}</div>
            <div style={{ ...fontss, padding: "10px", border: "1px solid black", userSelect: 'none', color: 'black', margin: "5px", opacity: 0 }}>5</div>
           
          </div>
          
          </div>
                                )}
                           
          
          
            <div className="steps" ref={stepsref}>
            <div ref={mirror3ref} className="box23 " style={{ display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0', position:'absolute'}} >
                                            <div style={{ padding: "10px"}} className="box1">
                                            {q}
                                            </div>
                                             <div style={{ padding: "10px", backgroundColor:'white'}} className="boxx">
                                                y                   
                                            </div>
                                          
                                            <div style={{ padding: "10px"}} className="assignbox1">
                                            =
                                            </div>
                                          
                                            <div style={{color:'#005BD9', padding: "10px",fontWeight:'bolder'}} className="box4">
                                            {e}
          
                                            </div>
                                         
                                            <div style={{ color:'#005BD9',padding: "10px"}}>
                                        Step2
                                    </div>
          
          
          </div>
          
          <div ref={mirror2ref} className="box23 " style={{ display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0', position:'absolute'}} >
                                            <div style={{ padding: "10px"}} className="box1">
                                            {j}
                                            </div>
                                             <div style={{ padding: "10px"}} className="boxx">
                                                y                   
                                            </div>
                                          
                                            <div style={{ padding: "10px"}} className="assignbox1">
                                            =
                                            </div>
                                            <div  style={{ padding: "10px"}} className="box4">
                                            {k}
          
                                            </div>
                                            <div style={{color:'#005BD9', fontWeight:'bolder', padding: "10px"}} className="box4">
                                            {op3}
          
                                            </div>
                                            <div style={{color:'#005BD9', fontWeight:'bolder',padding: "10px"}} className="box3">
                                            {l}
          
                                            </div>
                                            <div style={{ padding: "10px",color:'#005BD9'}}>
                                        Step1
                                    </div>
          
          
          </div>
          
          
          
          <div ref={mirror1ref}  className="box2" style={{display:'flex',fontFamily:'helvetica',fontSize:'30px',margin:'10px',padding:'10px',color:'grey',opacity:'0',position:'absolute' }}  >
                                            <div style={{ padding: "10px"}} className="box1">
                                            {a}
                                            </div>
                                             <div style={{ padding: "10px"}} className="boxx">
                                                y                   
                                            </div>
                                            <div style={{ padding: "10px"}}>
                                                {op1}
                                            </div>
                                            <div style={{ padding: "10px",}}  className="box3">
                                            {b}
          
                                            </div>
                                            <div style={{ padding: "10px"}}  className="assignbox1">
                                            {op2}
                                            </div>
                                            <div style={{ padding: "10px"}} ref={box4ref} className="box4">
                                            {c}
            
                                            </div>
                                            <div style={{ padding: "10px",color:'#005BD9'}}>
                                            question
                                            </div>
          
          </div>
                </div>  
          
          
          <div  className='cris' ref={crossesref} style={{ opacity:100, position:'fixed', zIndex:3}}>
          <Player ref={xrossesref} style={{  zIndex: 3 ,opacity:'100' }} autoplay={false} loop={false} keepLastFrame={true} src={cross1} hover={false} />
          
          </div>
          <div className='cris2' ref={crosses5ref} style={{opacity:100, position:'fixed', zIndex:3}}>
          <Player ref={xrosses5ref} style={{  zIndex: 3, opacity:'100' }} autoplay={false} loop={false} keepLastFrame={true} src={cross5} hover={false} />
          
          
          
          </div>
          {!calcactive && (
            <div className="nexton" onClick={addnextno} style={{position:'fixed',top:'275px',left:'950px',zIndex:3,  pointerEvents: isDisabled ? 'none' : 'auto', cursor: isDisabled ? 'default' : 'pointer'  }}>
          {navigate? (
            <Link to='/mtrain'>
            <Player style={{width:'300px',height:'300px'}} ref={nextbuttonref} autoplay={true} loop={true} src={buttonmain} keepLastFrame={true} hover={true} />
            </Link>
          ):
          (
            <Player style={{width:'300px',height:'300px'}} ref={nextbuttonref} autoplay={true} loop={true} src={buttonmain} keepLastFrame={true} hover={true} />
          
          )
          }
          
          </div>
          )}
          
              {ismirror===3 && (
                                        <div style={{display:'flex'}} ref={mirror3ref}className="box ">
                                            <div style={{ display:'flex',flexDirection:'column'}}>
                                            <div>
                                            <div className="box1">
                                            {variable}
                                            </div>
          
                                             <div className="boxx">
                                                y                   
                                            </div>
                                          
                                            <div className="assignbox1">
                                            =
                                            </div>
                                            <div className="box4">
                                            {variable3}
                                            </div>
                                            </div>
                                            <div>
                                            <div>
                                            <div className="box1">
                                            {divisor}
                                            </div>
          
                                             <div className="boxx">
                                                __                  
                                            </div>
                                          
                                            <div className="assignbox1">
                                                __
                                            </div>
                                            <div className="box4">
                                            {divisor}
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            
          
                                          
          
          
          </div>
                 )}
          
          {quadratic && (
                                <div style={{zIndex:5,marginTop:'100px', position:'absolute',top:'200px',left:'400px',fontFamily:'helvetica'}}>
                                    <QuadraticExample a={A2} b={B2} c={C2} />
                                     </div>
                             )}   
          
          
                          
             </div>
          
             
                    )}
          
          
          
          
              <div >
          <Player ref={stickyref} style={{zIndex:0, width:'1050px',height:'1050px',left:'340px',position:'fixed',top:'-140px',opacity:'50' }} autoplay={true} loop={true}  src={sticky} hover={false} />
          
          </div>
          
                    
                </div>
            )}

        </div>
       
    );
};

export default Select;
