import { gsap, selector } from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";
import anequation from '../jsons/anequation.json';
import variable from '../jsons/variables.json';
import constants from '../jsons/constants.json';
import task1 from '../jsons/task1.json';
import congs from '../jsons/congs.json';
import wrong from '../jsons/wrongmove.json';
import sub from '../jsons/subtraction.json';
import tryagain from '../jsons/wrongmove.json';
import drag from '../jsons/draghere.json';
import { Draggable } from "gsap/all";

import '../MathLearning.css';
import instruction from '../jsons/swipe1.json';
gsap.registerPlugin(Draggable);


const Unit2 = ({value1,value2}) => {

    const oneref = useRef(null);
    const tworef = useRef(null);
    const plusRef = useRef(null);
    const xref = useRef(null);
    const extraref = useRef(null);
    const instructionref = useRef(null);
    const apointer = useRef(null);

    const m2ref = useRef(null);
    const value1ref = useRef(null);
    const value2ref = useRef(null);
    const assignRef = useRef(null);
    const negateref = useRef(null);
    const extrarightref = useRef(null);  
    const bottomanimref = useRef(anequation);
    const upperanimref = useRef(task1);
    const [bottomanimation, setbottomanimation] = useState(null);
    const [playbottomanimation, setplaybottomanimation] = useState(true);
    const [playupperanimation, setplayupperanimation] = useState(false);
    const [upperanimations, setupperanimations] = useState(task1);

  const [showdivide, setshowdivide] = useState(false);
  const [showmultiply, setshowmultiply] = useState(false);
  const [cross, setcross] = useState(false); // State to track the visibility of the minus box
  const [draggableElements, setDraggableElements] = useState([]);
  const [showthird, setthird] = useState(false); // State to track the visibility of the minus box
  const [iswelldone, setiswelldone] = useState(false); // State to track the visibility of the minus box
  const [color1, setcolor1] = useState('4E5154'); // State to track the visibility of the minus box
  const [color2, setcolor2] = useState('4E5154'); // State to track the visibility of the minus box
  const [colorop, setcolorop] = useState('4E5154'); // State to track the visibility of the minus box
  const [colorx, setcolorx] = useState('4E5154'); // State to track the visibility of the minus box
  const [looping, setlooping] = useState(false); // State to track the visibility of the minus box

  

    useEffect(() => {
      const one = oneref.current;
      const two = tworef.current;
      const x = xref.current;
      const plus = plusRef.current;
      const extra = extrarightref.current;
      const neg = negateref.current;
      const point = apointer.current;

      const place1 = one.offsetLeft;
      const place2 = two.offsetLeft;
      const placeextra = extra.offsetLeft;
      const placeone = placeextra - place1;
      const placeback = place1 - placeextra;
      const originalPosition = { x: point.offsetLeft, y: point.offsetTop };
    
      //needs to loop
      const tl = gsap.timeline({ repeat: -1 }); // repeat: -1 for infinite looping

     
      tl.to(point, { x: placeone, duration: 2, delay: 1.5 })
        .to(point, { x: originalPosition, duration: 0, delay: 3 });

      //end of loop
      const draggableOneInstance = Draggable.create(one,{
        type: "x",
        edgeResistance: 0.65,
        onDragEnd: function() {
          const position1 = one.getBoundingClientRect().left;
          const position2 = two.getBoundingClientRect().left;
          const place1 = one.offsetLeft;
          const place2 = two.offsetLeft;
          const placeextra = extra.offsetLeft;
          const placeone = placeextra - place1;
          const placetwo = place2 - place1;
          const placeorigin = place1 - one.offsetLeft;

          if(position1 > position2){
            gsap.to(point, { opacity: 0, duration: 1, delay:1 });

            gsap.to(one, { x: placeone, duration: 1 });
            gsap.to(plus, { opacity: 0, duration: 0.5 });
            gsap.to(x, { x:placetwo , duration: 1 });
            gsap.to(neg, { opacity:100 , duration: 0.7 });

            setupperanimations(sub);
            gsap.to(upperanimref.current, { opacity: 100, duration: 1.5, delay:1 });
            gsap.to(bottomanimref.current, { opacity: 0, duration: 0.7, delay:0.8 });

            //change colors
            gsap.to(one, { color: 'white', duration: 1, delay:2 });
            gsap.to(two, { color: 'white', duration: 1, delay:2 });
            gsap.to(neg, { color: 'white', duration: 1, delay:2 });

            gsap.to(two, { backgroundColor: 'blue', duration: 1, delay:2 });
            gsap.to(one, { backgroundColor: 'blue', duration: 1, delay:2 });
            gsap.to(neg, { backgroundColor: 'blue', duration: 1, delay:2 });

            //calculate
            const difference = value2 - value1;
            setTimeout(() => {
              two.textContent = difference;
            }, 3500);
            gsap.to(neg, { opacity: 0, duration: 1, delay:3.5 });
            gsap.to(one, { opacity: 0, duration: 1, delay:3.5 });

            gsap.to(two, { color: 'black', duration: 1, delay:5 });
            gsap.to(two, { backgroundColor: 'white', duration: 1, delay:5 });

            setTimeout(() => {
              setbottomanimation(congs);

            }, 5000);
            gsap.to(upperanimref.current, { opacity: 0, duration: 1.5, delay:6 });
            gsap.to(bottomanimref.current, { opacity: 100, duration: 0.7, delay:6 });
            


          }
          else{
            setbottomanimation(tryagain);
            setTimeout(() => {
              setbottomanimation(instruction);
              gsap.to(one, { x: placeorigin, duration: 1 });

            }, 1500);

          }
        }
      });

      },[]);

 const trainingelements = {
    display: "flex",
    position: "relative",
    alignItems: 'center',
    justifyContent: 'center',
 
    zindex:2,
    alignItems: "center",

  };

  const trainingelements2 = {
    display: "flex",
    position: "relative",
    marginLeft:"380px",
    zindex:2,
    alignItems: "center",
  };
    return(
        <div>
          
               <div ref={apointer} style={{position:'relative', top:'180px', width:'200px', height:'200px',left:'-18px' }}>

               <Player ref={instructionref} autoplay={true} loop={true}  src={drag} />

               </div>

            <div ref={upperanimref} className="upperanimation" style={{opacity:'0'}}>
            <Player ref={upperanimref} autoplay={true} loop={false} keepLastFrame={true} src={upperanimations} />
            </div>

             <div className="workarea" >
                   <div className='test2' ref={xref} style={{ padding: "10px", fontSize: '30px',backgroundColor:'white', color: colorx, userSelect: 'none', margin: "15px" }}>x</div>
                   <div className='test2' ref={plusRef} style={{ padding: "10px", fontSize: '30px',backgroundColor:'white', color: colorop, userSelect: 'none', margin: "15px" }}>+</div>
                   <div className='test2' ref={oneref} style={{ position:'relative', padding: "10px", fontSize: '30px',backgroundColor:'#B8DBFF', color: color1, userSelect: 'none',borderRadius:'5px', margin: "15px" }} >

                    {value1}                    

                    </div>
                   <div className='test2' ref={assignRef} style={{ padding: "10px", fontSize: '30px', backgroundColor:'white',color: colorop, userSelect: 'none', margin: "15px" }}>=</div>
                   <div className='test2' ref={tworef} style={{ padding: "10px", fontSize: '30px',backgroundColor:'white', color: color2, userSelect: 'none', margin: "15px" }}>{value2}</div>
                   <div className='test2' ref={negateref} style={{ padding: "10px", fontSize: '30px',backgroundColor:'white', userSelect: 'none', color: colorop, margin: "15px", opacity: 0 }}>-</div>
                   
                   <div className='test2' ref={extrarightref} style={{ padding: "10px", fontSize: '30px', userSelect: 'none', color: colorop, margin: "15px", opacity: 0 }}>   </div>

               </div>

               {playbottomanimation && (
 <div ref={bottomanimref} className="loweranimation" >
 <Player style={{position:'relative',left:'100px' ,bottom:'60px'}} ref={bottomanimref} autoplay={true} loop={false} keepLastFrame={true}  src={bottomanimation} />
</div>
               )}

            
              
        </div>
    );

};
export default Unit2;