import React, { useState, useRef, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import stable2 from '../jsons/stable2.json';
import unstable2 from '../jsons/unstable2.json';

const Sim6 = ({ sendDataToParent}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const animation1Ref = useRef(null);
  const animation2Ref = useRef(null);

  const handleAnimationClick = () => {
    setIsPlaying(true);
    setTimeout(() => {
        if(animation1Ref.current){
            animation1Ref.current.play();

        }
        if(animation2Ref.current){
            animation2Ref.current.play();

        }
    }, 500);
  }; 
  const handleAnimationClick2 = () => {
    setIsPlaying(true);
    sendData();
    setTimeout(() => {
        if(animation1Ref.current){
            animation1Ref.current.play();

        }
        if(animation2Ref.current){
            animation2Ref.current.play();

        }
    }, 500);
  };
  const sendData = () => {
    const data = true;
    sendDataToParent(data);
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
     <div  onClick={() => handleAnimationClick()} >
     <Player
        ref={animation1Ref}
        src={stable2}
        loop={true}


        autoplay={false}
        style={{ width: '200px', height: '200px' }}
       
      />
     </div>
       {/* 1px Border between animations */}
       <div style={{ width: '1px', height: '158px', backgroundColor: 'black' }}></div>
     <div  onClick={() => handleAnimationClick()} >
     <Player
        ref={animation2Ref}
        src={unstable2}
        loop={true}
        autoplay={false}
        style={{ width: '200px', height: '200px' }}
        onClick={() => handleAnimationClick2()}
      />
    </div>
    
    </div>
  );
};

export default Sim6;
