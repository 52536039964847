import { gsap, selector } from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";
import anequation from '../jsons/anequation.json';
import variable from '../jsons/variables.json';
import constants from '../jsons/constants.json';
import task1 from '../jsons/task1.json';
import congs from '../jsons/congs.json';
import wrong from '../jsons/wrongmove.json';
import sub from '../jsons/subtraction.json';
import crosses from '../jsons/crosses1.json';
import crosses2 from '../jsons/crosses2.json';
import crosses3 from '../jsons/crosses1.json';
import welldone from '../jsons/welldone.json';
import division from '../jsons/Division.json';
import swipeinstruction from '../jsons/swipeinstruction.json';
import { Draggable } from "gsap/all";
import tryagain from '../jsons/wrongmove.json';
import doubleclick from '../jsons/dbclick.json';
import drag from '../jsons/draghere.json';


import '../MathLearning.css';
import instruction from '../jsons/doubleclickinstruction.json';
gsap.registerPlugin(Draggable);


const Unit3m = ({value1,value2}) => {
  
    const crossesref = useRef(crosses);
    const crosses2ref = useRef(crosses2);
    const apointer = useRef(null);

    const oneref = useRef(null);
    const tworef = useRef(null);
    const plusRef = useRef(null);
    const xref = useRef(null);
    const extraref = useRef(null);
    const m2ref = useRef(null);
    const value1ref = useRef(null);
    const value2ref = useRef(null);
    
    const instructionref = useRef(null);
    const doubleclickref = useRef(null);

    const assignRef = useRef(null);
    const negateref = useRef(null);
    const extrarightref = useRef(null);  
    const bottomanimref = useRef(anequation);
    const upperanimref = useRef(task1);
    const [bottomanimation, setbottomanimation] = useState(instruction);
    const [playbottomanimation, setplaybottomanimation] = useState(true);
    const [playupperanimation, setplayupperanimation] = useState(false);
    const [upperanimations, setupperanimations] = useState(task1);
    const [showNum1, setShowNum1] = useState(true); // State to track the visibility of the minus box
    const [twoanswered, settwoanswered] = useState(false);
    const [pointertype, setpointertype] = useState(doubleclick);

  const [showdivide, setshowdivide] = useState(false);
  const [showmultiply, setshowmultiply] = useState(false);
  const [cross, setcross] = useState(false); // State to track the visibility of the minus box
  const [draggableElements, setDraggableElements] = useState([]);
  const [showthird, setthird] = useState(false); // State to track the visibility of the minus box
  const [iswelldone, setiswelldone] = useState(false); // State to track the visibility of the minus box
  const [color1, setcolor1] = useState('4E5154'); // State to track the visibility of the minus box
  const [color2, setcolor2] = useState('4E5154'); // State to track the visibility of the minus box
  const [colorop, setcolorop] = useState('4E5154'); // State to track the visibility of the minus box
  const [colorx, setcolorx] = useState('4E5154'); // State to track the visibility of the minus box
  const [looping, setlooping] = useState(false); // State to track the visibility of the minus box

  

    useEffect(() => {
      const one = oneref.current;
      const two = tworef.current;
      const x = xref.current;
      const plus = plusRef.current;
      const extra = extrarightref.current;
      const neg = negateref.current;

      

      },[]);

      const handleddClick = () => {
        console.log("doubleclicked");
      setbottomanimation(swipeinstruction);
      setpointertype(drag);
      gsap.to(apointer.current, { y: +30,x:-15, duration: 1 });
      const place1 = oneref.current.offsetLeft;
      const place2 = tworef.current.offsetLeft;
      const allocation = place2 - place1;
      const originalPosition = { x: apointer.current.offsetLeft, y: apointer.current.offsetTop };

      const tl = gsap.timeline({ repeat: -1 }); // repeat: -1 for infinite looping

      tl.to(apointer.current, { x: allocation, duration: 2, delay: 1.5 })
      .to(apointer.current, { x: originalPosition, duration: 0, delay: 3 });

        const newValueElement = document.createElement("div");
                newValueElement.textContent = value1; // Set the text content to the value of 'x'
                newValueElement.style.position = "fixed";
                newValueElement.className = "dropdown";
                newValueElement.style.padding = "10px";
                newValueElement.style.color = "#585757";
                newValueElement.style.fontSize = "30px";
                newValueElement.style.fontFamily = "Helvetica";
                newValueElement.style.top = "+400px";

                newValueElement.style.left = `${oneref.current.getBoundingClientRect().left + 10}px`; // Position it below the 'x' element
                newValueElement.style.cursor = "default";
              //  document.body.appendChild(newValueElement); // Append it to the body
              const referenceElement = document.querySelector('#thisid'); // Replace with your specific div's selector
      
      // Insert the new div after the reference element
      if (referenceElement && referenceElement.nextSibling) {
        document.body.insertBefore(newValueElement, referenceElement.nextSibling);
      } else if (referenceElement) {
        // If referenceElement is the last child
        document.body.appendChild(newValueElement);
      } else {
        // If referenceElement is not found, append to body as default
        document.body.appendChild(newValueElement);
      }
      const draggableXValueInstance = Draggable.create(newValueElement, {
        type: "x",
        edgeResistance: 0.65,
        onDragEnd: function () {
            const xValue = parseFloat(this.target.textContent); // Get the value of the dragged element
            const xValuePosition = this.target.getBoundingClientRect().left;
            const equal = assignRef.current;
      
            const equalPosition = equal.getBoundingClientRect().left;
      
            if (xValuePosition > equalPosition) {
                 gsap.to(newValueElement, { x: 132, duration: 1 });
                 setcross(true);
                 gsap.to(apointer.current, { opacity: 0, duration: 1 });
                 
                 gsap.to(bottomanimref.current, { opacity: 100, duration: 1,delay:2});

                 setTimeout(() => {
                   const new2 = value2 / xValue; // Perform division using the value of the dragged element
                   tworef.current.textContent = new2; 
                   gsap.to(newValueElement, { opacity:  0, duration: 0.3, ease: "power2.inOut" });
              
                   setTimeout(() => {
                    settwoanswered(true);
                  }, 300);
                 }, 3000);
               
setTimeout(() => {
  
  gsap.to(upperanimref.current, { opacity:  100, duration: 0.3, ease: "power2.inOut" });

  setupperanimations(division);
}, 1000);


                 setTimeout(() => {
                
                  gsap.to(bottomanimref.current, { opacity:  0, duration: 0.3, ease: "power2.inOut" });

                 }, 500);
                 gsap.to(upperanimref.current, { opacity:  0, duration: 0.3, ease: "power2.inOut",delay:5 });


                 setTimeout(() => {
                  gsap.to(bottomanimref.current, { opacity:  100, duration: 0.3, ease: "power2.inOut" });

                  setbottomanimation(congs);

                 }, 6000);
               
                 setTimeout(() => {
                  setShowNum1(false);
                }, 500);
      
                  setTimeout(() => {
                   criscross();
                 }, 400);
      
      
                  setTimeout(() => {
                    criscross2();
                  }, 600);
                
          
                 // Update the text content of the '5' element
                 this.disable(); // Disable dragging after the condition is met
            }
            else{
              setbottomanimation(tryagain);

              setTimeout(() => {
                setbottomanimation(swipeinstruction);

                
              }, 1500);
            }
        },
      });
      
      };

 const trainingelements = {
    display: "flex",
    position: "relative",
    alignItems: 'center',
    justifyContent: 'center',
 
    zindex:2,
    alignItems: "center",

  };

  const trainingelements2 = {
    display: "flex",
    position: "relative",
    marginLeft:"380px",
    zindex:2,
    alignItems: "center",
  };

  const crossposition = {
    position: 'absolute', // Make sure to include the 'position' property
    width: '50px',
    height: '50px',
    bottom: '90px',
    left: '150px',
    marginBottom:"18px",

    zIndex: 2
  };
  const crossposition3 = {
    position: 'absolute', // Make sure to include the 'position' property
    width: '100px',
    height: '100px',
    bottom: '155px',
    left: '0px',
    marginBottom:"5px",
    zIndex: 2
  };
  const crossposition2 = {
    position: 'absolute', // Make sure to include the 'position' property
    width: '60px',
    height: '60px',
    bottom: '184px',
    left: '150px',
    marginTop:"15px",

    zIndex: 2
  };
  const criscross = () => {
    if (crossesref.current) {
      crossesref.current.play();
  }  };
  
  const criscross2 = () => {
    if (crosses2ref.current) {
      crosses2ref.current.play();
  }  };
 

    return(
        <div>
            

            <div ref={upperanimref} className="upperanimation" style={{opacity:'0'}}>
            <Player ref={upperanimref} autoplay={true} loop={false} keepLastFrame={true} src={upperanimations} />

            </div>
             <div  style={{display:'flex'}} >
             <div className='test2' id={"test2"} onDoubleClick={handleddClick} ref={oneref} style={{position:"relative", padding: "10px", fontSize: '30px', userSelect: 'none', margin: "10px",zindex:4,cursor:"default"}}>
                  {showNum1 && (
                      <span >{value1}</span>
                  )}  x
              </div>  
               
              <div style={{position:'fixed', top:'200px',left:'100px'}}>
         <Player  ref={crossesref} autoplay={false} loop={false} src={crosses} speed={0.3}   />

         </div> 
         <div style={{position:'fixed', top:'400px',left:'200px'}}>
         <Player  ref={crosses2ref} autoplay={false} loop={false} src={crosses2} speed={0.3}    />

         </div>
           
         

                   <div className='test2' ref={assignRef} style={{ padding: "10px", fontSize: '30px', backgroundColor:'white',color: colorop, userSelect: 'none', margin: "15px" }}>=</div>
                   <div className='test2' ref={tworef} style={{ padding: "10px", fontSize: '30px',backgroundColor:'white', color: color2, userSelect: 'none', margin: "15px" }}>{value2}</div>
                   

               </div>
                  <div ref={apointer} >

<Player ref={instructionref} autoplay={true} loop={true}  src={pointertype} style={{marginRight:'180px',width:'150px',height:'150px'}} />

</div>
               {playbottomanimation && (
 <div ref={bottomanimref} className="loweranimation" style={{opacity:'0'}} >
 <Player ref={bottomanimref} autoplay={true} loop={false} keepLastFrame={true}  src={congs} />
</div>
               )}
              
        </div>
    );

};
export default Unit3m;