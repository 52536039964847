import React, { useEffect, useRef, useState } from "react";
import sqrt from '../jsons/sqrt.json';
import { Player } from "@lottiefiles/react-lottie-player";
import gsap from "gsap";
import startbtn from '../jsons/startbutton.json';
import Fraction from 'fraction.js';
//import { math } from 'mathjs';
import { Card,Container, Row, Col } from 'react-bootstrap';


const QuadraticExamplem2 = ({a,b,c}) =>{
    const playref = useRef(null);
    const variableref = useRef(null);
    const variable2ref = useRef(null);
    const variable3ref = useRef(null);
    const constantref = useRef(null);
    const constant2ref = useRef(null);
    const bracket2ref = useRef(null);
    const minusref = useRef(null);
    const plusref = useRef(null);
    const startref = useRef(null);
    const mainref = useRef(null);
    const barref = useRef(null);
    const bottomref = useRef(null);

   // const [a, setA] = useState(6);
   // const [b, setB] = useState(7);
   // const [c, setC] = useState(2);
    const [variable, setVariable] = useState('a');
    const [variable2, setVariable2] = useState('-b');
    const [variable21, setVariable21] = useState('b');
    const [operator, setOperator] = useState('+/-');
    
    const [bar1, setbar1] = useState('___________________');
    const [startbuttonon, setstartbuttonon] = useState(true);

    const [variable3, setVariable3] = useState('c');
    const [constant, setConstant] = useState(4);
    const [constant2, setConstant2] = useState(2);

    const [otheroption, setotheroption] = useState(null);
    const [ac, setac] = useState(true);

    const [a2, seta2] = useState(true);
    const [brackets, setbrackets] = useState(true);
    const [brackets2, setbrackets2] = useState(true);
    const [bars, setBars] = useState(true);

    const [forsquareroot, setforsquareroot] = useState(true);


    const shades = {
        margin:'4px',
        fontSize:'20px',
    };
    useEffect(() => {
     
      
      
    }, [variable3,a,b,c,variable2,variable3]);


const playstart = () =>{
    setstartbuttonon(false);
    const square = b *b;
    const mult = 4*+a*+c;
    const multi2 = 2*+a;
    const sub = square - mult;
    const sqrt = Math.sqrt(sub);
    const t1 = -1 *b;
    const numerator = -b + Math.sqrt(b * b - 4 * a * c);
    const numerator2 = -b - Math.sqrt(b * b - 4 * a * c);

    const denominator = 2 * a;
   // const x1 = new Fraction(numerator, denominator);
    //setResult(result);

    const x1 = t1 - sqrt;
    const x2 = t1 + sqrt;
    const xa = numerator/denominator;
    const xa3 = xa.toFixed(3);

    const xb = numerator2/denominator;
    const xb3 = xb.toFixed(3);
    setTimeout(() => {
        setVariable3(c);
        gsap.to(variable3ref.current,{color:'red',duration:1})

    }, 1000);


    setTimeout(() => {
        setVariable(a);
        gsap.to(variableref.current,{color:'red',duration:1})

    }, 2500);

    setTimeout(() => {
        setConstant(mult);
        gsap.to(constantref.current,{color:'blue',duration:1})

        setac(false);
    }, 4000);

    setTimeout(() => {
        setConstant2(multi2);
        gsap.to(constantref.current,{color:'blue',duration:1})
        seta2(false);

    }, 5000);

setTimeout(() => {
setVariable2(b*-1);
setVariable21(b);

gsap.to(variable2ref.current,{color:'red',duration:1})

}, 7000);

setTimeout(() => {
setVariable21(square);
gsap.to(variable2ref.current.querySelector('span'), { opacity: 0, duration: 0.5 });

}, 9000);

setTimeout(() => {
setVariable21(sub);
gsap.to(constantref.current,{opacity:'0',duration:1})
gsap.to(minusref.current,{opacity:'0',duration:1})
setforsquareroot(false);
setTimeout(() => {
console.log('sub is ', sub);
console.log('square is ', square);
console.log('multi2 is ', multi2);

setbrackets(false)
gsap.to(bracket2ref.current,{opacity:'0',duration:0})


}, 3000);
}, 12000);


setTimeout(() => {
    setVariable21(sqrt);
    gsap.to(playref.current,{opacity:'0',duration:1})

}, 15000);

setTimeout(() => {
    setOperator("+");
}, 18000);

setTimeout(() => {
    setVariable2(x2);
    setbrackets2(false)
    setbar1('___');
  //  gsap.to(barref.current,{marginBottom:'35px',duration:2})
  //  gsap.to(barref.current,{y:'-10',duration:2})
    setotheroption(x1);
}, 20000);

setTimeout(() => {
    setotheroption(xb3);
    setVariable2(xa3);
    setBars(false);
   gsap.to(bottomref.current,{marginLeft:'200px',duration:2})

}, 23000);

}

return(
    <div>

    <div style={{fontSize:'20px',fontSize:'helvetica',...shades}}>
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
    <Row className="justify-content-center flex-wrap">
    <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
    <Card style={{ width: 'auto' }}>
    <Card.Body className="text-center">

<div style={{display:'flex',flexDirection:'column'}}>
   <div ref={mainref} style={{display:'flex',height:'15px' }}>
   <div style={{marginRight:'10px'}}>
        x
    </div>
    <div style={{marginRight:'10px'}}>
        =
    </div>
    {brackets2 && (
   <div>
   (
   </div>
   
    )}
 

  
   <div ref ={variable2ref} >
   {variable2}

   </div>
   <div style={{color:'maroon',fontSize:'20px'}}>
 
 {brackets2 && (
 <div>
 {operator} </div>

 )}

 

   </div>
   <div ref={playref} style={{width:'50px',height:'70px',}} >
   <Player  ref={playref} autoplay={true} keepLastFrame={true} loop={false} src={sqrt} />
   </div>
   {brackets && (
    <div ref={bracket2ref} >
    (
   </div>
   )}

   {brackets2 && (

<div ref ={variable2ref} style={{...shades}}>
{variable21}
<span style={{fontSize:'10px',height:"50px"}}>2</span>
</div>
   )}
 
   {forsquareroot &&  (
   <div ref={minusref} style={{...shades,color:'maroon',}}>
   -
  </div>
   )

   }

{brackets && forsquareroot && (
 <div ref={constantref} style={{...shades}}>
 {constant}
</div>
)}
  


   {variable===a && ac && (
    <div style={{...shades}}>
    x
   </div>
   )

   }
   {ac&& brackets && (

<div ref ={variableref} style={{...shades}}>
    {variable}
   </div>
   )}
   
   {variable===a && ac && (
    <div style={{...shades}}>
    x
   </div>
   )

   }
   {ac && brackets && (


<div ref ={variable3ref} style={{...shades}}>
{variable3}

</div>
   )}
    {brackets && (

   <div style={{...shades}}>
    )

   </div>
   )}

{brackets2 && (

<div style={{...shades}}>
  )
 </div>
)}
{!brackets2 && (
    <div style={{marginRight:'80px',marginLeft:'10px',marginTop:'10px'}}>
    or
  </div>

)}
  
 {!brackets2 && (
    <div style={{display:'flex', flexDirection:'column'}}>
            <div >
            {otheroption}
            </div>
            {bars && (
                <div >
                ___
                </div>
            )}
                        {bars && (

                <div >
                    2
                </div>
                            )}

</div>
 )}
   
   </div>
   {bars && (

<div ref={barref} style={{height:'15px'}}  >
    {bar1}
   </div>
   )}
   
   {bars && (
 <div ref={bottomref} style={{display:'flex',...shades,marginLeft:'120px'}} >
 <div ref={constant2ref} style={{...shades}}>
      {constant2}
  </div>
  <div>
  {variable===a && a2 && (
  <div style={{...shades}}>
  x
 </div>
 )

 }
  </div>
  {a2 && (
      <div ref ={variableref} style={{...shades}}>
      {variable}
  </div>
  )}
  

 </div>
   )}
  
</div>
</Card.Body>
<Card.Footer>
    {startbuttonon && (
        <div onClick={playstart}>
<Player  ref={startref} autoplay={true} loop={true} src={startbtn}  hover={true}  />

</div>
    )}

</Card.Footer>
</Card>
</Col>
</Row>
</Container> 
    </div>
    </div>

);
};
export default QuadraticExamplem2;
