import React from 'react';
import { useLocation } from 'react-router-dom';
import PracticeDashboard from './PracticeDashboard';
import LearningDashboard from './LearningDashboard';

const DashboardContainer = ({weeklymessage}) => {
  const weeklyMessage = "This is your weekly message!";

  const location = useLocation();

  if (location.pathname.startsWith('/mtrain')) {
    return <PracticeDashboard message={weeklymessage}/>;
  } else if (location.pathname.startsWith('/mlearn')) {
    return <LearningDashboard message={weeklymessage}/>;
  }

  return null; // Don't render anything if not in the specified paths
};

export default DashboardContainer;
