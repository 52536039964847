import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/all";
import { Player } from "@lottiefiles/react-lottie-player";
import './Component.css';
import firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, updateDoc, increment } from 'firebase/firestore';
import { app } from '../Firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

gsap.registerPlugin(Draggable);

const Component5mob = ({ C1, C2, C3, C4, sign1, sign2,sendDataToParent }) => {
    const c1ref = useRef(null);
    const c2ref = useRef(null);
    const c3ref = useRef(null);
    const c4ref = useRef(null);
    const equalsref = useRef(null);
    const rightsign = useRef(null);
    const firstsign = useRef(null);
    const secondsign = useRef(null);
    const leftsign = useRef(null);
    const extrarightref = useRef(null);
    const extraleftref = useRef(null);
    const [showvariable1, setshowvariable1] = useState(true); 
    const [showvariable2, setshowvariable2] = useState(true);
    const [variable1, setvariable1] = useState(C1); 
    const [variable2, setvariable2] = useState(C3); 
    const [sign3, setsign3] = useState('-'); 
    const [sign4, setsign4] = useState('+'); 
    const [thirdclick, setthirdclick] = useState(false); 
    const [twofirst, settwofirst] = useState(false); 
    const [threefirst, setthreefirst] = useState(false);
    const [M1, setM1] = useState(0);
    const [Mn, setMn] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const db = getFirestore();
    const auth = getAuth();

    const currentUser = auth.currentUser;
    const currentUserId = currentUser.uid;
    const incrementM1 = () => {
        setM1(prevM1 => {
          const newM1 = prevM1 + 1;
          updateM1InFirestore(newM1).then(() => {
            // code to be executed after the update is complete
          }).catch(error => {
            console.error('Error updating M1:', error);
          });
          return newM1;
        });
    
      };
      
       const updateM1InFirestore = async (newM1Value) => {
  try {
    const userDocRef = doc(db, 'users', currentUserId);
    await updateDoc(userDocRef, { M1: increment(1) });
    console.log('M1 updated successfully');
  } catch (error) {
    console.error('Error updating M1:', error);
  }
};
      const incrementMn = () => {
        setMn(Mn + 1);
        // Here you can also call the function to update M1 in the database
        updateMnInFirestore(Mn + 1);
      };
      
      const updateMnInFirestore = async (newM1Value) => {
        try {
          const userDocRef = doc(db, 'users', 'DzUD2GtHCGNtuapAWoIN9NOuH253');
          await updateDoc(userDocRef, { Mn: newM1Value });
          console.log('Mn updated successfully');
        } catch (error) {
          console.error('Error updating Mn:', error);
        }
      };
  
    const thirddoubleclick = () => {
        if (thirdclick) {
            incrementM1();
            
            console.log('third');
            const ValueElement3 = document.createElement("div");
            ValueElement3.textContent = variable2; // Set the text content to the value of 'x'
            ValueElement3.style.position = "absolute";
            ValueElement3.style.top = "330px";
            ValueElement3.style.padding = "10px";
            ValueElement3.style.border = "1px solid #585757";
            ValueElement3.style.color = "#585757";
            ValueElement3.style.left = `${c3ref.current.getBoundingClientRect().left + 15}px`; // Position it below the 'x' element
            ValueElement3.style.cursor = "crosshair";
        }
    };

    useEffect(() => {
        const one = c1ref.current;
        const two = c2ref.current;
        const three = c3ref.current;
        const four = c4ref.current;
        const assign = equalsref.current;
        const extraleft = extraleftref.current;
        const extraright = extrarightref.current;

        const position1 = one.getBoundingClientRect().left;
        const position2 = two.getBoundingClientRect().left;
        const position3 = three.getBoundingClientRect().left;
        const position4 = four.getBoundingClientRect().left;
        const equalsposition = assign.getBoundingClientRect().left;
        const secondplace = two.offsetLeft;    
        const thirdplace = three.offsetLeft;    
        const firstplace = one.offsetLeft;    
        const fourthplace = four.offsetLeft; 
        const leftplace = extraleft.offsetLeft;
        const rightplace = extraright.offsetLeft;

        if (C1 === 1) {
            setshowvariable1(false);
        } 
        if(C3===1){
            setshowvariable2(false)
        }

        // drag 2
        const draggableInstance2 = Draggable.create(two, {
            type: "x",
            edgeResistance: 0.75,
            onDragEnd: function() {
                // declare the positions for shifting
                const place1right = extrarightref.current.offsetLeft - two.offsetLeft;
                const place1left = two.offsetLeft - one.offsetLeft;
                const place22 = fourthplace - secondplace;
                const place23 = two.offsetLeft - three.offsetLeft;


                // declare the positions of the elements
                const position2 = two.getBoundingClientRect().left;
                const position3 = three.getBoundingClientRect().left;
                const position4 = four.getBoundingClientRect().left;
                // condition to be met by dragging two
              if(threefirst){
                    if(position2>position4){
                        incrementM1();
                        gsap.to(two, { x: place22, duration: 1.5 });
                        gsap.to(three, { x: place23, duration: 1.5 });
                        gsap.to(firstsign.current, { opacity: '0', duration: 1 });
                        gsap.to(secondsign.current, { opacity: '100', duration: 1 });
                        gsap.to(secondsign.current, { textContent: '-', duration: 1 });
               
                        //change colors
                        gsap.to(two, { backgroundColor: 'black', duration: 1, delay: 1 });
                        gsap.to(two, { color: 'white', duration: 1, delay: 1 });
                        gsap.to(four, { backgroundColor: 'black', duration: 1, delay: 1 });
                        gsap.to(four, { color: 'white', duration: 1, delay: 1 });

                        setTimeout(() => {
                            const newsub = C4 - C2; 
                            gsap.to(four, { textContent: newsub,duration:0 });
                            gsap.to(two, { opacity: '0', duration: 1 });
                            gsap.to(secondsign.current, { opacity: '0', duration: 0.5 });


                        }, 2000);

                        //change color back
                        gsap.to(four, { backgroundColor: 'white', duration: 1, delay: 3 });
                        gsap.to(four, { color: 'black', duration: 1, delay: 3 });
                        sendData();

                    }
                    else{
                        const ppa = two.offsetLeft-two.offsetLeft;
                        gsap.to(two, { x: ppa, duration: 1.5 });
                        decreaseM1();

                    }
              }
              else{
                if (position2 > position4) {
                    incrementM1();
                    // shift the dragged element
                    console.log("its here");
                    gsap.to(two, { x: place1right, duration: 1.5 });
                    settwofirst(true);
                    // set the signs
                    if (sign1 === '-') {
                        setsign4('+');
                    } else if (sign1 === '+') {
                        setsign4('-');
                    }
                    // hide/show the signs
                    gsap.to(rightsign.current, { opacity: '100', duration: 1 });
                    gsap.to(firstsign.current, { opacity: '0', duration: 1 });

                    // shift the abandoned element
                    gsap.to(one, { x: place1left, duration: 1.5 });

                    // change colors
                    gsap.to(two, { backgroundColor: 'black', duration: 1, delay: 2 });
                    gsap.to(two, { color: 'white', duration: 1, delay: 2 });
                    gsap.to(four, { backgroundColor: 'black', duration: 1, delay: 2 });
                    gsap.to(four, { color: 'white', duration: 1, delay: 2 });

                    // perform calculation
                    setTimeout(() => {
                        const new4 = C4 - C2;
                        four.textContent = new4; 
 
                    }, 3000);
                    
                    // hide the calculated & right sign
                    gsap.to(two, { opacity: '0', duration: 1, delay: 3 });
                    gsap.to(rightsign.current, { opacity: '0', duration: 1, delay: 2.5 });

                    // reverse color change
                    gsap.to(four, { backgroundColor: 'white', duration: 1, delay: 4 });
                    gsap.to(four, { color: 'black', duration: 1, delay: 4 });
                }
                else{
                    const ppa = two.offsetLeft-two.offsetLeft;
                    gsap.to(two, { x: ppa, duration: 1.5 });
                    decreaseM1();
                }
               // this.disable(); // Disable dragging after the condition is met
              }
              
              
            },
        });

        // drag 3
        const draggableInstance3 = Draggable.create(three, {
            type: "x",
            edgeResistance: 0.75,
            onDragEnd: function() {
                // DRAG2 check 
                if (twofirst) {
                    if (three.getBoundingClientRect().right < assign.getBoundingClientRect().right) {
                        incrementM1();
                        // declare positions for shifting
                        const place = secondplace - thirdplace;
                        const place2 = firstplace - one.offsetLeft;
                        const place3 = thirdplace - four.offsetLeft;

                        gsap.to(three, { x: place, duration: 1.5 });
                        gsap.to(one, { x: place2, duration: 1.5 });
                        
                        // toggle the signs
                        firstsign.current.textContent = '-'
                        gsap.to(firstsign.current, { opacity: '100', duration: 1, delay: 1.2 });

                        // move the fourth element

                        gsap.to(four, { x: place3, duration: 1.5 });
                        gsap.to(secondsign.current, { opacity: 0, duration: 1 });

                        // calculate the left side
                        const newc4 = C1 - C3;
                        sendData();

                        console.log(C1, C3, newc4);
                        setTimeout(() => {
                            setvariable2(newc4);   
                            setshowvariable2(false);
                            gsap.to(secondsign.current, { opacity: '0', duration: 1 });

                            gsap.to(one, { opacity: '0', duration: 1 });
                            gsap.to(firstsign.current, { opacity: '0', duration: 1 });
                            this.disable();
                            setthirdclick(true);
                            gsap.to(three, { userSelect: 'none', duration: 1 });
                        }, 3000);
                    }
                    else {
                        const ppb = three.offsetLeft-three.offsetLeft;
                        gsap.to(three, { x: ppb, duration: 1.5 });
                        decreaseM1();

                    }
                }
                else {
                    console.log("the else");
                    const position2 = two.getBoundingClientRect().left;
                    const position3 = three.getBoundingClientRect().left;
                    decreaseM1();

if(position3 < position2){
    console.log("the if with else");
incrementM1();
    const place = firstplace - thirdplace;
    const place2 = leftplace - firstplace;
    const place3 = thirdplace - fourthplace;
    gsap.to(three, { x: place, duration: 1.5 });
    gsap.to(one, { x: place2, duration: 1.5 });
    gsap.to(leftsign.current, { opacity: '100', duration: 1,delay:1  });
    gsap.to(four, { x: place3, duration: 1.5 });
    gsap.to(secondsign.current, { opacity: '0', duration: 0.3,  });
    setthreefirst(true);
    //change colors before subtraction
    gsap.to(one, { color: 'white', duration: 1.5, delay:1 });
    gsap.to(one, { backgroundColor: 'black', duration: 1.5 , delay:1});
    gsap.to(three, { color: 'white', duration: 1.5, delay:1 });
    gsap.to(three, { backgroundColor: 'black', duration: 1.5 , delay:1});
    //calculate

    setTimeout(() => {
        const sub =C1 - C3;

        setvariable2(sub);
        if(sub===1){
            setshowvariable2(false); 
            gsap.to(one, { opacity: '0', duration: 1 });
            gsap.to(leftsign.current, { opacity: '0', duration: 1 });

        }
    }, 2500);

    //switch back colors
    gsap.to(one, { color: 'black', duration: 1.5, delay:3 });
    gsap.to(one, { backgroundColor: 'white', duration: 1.5 , delay:3});
    gsap.to(three, { color: 'black', duration: 1.5, delay:3 });
    gsap.to(three, { backgroundColor: 'white', duration: 1.5 , delay:3});

}     
else{
    const ppb = three.offsetLeft-three.offsetLeft;
    gsap.to(three, { x: ppb, duration: 1.5 });
    decreaseM1();

}               
                   

                }
            },
        });

        // drag 4
        const draggableInstance4 = Draggable.create(four, {
            type: "x",
            edgeResistance: 0.75,
            onDragEnd: function() {
                // handle drag end for four]
                const place1 = four.offsetLeft - four.offsetLeft;
                gsap.to(four, { x: place1, duration: 1.5 });

            },
        });
        const draggableInstance1 = Draggable.create(one, {
            type: "x",
            edgeResistance: 0.75,
            onDragEnd: function() {
                // handle drag end for four]
                const placeone = one.offsetLeft - one.offsetLeft;
                gsap.to(one, { x: placeone, duration: 1.5 });

            },
        });

        three.addEventListener("dblclick", thirddoubleclick);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            three.removeEventListener("dblclick", thirddoubleclick);
        };
    }, [variable1,variable2,twofirst]);

    const thebase = {
        position: 'fixed',
        display: "flex",
        alignItems: "center",
        fontSize: "20px",
        userSelect:'none',
        left:'80px',
        top:'200px'
    };
    const digitposition = {
        position: "relative",
        left: "-60px",
        bottom: "-50px",
        padding: "5px",
        fontSize: '30px',
        color: 'black',
        userSelect: 'none',
        backgroundColor: "white",
        fontFamily: 'Helvetica',
        fontWeight:'bold',
        border: "1px solid #000",
        margin:'2px'

    };
    const borderless = {
        position: "relative",
        left: "-60px",
        bottom: "-50px",
        padding: "5px",
        fontSize: '30px',
        color: 'black',
        userSelect: 'none',
        margin:'2px'
        // border: "1px solid #000"
    };
    const equalposition = {
        position: "relative",
        left: "-60px",
        bottom: "-50px",
        padding: "10px",
        fontSize: '35px',
        color: 'red',
        userSelect: 'none',
        margin:'2px'

    };
    const signposition = {
        position: "relative",
        left: "-60px",
        bottom: "-50px",
        padding: "5px",
        fontSize: '35px',
        color: 'blue',
        userSelect: 'none',
        fontFamily: 'Helvetica',
        fontWeight:'bold',
        margin:'2px'

    };
    const maindivposition = {
        position: 'relative',
        display: "flex",
        fontSize: "30px",
    };
    const numbersposition = {
        padding: "10px", 
         fontFamily: 'Helvetica',
         fontWeight:'bold',
          border: "1px solid #000",
          userSelect:'none',
           margin: "5px"
      };
      const sendData = () => {
        const data = true;
        sendDataToParent(data);
      };
    
      
      const decreaseM1InFirestore = async (newM1Value) => {
        try {
          const userDocRef = doc(db, 'users', currentUserId);
          await updateDoc(userDocRef, { M1: increment(-1) });
          console.log('M1 updated successfully');
        } catch (error) {
          console.error('Error updating M1:', error);
        }
      };

      const decreaseM1 = () => {
        setM1(prevM1 => {
          const newM1 = prevM1 - 1;
          decreaseM1InFirestore(newM1).then(() => {
            // code to be executed after the update is complete
          }).catch(error => {
            console.error('Error updating M1:', error);
          });
          return newM1;
        });
    
      };










    return (
        <div>
            {isMobile? (
 <div className="practicearea">
 <div className="base" style={{ ...thebase }}>
     <div ref={extraleftref} style={{ ...borderless,opacity:'0' }}>
         xx
     </div>
     <div ref={leftsign} style={{ ...signposition, opacity: '0' }}>
         {sign3}
     </div>
     <div ref={c1ref} style={{ ...digitposition }}>
         {showvariable1 && (
             <span>
                 {variable1}
             </span>
         )}
         x
     </div>
     <div ref={firstsign} style={{ ...signposition }}>
         {sign1}
     </div>
     <div ref={c2ref} style={{ ...digitposition }}>
         {C2}
     </div>
     <div ref={equalsref} style={{ ...equalposition }}>
         =
     </div>
     <div onDoubleClick={thirddoubleclick} ref={c3ref} style={{ ...digitposition }}>
         {showvariable2 && (
             <span>
                 {variable2}
             </span>
         )}
         x
     </div>
     <div ref={secondsign} style={{ ...signposition }}>
         {sign2}
     </div>
     <div ref={c4ref} style={{ ...digitposition }}>
         {C4}
     </div>
     <div ref={rightsign} style={{ ...signposition, opacity: '0' }}>
         {sign4}
     </div>
     <div ref={extrarightref} style={{ ...borderless }}>
     </div>
 </div>
</div>
            ): (

                <div className="practicearea">
                <div className="base" style={{ ...thebase }}>
                    <div ref={extraleftref} style={{ ...borderless,opacity:'0' }}>
                        xx
                    </div>
                    <div ref={leftsign} style={{ ...signposition, opacity: '0' }}>
                        {sign3}
                    </div>
                    <div ref={c1ref} style={{ ...digitposition }}>
                        {showvariable1 && (
                            <span>
                                {variable1}
                            </span>
                        )}
                        x
                    </div>
                    <div ref={firstsign} style={{ ...signposition }}>
                        {sign1}
                    </div>
                    <div ref={c2ref} style={{ ...digitposition }}>
                        {C2}
                    </div>
                    <div ref={equalsref} style={{ ...equalposition }}>
                        =
                    </div>
                    <div onDoubleClick={thirddoubleclick} ref={c3ref} style={{ ...digitposition }}>
                        {showvariable2 && (
                            <span>
                                {variable2}
                            </span>
                        )}
                        x
                    </div>
                    <div ref={secondsign} style={{ ...signposition }}>
                        {sign2}
                    </div>
                    <div ref={c4ref} style={{ ...digitposition }}>
                        {C4}
                    </div>
                    <div ref={rightsign} style={{ ...signposition, opacity: '0' }}>
                        {sign4}
                    </div>
                    <div ref={extrarightref} style={{ ...borderless }}>
                    </div>
                </div>
            </div>
            )}

        </div>
       
    );
};

export default Component5mob;
