import React, { useEffect, useRef, useState } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import wait from './jsons/wait.json';
import next from './jsons/goback.json';
import './MathLearning.css';
import { gsap } from "gsap";
import Component1 from "./PracticeComponents/Component1";
import Component11 from "./PracticeComponents/Component11";
import crosses from './jsons/crosses1.json';
import crosses2 from './jsons/crosses1.json';
import crosses3 from './jsons/crosses1.json';
import task1 from './jsons/task1.json';
import Unit2 from "./LearningComponents/Unit2";
import Unit1 from "./LearningComponents/Unit1";

import Unit3 from "./LearningComponents/Unit3";
import Unit32 from "./LearningComponents/Unit32";
import nextbutton from './jsons/nextbtnlong.json';
import backbutton from './jsons/goback.json';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import mswipe from './jsons/mssswipe1.json';
import ok from './jsons/ok.json';
import not from './jsons/not.json';
import msound from './jsons/swipesound2.mp3'
import wave from './jsons/wav2.mp3';
import equation from './jsons/equation.json';
import solvingx from './jsons/solvingx.json';
import rpt from './jsons/rpt.json';
import bookscene1 from './jsons/bookscene1.json';
import bookscene1audio from './jsons/bookscene1.mp3';
import solvep from './jsons/solveforp.json';
import solvepaudio from './jsons/solvepaudio.mp3';
import book3scene from './jsons/bookscene3.json';
import CircularButton from "./bootsrap elements/CircularButton";
import CircularButtonback from "./bootsrap elements/CircularButtonback";
import book3sceneaudio from './jsons/book3sceneaudio.mp3';
import { Container, Row, Col,Card } from 'react-bootstrap';
import Unit3m from "./LearningComponents/Unit3m";
import Unit3m2 from "./LearningComponents/Unit3m2";
const MathLearning3 = () =>{
  const navigate = useNavigate();


    const tag3ref = useRef(null);
    const audioref = useRef(null);
    const newref = useRef(null);
    const newref2 = useRef(null);
    const newref3 = useRef(null);
    const theref = useRef(null);
   
    const windowref = useRef(null);
 
    const audioref3 = useRef(null);
    const [buttonmain, setbuttonmain] = useState(nextbutton);
 
    const [scdiv, setscdiv] = useState(false);
    const [scdiv2, setscdiv2] = useState(false);

    const rptref = useRef(null);
   
    const narratorref = useRef(null);
    const upperanimref = useRef(task1);
    const nextbuttonref = useRef(null);
    const backbuttonref = useRef(backbutton);
    const [unit, setUnit] = useState(1); // State to track the visibility of the minus box
    const [counter, setcounter] = useState(1); // State to track the visibility of the minus box
    const [playing, setplaying] = useState(bookscene1);
    const [playingm, setplayingm] = useState(bookscene1);
    const [sounding, setsounding] = useState(bookscene1audio);
    const [secondtile, setsecondtile] = useState(solvingx);
    const [bottomtile, setbottomtile] = useState(equation);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const crossesref = useRef(crosses);
    const crosses3ref = useRef(crosses);
   
  
  

    useEffect(() => {
      console.log('counter is', counter);
    }, [counter]);
    
    const thebottomslower = {
        flex: 1,
        position: "relative",
        background:"white",
        height: "418px",
        padding:"0px",
        width: "908px",
        marginBottom: "150px",
        marginTop: "110px",
        display:"flex",
        marginLeft: "340px",
        zindex:2 
      }; 
      
  
      const repeatanimation = () => {
        if (newref.current) {
          newref.current.stop();  // Reset the animation
          newref.current.play();  // Play the animation from the start
        }
        if (audioref.current) {
          
          audioref.current.play();  // Play the audio
        }
       // setcounter(counter - 1);
        playnarrator();
        playsound();
      };
      
      const goback = () => {
        // Decrement the counter first
        setcounter((prevCounter) => {
          const newCounter = prevCounter - 1;
          return newCounter;
        });
      
        // Stop the current animation and sound
        if (newref.current) {
          newref.current.stop();
        }
        if (audioref.current) {
          audioref.current.pause();
          audioref.current.currentTime = 0;
        }
      
        // Play the new animation and sound after state updates
        setTimeout(() => {
          if (newref.current) {
            newref.current.play();
          }
          if (audioref.current) {
            audioref.current.play();
          }
        }, 100); // Slight delay to ensure state updates before playing
      };
      
      
      
      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
      }, []);
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      
    
      
      const playnextscene = () =>{
        if(counter===1){
          playnarrator();
          playbackgroundsound();
          playsound();
          setcounter(counter+1);
        }
        else if(counter===2){
          setplaying(solvep);
          setsounding(solvepaudio);
          playnarrator();
          playsound();
          setTimeout(() => {
            setscdiv(true);
          }, 20000);
          setcounter(counter+1);
        }
        else if(counter===3){
          setscdiv(false);
          setplaying(book3scene);
          setsounding(book3sceneaudio);
          playnarrator();
          playsound();
          setTimeout(() => {
            setscdiv2(true);
          }, 5000);

        }
      }
      
      const playsound = () =>{

        if(audioref.current){
          audioref.current.play()
        }
      }
      const playbackgroundsound = () =>{
setTimeout(() => {
  if(audioref3.current){
    audioref3.current.play()
  }
}, 500);
        
      }
  

      const playnarrator = () =>{
setTimeout(() => {
  if(newref.current){
    newref.current.play()
  }
}, 500);
       
      }
      const next = () => {
//wait scene 
if (!isDisabled) {
  setbuttonmain(wait);
  if(nextbuttonref.current){
    nextbuttonref.current.play();
  }
  console.log("Button clicked");
  setIsDisabled(true);
  setTimeout(() => {
    setIsDisabled(false);
    setbuttonmain(nextbutton);

  }, 2500);
} 
playnextscene();

    }
    const handleAudioLoad = () => {
      if (audioref.current) {
        if(counter>1){
          audioref.current.play().catch((error) => {
            console.error("Audio play interrupted:", error);
          });
        }
       
      }
    };
    const handleAudioLoad3 = () => {
      if (audioref3.current) {
        if(counter>1){
          audioref3.current.play().catch((error) => {
            console.error("Audio play interrupted:", error);
          });
        }
       
      }
    };
    const handleAnimationLoad = () => {
      if (newref.current) {
        newref.current.play().catch((error) => {
          console.error("Animation play interrupted:", error);
        });
      }
    };

  

return(
  <div>
    {isMobile? (
 <div style={{display: "flex",background:"#C3DFFF",overflow:"hidden", position:'relative'}}>
 <div style={{ 
position: 'fixed', 
top: 0, 
left: 0, 
width: '100%', 
height: '20px', 
backgroundColor: '#000E75', 
padding: '3px', 
zIndex: 1000, 
display: 'flex', 
justifyContent: 'center', 
alignItems: 'center' 

}}> 
<Link to ='../mtrain2'>

<div style={{ color:"#FFFFFF",userSelect:'none' }}>
<h4>
MATH SWIPE
</h4>
</div>
</Link>
</div>

<div>
<Player>
<audio ref={audioref} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
</Player>
</div>
<div>
<Player>
<audio ref={audioref3} src={wave} type="audio/mp3" loop={true} onLoadedData={handleAudioLoad3}/>

</Player>
</div>
<div ref={windowref} style={{ position:"fixed",  marginRight: '10px',height:"100vh",width:"100vw",backgroundColor:"#E7E5D4" }}>

<div >

<Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
  <Row className="justify-content-center flex-wrap">
    <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
      <Card style={{ width: 'auto' }}>
        <Card.Body className="text-center">
          <div> 

          <Player
            ref={newref}
            autoplay={false}
            loop={false}
            keepLastFrame={true}
            hover={false}
            src={playing}
            aspectRatio="3:4"
            style={{ width: '350px', height: '250px' }}
          />
                    </div>
          
        </Card.Body>
      </Card>
    </Col>
    <Col>
    <Card style={{ width: 'auto' }}>
    <Card.Body className="text-center">

    {scdiv && (
  
  <div >
  <Unit3m2 style={{zindex:10,}} value1={5} value2={175000} />
  
  </div>
  )}   
    </Card.Body>

    </Card>
    </Col>

    </Row>
    </Container>


</div>




</div>



{scdiv2 && (
 <div style={{zindex:10, position:'fixed',left:'1200px',bottom:'90px'}}>
 <Unit1 style={{zindex:10}} value1={70000} value2={175000} />

</div>
)}
<Container fluid className="fixed-bottom bg-light p-3">

<Row >

  <Col xs={12} md={4} lg={5}  >
    <Card style={{ width: 'auto' }}>
      <Card.Footer className="d-flex justify-content-between">
           
            <CircularButtonback onClick={goback}>


            </CircularButtonback>
            <div onClick={repeatanimation} style={{ cursor: 'pointer' }}>
                <Player
                  ref={rptref}
                  autoplay={false}
                  loop={false}
                  keepLastFrame={true}
                  hover={true}
                  src={rpt}
                  style={{ width: '100px', height: '70px' }}
                />
              </div>            <CircularButton className='ml-auto' onClick={next}>
              
              </CircularButton>
      </Card.Footer>
    </Card>
  </Col>



  
</Row>
</Container>


</div>
    ): (

      <div style={{display: "flex",background:"#C3DFFF",overflow:"hidden", position:'relative'}}>
      <div style={{ 
 position: 'fixed', 
 top: 0, 
 left: 0, 
 width: '100%', 
 height: '20px', 
 backgroundColor: '#000E75', 
 padding: '3px', 
 zIndex: 1000, 
 display: 'flex', 
 justifyContent: 'center', 
 alignItems: 'center' 

 }}> 
 <Link to ='../scenario'>

 <div style={{ color:"#FFFFFF",userSelect:'none' }}>
 <h4>
MATH SWIPE
 </h4>
 </div>
 </Link>
 </div>

<div>
 <Player>
 <audio ref={audioref} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
 </Player>
</div>
<div>
 <Player>
 <audio ref={audioref3} src={wave} type="audio/mp3" loop={true} onLoadedData={handleAudioLoad3}/>

 </Player>
</div>
 <div ref={windowref} style={{ position:"fixed",  marginRight: '10px',height:"100vh",width:"100vw",backgroundColor:"#E7E5D4" }}>

<div style={{position:'fixed',width:'700px',height:'700px',left:'500px',bottom:'10px'}}>
<Player ref={newref} autoplay={false} loop={false} keepLastFrame={true}   src={playing} />

</div>




   </div>

 
     {scdiv && (
<div style={{zindex:10, position:'fixed',left:'1200px',bottom:'90px'}}>
<Unit32 style={{zindex:10}} value1={5} value2={175000} />

</div>
  )}
   {scdiv2 && (
      <div style={{zindex:10, position:'fixed',left:'1200px',bottom:'90px'}}>
      <Unit1 style={{zindex:10}} value1={70000} value2={175000} />
     
     </div>
   )}
<div style={{ position:'fixed',zindex:7,left:'900px',bottom:'10px' , pointerEvents: isDisabled ? 'none' : 'auto', cursor: isDisabled ? 'default' : 'pointer' }}onClick={next} className="nextb2">
<Player ref={nextbuttonref} autoplay={true} loop={true} keepLastFrame={true} hover={true}  src={buttonmain} />

</div>
<div onClick={goback} style={{position:'fixed',left:'400px',zIndex:7}} className="nextb3">
<Player ref={backbuttonref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={backbutton} />

</div>
<div onClick={repeatanimation} style={{position:'fixed',left:'740px',top:'530px', scale:'0.5'}} >
<Player ref={rptref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={rpt} />

</div>


 </div>
    )}

  </div>
   
);

};

export default MathLearning3;