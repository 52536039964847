import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Row, Col } from 'react-bootstrap';
import linear from '../jsons/linearmotion.json';
import { Player } from "@lottiefiles/react-lottie-player";
import msswipe from '../jsons/mssswipe1.json';
import nextbtn from '../jsons/nextbtn.json';
import CircularButton from "../bootsrap elements/CircularButton";
import CircularButtonback from "../bootsrap elements/CircularButtonback";
import babs1 from '../jsons/babs1.json';
import babs2 from '../jsons/babs2.json';
import babs3 from '../jsons/babs3.json'; // Import babs3
import babs4 from '../jsons/babs4.json'; // Import babs4
import babs5 from '../jsons/babs5linear.json';
import babs5sound from '../jsons/babs5.mp3';
import babs1sound from '../jsons/babs1sound.mp3';
import linearball from '../jsons/linearmotionball.json';
import non_linear from '../jsons/launchballup.json';
import hello from '../sounds/hello.mp3';
import LoadingPage from '../LoadingPages/LoadingPage';
import PhysicsHeader from "../PhysicsHeader";
import Timer from "../Timer"; // Import the Timer component
import ProgressBar from '../LoadingPages/ProgressBar';
import wronganswer from '../jsons/babswrong.json';
import scisound from '../jsons/scisteps sound.mp3'; // Import scisound
import quesound from '../jsons/havequestion.mp3'; // Import scisound
import runsound from '../jsons/runshop.mp3'; // Import scisound
import timersound from '../jsons/timer.mp3'; // Import scisound
import whichlinear from '../jsons/whichlinear.mp3'; // Import scisound
import cog1sound from '../jsons/COG2.mp3'; // Import scisound
import cog2sound from '../jsons/cog2.2.mp3'; // Import scisound
import lottie from 'lottie-web'; // Import lottie-web
import bb from '../jsons/bb.json';
import babscorrectsound from '../jsons/babscorrectsound.mp3';
import wronganswersound from '../jsons/wronganswer.mp3';
import lineardef from '../jsons/LN.mp3';
import lineardefinition from '../jsons/lineardefinition.json';
import cog1 from '../jsons/cog1.json';
import cog2balance from '../jsons/cog2balance.json';
import beambalance from '../jsons/x10.json';
import AudioPlayer from 'react-audio-player';
import { gsap } from "gsap";
import Sim2 from "../Experiments/Sim2";
import Sim3 from "../Experiments/Sim3";
import Sim4 from "../Experiments/Sim4";
import Sim5 from "../Experiments/Sim5";
import Sim6 from "../Experiments/Sim6";
import Sim7 from "../Experiments/Sim7";
import { getFirestore, doc, getDoc,updateDoc,onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { updatemDp, updatemEf } from '../authp'; // Import the updateScore function
import { updatemDf } from '../authp'; // Import the updateScore function

const Physicsp1 = () => {
  
  const questionref = useRef(null);

    const animationref = useRef(null);
    const option1ref = useRef(null);
    const option2ref = useRef(null);
    const [options, setoptions] = useState(false);
    const [istutorial, setistutorial] = useState(1);
    const [tutorial, settutorial] = useState(true);
    const [tutorialnum, settutorialnum] = useState(1);

    const [issim, setissim] = useState(false);
    const [simnum, setsimnum] = useState(1);
    const [counter, setCounter] = useState(1); // Add counter state
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isLoading, setIsLoading] = useState(true);
    const [showTimer, setShowTimer] = useState(false); // Manage the visibility of the Timer component
    const [subtopicno, setsubtopicno] = useState("01 ");
    const [progressvalue, setprogressvalue] = useState(0);
    const [scorevalue, setscorevalue] = useState(0);
    const [mainaudio, setmainaudio] = useState(scisound);
    const [charsound, setcharsound] = useState(cog1sound);
    const [charanimation, setcharanimation] = useState(cog1);
    const [questions, setquestions] = useState(1);
    const [question, setquestion] = useState("(a) (i) What is meant by center of Gravity ?");
    const [sliderValue, setSliderValue] = useState(0); // Slider control state
    const [currentFrame, setCurrentFrame] = useState(0);
    const lottieInstance = useRef(null);
    const [navigation, setnavigation] = useState(true);
    const [dataFromChild, setDataFromChild] = useState('');

    const [answerset, setanswerset] = useState(true);
    const [topic, settopic] = useState("Mechanics");
    const [subtopic, setsubtopic] = useState("Forces and Moments");
    const [activity, setactivity] = useState('Centre of Gravity');
    const [yPosition, setYPosition] = useState(500);
    const [animationInstance, setAnimationInstance] = useState(null);
    const [Status, setStatus] = useState(1);

    const audioref = useRef(null);
    const audioref2 = useRef(null); // New audio reference

    useEffect(() => {
      if (Status === 1) {
          setStatus("Attempt question to continue");
      } else if (Status === 2) {
          setStatus("That's correct");
          updatemDp();
          setTimeout(() => {
          setnavigation(true); // This will trigger after 1.5 seconds
          }, 1500);
      } else if (Status === 3) {
          setStatus("Sorry, but that's wrong");
          updatemDf();
      }
  }, [Status]); // Ensure `Status` is added as a dependency if it changes over time
  
    
    
    useEffect(() => {
      // Initialize Lottie animation
      lottieInstance.current  = lottie.loadAnimation({
        container: animationref.current, // container where the animation will render
        animationData: beambalance, // Your animation JSON data
        renderer: 'svg',
        loop: true,
        autoplay: true,
      });
  
  
      // Cleanup when component unmounts
      return () => lottieInstance.current.destroy();
    }, []);
    

    useEffect(() => {
      reRenderAnimation();
    }, []);

    const handleDataFromChild = (data) => {
      setDataFromChild(data);
      if(data){
          setscorevalue(scorevalue+1);
          updatemDp(); // Call the updatemEp function to increment mDp in the database

      }
     
    };

    const updateAnimationData = (sliderValue) => {
      const updatedData = JSON.parse(JSON.stringify(beambalance)); // deep clone
      updatedData.layers[1].ks.p.k = [
        136.837 + (sliderValue / 100) * (984.837 - 136.837), // calculate new x value
        607.45,
        0,
      ];

      if(sliderValue>50.9){
        updatedData.layers[4].ks.p.k[1] = 550 
        updatedData.layers[3].ks.p.k[1] = 550 + (sliderValue / 100) * (500 - 300);
        if(issim){
          setquestion("Not balanced");
          gsap.to(questionref.current, { color: 'red', duration:0.3,  });

        }


      }
else if(sliderValue>-1 && sliderValue<49.9){
  updatedData.layers[3].ks.p.k[1] = 550 ;
  updatedData.layers[4].ks.p.k[1] = 750 - (sliderValue / 100) * (500 - 200);
  if(issim===true){
    setquestion("Not balanced");
    gsap.to(questionref.current, { color: 'red', duration:0.3,  });
  }


}
   else if(sliderValue>49.9 && sliderValue<50.9){
    updatedData.layers[3].ks.p.k[1] = 550 ;
    updatedData.layers[4].ks.p.k[1] = 550 ;
    if(issim===true){
      setscorevalue(scorevalue+1);
      updatemDp();

    setquestion("Balanced, the center of gravity is at this point!");
    setStatus(2);
    setTimeout(() => {
      setnavigation(true);
     }, 1000); 
    gsap.to(questionref.current, { color: 'green', duration:0.3,  });
    setStatus(2);
    setTimeout(() => {
      if(sliderValue===50){
        if(scorevalue<5){
    
        }  
      }
    }, 2000);
   

    }

   
   }


     
      
      // Update point B
      return updatedData;
    };
    
    const intervalId = setInterval(() => {
      if (lottieInstance.current) {
        const frame = lottieInstance.current.currentFrame;
        const duration = lottieInstance.current.getDuration(true);
        const time = (frame / lottieInstance.current.totalFrames) * duration;
        setCurrentFrame(frame);
      }
    }, 1000); // Update every 100ms

    const reRenderAnimation = () => {
      const updatedData = updateAnimationData(sliderValue);
      if (lottieInstance.current) {
        lottieInstance.current.destroy();
      }
            lottieInstance.current = lottie.loadAnimation({
        container: animationref.current,
        animationData: updatedData,
        renderer: 'svg',
        loop: true,
        autoplay: true,
      });
     
    

    };
    
    const updatemEpInDatabase = async (newmEp) => {
      try {
          const response = await fetch('/api/update-mEp', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ mEp: newmEp }),
          });

          if (!response.ok) {
              throw new Error('Failed to update mEp in the database');
          }

          const data = await response.json();
          console.log('mEp updated successfully:', data);
      } catch (error) {
          console.error('Error updating mEp in the database:', error);
      }
  };
   
    
    useEffect(() => {
        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 3600);
    
        return () => clearTimeout(timer);
      }, []);

      const handleShowTimer = () => {
        setShowTimer(true); // Display the timer when user clicks Timer in the dropdown
      };
    

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    const playanimation = () => {
      setTimeout(() => {
        if (animationref.current) {
          animationref.current.play(); // Use the stored instance to play the animation
        }
       
        if (audioref.current) {
          audioref.current.play(); // Play another audio
        }
        if (audioref2.current) {
          audioref2.current.play(); // Play another audio
        }
      }, 200);
    };
  
    const updatePoints = (sliderValue) => {
      const updatedData = JSON.parse(JSON.stringify(beambalance)); // deep clone
    
    
      // Update point A
      updatedData.layers[3].ks.p.k[1] = 500 - (sliderValue / 100) * (500 - 300);
      
      // Update point B
      updatedData.layers[4].ks.p.k[1] = 500 + (sliderValue / 100) * (500 - 300);
    
    
      return updatedData;
    };
    
    const backclicked = () => {
      setprogressvalue((prevProgress) => Math.max(prevProgress - 10, 0)); // Decrease progress, ensure it doesn't go below 0
    
      setCounter((prevCounter) => {
        const newCounter = Math.max(prevCounter - 1, 0); // Ensure the counter doesn't go below 0
    
        if (newCounter === 8) {
          setsimnum(6);
          setquestion("which one is more stable, A or B ?");
        } 
        else if (newCounter === 7) {
          setsimnum(5);
          setquestion("Select the center of gravity");
        } 
        else if (newCounter === 6) {
          setsimnum(4);
          setquestion("Select the center of gravity");
        } 
        else if (newCounter === 5) {
          setsimnum(3);
          setquestion("which one is more stable?");
        } 
        else if (newCounter === 4) {
          setsimnum(2);
          setquestion("select the center of gravity");
        } 
        else if (newCounter === 3) {
          setquestion("move slider below to balance the beam");
          setissim(false);
          setistutorial(true);
        } 
        else if (newCounter === 2) {
          setcharanimation(cog2balance); // reset animation to previous one if applicable
          setcharsound(cog2sound);
          playanimation();
        } 
        else if (newCounter === 1) {
          playanimation(); // Plays animation as in initial setup
        }
    
        return newCounter;
      });
    };
    
    
    const nextclicked = () => {
      setprogressvalue((prevProgress) => prevProgress + 10);
     
      setCounter((prevCounter) => {

        if (prevCounter === 1) {
            playanimation();

        } else if (prevCounter === 2) {
          setcharanimation(cog2balance);
          setcharsound(cog2sound);
          playanimation();

        }
        else if (prevCounter === 3) {

          setquestion('move slider below to balance the beam')
          setissim(true);
          setistutorial(false);

        }
        else if (prevCounter === 4){
          setsimnum(2);
          setquestion('select the center of gravity ');
          
        }
        else if (prevCounter === 5){
          setsimnum(3);
          setquestion("which one is more stable, ?")
        }
        else if (prevCounter === 6){
          setsimnum(4);
          setquestion("Select the center of gravity")
        }
        else if (prevCounter === 7){
          setsimnum(5);
          setquestion("Select the center of gravity")
        }
        else if (prevCounter === 8){
          setsimnum(6);
          setquestion("which one is more stable, A or B ?")
        }
        else if (prevCounter === 9){
          setsimnum(7);
          setquestion("How Can you make a bus more stable")
        }
        return prevCounter + 1; // Increment counter safely
    });

  };
    const squareStyle = {
        width: '90px',
        height: '65px',
        backgroundColor: 'skyblue',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft:'20px',
     
      
      };
      const squareStyle2 = {
        width: '50%',
        height: '65px',
        backgroundColor: 'skyblue',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft:'20px',

      
      };
      const scoreStyle = {
        fontSize: '15px',
        fontWeight: 'bolder',
        color: 'black',
      };
      const scoreStyle2 = {
        fontSize: '15px',
        fontWeight: 'bolder',
        color: 'black',
      };
      const handleAudioLoad = () => {
        if (audioref.current) {
          if(counter>1){
            audioref.current.play().catch((error) => {
              console.error("Audio play interrupted:", error);
            });
          }
         
        }
      };
      const handleAudioLoad3 = () => {
        if (audioref2.current) {
          if(counter>1){
            handleAudioLoad3.current.play().catch((error) => {
              console.error("Audio play interrupted:", error);
            });
          }
         
        }
      };


      
    return (
        <>
        {isMobile? (
             <>

             {isLoading? (
              <LoadingPage />
      
             ):(
              <div style={{position:'absolute',userSelect:'none'}}>
                 <div>
                 <PhysicsHeader onTimerClick={handleShowTimer} /> {/* Pass the function to PhysicsHeader */}
                 </div>
                 <Container className="d-flex justify-content-center align-items-center">
              <Row className="justify-content-center flex-wrap">
              {showTimer && (
  <div style={{
    position: 'absolute',
    top: '250px',
    left: '50%',
    zIndex: 10, 
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: Add background for better visibility
    padding: '20px',
    borderRadius: '10px'
  }}>
    <Timer />
  </div>
)}

              </Row>
            </Container>
              <Container className="d-flex justify-content-center align-items-center">
                  <Row className="justify-content-center flex-wrap">
                      {nextbtn && !showTimer && (
                          <Col xs={12} md={4} lg={5}>
                              <Card style={{ width: '400px', height: '705px', textAlign: 'center',    position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)', }}>
                              <Card.Header style={{height:'230px',backgroundColor:'skyblue'}}>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
      <div style={{ display: 'flex',flexDirection:'row', fontFamily:'helvetica',fontWeight:'bold' }}>
       <div style={{fontSize:'10px', fontWeight:'bolder',marginTop:'15px',color:'red',marginLeft:'10px',marginRight:'10px'}}>
        {subtopicno}
       </div>
       <div style={{fontSize:'13px',marginTop:'15px'}}>
        {activity}
       </div>
       
       <div style={{display:'flex',flexdirection:'column',fontSize:'15px'}}>
       <div style={{width: '1px', height: '40px', marginTop:'14px',backgroundColor: 'black', margin: '0 15px'}}></div>
      
      <div style={{ width: '150px',marginTop:'8px',marginLeft:'5px' }}> 
      <ProgressBar progress={progressvalue} label="Completed"/>
      
      </div>
      
      <Player>
                    <audio  ref={audioref} src={charsound} type="audio/mp3" onLoadedData={handleAudioLoad} />
                  </Player>
      
                  <Player>
                      <audio ref={audioref2} src={mainaudio}  type="audio/mp3"onLoadedData={handleAudioLoad3} />
                  </Player>
      
       </div>
       
        {/* New Row with Text Options */}
      
      
        {/* Original Row with Equations */}
        
      
      
      </div>
      
      <div style={{alignItems:'center'}}>
         <div style={squareStyle2}>
         <div style={scoreStyle2}>Score</div>
         <div style={scoreStyle2}>{scorevalue}</div>
       </div>
       </div>
      </div>
      
      </Card.Header>
      
                                  <Card.Body style={{ border: '1px solid #9B9B9B', boxShadow: 'none'  }} >
                                     
                                      <div style={{height:'100%', display:'flex',justifyContent:'center',alignItems:'center',flexdirection:'column'}}>
                                     {options && (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexdirection:'column'}}>
          <div style={{ flex: 1, borderRight: '2px solid #000', paddingRight: '10px' }}>
        <Player ref={option1ref} autoplay={true} loop={true}  src={linearball} style={{ width: '150px',zIndex:2,cursor:'crosshair', height: '150px',position:'absolute',top:'150px' }} />
      
        </div>
        <div style={{width: '1px', height: '40px', position:'fixed',backgroundColor: 'black', margin: '0 15px',position:'absolute',top:'250px',right:'190px'}}></div>

        <div >
        <Player ref={option2ref} autoplay={true} loop={true} src={non_linear} style={{ width: '150px',zIndex:3,cursor:'crosshair', height: '150px',position:'absolute',top:'150px',right:'5px' }} />
      
        </div>
      </div>
                                     )}
                                      {questions && (
        <div ref={questionref} style={{position:'fixed',top:'250px'}}>
            <h5>
            {question}     
  
            </h5>
       
      
        </div>
                                     )}
      <div style={{display:'flex',flexDirection:'column'}}>
{issim && simnum==1&&(
                   <div
                   ref={animationref}
                   style={{ height: '300px', width: '300px' }}
                 />
)}
{issim && simnum===2 && (
           <Sim2 sendDataToParent={handleDataFromChild}/>
          )}
{issim && simnum===3 && (
  <Sim3 sendDataToParent={handleDataFromChild}/>
)}
{issim && simnum===4 && (
           <Sim4 sendDataToParent={handleDataFromChild}/>
          )}
       {issim && simnum===5 && (
           <Sim5 sendDataToParent={handleDataFromChild}/>
          )} 
          {issim && simnum===6 && (
           <Sim6 sendDataToParent={handleDataFromChild}/>
          )}
     {issim && simnum===7 && (
           <Sim7 sendDataToParent={handleDataFromChild}/>
          )}
{istutorial && !issim && (
   <div>
   <Player ref={animationref} autoplay={false} loop={false} keepLastFrame={true} src={charanimation} style={{ width: '400px', height: '400px' }} />
  
   </div>
)}
                   
{issim && simnum===1 &&(
 <div style={{height:'10px',zIndex:5,width:'100px'}}>
 <input
type="range"
min="-100"
max="300"
value={sliderValue}
onChange={(e) => {
setSliderValue(e.target.value);
reRenderAnimation();
}}
/>

</div>
)}
                                       

                                      </div>                         
                                     
                                      </div>
      
                                  </Card.Body>
                                  <Card.Footer style={{ border: '1px solid #9B9B9B' }} className="d-flex justify-content-between">
                                  <div onClick={backclicked}>
      
                                      <CircularButtonback />
                                      </div>
      
                                      <div onClick={nextclicked}>
                                          <CircularButton />
                                      </div>
                                  </Card.Footer>
                              </Card>
                          </Col>
                      )}
                  </Row>
              </Container>
          </div>
             )}
                     
                     </>
        ):(
             <>

             {isLoading? (
              <LoadingPage />
      
             ):(
              <div>
                 <div style={{userSelect:'none'}}>
                 <PhysicsHeader onTimerClick={handleShowTimer} /> {/* Pass the function to PhysicsHeader */}
                 </div>
                 <Container className="d-flex justify-content-center align-items-center">
              <Row className="justify-content-center flex-wrap">
                {showTimer && (
                  <Col xs={12} md={4} lg={5}>
                    <Timer /> {/* Show the Timer component */}
                  </Col>
                )}
              </Row>
            </Container>
              <Container className="d-flex justify-content-center align-items-center">
                  <Row className="justify-content-center flex-wrap">
                      {nextbtn &&   (
                          <Col xs={12} md={4} lg={5}>
                              <Card style={{ width: '500px', height: '605px', textAlign: 'center',    position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)', }}>
                              <Card.Header style={{height:'130px',backgroundColor:'skyblue'}}>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%',userSelect:'none'}}>
      <div style={{ display: 'flex',flexDirection:'row', fontFamily:'helvetica',fontWeight:'bold' }}>
       <div style={{fontSize:'15px', fontWeight:'bold',marginTop:'15px',color:'blue',marginLeft:'10px',marginRight:'10px'}}>
        {subtopicno}
       </div>
       <div style={{fontSize:'13px',marginTop:'15px'}}>
        {activity}
       </div>
       
       <div style={{display:'flex',flexdirection:'column',fontSize:'15px'}}>
       <div style={{width: '1px', height: '40px', marginTop:'14px',backgroundColor: 'black', margin: '0 15px'}}></div>
      
      <div style={{ width: '200px',marginTop:'8px',marginLeft:'5px' }}> 
      <ProgressBar progress={progressvalue} label="Completed"/>
      
      </div>
      
      <Player>
                    <audio  ref={audioref} src={charsound} type="audio/mp3" onLoadedData={handleAudioLoad} />
                  </Player>
      
                  <Player>
                      <audio ref={audioref2} src={scisound} type="audio/mp3"onLoadedData={handleAudioLoad3} />
                  </Player>
      
       </div>
       
        {/* New Row with Text Options */}
      
      
        {/* Original Row with Equations */}
        
      
      
      </div>
      
      <div style={{alignItems:'center'}}>
         <div style={squareStyle}>
         <div style={scoreStyle}>Score</div>
         <div style={scoreStyle}>{scorevalue}</div>
       </div>
       </div>
      </div>
      
      </Card.Header>
      
                                  <Card.Body style={{ border: '1px solid #9B9B9B', boxShadow: 'none',userSelect:'none'  }} >
                                     
                                      <div style={{height:'100%', display:'flex',justifyContent:'center',alignItems:'center',flexdirection:'column'}}>
                                     {options && (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexdirection:'column'}}>
          <div   style={{ flex: 1, borderRight: '2px solid #000', paddingRight: '10px' }} >
          <Player ref={option1ref} autoplay={true} loop={true}  src={linearball} style={{ width: '150px',zIndex:2,cursor:'crosshair', height: '150px',position:'absolute',top:'50px' }} />
     
        </div>
        <div style={{width: '1px', height: '40px', position:'fixed',backgroundColor: 'black', margin: '0 15px',position:'absolute',top:'160px',right:'205px'}}></div>

        <div >
        <Player ref={option2ref} autoplay={true} loop={true} src={non_linear} style={{ width: '150px',zIndex:3,cursor:'crosshair', height: '150px',position:'absolute',top:'50px',right:'5px' }} />
      
        </div>
      </div>
                                     )}
                                     {questions && (
        <div ref={questionref} style={{position:'fixed',top:'130px'}}>
            <h5>
            {question}     
  
            </h5>
       
      
        </div>
                                     )}
                                      <div style={{display:'flex',flexDirection:'column'}}>
{issim && simnum===1 && (
                   <div
                   ref={animationref}
                   style={{ height: '300px', width: '300px' }}
                 />
)}
{issim && simnum===2 && (
           <Sim2 sendDataToParent={handleDataFromChild}/>
          )}
{issim && simnum===3 && (
  <Sim3 sendDataToParent={handleDataFromChild}/>
)}
{issim && simnum===4 && (
           <Sim4 sendDataToParent={handleDataFromChild}/>
          )}
       {issim && simnum===5 && (
           <Sim5 sendDataToParent={handleDataFromChild}/>
          )}   
                  {issim && simnum===6 && (
  <Sim6 sendDataToParent={handleDataFromChild}/>
)}
                {issim && simnum===7 && (
  <Sim7 sendDataToParent={handleDataFromChild}/>
)}
{istutorial && !issim && (
   <div>
   <Player ref={animationref} autoplay={false} loop={false} keepLastFrame={true} src={charanimation} style={{ width: '400px', height: '400px' }} />
  
   </div>
)}
                   
{issim && simnum===1 && (
 <div style={{height:'10px',zIndex:5}}>
 <input
type="range"
min="0"
max="100"
value={sliderValue}
onChange={(e) => {
setSliderValue(e.target.value);
reRenderAnimation();
}}
/>

</div>
)}
                                       

                                      </div>
                                     
                                      </div>
      
                                  </Card.Body>
                                  <Card.Footer style={{ border: '1px solid #9B9B9B' }} className="d-flex justify-content-between">
                                
{navigation && (
  <div  onClick={backclicked} >

  <CircularButtonback />
  </div>
)}
{issim && (
  <div>
   {Status}
  </div>
)}

   
  
{navigation && (
  <div onClick={nextclicked}>
          <CircularButton />
      </div>
)}
      
                                
                                                      

                                  </Card.Footer>
                              </Card>
                          </Col>
                      )}
                  </Row>
              </Container>
          </div>
             )}
                     
                     </>
        )}
       
  </>
    );
};

export default Physicsp1;
