import React, { useRef,useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './jsons/images/scistepslogo.png';
import { Card, Container, Row, Col } from 'react-bootstrap';
import Cprac1 from './Chemix/Cprac1';
import Sim2 from './Experiments/Sim6';
import { Player } from "@lottiefiles/react-lottie-player";
import { Bar } from 'react-chartjs-2';
import cog1 from './jsons/cog1.json';
import ess1 from './jsons/images/Ess.png';
import ms1 from './jsons/images/ms1.jpg';
import nonlineardefinition from './jsons/nonlineardefinition.json';
import missionImage from './jsons/svgs/science.svg';
import emoticon from './jsons/svgs/emoticon.svg';
import access from './jsons/svgs/access.svg';
import Slider from "react-slick";
import ohm from './jsons/images/ohmslaw.png';
import ess2 from './jsons/images/ess2.png';
import inter from './jsons/images/interview01.jpg';
import mtn1 from './jsons/images/mtn1.jpg';
import square from './jsons/images/square.jpg';
import showcase from './jsons/images/showcase.jpg';
import './about.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const About = () => {
  const animationref = useRef(null);
  const images = [ess1,ohm,ess2,]; // Add more images or videos as needed
const exhibit = [inter,ms1,mtn1,showcase]; // Add more images or videos as needed

  const [learningcomponents, setlearningcomponents] = useState(nonlineardefinition);

  const data = {
    labels: ['Math', 'Physics', 'Chemistry'], // Subjects
    datasets: [
      {
        label: 'Performance (%)',
        data: [75, 85, 90], // Sample scores for the subjects
        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Color of the bars
        borderColor: 'rgba(54, 162, 235, 1)', // Border color of bars
        borderWidth: 1,
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Your Performance',
      },
    },
  };

  const colorPalette = {
    primary: '#84250C',
    secondary: '#3C393E',
    light: '#F5F4EB'
  };

  return (
    <div className="about-page">
      {/* Hero Section */}
      <div className="hero text-white text-center py-5" style={{ backgroundColor: colorPalette.primary }}>
        <h1>Welcome to Scistep</h1>
        <p className="lead">
          Revolutionizing science education with interactive simulations and experiments.
        </p>
      </div>

      {/* About Section */}
      <div className="container my-5" style={{ backgroundColor: colorPalette.light }}>
        <div className="row align-items-center">
          <div className="col-md-6">
            <img src={logo} alt="What is Scistep" className="img-fluid rounded" />
          </div>
          <div className="col-md-6">
            <h2>What is Sci-step?</h2>
            <p>
              Scistep is an innovative platform designed to help students learn and practice
              science through interactive experiments and simulations.
            </p>
            <p>
              This project was founded by Sam Nkurunungi
            </p>
          </div>
        </div>
      </div>

  {/* Mission Section */}
  <Container fluid className="my-5" style={{ backgroundColor: "#3C393E" }}>
        <h2 className="text-center mb-4" style={{ color: "#F5F4EB" }}>
          Our Mission
        </h2>
        <Row className="justify-content-center">
          <Col md={6} className="d-flex align-items-center">
            <Card >
              <Row>
                <Col md={6}>
                  <img
                    src={emoticon}
                    alt="Mission"
                    className="img-fluid rounded"
                    style={{ width: "300px", height: "300px" }}
                  />
                </Col>
                <Col md={6} className="d-flex flex-column justify-content-center">
                  <Card.Body>
                    <Card.Text style={{ fontSize: "1.1rem" }}>
                      To make math and science **more fun** while fostering a **deep love** for learning and exploration in science subjects.
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <img
                    src={access}
                    alt="Mission"
                    className="img-fluid rounded"
                    style={{ width: "300px", height: "300px" }}
                  />
                </Col>
                <Col md={6} className="d-flex flex-column justify-content-center">
                  <Card.Body>
                    <Card.Text style={{ fontSize: "1.1rem" }}>
                    To make math and science education more accessible.
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
           
            
          </Col>
        </Row>
      </Container>

  {/* Goals Section */}
  <Container fluid className="my-5" style={{ backgroundColor: "#84250C" }}>
        <h2 className="text-center mb-4 text-white">Our Goals</h2>
        <Row className="g-4 justify-content-center">
          {/* Goal 1 */}
          <Col md={5}>
            <Card className="h-100 shadow-sm border-0">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src={missionImage}
                    alt="Inspire Innovators"
                    className="img-fluid rounded"
                    style={{ width: "300px", height: "300px" }}
                  />
                </Col>
                <Col md={6} className="p-3">
                  <h5>Inspire the Next Generation of Inventors</h5>
                  <p>
                    Empower students to think critically, innovate, and build solutions to real-world problems by cultivating curiosity in science and math.
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* Goal 2 */}
          <Col md={5}>
            <Card className="h-100 shadow-sm border-0">
              <Row className="align-items-center">
                <Col md={6}>
                  <img
                    src={missionImage}
                    alt="Digitize Science"
                    className="img-fluid rounded"
                    style={{ width: "300px", height: "300px" }}
                  />
                </Col>
                <Col md={6} className="p-3">
                  <h5>Digitize Science Education</h5>
                  <p>
                    Transform traditional science subjects into **engaging, digital learning experiences**, making them more accessible and interactive.
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* Usage Section */}
      <div className="py-5" style={{ backgroundColor: colorPalette.secondary }}>
        <Container>
          <h2 className="text-center mb-4" style={{ color: colorPalette.light }}>How Sci-step is Used</h2>
          <Row className="g-4">
            {/* Learning Modules */}
            <Col md={4}>
              <Card>
                <Card.Header style={{backgroundColor:colorPalette.primary,color:'white'}} >
                  <h5>Learning Components</h5>
                </Card.Header>
                <Card.Body>
                  <Player ref={animationref} autoplay={true} loop={true}  src={learningcomponents} style={{ width: '400px', height: '300px' }} />
                </Card.Body>
                <Card.Footer className="text-muted">
                  Explore topics with detailed explanations and interactive content.
                  <button 
    className="btn btn-primary" 
    onClick={() => setlearningcomponents(cog1)}
  >
    Next
  </button>
                </Card.Footer>
              </Card>
            </Col>

            {/* Experiment Modules */}
            <Col md={4}>
              <Card>
              <Card.Header style={{backgroundColor:colorPalette.primary,color:'white'}} >
              <h3>Virtual Experiments</h3>
                  <h6> Chemistry and Physics</h6>
                </Card.Header>
                <Card.Body className="d-flex flex-column justify-content-center align-items-center" style={{ height: '400px' }}>
                  <Cprac1 />
                </Card.Body>
                <Card.Footer className="text-muted text-center">
                  In this example, you can observe and test how different metals react when exposed to water.
                </Card.Footer>
              </Card>
            </Col>

            {/* Tests */}
            <Col md={4}>
              <Card>
              <Card.Header style={{backgroundColor:colorPalette.primary,color:'white'}} >
              <h4>Tests</h4>
              <h6> Example: select which is more stable, between A and B</h6>
                </Card.Header>
                <Card.Body>
                  <div style={{width:'100px', height:'300px'}}>
                  <Sim2 />

                  </div>
                </Card.Body>
                <Card.Footer className="text-muted">
                  Challenge yourself and improve through practice tests.
                </Card.Footer>
              </Card>
            </Col>

            {/* Track Progress */}
            <Col md={4}>
              <Card>
                <Card.Header className="bg-primary text-white text-center">
                  <h5>Track Progress</h5>
                </Card.Header>
                <Card.Body>
                  <p>
                    Monitor your performance with detailed analytics and personalized feedback.
                  </p>
                  {/* The Chart goes here */}
                  <Bar data={data} options={options} />
                </Card.Body>
                <Card.Footer className="text-muted">
                  Keep track of your journey with insights and data.
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>


   

      {/* Future Plans */}
      <div className="container my-5" style={{ backgroundColor: colorPalette.light }}>
        <h2 className="text-center mb-4">Future Plans</h2>
        <div className="row g-4">
          {/* Expand Subject Areas */}
          <div className="col-md-6">
            <Card className="h-100">
              <Card.Header className="bg-primary text-white text-center">
                <h5>Create More Lessons, Tests, and Games</h5>
              </Card.Header>
              <Card.Body>
                <p>
                  Develop interactive lessons, quizzes, and games for a wide range of topics and subtopics in the following subjects:
                </p>
                <ul>
                  <li>Physics</li>
                  <li>Mathematics</li>
                  <li>Chemistry</li>
                </ul>
              </Card.Body>
            </Card>
          </div>

          {/* Team Expansion */}
          <div className="col-md-6">
            <Card className="h-100">
              <Card.Header className="bg-primary text-white text-center">
                <h5>Expand the Team</h5>
              </Card.Header>
              <Card.Body>
                <p>
                  To accelerate content creation, we plan to hire additional animators, software developers,
                  teachers, and scriptwriters to ensure a comprehensive and engaging experience for users.
                </p>
              </Card.Body>
            </Card>
          </div>

          {/* Mobile App Development */}
          <div className="col-md-6">
            <Card className="h-100">
              <Card.Header className="bg-primary text-white text-center">
                <h5>Mobile App Development</h5>
              </Card.Header>
              <Card.Body>
                <p>
                  Launch a mobile application for students to access Scistep content anytime and anywhere, making learning more accessible.
                </p>
              </Card.Body>
            </Card>
          </div>

          {/* Global Outreach */}
          <div className="col-md-6">
            <Card className="h-100">
              <Card.Header className="bg-primary text-white text-center">
                <h5>Global Outreach</h5>
              </Card.Header>
              <Card.Body>
                <p>
                  Collaborate with schools and educational institutions worldwide to integrate Scistep into their curriculum and reach a broader audience.
                </p>
              </Card.Body>
            </Card>
          </div>

          {/* Call for Support */}
          <div className="col-md-12">
            <Card className="h-100">
              <Card.Header className="bg-primary text-white text-center">
                <h5>Call for Support</h5>
              </Card.Header>
              <Card.Body>
                <p>
                  To bring these plans to life, we invite voluntary team members to join our mission,
                  and we welcome contributions through donations and funding to scale our efforts
                  and make Scistep accessible to millions of learners globally.
                </p>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      {/* Journey/History Section */}
      <div className="container my-5">
  <h2 className="text-center mb-4">Journey & History</h2>
  
  {/* ESS - Electricity Study Simulator */}
  <div className="row align-items-center mb-4">
    <div className="col-md-6">
      <h5>Electricity Study Simulator (ESS)</h5>
      <p>
        Scisteps began as <strong>ESS (Electricity Study Simulator)</strong>, a final year project 
        at the university in 2022. The project aimed to simplify the study of electricity concepts 
        through interactive simulations, marking the foundation of what would become Scisteps.
      </p>
    </div>
    <div style={{ width: '500px', height: '400px', margin: '0 auto', overflow: 'hidden' }}>
  <Slider
    autoplay={true}
    autoplaySpeed={3000}
    infinite={true}
    slidesToShow={1}
    slidesToScroll={1}
    dots={true}
    className="slick-carousel-container"
  >
    {exhibit.map((image, index) => (
      <div key={index} style={{ width: '300px', height: '300px' }}>
        <img
          src={image}
          alt={`Slideshow ${index}`}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
    ))}
  </Slider>
</div>

  </div>

  {/* Mathswipe */}
  <div className="row align-items-center mb-4">
    <div className="col-md-6 order-md-2">
      <h5>Mathswipe</h5>
      <p>
        In 2023, <strong>Mathswipe</strong> was introduced as an interactive math learning 
        platform. It featured engaging lessons and practice tests for students. The project was 
        showcased at the <strong>UgDevSummit 2023</strong>, gaining recognition for its innovative 
        approach to education.
      </p>
    </div>
    <div style={{ width: '500px', height: '400px', margin: '0 auto',  }}>
  <Slider
    autoplay={true}
    autoplaySpeed={3000}
    infinite={true}
    slidesToShow={1}
    slidesToScroll={1}
    dots={true}
    className="slick-carousel-container"
  >
    {images.map((image, index) => (
      <div key={index} style={{ width: '300px', height: '300px' }}>
        <img
          src={image}
          alt={`Slideshow ${index}`}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
    ))}
  </Slider>
</div>

  </div>

  {/* Scisteps */}
  <div className="row align-items-center mb-4">
    <div className="col-md-6">
      <h5>Scisteps</h5>
      <p>
        Building on the success of ESS and Mathswipe, <strong>Scisteps</strong> was born as a 
        comprehensive science education platform. It integrates the best features of its 
        predecessors, providing interactive simulations, experiments, and engaging content 
        across various science subjects.
      </p>
    </div>
    <div className="col-md-6">
      <img 
        src={logo} 
        alt="Scisteps" 
        className="img-fluid rounded"
      />
    </div>
  </div>
</div>
    </div>
  );
};

export default About;
