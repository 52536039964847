import { Link } from 'react-router-dom';
import { gsap } from "gsap";
import instructor from './jsons/tile1.json';
import solver from './jsons/tile2.json';
import practicing from './jsons/tile3.json';
import React, { useEffect, useRef, useState } from "react";
import mathintro from './jsons/mathintro.json';
import { Player } from '@lottiefiles/react-lottie-player';
import trainingbtn from './jsons/training button.json';
import './index.css';
import welcome from './jsons/mathintro2.json';
import startbtn from './jsons/startbutton.json';
import { animateScroll as scroll } from 'react-scroll';
import nextbtn from './jsons/nextbtn.json';
import homebtn from './jsons/home.json';
import learnhow from './jsons/learnhowto.json';
import Lottie from 'react-lottie-player';
import showclicker from './jsons/doubleclick.json';
import showswipe from './jsons/swipeshow.json';
import clicker2 from './jsons/clicking.json';
import topicsbox from './jsons/aisolver.json';
import questionbox from './jsons/practice box.json';
import learnbox from './jsons/learnbox.json';
import portrait from './jsons/portraitbg.json';
import CardGrid from './Bootsrapelements/CardGrid';
import { Card } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import CircularButton from './bootsrap elements/CircularButton';
import './newstyles.css'; // Import the CSS file

const MathIntro = () => {
    const introref = useRef(null);
    const trainingref = useRef(null);
    const welcomeref = useRef(null);
    const practiceref = useRef(null);
    const solverref = useRef(null);
    const instructorref = useRef(null);
    
    const interactiveboxref = useRef(null);
    const optionsboxref = useRef(null);

    const homebtnref = useRef(null);

    const nextbtnref = useRef(null);
    const windowref = useRef(null);
    const learnhowref = useRef(null);
    const swiperef = useRef(null);
    const clickref = useRef(null);
    const clickref2 = useRef(null);
    const welcomeboxref = useRef(null);
    
    const startref = useRef(null);


    const [showmenu, setshowmenu] = useState(false); // State to track the visibility of the minus box
    const [showelcome, setshowelcome] = useState(true); // State to track the visibility of the minus box
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    
    const [btn, setbutton] = useState(false); // State to track the visibility of the minus box
    const [btn2, setbutton2] = useState(false); // State to track the visibility of the minus box

    useEffect(() => {
      if  (welcomeref.current){
        welcomeref.current.play();

      }
     
      setTimeout(() => {
     setbutton(true);
      }, 3000);
      
     
    });
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
  };
    const sendleft = () => {
      const isMobile = window.matchMedia("(max-width:1000px)").matches;
      const widthValue = isMobile ? "600vw" : "500vh";

      const scrollToPosition = isMobile ? 1000 : 2000;
     // gsap.to(windowref.current, { width: widthValue });
      gsap.to(welcomeboxref.current, { x: -600, duration:1,delay:0.5 });
      gsap.to(welcomeboxref.current, { opacity: 0, duration:0.5,delay:1 });
     
      setTimeout(() => {
        setshowmenu(true);
setshowelcome(false);
      }, 1000);

      setTimeout(() => {
        setbutton2(true);


      }, 2000);
      
        

    };
    const sendleft2 = () => {
     // gsap.to(windowref.current, { width: "600vh" ,overflow:'hidden'});

      gsap.to(interactiveboxref.current, { x: -600 , duration:1});
      gsap.to(interactiveboxref.current, { opacity: 0 , duration:1.2,delay:0.5});

      setTimeout(() => {
        setshowmenu(true);

      }, 1500);

      setTimeout(() => {
        setbutton2(false);
      }, 500);
    };


    const introposition = {
        position: 'fixed', // Make sure to include the 'position' property
        width: '1280px',
        height: '1280px',
        bottom: '-550px',
        left: '150px',
        
        zindex: 0
      };

      const trainingbtnposition = {
        position: 'relative', 
        width: '800px',
      
        height: '400px',
        bottom: '-100px',
        left: '380px',
        display: 'flex',
        zindex: 2
      };

      const postion2 = {
        position: 'relative', // Make sure to include the 'position' property
        width: '900px',
      
        height: '550px',
        bottom: '-100px',
        left: '400px',
        display: 'flex',
        zindex: 2
      };

      const boxes3position = {
        position: 'relative', 
        width: '1000px',
      
        height: '900px',
        bottom: '-150px',
       left: '280px',
        display: 'flex',
        margin:'10px',
        zindex: 2
      };


      const crossposition = {
        position: 'relative',
        width: '330px',
        height: '330px',
        bottom: '-25px',
        
        left: '240px',
        zIndex: 2
      };

      

      const nextbtnposition = {
        position: 'fixed', // 2nd next button
        width: '330px',
        height: '330px',
       // bottom: '1520px',
        top:'80px',
        left: '380px',
        zIndex: 2
      };

      const startbtnposition = {
        position: 'fixed', // Make sure to include the 'position' property
        width: '330px',
        height: '330px',
        bottom: '-205px',
        left: '200px',
        zIndex: 2
      };
      const learnposition = {
        position: 'relative', // Make sure to include the 'position' property
        width: '400px',
      
        height: '400px',
        bottom: '-20px',
        left: '245px',
        display: 'flex',
        zindex: 2
      };
      
      const showers = {
        position: 'absolute', // Make sure to include the 'position' property
        width: '800px',
      
        height: '800px',
        top:'250px',
        left: '0px',
        //display: 'flex',
       // zindex: 2
      };
    return(
      <div style={{userSelect:'none'}}>

{isMobile ? ( 
<div id="main" ref={windowref} style={{height:'100vh',width:'100vh',overflow:'hidden',position:'fixed' }}>
   
      <div style={{position:'fixed',top:'50px'}} >
            <Player  ref={introref} autoplay={true} loop={true} src={portrait} intermission={3}     />

            </div>


   
            
<div className='cont1' style={{display:'flex',position:'fixed'  }}>
{showelcome &&  (
  <div>
  <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
      <Row className="justify-content-center flex-wrap">
      <Col xs={10} md={4} lg={5} className="mb-4 d-flex justify-content-center">
      <Card style={{ width: 'auto',backgroundColor:' rgba(16, 143, 255, 0.4)', }}  >
      <Card.Body className="text-center">

  <div ref ={welcomeboxref}  id="welcome" >
              <Player  ref={welcomeref} autoplay={true} loop={false} keepLastFrame={true} src={welcome} speed={1.5}
              
              />
</div>
{btn && (

<div 
 onClick={sendleft}>
<Player  ref={nextbtnref} autoplay={true} loop={true} src={startbtn} style={{width:'220px',height:'200px',marginRight:'60px'}}   />

</div>
)

}
</Card.Body>

</Card>
</Col>




</Row>
</Container>





           </div>
)}




 {showmenu && (
  <div>

<Container className ="d-flex justify-content-center align-items-center"
style={{ minHeight: '100vh' }}
>
<Row className="justify-content-center  flex-wrap">
  {/* Card 1 */}
  <Col xs={10} md={4} lg={3}  className="mb-4 d-flex justify-content-center">
  <Link to="/title" style={{ textDecoration: 'none', width: '100%' }}>

  <Card style={{ width: 'auto',backgroundColor:' rgba(122, 151, 211, 0.8)'  }}>
  <Card.Body className="text-center">
        <Player
        ref={instructorref}
          autoplay={false}
          loop={false}
          keepLastFrame={true}
          hover={true}
          src={instructor}
          aspectRatio="16:9"
          style={{
            width:'150px',height:'150px'
           }}
        />
        <Card.Title>Learn Math / how to use app</Card.Title>
       
      </Card.Body>
    </Card>
    </Link>
  </Col>

 {/* Card 3 */}
 <Col xs={10} md={4} lg={3}  className="mb-4 d-flex justify-content-center">
 <Link to='mtrain' style={{ textDecoration: 'none', width: '100%', }}>

 <Card style={{ width: 'auto',backgroundColor:' rgba(122, 151, 211, 0.8)'  }}>
 <Card.Body className="text-center">
    <Player
     ref={practiceref}

      autoplay={false}
      loop={false}
      keepLastFrame={true}
      hover={true}
      src={practicing}
      style={{
        width:'150px',height:'150px'
       }}
    
    />
    <Card.Title>Test yourself with math games</Card.Title>
   
  </Card.Body>
</Card>
</Link>

</Col>

  {/* Card 2 */}
  <Col xs={10} md={4} lg={3} className="mb-4 d-flex justify-content-center">
<Link to='select' style={{ textDecoration: 'none', width: '100%' }}>
<Card style={{ width: 'auto',backgroundColor:' rgba(122, 151, 211, 0.8)'  }}>
<Card.Body className="text-center">
        <Player
                ref={solverref}

          autoplay={false}
          loop={false}
          keepLastFrame={true}
          hover={true}
          src={solver}
          style={{ 
           width:'150px',height:'150px'
          }}
        
        />
        <Card.Title>Enter an equation to solve it</Card.Title>
       
      </Card.Body>
    </Card>
    </Link>
  </Col>

 
</Row>
</Container>
</div>

//show ends after here
           )}
</div>
            
        </div>): (

<div id="main" ref={windowref} style={{height:'100vh',width:'200vh',overflow:'hidden',position:'absolute' }}>
{isMobile ? (
 <div className='introbox' >
       <Player  ref={introref} autoplay={true} loop={true} src={portrait} intermission={3}     />

       </div>

): (
<div className='introbox' >
       <Player  ref={introref} autoplay={true} loop={true} src={mathintro} intermission={3}     />

       </div>

)}
       
<div className='cont1' style={{display:'flex',  }}>

{showelcome &&  (

<div ref ={welcomeboxref} className='welcom' id="welcome" >
        <div className= 'welcomewords' >
        <Player  ref={welcomeref} autoplay={true} loop={false} keepLastFrame={true} src={welcome}
        
        />

        </div>




{btn && (

<div className='startbutton'
onClick={sendleft}style={{...startbtnposition}}>
   <Player  ref={startref} autoplay={true} loop={true} src={startbtn} />
   </div>

)

}


     </div>
)}





{showmenu && (
  <div style={{position:'fixed', left:'200px'}}>
 <Container
className="d-flex justify-content-center align-items-center"
style={{ minHeight: '100vh' }}
>
<Row className="justify-content-center  flex-wrap">
  {/* Card 1 */}
  <Col xs={12} md={4} lg={3}  className="mb-4 d-flex justify-content-center">
   
  <Link to="/title" style={{ textDecoration: 'none', width: '100%' }}>

  <Card style={{ width: 'auto',backgroundColor:' rgba(255, 227, 119, 0.8)'  }}>
  <Card.Body className="text-center">
        <Player
        ref={instructorref}
          autoplay={false}
          loop={false}
          keepLastFrame={true}
          hover={true}
          src={instructor}
          aspectRatio="16:9"
          style={{
            width:'150px',height:'150px'
           }}
        />
        <Card.Title>Learn Math / how to use app</Card.Title>
       
      </Card.Body>
    </Card>
    </Link>

  </Col>

 

  {/* Card 2 */}

  <Col xs={12} md={4} lg={3}  className="mb-4 d-flex justify-content-center">
<Link to='/mtrain' style={{ textDecoration: 'none', width: '100%' }}>
<Card style={{ width: 'auto',backgroundColor:' rgba(255, 227, 119, 0.8)'  }}>
      <Card.Body className="text-center">
        <Player
         ref={practiceref}

          autoplay={false}
          loop={false}
          keepLastFrame={true}
          hover={true}
          src={practicing}
          style={{
            width:'150px',height:'150px'
           }}
        
        />
        <Card.Title>Test yourself with math games</Card.Title>
       
      </Card.Body>
    </Card>
    </Link>

  </Col>

   {/* Card 3 */}
   <Col xs={12} md={4} lg={3} className="mb-4 d-flex justify-content-center">
<Link to='/select' style={{ textDecoration: 'none', width: '100%' }}>
<Card style={{ width: 'auto',backgroundColor:' rgba(255, 227, 119, 0.8)'  }}>
      <Card.Body className="text-center">
        <Player
                ref={solverref}

          autoplay={false}
          loop={false}
          keepLastFrame={true}
          hover={true}
          src={solver}
          style={{ 
           width:'150px',height:'150px'
          }}
        
        />
        <Card.Title>Enter an equation to solve it</Card.Title>
       
      </Card.Body>
    </Card>
    </Link>
  </Col>
</Row>
</Container>
  </div>
 

//show ends after here
      )}
</div>
       
   </div>
        )}



      </div>
       
    );
};

export default MathIntro;