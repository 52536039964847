import React, { useEffect, useRef, useState } from "react";
import sqrt from '../jsons/sqrt.json';
import { Player } from "@lottiefiles/react-lottie-player";
import gsap from "gsap";
import startbtn from '../jsons/startbutton.json';
import Fraction from 'fraction.js';
//import { math } from 'mathjs';


const QuadraticExample2 = ({a,b,c,start,sendDataToParent}) =>{
    const playref = useRef(null);
    const variableref = useRef(null);
    const variable2ref = useRef(null);
    const variable3ref = useRef(null);
    const constantref = useRef(null);
    const constant2ref = useRef(null);
    const bracket2ref = useRef(null);
    const minusref = useRef(null);
    const plusref = useRef(null);
    const startref = useRef(null);
    const mainref = useRef(null);
    const barref = useRef(null);
    const bottomref = useRef(null);
    
    const spanref2 = useRef(null);

   // const [a, setA] = useState(6);
   // const [b, setB] = useState(7);
   // const [c, setC] = useState(2);
    const [variable, setVariable] = useState('a');
    const [variable2, setVariable2] = useState('-b');
    const [variable21, setVariable21] = useState('b');
    const [operator, setOperator] = useState('+/-');
    
    const [bar1, setbar1] = useState('___________________');

    const [variable3, setVariable3] = useState('c');
    const [constant, setConstant] = useState(4);
    const [constant2, setConstant2] = useState(2);
    
    const [sqrm, setsqrm] = useState(true);

    const [otheroption, setotheroption] = useState(null);
    const [finalanswer, setfinalanswer] = useState(null);

    const [ac, setac] = useState(true);

    const [a2, seta2] = useState(true);
    const [brackets, setbrackets] = useState(true);
    const [brackets2, setbrackets2] = useState(true);
    const [bars, setBars] = useState(true);

    const [forsquareroot, setforsquareroot] = useState(true);


    const shades = {
        margin:'2px',
        fontSize:'30px',
    };
    useEffect(() => {
     
      if(start===1){
        playstart();
      }
      
    }, [variable3,a,b,c,variable2,variable3]);

   

    const sendData = () => {
        if(finalanswer>0){
            const data = finalanswer;
            sendDataToParent(data);

        }
        else{
            const data = variable2;
            sendDataToParent(data);

        }
      };
const playstart = () =>{
    const square = b *b;
    const mult = 4*+a*+c;
    const multi2 = 2*+a;
    const sub = square - mult;
    const sqrt = Math.sqrt(sub);
    const t1 = -1 *b;
    const numerator = -b + Math.sqrt(b * b - 4 * a * c);
    const numerator2 = -b - Math.sqrt(b * b - 4 * a * c);

    const denominator = 2 * a;
   // const x1 = new Fraction(numerator, denominator);
    //setResult(result);

    const x1 = t1 - sqrt;
    const x2 = t1 + sqrt;
    const xa = numerator/denominator;
    const xb = numerator2/denominator;
    const xb3 = xb.toFixed(3);
    const xa3 = xa.toFixed(3);

    setTimeout(() => {
        setVariable3(c);
        gsap.to(variable3ref.current,{color:'red',duration:1})

    }, 1000);


    setTimeout(() => {
        setVariable(a);
        gsap.to(variableref.current,{color:'red',duration:1})

    }, 500);

    setTimeout(() => {
        setConstant(mult);
        gsap.to(constantref.current,{color:'blue',duration:1})

        setac(false);
    }, 1000);

    setTimeout(() => {
        setConstant2(multi2);
        gsap.to(constantref.current,{color:'blue',duration:1})
        seta2(false);

    }, 1000);

setTimeout(() => {
setVariable2(b*-1);
setVariable21(b);

gsap.to(variable2ref.current,{color:'red',duration:1})
//gsap.to(variable2ref.current.querySelector('span'), { opacity: 0, duration: 0.5 });

}, 2000);

setTimeout(() => {
setVariable21(square);
setsqrm(false);
//gsap.to(variable2ref.current.querySelector('span'), { opacity: 0, duration: 0.5 });

}, 2500);

setTimeout(() => {
setVariable21(sub);
gsap.to(constantref.current,{opacity:'0',duration:1})
gsap.to(minusref.current,{opacity:'0',duration:1})
setforsquareroot(false);
setTimeout(() => {
console.log('sub is ', sub);
console.log('square is ', square);
console.log('multi2 is ', multi2);

setbrackets(false)
gsap.to(bracket2ref.current,{opacity:'0',duration:0})


}, 1500);
}, 2000);


setTimeout(() => {
    setVariable21(sqrt);
    gsap.to(playref.current,{opacity:'0',duration:1})

}, 3500);

setTimeout(() => {
    setOperator("+");
}, 4000);

setTimeout(() => {
    setVariable2(x2);
    setbrackets2(false)
    setbar1('___');
  //  gsap.to(barref.current,{marginBottom:'35px',duration:2})
  //  gsap.to(barref.current,{y:'-10',duration:2})
    setotheroption(x1);
}, 4500);

setTimeout(() => {

    setfinalanswer(xb3);
    setotheroption(xb3);
    setVariable2(xa3);
    if(xa3>0){
        setfinalanswer(xa3);
    }
    else if (xb3>0){

        setfinalanswer(xb3);
    }
    setBars(false);
   gsap.to(bottomref.current,{marginLeft:'200px',duration:2})
   sendData();
}, 5000);

}

return(
    <div style={{fontSize:'20px',fontSize:'helvetica',...shades}}>
<div style={{display:'flex',flexDirection:'column',top:'260px',left:'1000px',}}>
   <div ref={mainref} style={{ display:'flex'}}>
   <div style={{marginRight:'30px'}}>
        x
    </div>
    <div style={{marginRight:'30px'}}>
        =
    </div>
    {brackets2 && (
   <div>
   (
   </div>
   
    )}
 

  
   <div ref ={variable2ref} style={{...shades}}>
   {variable2}

   </div>
   <div style={{color:'maroon',marginLeft:'20px',marginRight:'38px',fontSize:'20px'}}>
 
 {brackets2 && (
 <div>
 {operator} </div>

 )}

 

   </div>
   <div ref={playref} style={{position:'absolute',left:'200px',top:'100px',width:'140px',height:'140px',marginRight:'15px'}} >
   <Player  ref={playref} autoplay={true} keepLastFrame={true} loop={false} src={sqrt} />
   </div>
   {brackets && (
    <div ref={bracket2ref} style={{...shades}}>
    (
   </div>
   )}

   {brackets2 && (

<div ref ={variable2ref} style={{...shades}}>
{variable21}
{sqrm && (
    <span ref={spanref2}style={{scale:'0.5',position:'absolute',bottom:'160px'}}>2</span>

)}
</div>
   )}
 
   {forsquareroot &&  (
   <div ref={minusref} style={{...shades,color:'maroon',}}>
   -
  </div>
   )

   }

{brackets && forsquareroot && (
 <div ref={constantref} style={{...shades}}>
 {constant}
</div>
)}
  


   {variable===a && ac && (
    <div style={{...shades}}>
    x
   </div>
   )

   }
   {ac&& brackets && (

<div ref ={variableref} style={{...shades}}>
    {variable}
   </div>
   )}
   
   {variable===a && ac && (
    <div style={{...shades}}>
    x
   </div>
   )

   }
   {ac && brackets && (


<div ref ={variable3ref} style={{...shades}}>
{variable3}

</div>
   )}
    {brackets && (

   <div style={{...shades}}>
    )

   </div>
   )}

{brackets2 && (

<div style={{...shades}}>
  )
 </div>
)}
{!brackets2 && (
    <div style={{marginRight:'80px',marginLeft:'10px',marginTop:'10px'}}>
    or
  </div>

)}
  
 {!brackets2 && (
    <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{marginLeft:'10px',marginTop:'10px'}}>
            {otheroption}
            </div>
            {bars && (
                <div style={{color:'blue',marginTop:'-10px'}}>
                ___
                </div>
            )}
                        {bars && (

                <div style={{marginBottom:'260px',marginLeft:'10px',}}>
                    2
                </div>
                            )}

</div>
 )}
   
   </div>
   {bars && (

<div ref={barref} style={{display:'flex',color:'blue',flexDirection:'column',...shades,marginRight:'50px',height:'50px',marginBottom:'30px'}} >
    {bar1}
   </div>
   )}
   
   {bars && (
 <div ref={bottomref} style={{display:'flex',...shades,marginLeft:'120px',marginTop:'-10px'}} >
 <div ref={constant2ref} style={{...shades}}>
      {constant2}
  </div>
  <div>
  {variable===a && a2 && (
  <div style={{...shades}}>
  x
 </div>
 )

 }
  </div>
  {a2 && (
      <div ref ={variableref} style={{...shades}}>
      {variable}
  </div>
  )}
  

 </div>
   )}
  
</div>


    </div>
);
};
export default QuadraticExample2;
