import React, { useEffect, useRef, useState } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import prac1 from './jsons/practicelesson1.json';
import prac2 from './jsons/practicelesson2.json';
import horizontal from './jsons/horizontal.json';
import vertical from './jsons/vertical.json';
import { gsap } from "gsap";

const Teaching = () => {
   const pracref = useRef(null);
   const oneref = useRef(null);
   const tworef = useRef(null);
   const threeref = useRef(null);
   const fourref = useRef(null);

    const [holder, setHolder] = useState(horizontal);
    const [isclicked, setisclicked] = useState(false);
    const [sumofnumbers, setsumofnumbers] = useState('___');
    const [valuea, setValueA] = useState(0);
    const [valueb, setValueB] = useState(0);
    const [variable1, setvariable1] = useState(1);
    const [variable2, setvariable2] = useState(2);
    const [variable3, setvariable3] = useState(3);
    const [variable4, setvariable4] = useState(4);

   //function for playing animation
 const playref = () =>{
    if(pracref.current){
        pracref.current.play();
    }
 }


const playit = () =>{
    if(pracref.current){
        pracref.current.play();
    }

}
 const clicked = (ref) =>{
    if(isclicked){
        const sum = +valuea + +valueb;

      

        if(ref===oneref){
            setValueA(variable1);
            console.log('value',variable1);

        }
        else if(ref===tworef){
            setValueA(variable2);
            console.log('value',variable2);

        }
        else if(ref===threeref){
            console.log('value',variable3);

            setValueA(variable3);

        }
        else if(ref===fourref){
            setValueA(variable4);
            console.log('value',variable4);

        }
        console.log('value',valuea);
        console.log('valueb',valueb);

        setsumofnumbers(sum);


        gsap.to(oneref.current,{color:'red',duration:'2'});
       // console.log('the sum is', sum);
        
    }
    else {

        if(ref===oneref){
            setValueB(variable1);
        }
        else if(ref===tworef){
            setValueB(variable2);
        }
        else if(ref===threeref){
            setValueB(variable3);

        }
        else if(ref===fourref){
            setValueB(variable4);

        }
        console.log('value b is', valueb);

        setisclicked(true);


    }
    
     }
    

 const numberposition={
    margin:'30px',
    cursor:'default',
    userSelect:'none',
 }

    return(
        <div>

<div>
<div style={{display:'flex',flexDirection:'column',fontSize:'40px',...numberposition, }}>
<div style={{display:'flex',...numberposition}}>

<div ref={oneref} onClick={()=> clicked(oneref)}  style={{...numberposition}}>
    {variable1}
</div>
<div ref={tworef} onClick={()=> clicked(tworef)}  style={{...numberposition}}>
{variable2}

</div>
</div>
<div style={{display:'flex',...numberposition}}>
<div ref={fourref} onClick={()=> clicked(threeref)} style={{...numberposition}}>
{variable3}

</div>
<div ref={fourref} onClick={()=> clicked(fourref)}style={{...numberposition}}>
{variable4}
</div>

</div>
</div>

</div>


<div>
    {sumofnumbers}
</div>

   <div   style={{width:'500px',height:'500px',...numberposition}}>
 <Player  ref={pracref} autoplay={false} loop={false} keepLastFrame={true} src={holder} />
 </div>

        </div>
     
    );
}
export default Teaching;
