import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';

const DistanceTracker = () => {
  const [distance, setDistance] = useState(0);
  const [lastPosition, setLastPosition] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);

  useEffect(() => {
    let watchId;

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      // Haversine formula to calculate distance between two points
      const R = 6371000; // Radius of the Earth in meters
      const dLat = (lat2 - lat1) * (Math.PI / 180);
      const dLon = (lon2 - lon1) * (Math.PI / 180);
      const a = 
        Math.sin(dLat / 2) * Math.sin(dLat / 2) + 
        Math.cos(lat1 * (Math.PI / 180)) * 
        Math.cos(lat2 * (Math.PI / 180)) * 
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    };

    const geoSuccess = (position) => {
      const { latitude, longitude } = position.coords;
      setCurrentPosition({ lat: latitude, lon: longitude });

      setLastPosition((prevLastPosition) => {
        if (prevLastPosition) {
          const dist = calculateDistance(prevLastPosition.lat, prevLastPosition.lon, latitude, longitude);
          setDistance(prevDistance => prevDistance + dist);
        }
        return { lat: latitude, lon: longitude };
      });
    };

    watchId = navigator.geolocation.watchPosition(geoSuccess);

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []); // Empty dependency array

  return (
    <Card 
      style={{ 
       position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '200px',
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}
    >
      <Card.Body>
        <h2 style={{ textAlign: 'center' }}>Distance</h2>
        <h3 style={{ textAlign: 'center' }}>{Math.round(distance)} m</h3>
      </Card.Body>
    </Card>
  );
};

export default DistanceTracker;
