import React, { useState, useRef } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import bus from '../jsons/bus.json';
import bus1 from '../jsons/bus01.json';
import bus1_2 from '../jsons/bus1_2.json';
import bus1_3 from '../jsons/bus1_3.json';
import bus1_4 from '../jsons/bus1_4.json';

const Sim7 = ({ sendDataToParent }) => {
  const animation1Ref = useRef(null);
  const [holder, setholder] = useState(bus);
  const [previousholder, setpreviousholder] = useState(bus);
  const [selectedButtons, setSelectedButtons] = useState([]);

  const playit = () => {
    setTimeout(() => {
      if (animation1Ref.current) {
        animation1Ref.current.play();
      }  
    }, 500);
  };

  const busize = (hol, buttonId) => {
    setholder(hol);
    setpreviousholder(holder);
    playit();

    setSelectedButtons((prev) => {
      const newSelected = [...prev];
      if (newSelected.includes(buttonId)) {
        return newSelected; // Keep button if already selected
      }
      if (newSelected.length >= 2) {
        newSelected.shift(); // Remove the oldest selection if we already have 2
      }
      newSelected.push(buttonId);
      return newSelected;
    });
  };

  const getButtonStyle = (buttonId) => ({
    backgroundColor: selectedButtons.includes(buttonId) ? 'black' : 'white',
    color: selectedButtons.includes(buttonId) ? 'white' : 'black',
    margin: '5px',
    padding: '10px',
  });

  return (
    <div>
      <Player
        ref={animation1Ref}
        src={holder}
        loop={false}
        autoplay={false}
        keepLastFrame={true}
        style={{ width: '300px', height: '300px' }}
      />
      <div style={{ marginTop: '20px' }}>
        <button
          onClick={() => busize(bus1, 'button1')}
          style={getButtonStyle('button1')}
        >
          High C.O.G
        </button>
        <button
          onClick={() => busize(bus1_2, 'button2')}
          style={getButtonStyle('button2')}
        >
          Low C.O.G
        </button>
        <button
          onClick={() => busize(bus1_3, 'button3')}
          style={getButtonStyle('button3')}
        >
          Wide base
        </button>
        <button
          onClick={() => busize(bus1_4, 'button4')}
          style={getButtonStyle('button4')}
        >
          Narrow base
        </button>
      </div>
    </div>
  );
};

export default Sim7;
