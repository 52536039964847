import { Link } from 'react-router-dom';
import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";

const Matrix = () => {
    const [a, seta] = useState(3);
    const [b, setb] = useState(1);
    const [c, setc] = useState(2);
    const [d, setD] = useState(0);
    const [z, setZ] = useState(4);
    const [y, setY] = useState(1);
    const [a4, setA4] = useState("_");
    const [q, setQ] = useState(0);
    const [a1, setA1] = useState("_");
    const [a2, setA2] = useState("_");
    const [x, setX] = useState(2);
    const [a3, setA3] = useState("_");

    const [sum1, setSum1] = useState(null);
    const [sum2, setSum2] = useState(null);

    const aref = useRef(null);
    const bref = useRef(null);
    const cref = useRef(null);
    const dref = useRef(null);
    const zref = useRef(null);
    const xref = useRef(null);
    const yref = useRef(null);
    const qref = useRef(null);
    const a1ref = useRef(null);
    const a2ref = useRef(null);
    const a3ref = useRef(null);
    const a4ref = useRef(null);

    useEffect(() => {
        const refs = [
            aref, bref, cref, dref,
            zref, xref, yref, qref,
            a1ref, a2ref, a3ref, a4ref
        ];

        let clickCounter = 0;

        refs.forEach(ref => {
            ref.current.addEventListener("mouseenter", () => {
                gsap.to(ref.current, { color: "red", duration: 0.3 });
            });

            ref.current.addEventListener("mouseleave", () => {
                gsap.to(ref.current, { color: "black", duration: 0.3 });
            });

            ref.current.addEventListener("click", () => {
                gsap.to(ref.current, { 
                    border: "2px solid red", 
                    padding: "10px", 
                    boxSizing: "border-box", 
                    duration: 0.3 
                });

                clickCounter++;

                if (clickCounter % 2 !== 0) {
                    setSum1(parseInt(ref.current.textContent));
                } else {
                    const value = parseInt(ref.current.textContent);
                    if ((sum1 === a && value === z) || 
                        (sum1 === b && value === x) || 
                        (sum1 === c && value === y) || 
                        (sum1 === d && value === q)) {
                        setSum2(value);
                    } else {
                        gsap.to(a1ref.current, { backgroundColor: "red", duration: 0.3 });
                        setTimeout(() => {
                            gsap.to(a1ref.current, { backgroundColor: "white", duration: 0.3 });
                        }, 2000);
                    }
                }
            });
        });
    }, [sum1]);

    useEffect(() => {
        if (sum1 !== null && sum2 !== null) {
            setA1(sum1 + sum2);
            setSum1(null);
            setSum2(null);
        }
    }, [sum1, sum2]);

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', cursor:'default' }}>

                <div className='matrix1' style={{ top: '200px', left: '400px', position: 'fixed' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'helvetica', fontSize: '40px' }}>
                        <div style={{ display: 'flex', margin: '-10px' }}>

                            <div ref={aref} style={{ margin: '30px' }}>
                                {a}
                            </div>
                            <div ref={bref} style={{ margin: '30px' }}>
                                {b}
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: '-10px' }}>

                            <div ref={cref} style={{ margin: '30px' }}>
                                {c}
                            </div>
                            <div ref={dref} style={{ margin: '30px' }}>
                                {d}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='matrix2' style={{ top: '200px', left: '700px', position: 'fixed' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'helvetica', fontSize: '40px' }}>
                        <div style={{ display: 'flex', margin: '-10px' }}>

                            <div ref={zref} style={{ margin: '30px' }}>
                                {z}
                            </div>
                            <div ref={xref} style={{ margin: '30px' }}>
                                {x}
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: '-10px' }}>

                            <div ref={yref} style={{ margin: '30px' }}>
                                {y}
                            </div>
                            <div ref={qref} style={{ margin: '30px' }}>
                                {q}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='matrix3' style={{ top: '200px', left: '1100px', position: 'fixed' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'helvetica', fontSize: '40px' }}>
                        <div style={{ display: 'flex', margin: '-10px' }}>

                            <div ref={a1ref} style={{ margin: '30px' }}>
                                {a1}
                            </div>
                            <div ref={a2ref} style={{ margin: '30px' }}>
                                {a2}
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: '-10px' }}>

                            <div ref={a3ref} style={{ margin: '30px' }}>
                                {a3}
                            </div>
                            <div ref={a4ref} style={{ margin: '30px' }}>
                                {a4}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Matrix;
