// Runner2.js
import React, { useEffect, useRef } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import lottie from 'lottie-web';
import distance1m from '../jsons/distanceof1m.json';
import distance2m from '../jsons/distanceof2m.json';
import distance5m from '../jsons/distanceof5m.json';
import distancehalf from '../jsons/distanceof0.5m.json';

const Runner2 = () => {
  // Creating refs for each animation container
  const animationContainer1 = useRef(null);
  const animationContainer2 = useRef(null);
  const animationContainer3 = useRef(null);
  const animationContainer4 = useRef(null);

  useEffect(() => {
    // Load animations when component mounts
    const animation1 = lottie.loadAnimation({
      container: animationContainer1.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: distancehalf, // Replace with the correct path
    });
    animation1.setSpeed(0.5); // Set speed for animation 1

    const animation2 = lottie.loadAnimation({
      container: animationContainer2.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: distance1m, // Replace with the correct path
    });
    animation2.setSpeed(1); // Set speed for animation 1

    const animation3 = lottie.loadAnimation({
      container: animationContainer3.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: distance2m// Replace with the correct path
    });
    animation3.setSpeed(2); // Set speed for animation 1

    const animation4 = lottie.loadAnimation({
      container: animationContainer4.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData:distance5m // Replace with the correct path
    });
    animation4.setSpeed(3); // Set speed for animation 1



    // Cleanup animations on unmount
    return () => {
      animation1.destroy();
      animation2.destroy();
      animation3.destroy();
      animation4.destroy();
    };
  }, []);

  return (
    <Card className="p-4">
      <div style={{display:'flex',flexDirection:'column'}}>

      <div style={{display:'flex'}}>
        <div ref={animationContainer1} style={{ width: '300px', height: '300px' }} />
        <div ref={animationContainer2} style={{ width: '300px', height: '300px' }} />

        </div>

        <div style={{display:'flex'}}>
        <div ref={animationContainer3} style={{ width: '300px', height: '300px' }} />
        <div ref={animationContainer4} style={{ width: '300px', height: '300px' }} />

        </div>

        </div>
       
    
       
     
     
     

   
    </Card>
  );
};

export default Runner2;
