import cursorImage from './pen.png';
import welcome from './jsons/welcome.json';
import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import bg from './jsons/bga.json';
import './title.css';


const MyComponent = () => {
    const introref = useRef(null);

  return (
    <div
      style={{
        cursor: `url(${cursorImage}) 10 10, auto`, // adjust the coordinates (10 10) to match your image's hotspot
      }}
    >

        <div>
        <Player style={{scale:'1.05'}}  ref={introref} autoplay={true} loop={true} src={bg}  hover={true}  />

        </div>
    </div>
  );
}
export default MyComponent;
