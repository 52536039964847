// src/auth.js
import { auth, db } from './Firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, serverTimestamp, updateDoc, increment, getDoc } from 'firebase/firestore';

export const signup = async (name, email, password, dateOfBirth) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    const userDoc = {
      name,
      dateOfBirth,
      email,
      createdAt: serverTimestamp(),
      M1: 0,
      Mn: 0,
      totalScore: 0,
      Level1:0,
      comments:null,
      selection:null,
    };
    await setDoc(doc(db, 'users', user.uid), userDoc);

    console.log('User signed up successfully', userDoc);
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    console.log('Login successful:', user);
    return true;
  } catch (error) {
    console.error('Error during login:', error);
    return false;
  }
};

export const updateScore = async (uid, scoreType) => {
  try {
    const scoreField = scoreType === 'M1' ? 'M1' : 'Mn';
    const updateData = {
      [scoreField]: increment(1)
    };
    await updateDoc(doc(db, 'users', uid), updateData);

    // Recalculate the total score
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();
    const newTotalScore = Math.max(userData.M1 - userData.Mn, 0);

    await updateDoc(userRef, { totalScore: newTotalScore });
  } catch (error) {
    console.error('Error updating score:', error);
    throw error;
  }
};
