// Newton.js
import React, { useEffect, useRef, useState } from "react";
import gsap from 'gsap';
import jumper from '../jsons/jumper4.json';
import { Draggable } from 'gsap/Draggable';
import { Player } from '@lottiefiles/react-lottie-player';
import runlaunch from '../jsons/runlaunch.json';
import run1 from '../jsons/run1.json';
import run2 from '../jsons/run2.json';
import runend from '../jsons/runend.json';
import O from '../jsons/H.json';
import jumphalf from '../jsons/jumphalf.json';
import jumpabit from '../jsons/jumpabit2.json';
gsap.registerPlugin(Draggable);

const Newton3 = ({play,jump,sendDataToParent}) => {
  const blockRef = useRef(null); // Ref for the draggable block
  const lineRef = useRef(null);  // Ref for the ground line
  const animref = useRef(null);  // Ref for the Lottie animation
  const draggableInstanceRef = useRef([]); // Ref for storing the GSAP Draggable instances
  const [holder, setholder] = useState(runlaunch);
  const animref2 = useRef(null);  // Ref for the Lottie animation
  const [looper, setlooper] = useState(false);






      
  useEffect(() => {

    if(jump){

  setholder(jumpabit);
  setlooper(false);

        

        setTimeout(() => {
         // setlooper(false);

         // setholder(jumpabit);
          setTimeout(() => {
            if(animref2.current){
              animref2.current.play();
            }
          }, 100);
        }, 1000);
    
          gsap.to(blockRef.current, {
            y: "-=200", // Move up 10px relative to current position
            duration: 0.3,
            delay: 1.1,
            onComplete: () => {
              setlooper(false);

              setTimeout(() => {
                sendData();
              }, 500);
              // Move block back to initial position after 1 second
              gsap.to(blockRef.current, {
                y: "+=200", // Move down 10px to return to original position
                duration: 0.3,
                delay: 0.5,
                
              });
            }
          });
    } else {

      if(play){
        setTimeout(() => {
          setholder(run1);
          setlooper(true);
    
  
          setTimeout(() => {
            setholder(run2);
  setTimeout(() => {
    if(animref2.current){
      animref2.current.play();
  
    }    
  }, 100
  );
                }, 100);
    
        }, 100);
       
    
          setTimeout(() => {
            setholder(runend);
            setlooper(false);
            setTimeout(() => {
              if(animref2.current){
                animref2.current.play();
  
              }
    
            }, 100);
          }, 3100);
      }
    }

  

    
  
}, [animref2,runend,blockRef,run1,jump,jumpabit,jumphalf,run2,looper]);

const sendData = () => {
  const data = 1;
  
  sendDataToParent(data);
};

  return (
    <div style={{ position: 'relative', }}>
    <>
    
   
     {looper?(
      <div
        ref={blockRef}
        style={{
          position: 'absolute',
          width: '200px',
          height: '200px',
        }}
      >
        <Player
          ref={animref2}
          src={holder}
          loop={true}
          autoplay={true}
          style={{ width: '130px', height: '130px' }}
        />
      </div>
     ):(
<div
        ref={blockRef}
        style={{
          position: 'absolute',
          width: '200px',
          height: '200px',
        }}
      >
        <Player
          ref={animref2}
          src={holder}
          loop={false}
          autoplay={false}
          keepLastFrame={true}
          style={{ width: '130px', height: '130px' }}
        />
      </div>
     )}
      </>
      
      <>
     

     
 </>
     
      {/* Ground line */}
     
    </div>
  );
};

export default Newton3;