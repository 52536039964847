import React, { useRef, useState, useEffect } from "react";
import { gsap } from "gsap";
import { useDrag } from "react-use-gesture";
import Newton3 from "./Newton3";

const DragLine = () => {
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [dragValue, setDragValue] = useState(1);
  const lineRef = useRef(null);
  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const blockref = useRef(null);
  const [jump, setJump] = useState(false);
  const [rulerData, setRulerData] = useState([]);
  const [highlightedPoints, setHighlightedPoints] = useState({});
  const [prevdragvalue, setprevdragvalue] = useState(1);

  useEffect(() => {
    const data = [];
    for (let i = 0; i <= window.innerWidth; i += 100) {
      data.push({ x: i, label: `${i / 10} cm` });
    }
    setRulerData(data);
  }, []);

  useEffect(() => {
    gsap.to(blockref.current, { x: 0, y: 0 });
  }, []);

  const handleDragStart = ({ xy: [x, y] }) => {
    setStartPoint({ x, y });
    setEndPoint({ x, y });
    setDragValue(1);
  };

  const handleDrag = ({ xy: [x, y], movement: [mx, my] }) => {
    setEndPoint({ x, y });
    setDragValue(Math.floor(mx / 10) + 1);

    gsap.to(lineRef.current, { opacity: 1, duration: 0 });
    gsap.to(circle1.current, { opacity: 1, duration: 0 });

    if (my > 50 && !jump) {
      setJump(true);
    }
  };

  const handleDragEnd = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setTimeout(checker, 100);
      if(prevdragvalue<0 && dragValue>0){
        gsap.to(blockref.current, { x: '-=50', duration: 0 });

      }
      else if(prevdragvalue>0 && dragValue<0){
        gsap.to(blockref.current, { x: '+=50', duration: 0 });

      }
    }

    gsap.to([lineRef.current, circle1.current, circle2.current], {
      opacity: 0,
      duration: 0.5,
      stagger: 0.5,
    });
  };

  const handleDataFromChild = (data) => {
    if (data) {
      setTimeout(() => {
        setJump(0);
        setIsAnimating(0);
      }, 500);
    }
  };

  const checker = () => {
    const distance = Math.abs(dragValue * 13);
    if (dragValue < 0) {
      gsap.to(blockref.current, {
        x: `+=${distance}`,
        duration: 3,
        ease: "none",
        onComplete: () => setIsAnimating(false),
        
      });


      setprevdragvalue(dragValue);


      gsap.to(blockref.current, { scaleX: 1, duration: 0 });
    } else if (dragValue > 0) {
      gsap.to(blockref.current, {
       
        x: `-=${distance}`,
        duration: 3,
        ease: "none",
        onComplete: () => setIsAnimating(false),
      });
    
      gsap.to(blockref.current, { scaleX: -1, duration: 0 });

      setprevdragvalue(dragValue);

    }
  };




  const bind = useDrag(
    ({ first, last, ...state }) => {
      if (first) handleDragStart(state);
      if (!last) handleDrag(state);
      if (last) handleDragEnd();
    },
    { pointer: { touch: true } }
  );

  return (
    <div
      {...bind()}
      style={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        touchAction: "none",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: 0,
          width: "100vw",
          height: "50vh",
          backgroundColor: "black",
          zIndex: -1,
        }}
      />

<svg
  style={{
    userSelect: "none",
    position: 'fixed',
    top: "50vh",
    zIndex: 3,
  }}
  width="100vw"
  height="50px"
>
  {rulerData.map((point, index) => (
    <g key={index}>
      <line
        x1={point.x}
        y1="0"
        x2={point.x}
        y2="100%"
        stroke={highlightedPoints[point.x] ? "red" : "white"}
        strokeWidth="1"
      />
      <text
        x={point.x}
        y="28" // Adjust this to control vertical spacing for text
        textAnchor="middle"
        fontSize="12"
        fill="white"
      >
        {point.label}
      </text>
    </g>
  ))}
</svg>

      <svg width="100%" height="100%">
        {startPoint && endPoint && (
          <>
            <line
              ref={lineRef}
              x1={startPoint.x}
              y1={startPoint.y}
              x2={endPoint.x}
              y2={endPoint.y}
              stroke="black"
              strokeWidth="3"
            />
            <text
              x={startPoint.x}
              y={startPoint.y - 10}
              style={{ userSelect: "none" }}
              fontSize="12"
              fill="black"
            >
              {dragValue}
            </text>
            <circle ref={circle1} cx={startPoint.x} cy={startPoint.y} r="5" fill="black" />
            <circle ref={circle2} cx={endPoint.x} cy={endPoint.y} r="5" fill="black" />
          </>
        )}
      </svg>

     


      <div style={{ position: "fixed", top: "29vh", left: "200px" }}>
        <div ref={blockref} style={{ position: "fixed" }}>
          <Newton3
            key={`${jump}-${isAnimating}`}
            play={isAnimating ? 1 : 0}
            jump={jump ? 1 : 0}
            sendDataToParent={handleDataFromChild}
          />
        </div>
      </div>
    </div>
  );
};

export default DragLine;
