import React, { useEffect, useRef, useState } from "react";
import lottie from "lottie-web";
import boytag from '../jsons/boytag.json';

import watertest from '../jsons/p4.json';

import leftwin from '../jsons/leftwin2.json';
import rightwin from '../jsons/rightwin2.json';
import wallpaper from '../jsons/wallpaper.mp3';
import { Player } from '@lottiefiles/react-lottie-player';
import LoadingPage from "../LoadingPages/LoadingPage";
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import bb from '../jsons/bb.json';

const TagofWar = ({role}) => {
  const rptref = useRef(null);
  const leftref = useRef(null);
  const [resttime, setresttime] = useState(5);
  const [isenabled, setisenabled] = useState(true);
  const [timeTaken, setTimeTaken] = useState(3);
  const audioref = useRef(null);
  const lottieInstance = useRef(null);
  const [sliderValue, setSliderValue] = useState(50);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [is100, setis100] = useState(false);
  const [is0, setis0] = useState(false);
  const [leftvalue, setleftvalue] = useState(20); // Initialize to 20
  const [rightvalue, setrightvalue] = useState(0);
  const [winholder, setwinholder] = useState(leftwin);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const playAudio = () => {
      if (audioref.current) {
        audioref.current.play().catch((error) => {
          console.error("Audio play interrupted:", error);
        });
      }
    };
  
    document.addEventListener("click", playAudio, { once: true });
  
    return () => {
      document.removeEventListener("click", playAudio);
    };
  }, []);
 
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4500);

    return () => clearTimeout(timer);
  }, []);

  // Function to update animation data and restart from the current frame
  const updateAnimationData = (newValue) => {
    const updatedData = JSON.parse(JSON.stringify(watertest)); // Clone watertest data
    updatedData.layers.forEach((layer) => {
      if (layer.ef) {
        layer.ef.forEach((effect) => {
          if (effect.nm === "Slider Control") {
            const sliderEffect = effect.ef.find(e => e.nm === "Slider");
            if (sliderEffect) {
              sliderEffect.v.k = newValue;
            }
          }
        });
      }
    });

    // Reinitialize the animation with the updated data
    if (lottieInstance.current) {
      lottieInstance.current.destroy();
    }
    lottieInstance.current = lottie.loadAnimation({
      container: rptref.current,
      animationData: updatedData,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    });

    // Play the animation from the current frame
    if (newValue > 0) {
      lottieInstance.current.goToAndPlay(currentFrame, true);
    }
  };

  const updateAnimationData2 = (newValue) => {
    const updatedData = JSON.parse(JSON.stringify(watertest)); // Clone watertest data
    updatedData.layers.forEach((layer) => {
      if (layer.ef) {
        layer.ef.forEach((effect) => {
          if (effect.nm === "Slider Control") {
            const sliderEffect = effect.ef.find(e => e.nm === "Slider");
            if (sliderEffect) {
              sliderEffect.v.k = newValue;
            }
          }
        });
      }
    });

    // Reinitialize the animation with the updated data
    if (lottieInstance.current) {
      lottieInstance.current.destroy();
    }
    lottieInstance.current = lottie.loadAnimation({
      container: rptref.current,
      animationData: updatedData,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    });

    // Play the animation from the current frame
    if (newValue > 0) {
      lottieInstance.current.goToAndPlay(currentFrame, true);
    }
  };

  // Handle slider change
  const handleSliderChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setSliderValue(newValue);
    updateAnimationData(newValue);
  };

  const addleft = () => {
    if (leftvalue > 0) {  // Ensure leftvalue doesn't go below 0
      setleftvalue(leftvalue - 1.5); // Decrement leftvalue
      setSliderValue(prev => prev + 1.5); // Adjust slider if needed
      updateAnimationData2(sliderValue + 1.5);
    }
  };

  const addright = () => {
    if (leftvalue > 0) {
      setleftvalue(leftvalue - 0.5); // Decrement leftvalue
      setSliderValue(prev => prev - 1);
      updateAnimationData2(sliderValue - 1);
    }
  };

  useEffect(() => {
    // Initialize Lottie animation
    if (rptref.current) {
      rptref.current.style.height = isMobile ? '300px' : '400px';
      rptref.current.style.width = isMobile ? '300px' : '700px';
      rptref.current.style.top = '100px';
    }

    lottieInstance.current = lottie.loadAnimation({
      container: rptref.current,
      animationData: watertest,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    });

    // Track current frame and time
    const intervalId = setInterval(() => {
      if (lottieInstance.current) {
        const frame = lottieInstance.current.currentFrame;
        const duration = lottieInstance.current.getDuration(true);
        const time = (frame / lottieInstance.current.totalFrames) * duration;
        setCurrentFrame(frame);
        setCurrentTime(time.toFixed(2)); // Format to 2 decimal places
      }
    }, 1000); // Update every 100ms

    return () => {
      clearInterval(intervalId);
      if (lottieInstance.current) {
        lottieInstance.current.destroy();
      }
    };
  }, [isMobile]);

  useEffect(() => {
    // Change breaths
    if (leftvalue < 1) {
      setisenabled(false);
      setTimeout(() => {
        setisenabled(true);
        setleftvalue(20);
        setTimeTaken(3);
      }, 3000);

    }
  }, [leftvalue]);

  useEffect(() => {
    if (leftref.current) {
      leftref.current.style.height = isMobile ? '300px' : '400px';
      leftref.current.style.width = isMobile ? '300px' : '700px';
      leftref.current.style.top = '100px';

      lottieInstance.current = lottie.loadAnimation({
        container: leftref.current,
        animationData: winholder,
        renderer: 'svg',
        loop: false,
        autoplay: true,
      });
    }

    if (sliderValue > 90) {
      setwinholder(leftwin);
      setis100(true);
      setis0(false);
    }
    else if (sliderValue < 5) {
      setTimeout(() => {
        setwinholder(rightwin);
        setis0(true);
        setis100(false);
      }, 100);
    }
    else {
      setis0(false);
      setis100(false);
    }
  }, [sliderValue, winholder, isMobile]);

  useEffect(() => {
    if(role === 1) {
      if (sliderValue > 3 && sliderValue < 95) {
        const timerId = setTimeout(() => {
          setSliderValue(prev => prev - 0.5);
          updateAnimationData2(sliderValue - 0.5);
        }, 200); // Speed of opponent pusher
        return () => clearTimeout(timerId);
      }
    }
    else if(role === 2) {
      if (sliderValue > 3 && sliderValue < 95) {
        const timerId = setTimeout(() => {
          setSliderValue(prev => prev + 1.5);
          updateAnimationData2(sliderValue + 1.5);
        }, 300); // Speed of opponent pusher
        return () => clearTimeout(timerId);
      }
    }
  }, [sliderValue, role]);

  useEffect(() => {
    if (!isenabled) {
      const timerId = setTimeout(() => {
        setTimeTaken(prevTime => prevTime - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [isenabled]);

  const handleClick = () => {
    setresttime(5);
    setisenabled(true);
    setTimeTaken(3);
  };

  const handleStopClick = () => {
    setisenabled(false);
    if (timeTaken === 0) {
      if (sliderValue >= 50) {
        setwinholder(leftwin);
        setis100(true);
        setis0(false);
      } else {
        setwinholder(rightwin);
        setis0(true);
        setis100(false);
      }
    }
  };

  const handleResetClick = () => {
    setresttime(5);
    setSliderValue(50);
    updateAnimationData2(50);
    setleftvalue(20);
    setrightvalue(0);
    setIsLoading(true);
  };

  const handleAudioLoad3 = () => {
    if (audioref.current) {
   
        audioref.current.play().catch((error) => {
          console.error("Audio play interrupted:", error);
        });
     
     
    }
  };
  return (
    <>
 {isMobile ? (
  <div>
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div>
          <Card
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '80px',
              textAlign: 'center',
              zIndex: 1000,
              color: 'black',
              fontFamily: 'Helvetica, Arial, sans-serif',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f8f9fa',
              borderBottom: '2px solid #ccc',
            }}
          >
            <h2 style={{ margin: 0, fontSize: 'clamp(1.5rem, 2.5vw, 2rem)' }}>Physics Steps</h2>
          </Card>

          <Container
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: '100vh', marginTop: '80px' }}
          >
            <Row className="justify-content-center w-100">
              <Col xs={12} className="mb-4">
                <Card
                  style={{
                    textAlign: 'center',
                    width: 'auto',
                    marginBottom: '20px',
                  }}
                >
                  <Card.Header style={{ backgroundColor: '#E0DBCF' }}>
                    <h3>Tag of War</h3>
                  </Card.Header>
                  <Card.Body>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        userSelect: 'none',
                      }}
                    >
                      {/* Define consistent size for both leftref and rptref */}
                      {is100 && (
                        <div
                          ref={leftref}
                          style={{
                            width: '300px',
                            height: '300px',
                            userSelect: 'none',
                            backgroundColor: '#f0f0f0', // optional background to make size clearer
                          }}
                        >
                          {/* Left win animation */}
                        </div>
                      )}
                      {is0 && (
                        <div
                          ref={leftref}
                          style={{
                            width: '300px',
                            height: '300px',
                            userSelect: 'none',
                            backgroundColor: '#f0f0f0',
                          }}
                        >
                          {/* Right win animation */}
                        </div>
                      )}
                      {!is100 && !is0 && (
                        <div
                          ref={rptref}
                          style={{
                            width: '300px',
                            height: '300px',
                            userSelect: 'none',
                            backgroundColor: '#f0f0f0',
                          }}
                        >
                          {/* Water test animation */}
                          <Player>
                            <audio
                              ref={audioref}
                              src={wallpaper}
                              type="audio/mp3"
                              onLoadedData={handleAudioLoad3}
                            />
                          </Player>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                  <Card.Footer style={{ backgroundColor: '#DFD3A5' }}>
                    <Row className="justify-content-center">
                    
                      <Col xs={12} md={6}>
                        <div className="mt-3" style={{ userSelect: 'none' }}>
                          <strong>Distance left:</strong> {(sliderValue - 95) * -1}
                        </div>
                        <div className="mt-2" style={{ userSelect: 'none' }}>
                          <strong>Pushes:</strong> {leftvalue}
                        </div>
                        <div className="mt-2" style={{ userSelect: 'none' }}>
                          <strong>Right Value:</strong> {rightvalue}
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className="mb-3" style={{ userSelect: 'none' }}>
                          <input
                            type="range"
                            min="0"
                            max="100"
                            value={sliderValue}
                            onChange={handleSliderChange}
                            className="form-range"
                          />
                        </div>
                        {!isenabled && (
                          <div className="mb-3" style={{ userSelect: 'none' }}>
                            <strong>Rest time:</strong> {timeTaken} seconds
                          </div>
                        )}
                        <Row className="mt-3" style={{ userSelect: 'none' }}>
                          {isenabled ? (
                            <Col>
                              <Button
                                variant="primary"
                                onClick={addright}
                                className="me-2"
                              >
                                Right
                              </Button>
                              <Button variant="primary" onClick={addleft}>
                                Left
                              </Button>
                            </Col>
                          ) : (
                            <Col>
                              <Button variant="secondary" disabled className="me-2">
                                Rest
                              </Button>
                              <Button variant="secondary" disabled>
                                Rest
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  </div>
):(
      <div>
      <>
      {isLoading ? (
  <LoadingPage/>
      ):(
        <div>
             <Card style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '80px',
      textAlign: 'center',  // Centers text horizontally
      zIndex: 1000,
      color: 'black',
      fontFamily: 'Helvetica, Arial, sans-serif',
      display: 'flex',
      alignItems: 'center',  // Centers content vertically
      justifyContent: 'center',  // Centers content horizontally
      backgroundColor: '#f8f9fa',
      borderBottom: '2px solid #ccc'
  }}>
      <h2 style={{ margin: 0, fontSize: 'clamp(1.5rem, 2.5vw, 2rem)' }}>Physics Steps</h2>
  </Card>
  
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <Row className="justify-content-center w-100">
          <Col xs={12} md={10} lg={8} className="mb-4">
            <Card style={{ width: '1000px',  textAlign: 'center',marginTop:'60px',marginLeft:'10px' }}>
            <Card.Header style={{ backgroundColor: '#E0DBCF' }}>
            <h3>Tag of War</h3>
              </Card.Header>
              <Card.Body>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',userSelect:'none'  }}>
                  {/* Conditional Rendering of Animations */}
                  {is100 && (
                    <div ref={leftref} style={{ height: '400px', width: '800px',userSelect:'none' }}>
                      {/* The leftwin animation will be rendered here */}
                    </div>
                  )}
                  {is0 && (
                    <div ref={leftref} style={{  height: '400px', width: '800px',userSelect:'none' }}>
                      {/* The rightwin animation will be rendered here */}
                    </div>
                  )}
                  {!is100 && !is0 && (
                    <div ref={rptref} style={{  height: '400px', width: '1000px',userSelect:'none' }}>
                      {/* The watertest animation will be rendered here */}
                       <Player>
      <audio ref={audioref} src={wallpaper} type="audio/mp3" onLoadedData={handleAudioLoad3}/>
  
      </Player>
                    </div>
                  )}
                
                </div>
              </Card.Body>
              <Card.Footer style={{ backgroundColor: '#DFD3A5' }}>
                <Row className="justify-content-center">
                  <Col xs={12} md={6}>
                    <div className="mb-3"  style={{userSelect:'none'}}>
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value={sliderValue}
                        onChange={handleSliderChange}
                        className="form-range"
                      />
                    </div>
                    {!isenabled && (
                      <div className="mb-3" style={{userSelect:'none'}}>
                        <strong>Rest time:</strong> {timeTaken} seconds
                      </div>
                    )}
                   
                    <Row className="mt-3" style={{userSelect:'none'}}>
                      {isenabled ? (
                        <Col>
                          <Button variant="primary" onClick={addright} className="me-2">Right</Button>
                          <Button variant="primary" onClick={addleft}>Left</Button>
                        </Col>
                      ) : (
                        <Col>
                          <Button variant="secondary" disabled className="me-2">Rest</Button>
                          <Button variant="secondary" disabled>Rest</Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="mt-3" style={{userSelect:'none'}}>
                      <strong>distance left:</strong> {(sliderValue-95)*-1}
                    </div>
                  
                    <div className="mt-2" style={{userSelect:'none'}}>
                      <strong>Pushes:</strong> {leftvalue}
                    </div>
                    <div className="mt-2" style={{userSelect:'none'}}>
                      <strong>Right Value:</strong> {rightvalue}
                    </div>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      </div>
      )}
     </>
     </div>
    )}
   
   </>

  );
};

export default TagofWar;
