import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";


gsap.registerEffect({
    name: "variablehover",
    effect: (targets, config) => {
      return gsap.to(targets, { backgroundColor: config.backgroundColor, color: config.color, duration: config.duration });
    },
    defaults: { duration: 0.1, backgroundColor: "white", color: "#C33602" }, // defaults for the config object
    extendTimeline: true,
  });
const Variables = ({onSelect}) =>{
    const nRef = useRef(null);
    const yRef = useRef(null);
    const cRef = useRef(null);
    const [varialbleactive, setvarialbleactive] = useState(true);

    const refs = [nRef, yRef, cRef];

    useEffect(() => {
        refs.forEach(ref => {
          if (ref.current) {
            ref.current.addEventListener("mouseenter", () => {
              gsap.effects.variablehover(ref.current);
            });
    
            ref.current.addEventListener("mouseleave", () => {
              gsap.to(ref.current, { backgroundColor: "#C33602", color: "white", duration: 0.3 });
            });
          }
        });
    
        return () => {
          refs.forEach(ref => {
            if (ref.current) {
              ref.current.removeEventListener("mouseenter", () => {
                gsap.effects.variablehover(ref.current);
              });
    
              ref.current.removeEventListener("mouseleave", () => {
                gsap.to(ref.current, { backgroundColor: "#C33602", color: "white", duration: 0.3 });
              });
            }
          });
        };
      }, [refs]);

      const calcposition = {
        margin: "10px",
     
        borderRadius: "10px",
        color: "white",
        backgroundColor:"#C33602",
        border: "2px solid red",
        fontFamily: "Helvetica",
        fontWeight:"bold"
      };

      const select = (value) => {
        if (onSelect) {
          onSelect(value);
        }
      };

      return (
        <div>
          {varialbleactive && (
            <div style={{ display: "flex", fontSize: "20px", alignItems: 'center', padding: '20px', userSelect: 'none',  }}>
            <div onClick={() => select('n')} ref={nRef} style={{ ...calcposition, padding: "10px" }}>n</div>
            <div onClick={() => select('y')} ref={yRef} style={{ ...calcposition, padding: "10px" }}>y</div>
            <div onClick={() => select('c')} ref={cRef} style={{ ...calcposition, padding: "10px" }}>c</div>
            </div>
          )}
          
        </div>
       
      );
};

export default Variables;