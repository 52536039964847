import React, { useState } from 'react';
import gsap from 'gsap';
import TopicsHeader from './TopicsHeader'; // Import the TopicsHeader component

const TopicsPage = () => {
  const [topics, setTopics] = useState(
    Array.from({ length: 9 }, (_, i) => ({
      title: `Topic ${i + 1}`,
      subtopics: Array.from({ length: 3 }, (_, j) => ({
        title: `Subtopic ${j + 1}`,
        activities: ['Activity 1', 'Activity 2'],
      })),
    }))
  );
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingSubtopic, setEditingSubtopic] = useState({ topicIndex: null, subtopicIndex: null });
  const [newTitle, setNewTitle] = useState('');

  // Handle topic double-click to edit title
  const handleDoubleClick = (index) => {
    setEditingIndex(index);
    setNewTitle(topics[index].title);
  };

  // Handle subtopic double-click to edit title
  const handleSubtopicDoubleClick = (topicIndex, subIndex) => {
    setEditingSubtopic({ topicIndex, subtopicIndex: subIndex });
    setNewTitle(topics[topicIndex].subtopics[subIndex].title);
  };

  // Confirm topic title change
  const handleOkClick = (index) => {
    const updatedTopics = [...topics];
    updatedTopics[index].title = newTitle.trim() === '' ? `Topic ${index + 1}` : newTitle;
    setTopics(updatedTopics);
    setEditingIndex(null);
  };

  // Confirm subtopic title change
  const handleSubtopicOkClick = (topicIndex, subIndex) => {
    const updatedTopics = [...topics];
    updatedTopics[topicIndex].subtopics[subIndex].title = newTitle.trim() === '' ? `Subtopic ${subIndex + 1}` : newTitle;
    setTopics(updatedTopics);
    setEditingSubtopic({ topicIndex: null, subtopicIndex: null });
  };

  // Toggle subtopics with GSAP
  const handleToggleSubtopics = (index) => {
    const subtopicElement = document.getElementById(`subtopics-${index}`);
    const isOpen = subtopicElement.style.height !== '0px';
    
    gsap.to(subtopicElement, {
      height: isOpen ? 0 : 'auto',
      opacity: isOpen ? 0 : 1,
      duration: 0.3,
    });
  };

  // Toggle activities under subtopics with GSAP
  const handleToggleActivities = (topicIndex, subIndex) => {
    const activityElement = document.getElementById(`activities-${topicIndex}-${subIndex}`);
    const isOpen = activityElement.style.height !== '0px';

    gsap.to(activityElement, {
      height: isOpen ? 0 : 'auto',
      opacity: isOpen ? 0 : 1,
      duration: 0.3,
    });
  };

  return (
    <div>
      {/* TopicsHeader component with fixed position */}
     

      {/* Main content starts below the header */}
      <div style={{ marginTop: '320px', maxWidth: '500px', margin: '0 auto', padding: '20px' }}>
        {topics.map((topic, index) => (
          <div key={index}>
            {/* Topic Div */}
            <div
              style={{
                display: 'flex',
                border: '1px solid #ccc',
                padding: '10px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                marginBottom: '10px',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() => handleToggleSubtopics(index)}
              onDoubleClick={() => handleDoubleClick(index)}
            >
              {editingIndex === index ? (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <input
                    type="text"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                    style={{ flexGrow: 1, marginRight: '10px', padding: '5px' }}
                  />
                  <button onClick={() => handleOkClick(index)} style={{ marginRight: '5px' }}>
                    OK
                  </button>
                </div>
              ) : (
                <span>{topic.title}</span>
              )}
            </div>

            {/* Subtopics */}
            <div
              id={`subtopics-${index}`}
              style={{
                height: 0,
                overflow: 'hidden',
                opacity: 0,
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '20px',
                fontSize: '0.9em',
              }}
            >
              {topic.subtopics.map((subtopic, subIndex) => (
                <div key={subIndex}>
                  <div
                    style={{
                      display: 'flex',
                      border: '0.5px solid #ccc',
                      padding: '8px',
                      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#FBF6CD',
                      marginBottom: '5px',
                      cursor: 'pointer',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => handleToggleActivities(index, subIndex)}
                    onDoubleClick={() => handleSubtopicDoubleClick(index, subIndex)}
                  >
                    {editingSubtopic.topicIndex === index && editingSubtopic.subtopicIndex === subIndex ? (
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <input
                          type="text"
                          value={newTitle}
                          onChange={(e) => setNewTitle(e.target.value)}
                          style={{ flexGrow: 1, marginRight: '10px', padding: '5px' }}
                        />
                        <button onClick={() => handleSubtopicOkClick(index, subIndex)} style={{ marginRight: '5px' }}>
                          OK
                        </button>
                      </div>
                    ) : (
                      <span>{subtopic.title}</span>
                    )}
                  </div>

                  {/* Activities */}
                  <div
                    id={`activities-${index}-${subIndex}`}
                    style={{
                      height: 0,
                      overflow: 'hidden',
                      opacity: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '40px',
                      fontSize: '0.8em',
                    }}
                  >
                    <div style={{ marginBottom: '5px' }}>Activity 1</div>
                    <div style={{ marginBottom: '5px' }}>Activity 2</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopicsPage;
