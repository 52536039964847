import React, { useEffect, useRef, useState } from "react";
import { Container, Card } from 'react-bootstrap';
import { Player } from '@lottiefiles/react-lottie-player';
import gsap from 'gsap';
import { Draggable } from 'gsap/Draggable';
import lottie from 'lottie-web';
import boiling from '../jsons/boilingwater.json';
import bunsen from '../jsons/burnsenb.json';
import nonluminous from '../jsons/nonluminous.json';
import ChemLoader from "../LoadingPages/ChemLoader";

gsap.registerPlugin(Draggable);

const Cprac2 = () => {
  const [sliderValue, setSliderValue] = useState(0);
  const [totalFrames, setTotalFrames] = useState(0);
  const [metalZIndex, setMetalZIndex] = useState(5);
  const [tubeZIndex, setTubeZIndex] = useState(5);
  const [holder, setholder] = useState(bunsen);
  const [isloading, setIsLoading] = useState(true);

  const tuberef = useRef(null);
  const metalref = useRef(null);
  const metalref2 = useRef(null);

  const animationInstance = useRef(null);

  // Make tuberef draggable
  useEffect(() => {
    setTimeout(() => {
      Draggable.create(tuberef.current, {
        type: "x,y",
        onDragEnd: function() {
          setTubeZIndex(-5);
          setMetalZIndex(5);
          gsap.to(tuberef.current, { zIndex: tubeZIndex, duration: 0 });
          gsap.to(metalref.current, { zIndex: metalZIndex, duration: 0 });
  
        }
  
      }); 
    }, 3100);
   
  }, [tubeZIndex,metalZIndex,tuberef,metalref]);
  useEffect(() => {
    setTimeout(() => {
      Draggable.create(metalref.current, {
        type: "x,y",
        onDragEnd: function() {
          setTubeZIndex(5);
          setMetalZIndex(-5);
          gsap.to(metalref.current, { zIndex: metalZIndex, duration: 0 });
          gsap.to(tuberef.current, { zIndex: tubeZIndex, duration: 0 });
  
        }
      }); 
    }, 3100);
  
  }, [metalZIndex,tubeZIndex,metalref,tuberef]);
  // Constantly check slider value and switch between nonluminous and bunsen
  useEffect(() => {
    setTimeout(() => {
      if (sliderValue > 60 && holder !== nonluminous) {
        setholder(nonluminous);
        if(metalref2.current){
          metalref2.current.play();
        } // Switch to nonluminous if value is above 60
      } else if (sliderValue <= 60 && holder !== bunsen) {
        setholder(bunsen); // Switch back to bunsen if value is 60 or less
      }
      else if(sliderValue<20){
      //  metalref2.current.pause();
      }
    }, 3100);
   
   
  }, [sliderValue, holder]);

  // Load the Lottie animation, without autoplay
  useEffect(() => {
    setTimeout(() => {
      if (animationInstance.current) {
        animationInstance.current.destroy(); // Destroy previous instance before reloading
      }
  
      animationInstance.current = lottie.loadAnimation({
        container: tuberef.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,  // Autoplay disabled
        animationData: holder,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
      });
  
      animationInstance.current.addEventListener('DOMLoaded', () => {
        setTotalFrames(animationInstance.current.totalFrames);
      });
  
      return () => {
        if (animationInstance.current) {
          animationInstance.current.destroy(); // Clean up on unmount
        }
      };
    }, 3100);

  }, [holder]); // Reload the animation when holder changes

  useEffect(() => {
   setTimeout(() => {
    setIsLoading(false);
   }, 3000);
   
  }, [isloading]);


  const handleSliderChange = (e) => {
    const newValue = e.target.value;
    setSliderValue(newValue);
    const currentFrame = (newValue / 100) * totalFrames;
    animationInstance.current.goToAndStop(currentFrame, true);  // Update animation frame based on slider
  };

  return (
    <div>
  <>
 {isloading && (
    <ChemLoader/>
   )}
   </>
   <>
   {!isloading && (
  <div>

  <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Card style={{ width: '600px', height: '500px', textAlign: 'center', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <Card.Header as="h5">Bunsen burner</Card.Header>
        <Card.Body className="card-body" style={{ justifyContent: 'space-between' }}>
          <div>
            <div ref={metalref} style={{ zIndex: metalZIndex, position: 'absolute', cursor: 'pointer', width: '300px', height: '300px' }}>
              <Player ref={metalref2} src={boiling} autoplay={false} loop={false} />
            </div>
            <div ref={tuberef} style={{ zIndex: tubeZIndex, position: 'absolute', width: '300px', height: '300px', cursor: 'pointer' }}>
              {/* Lottie animation will render inside this div */}
            </div>
          </div>
        </Card.Body>
        <Card.Footer style={{ color: 'maroon' }}>
          {/* Slider to control the Lottie animation */}
          <input
            type="range"
            min="0"
            max="100"
            step="1"
            value={sliderValue}
            onChange={handleSliderChange}
          />
          <div>flame strength: {sliderValue}</div>
        </Card.Footer>
      </Card>
    </Container>
    </div>
   )}
   </>
 

    </div>
 
  
    
      
    
       
    
   
  
  );
};

export default Cprac2;
