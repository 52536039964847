import React, { useEffect, useRef, useState } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import { useNavigate } from "react-router-dom";
import './MathLearning.css';
import wave from './jsons/wav2.mp3';
import correctsound from './jsons/genius ke.mp3';
import wrongsound from './jsons/wronganswer.mp3';
import wrongsound2 from './jsons/scenes/algebra/wronganswer2.mp3';
import wronganswer from './jsons/wronganswer.json';
import wronganswer2 from './jsons/scenes/algebra/wronganswer2.json';
import swipematch1 from './jsons/scenes/algebra/swipematch1.mp3';
import swipematch2 from './jsons/scenes/algebra/swipematch2.mp3';
import swipematch3 from './jsons/scenes/algebra/swipematch3.mp3';

import quad3 from './jsons/quad3.json';
import quad3sound from './jsons/quad3sound.mp3';
import nextbutton from './jsons/nextbtnlong.json';
import backbutton from './jsons/goback.json';
import quad1 from './jsons/quad1.json';
import quad1mob from './jsons/quad1mob.json';
import quad2 from './jsons/quad2.json';
import quad4 from './jsons/quad4.json';
import quad5 from './jsons/quad5.json';
import quad6 from './jsons/quad6.json';

import wait from './jsons/wait.json';
import quad1sound from './jsons/quad1.mp3';
import quad2sound from './jsons/quad2.mp3';
import startbtn from './jsons/startbutton.json';
import questionbar from './jsons/questionbar.json';
import quad4sound from './jsons/motion.mp3';
//import quad5sound from './jsons/quad5sound.mp3';
import qnew from './jsons/quadnew.json';
import rpt from './jsons/rpt.json';
import { Container, Row, Col, Card } from 'react-bootstrap';
import CircularButton from "./bootsrap elements/CircularButton";
import CircularButtonback from "./bootsrap elements/CircularButtonback";
import correctanswer from './jsons/correctanswer.json';
import quadini from './jsons/2quads.mp3';
import attemptbar from './jsons/attemptbar.json';
import { gsap } from "gsap";
import LoadingPage from "./LoadingPages/LoadingPage";
import { Link } from 'react-router-dom';
import tutorial from './jsons/tutorialbar.json';
import MathLoader from "./LoadingPages/MathLoader";
import ProgressBar from './LoadingPages/ProgressBar';
import SwipeMatch from "./PracticeComponents/SwipeMatch";

const MathLearning2 = () => {
  const navigate = useNavigate();
  const animationRef = useRef(null);
  const audioref = useRef(null);
  const audioref2 = useRef(null);
  const upperaref = useRef(null);
  const opt2ref = useRef(null);
  const opt1ref = useRef(null);
  const opt3ref = useRef(null);
  const opt4ref = useRef(null);

  
  const nextbuttonref = useRef(null);
  const rptref = useRef(null);
  const [option, setOptions] = useState(1);
  const [navigation, setnavigation] = useState(false);
  const startref = useRef(null);
  const [topanime, settopanime] = useState(0);
  const [progressvalue, setprogressvalue] = useState(0);
  const [scorevalue, setscorevalue] = useState(0);
  const [place4active, setplace4active] = useState('');
  const [place3active, setplace3active] = useState('');
  const [place3, setplace3] = useState(0);
  const [place4, setplace4] = useState(0);
  const [activateSwipeMatch, setActivateSwipematch] = useState(false);
  const [dataFromChild, setDataFromChild] = useState('');
  const [lastIncrementedData, setLastIncrementedData] = useState(null);

  
  const [bottomanime, setbottomanime] = useState(startbtn);
  const [option1, setoption1] = useState("x² + 2x - 1 = 0");
  const [option2, setoption2] = useState("x + 2 = 1");
  const [option3, setoption3] = useState("4x² + 2x - 1 = 5");
  const [option4, setoption4] = useState("2x² + x - 5 = 0");
  const [place1, setplace1] = useState(option1);
  const [subtopicno, setsubtopicno] = useState("01");
  const [answerset, setanswerset] = useState(true);

  const [place2, setplace2] = useState(option2);
  
  const [counter, setCounter] = useState(1);
  const [playing, setPlaying] = useState(qnew);
  const [playingMobile, setPlayingMobile] = useState(quad1mob);
  const [sounding, setSounding] = useState(quadini);
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonAnimation, setButtonAnimation] = useState(nextbutton);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoading, seisLoading] = useState(true);

  //the extrasounds
 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  useEffect(() => {
    const timer = setTimeout(() => {
      seisLoading(false);
    }, 4500);

    return () => clearTimeout(timer);
  }, []);

  const next = () => {
    setprogressvalue(progressvalue+10);
    if (!isDisabled) {
      setButtonAnimation(wait);
      if (nextbuttonref.current) {
        nextbuttonref.current.play();
      }
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
        setButtonAnimation(nextbutton);
      }, 2500);
    }
    updateContent2();
  };
  const squareStyle = {
    width: '90px',
    height: '65px',
    backgroundColor: 'skyblue',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft:'50px',
    
    
  
  };
  
 


  const scoreStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
  };
  const updateContent2 = () => {
    if (counter === 1) {
      settopanime(tutorial)
      setPlaying(quad1);
      setSounding(quad1sound);
      playAnimationAndSound();
      setCounter(counter + 1);

    } else if (counter === 2) {
      settopanime(questionbar);

      setnavigation(false)
      setplace1(option3);
      setplace2(option4);
      setPlaying(qnew);
      setSounding(quadini);
      playAnimationAndSound();
      setCounter(counter + 1);
    } else if (counter === 3) {
      settopanime(tutorial);

      setPlaying(quad2);
      setSounding(quad2sound);
      playAnimationAndSound();
      setCounter(counter + 1);
    } else if (counter === 4) {
      setActivateSwipematch(true);
      setnavigation(false);

     setSounding(swipematch1);
      setCounter(counter + 1);
    }
    else if (counter === 5) {
      setActivateSwipematch(false);
      setPlaying(quad3);
     setSounding(quad3sound);
      playAnimationAndSound();
      setCounter(counter + 1);
    }
    else if (counter === 6) {
      setPlaying(quad4);
     setSounding(quad4sound);
      playAnimationAndSound();
      setCounter(counter + 1);
    }
    else if (counter === 7) {
      setPlaying(quad5);
    // setSounding(quad5sound);
      playAnimationAndSound();
      setCounter(counter + 1);
    }
  };

  const updateContent = (newCounter) => {
    switch (newCounter) {
      case 2:
      setPlaying(quad1);
      break;
      case 3:
        setPlaying(quad2);
        break;
      case 4:
        setPlaying(quad3);
        break;
      case 5:
        setPlaying(quad4);
        break;
      case 6:
        setPlaying(quad5);
        break;
        case 7:
          setPlaying(quad6);
          break;
       
      default:
        break;
    }
    playAnimationAndSound();
  };

  const playinitialSound = () => {
    if (audioref.current) {
      audioref.current.play();
    }
  };

  const playAnimationAndSound = () => {
    setTimeout(() => {
      if (animationRef.current) {
        animationRef.current.play();
      }
      if (audioref2.current) {
        audioref2.current.play();
      }
    }, 500);
    if(!navigation){
      settopanime(questionbar)

    }
    setTimeout(() => {
      setbottomanime(attemptbar);
    }, 1000);
  };

  const handleDataFromChild = (data1, data2) => {
    setDataFromChild(data1, data2);
    if (data1) {
      setnavigation(true);
    }
    
    setscorevalue(prevScore => {
      if (data2 === 1 && lastIncrementedData !== data1) {
        setLastIncrementedData(data1);
        return prevScore + 5;
      } else if (data2 === 0) {
        return prevScore - 1;
      }
      return prevScore;
    });
  };

  const goback = () => {
    setCounter((prevCounter) => {
      const newCounter = prevCounter - 1;
      updateContent(newCounter);
      return newCounter;
    });
    setTimeout(() => {
      if (animationRef.current) {
        animationRef.current.stop();
        updateContent2();

      }
    }, 500);
    
    

    
  };

  const handleanimationplay = () => {
    if (animationRef.current) {
      animationRef.current.play();
    }
  };
  
  const handleAudioLoad = () => {
    if (audioref2.current) {
      if (counter > 1) {
        audioref2.current.play().catch((error) => {
          console.error("Audio play interrupted:", error);
        });
      }
    }
  };

  const repeatanimation = () => {
    if (animationRef.current) {
      animationRef.current.stop();  // Reset the animation
      animationRef.current.play();  // Play the animation from the start
    }
    if (audioref2.current) {
      audioref2.current.play();  // Play the audio
    }
    playnarrator();
    playsound();
  };

  const playnarrator = () => {
    setTimeout(() => {
      if (animationRef.current) {
        animationRef.current.play();
      }
    }, 500);
  };

  const playsound = () => {
    if (audioref2.current) {
      audioref2.current.play();
    }
  };

  const handleAudioLoad3 = () => {
    if (audioref.current) {
      if (counter > 1) {
        audioref.current.play().catch((error) => {
          console.error("Audio play interrupted:", error);
        });
      }
    }
  };

  const buttonStyle = {
    width: '200px',
    height: '100px',
    padding: "20px 20px",
    borderRadius: "20px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "20px",
  };

  const playit = () => {
    setTimeout(() => {
      if (animationRef.current) {
        animationRef.current.play();
      }
    }, 500);
   
  };

const place2execute = () => {
  
  if(place2===option2){
    setanswerset(false);

    setPlaying(wronganswer);
    setSounding(wrongsound);
    soundit();
    playit();
    gsap.to(opt2ref.current, { backgroundColor: 'red', duration:1,delay:0.5,ease:'power2.inOut' });
    gsap.to(opt2ref.current, { color: 'white', duration:1,delay:0.5,ease:'power2.inOut' });
  }
  else if(place2===option4){
    setPlaying(correctanswer);
    setSounding(correctsound);
    soundit();
    playit();
    setnavigation(true);
    gsap.to(opt2ref.current, { backgroundColor: 'green', duration:1,delay:0.5,ease:'power2.inOut' });
    gsap.to(opt2ref.current, { color: 'white', duration:1,delay:0.5,ease:'power2.inOut' });
  }
}


const place3execute = () => {
  
 
}
const place4execute = () => {
  
 
}
const place1execute = () => {
  
  if(place1===option1){

    setprogressvalue(10);
    if(answerset){
      setscorevalue(scorevalue+5)

    }
    setPlaying(correctanswer);
    setSounding(correctsound);
    soundit();
     playit();
     setnavigation(true);
     gsap.to(opt1ref.current, { backgroundColor: 'green', duration:1,delay:0.5,ease:'power2.inOut' });
     gsap.to(opt1ref.current, { color: 'white', duration:1,delay:0.5,ease:'power2.inOut' });
  }
  else if(place1 ===option3){
    setanswerset(false);

    setPlaying(wronganswer2);
    setSounding(wrongsound2);
    soundit();
     playit();
     gsap.to(opt1ref.current, { backgroundColor: 'red', duration:1,delay:0.5,ease:'power2.inOut' });
     gsap.to(opt1ref.current, { color: 'white', duration:1,delay:0.5,ease:'power2.inOut' });
  }
 
 
}

  
  const soundit = () => {
    setTimeout(() => {
      if (audioref2.current) {
        audioref2.current.play();
      }
    }, 500);
   
  };
  const nextPageRoute = () => {
    return <Link to="/subtopics" />;
  };

  return (
<>
    {isMobile? (

<div style={{display: "flex",background:"white",overflow:"hidden", position:'relative'}}>

<>
{isLoading? (
 <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
 <MathLoader/>

 </div>
):(
<div>

<div>
      <div style={{ height: "100vh", width: "100vw", backgroundColor: "#E7E3BD" }}>
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100%' }}>
          <Row className="justify-content-center flex-wrap">
            <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
              <Card style={{ width: '400px',height:'550px'}}>
              <Card.Header style={{height:'130px',backgroundColor:'skyblue'}}>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
<div style={{ display: 'flex',flexDirection:'column', fontFamily:'helvetica',fontWeight:'bold' }}>
 <div style={{fontSize:'20px',marginTop:'10px'}}>
  {subtopicno}
 </div>
 <div style={{fontSize:'15px',marginTop:'20px',marginLeft:'8px'}}>
  INTRODUCTION
 </div>
 
 <div style={{display:'flex',flexdirection:'column',fontSize:'15px'}}>
 <div style={{width: '1px', height: '40px', marginTop:'14px',backgroundColor: 'black', margin: '0 15px'}}></div>

<div style={{ width: '200px',marginTop:'8px',marginLeft:'5px' }}> 
<ProgressBar progress={progressvalue} label="Completed"/>

</div>





 </div>
 
  {/* New Row with Text Options */}


  {/* Original Row with Equations */}
  


</div>

<div style={{alignItems:'center'}}>
   <div style={squareStyle}>
   <div style={scoreStyle}>Score</div>
   <div style={scoreStyle}>{scorevalue}</div>
 </div>
 </div>
</div>

</Card.Header>


                <Card.Body className="text-center">
                  {topanime===questionbar && (
   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize:'20px',fontWeight:'bold',fontFamily:'helvetica' }}>
   <div 
     onClick={place1execute}
     style={{ flex: 1, textAlign: 'center', cursor: 'pointer',backgroundColor:'#E7E3BD', }}
     ref={opt1ref}

  >
    {place1}
    </div>

   {/* Middle Divider */}
   <div style={{ width: '1px', height: '20px', backgroundColor: '#000', margin: '0 20px' }}></div>

   <div 
     onClick={place2execute} 
     style={{ flex: 1, textAlign: 'center', cursor: 'pointer',backgroundColor:'#E7E3BD', }}
     ref={opt2ref}

   >
    {place2}
   </div>
   {place4active && (
    <div 
     onClick={place3execute} 
     style={{ flex: 1, textAlign: 'center', cursor: 'pointer',backgroundColor:'#E7E3BD', }}
     ref={opt3ref}

   >
    {place3}
   </div>
   )}
     {place4active && (
    <div 
     onClick={place4execute} 
     style={{ flex: 1, textAlign: 'center', cursor: 'pointer',backgroundColor:'#E7E3BD', }}
     ref={opt4ref}

   >
    {place4}
   </div>
   )}
   
 </div>
                  )}
           
           <>
                        
                        {activateSwipeMatch? (
       <div style={{position:'fixed',width:'400px',height:'400px'}}>
       <SwipeMatch sendDataToParent={handleDataFromChild} />

       </div>
                        ): (
                         <div style={{marginbottom:'200px'}} className="math-learning">
                         <Player
       ref={animationRef}
       autoplay={false}
       loop={false}
       keepLastFrame={true}
       src={playing}
       onLoadedData={handleanimationplay}
       className={isMobile ? 'mobile-animation' : 'desktop-animation'}
       style={{ height: '400px' }}
       />
       
                         </div>
                        )}
                               </>
       
                </Card.Body>
               


               
              </Card>
            </Col>
          </Row>
        </Container>
        <Container fluid className="fixed-bottom bg-light p-3">

<Row >

  <Col xs={15} md={7} lg={5}  >
    <Card style={{ width: 'auto' }}>
      <Card.Footer style={{backgroundColor:'#E7E3BD'}} className="d-flex justify-content-between">
           
      <div className="d-flex justify-content-between align-items-center w-100">
      {navigation ? (
<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
  <div>
    <CircularButtonback onClick={goback} />
  </div>
  <div onClick={repeatanimation} style={{ cursor: 'pointer', textAlign: 'center' }}>
    <Player
      ref={rptref}
      autoplay={false}
      loop={false}
      keepLastFrame={true}
      hover={true}
      src={rpt}
      style={{ width: '100px', height: '70px' }}
    />
  </div>
  <div>
    <CircularButton className='ml-auto' onClick={next} />
  </div>
</div>
) : 

(


 <div style={{  alignItems:'center'}}>
            <>
            {topanime===questionbar? (
               <div 
               ref={startref}
               onClick={playAnimationAndSound} 
               style={{ 
                 cursor: 'pointer',
                // backgroundColor: 'grey',
                 borderRadius: '10px',
                 width: '300px',
                 height: '50px',
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center',
                 color: 'red',
                 fontWeight: 'bold',
                 fontSize: '18px',
                 marginTop:'10px'
               }}
             >
               Attempt question to proceed
             </div>
            ):(
<div onClick={playAnimationAndSound} style={{ cursor: 'pointer' }}>
              <Player
                ref={startref}
                autoplay={true}
                loop={true}
                keepLastFrame={true}
                src={bottomanime}
                style={{ width: '250px', height: '100px' }}
              />
            </div> 
            )} 
                            </>

<div>


</div>
            </div>



)}
</div>
      </Card.Footer>
    </Card>
  </Col>



  
</Row>
</Container>
        <Card.Footer>

</Card.Footer>
      </div>

    <div>
      <Player>
        <audio ref={audioref} loop={true} src={wave} type="audio/mp3" onLoadedData={handleAudioLoad3} />
      </Player>
    </div>
    <div>
      <Player>
        <audio autoplay={true} ref={audioref2} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
      </Player>
    </div>
    <div>
    
    </div>
  </div>
     
  </div>
)
}</>

</div>




    ): (
      <div>

      <>
      {isLoading? (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
        <MathLoader/>

        </div>
  
      ):(
    <div>
    
    <div>
            <div style={{ height: "100vh", width: "100vw", backgroundColor: "#E7E3BD" }}>
              <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '30vh' }}>
                <Row className="justify-content-center flex-wrap">
                  <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
                    <Card style={{ width: '1400px',left:'100px',bottom:'10px',height:'auto' }}>
                    <Card.Header style={{height:'80px'}}>
      <div style={{ display: 'flex', fontFamily:'helvetica',fontWeight:'bold' }}>
       <div style={{fontSize:'30px',marginTop:'10px', marginLeft:'10px'}}>
        {subtopicno}
       </div>
       <div style={{fontSize:'15px',marginTop:'20px',marginLeft:'8px'}}>
        INTRODUCTION
       </div>
       
       <div style={{display:'flex',flexdirection:'column',fontSize:'15px'}}>
       <div style={{width: '1px', height: '40px', marginTop:'14px',backgroundColor: 'black', margin: '0 15px'}}></div>
  
    <div style={{ width: '200px',marginTop:'15px',marginLeft:'5px' }}> 
    <ProgressBar progress={progressvalue} label="Completed"/>
  
  </div>
      
     
      <div style={{alignItems:'center',}}>
         <div style={squareStyle}>
         <div style={scoreStyle}>Score</div>
         <div style={scoreStyle}>{scorevalue}</div>
       </div>
       </div>
  
     
       </div>
       
        {/* New Row with Text Options */}
      
    
        {/* Original Row with Equations */}
        
    
    
      </div>
    </Card.Header>
    
    
                      <Card.Body className="text-center">
                        {topanime===questionbar && (
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize:'30px',fontWeight:'bold',fontFamily:'helvetica' }}>
         <div 
           onClick={place1execute}
           style={{ flex: 1, textAlign: 'center', cursor: 'pointer',backgroundColor:'#E7E3BD',borderRadius:'10px' }}
           ref={opt1ref}
    
        >
          {place1}
          </div>
    
         {/* Middle Divider */}
         <div style={{ width: '1px', height: '20px', backgroundColor: '#000', margin: '0 20px' }}></div>
    
         <div 
           onClick={place2execute} 
           style={{ flex: 1, textAlign: 'center', cursor: 'pointer',backgroundColor:'#E7E3BD',borderRadius:'10px' }}
           ref={opt2ref}
    
         >
          {place2}
         </div>
         {place4active && (
          <div 
           onClick={place3execute} 
           style={{ flex: 1, textAlign: 'center', cursor: 'pointer',backgroundColor:'#E7E3BD',borderRadius:'10px' }}
           ref={opt3ref}
    
         >
          {place3}
         </div>
         )}
           {place4active && (
          <div 
           onClick={place4execute} 
           style={{ flex: 1, textAlign: 'center', cursor: 'pointer',backgroundColor:'#E7E3BD',borderRadius:'10px' }}
           ref={opt4ref}
    
         >
          {place4}
         </div>
         )}
         
       </div>
                        )}
                        <>
                        
                 {activateSwipeMatch? (

<SwipeMatch sendDataToParent={handleDataFromChild} />
                 ): (
                  <div style={{marginbottom:'200px'}} className="math-learning">
                  <Player
ref={animationRef}
autoplay={false}
loop={false}
keepLastFrame={true}
src={playing}
onLoadedData={handleanimationplay}
className={isMobile ? 'mobile-animation' : 'desktop-animation'}
style={{ height: '400px' }}
/>

                  </div>
                 )}
                        </>

                       
                      </Card.Body>
                     
    <Card.Footer>
      <div>
      {navigation ? (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <div>
          <CircularButtonback onClick={goback} />
        </div>
        <div onClick={repeatanimation} style={{ cursor: 'pointer', textAlign: 'center' }}>
          <Player
            ref={rptref}
            autoplay={false}
            loop={false}
            keepLastFrame={true}
            hover={true}
            src={rpt}
            style={{ width: '100px', height: '70px' }}
          />
        </div>
        <div>
          <CircularButton className='ml-auto' onClick={next} />
        </div>
      </div>
    ) : 
    
      (
    
    
       <div style={{  alignItems:'center'}}>
                  <>
                  {topanime===questionbar? (
                     <div 
                     ref={startref}
                     onClick={playAnimationAndSound} 
                     style={{ 
                       cursor: 'pointer',
                      // backgroundColor: 'grey',
                       borderRadius: '10px',
                       width: '300px',
                       height: '50px',
                       display: 'flex',
                       justifyContent: 'center',
                       alignItems: 'center',
                       color: 'red',
                       fontWeight: 'bold',
                       fontSize: '18px',
                       marginTop:'10px'
                     }}
                   >
                     Attempt question to proceed
                   </div>
                  ):(
  <div onClick={playAnimationAndSound} style={{ cursor: 'pointer' }}>
                    <Player
                      ref={startref}
                      autoplay={true}
                      loop={true}
                      keepLastFrame={true}
                      src={bottomanime}
                      style={{ width: '300px', height: '100px' }}
                    />
                  </div> 
                  )} 
                                  </>
  
    <div>
     
   
    </div>
                  </div>
    
      
    
      )}
      </div>
    </Card.Footer>
    
                     
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
    
          <div>
            <Player>
              <audio ref={audioref} loop={true} src={wave} type="audio/mp3" onLoadedData={handleAudioLoad3} />
            </Player>
          </div>
          <div>
            <Player>
              <audio autoplay={true} ref={audioref2} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
            </Player>
          </div>
          <div>
            {isMobile ? (
              <Container  fluid className="fixed-bottom">
                <Row >
                  <Col xs={6} className="text-right">
                    <CircularButtonback isDisabled={isDisabled} onClick={goback} />
                  </Col>
                  <Col xs={6} className="text-left">
                    <CircularButton ref={nextbuttonref} onClick={next} animation={buttonAnimation} isDisabled={isDisabled} />
                  </Col>
                </Row>
              </Container>
            ) : (
         
    <div>
     
    
      </div>
    
    
    
            )}
          </div>
        </div>
           
        </div>
      )
    }</>
    
    </div>
    )}
    </>

    

  );
};

export default MathLearning2;
