import React, { useState, useEffect } from 'react';
import { Player } from "@lottiefiles/react-lottie-player";
import logo from '../jsons/scistepslogo2.json';

const LoadingPage = ({ nextPageRoute }) => {
  const [timer, setTimer] = useState(5); // 4-second timer
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      setRedirect(true);
    }

    return () => clearInterval(intervalId);
  }, [timer]);

  if (redirect) {
    return <>{nextPageRoute()}</>;
  }

  return (
    <div>
      <Player
        autoplay={true}
        loop={true}
        src={logo}
        style={{ width: '400px', height: '650px' }}
      />
    </div>
  );
};

export default LoadingPage;
