import React, { useEffect, useRef, useState } from "react";
import crosses from '../jsons/crossesb.json';
import crosses2 from '../jsons/crossesb.json';
import crosses3 from '../jsons/crossesb.json';
import { gsap } from "gsap";
import { Draggable } from "gsap/all";
import wrong from '../jsons/wrongmove.json';
import divide from '../jsons/Division.json';
import '../index.css';
import sub from '../jsons/subtraction.json';
import { getFirestore, doc, updateDoc, increment } from 'firebase/firestore';
import { app } from '../Firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import bballf from '../jsons/bbf12.json';
import bballp from '../jsons/bbp12.json';
import bballf2 from '../jsons/bbfail2.json';
import bbp2 from '../jsons/bbp2.json';
import congs from '../jsons/congs.json';
import { Player } from "@lottiefiles/react-lottie-player";
import hint from '../jsons/hint2.json';
import hint1 from '../jsons/hinter.json';
import hinter2 from '../jsons/hinter2.json';
import correctsound from '../jsons/correct.mp3';
import wrongsound from '../jsons/wrong.mp3';
import { Container, Row, Col,Card } from 'react-bootstrap';

gsap.registerPlugin(Draggable);

const Component10 = ({ variableValue, oneValue, fiveValue, sign1, sign2 ,finished ,sendDataToParent }) => {
  const xRef = useRef(null);
  const plusRef = useRef(null);
  const oneRef = useRef(null);
  const equalRef = useRef(null);
  const crossesref = useRef(null);
  const crosses2ref = useRef(null);
  const crosses3ref = useRef(null);
  const upperanimref = useRef(null);
  const bballref = useRef(null);
  const hintref = useRef(null);
  const hinterref = useRef(null);
  const db = getFirestore(app);
  const minusRef = useRef(null);
  const fiveRef = useRef(null);
  const lowref = useRef(null);
  const extraref = useRef(null);
  const audioref = useRef(null);

 // const [finished, setFinished] = useState(false);
 const [responsesound, setresponsesound] = useState(wrongsound);

  const [M1, setM1] = useState(0);
  const [Mn, setMn] = useState(0);
  const [bball, setbball] = useState(null);
  const [clones, setClones] = useState(Array(5).fill(null));
  const [counter, setCounter] = useState(0);

  const [clones2, setClones2] = useState([]);

  const [cloneTopPosition, setCloneTopPosition] = useState(250); // Initial top position for the first clone
  const [cloneTopPositionm, setCloneTopPositionm] = useState(450); // Initial top position for the first clone

  const [doubleclicked, setdoubleclicked] = useState(false);

  const [showVariableValue, setShowVariableValue] = useState(true);
  const [showMinus, setShowMinus] = useState(false);
  const [showPlus, setShowPlus] = useState(true);
 // const [onetilecolor, setonetilecolor] = useState('white');
  const [fivetilecolor, setfivetilecolor] = useState('white');
  const [low, setlow] = useState(wrong);
  const [ans, setAns] = useState(wrong);
  const [upperanimations, setupperanimations] = useState(null);
  const [Showvariable, setvariable] = useState(true);
  const [uM1, setuM1] = useState(null);
  const [uMn, setuMn] = useState(null);
  const [showhint, setshowhint] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [onetilecolor, setonetilecolor] = useState('white');
  const [twotilecolor, settwotilecolor] = useState('black');
  const [onecolor, setonecolor] = useState('black');
  const [twocolor, settwocolor] = useState('white');
  const [hinters, sethinters] = useState(hint1);
  const [cloneref, setcloneref] = useState(hint1);

  const [sign1color, setsign1color] = useState('blue');
  const [sign2color, setsign2color] = useState('red');
  const auth = getAuth();
const currentUser = auth.currentUser;
const currentUserId = currentUser.uid;

  const incrementM1 = () => {
    setM1(prevM1 => {
      const newM1 = prevM1 + 1;
      updateM1InFirestore(newM1).then(() => {
        // code to be executed after the update is complete
      }).catch(error => {
        console.error('Error updating M1:', error);
      });
      return newM1;
    });

  };
  const incrementuM1 = () => {
    setuM1(uM1 => {
      
      const newuM1 = uM1 + 1;
  //    onUpdateUM1(newuM1); // Call the callback prop
      return newuM1;
    });
  };

  const playhinter = () => {
   
    if(hinterref.current){
      hinterref.current.play();
    }

  };


  const incrementuMn = () => {
    setuMn(uMn => {
      const newuMn = uMn + 1;
    //  onUpdateUMn(newuMn); // Call the callback prop
      return newuMn;
    });
  };


  const enablehint = () => {
  
    decreaseM1();
    setshowhint(true);
   // gsap.to(one, { backgroundColor: 'blue', duration: 1 });
    setTimeout(() => {
playhinter();

}, 500);

setTimeout(() => {
  setshowhint(false);
}, 5000);


  };
  const decreaseM1InFirestore = async (newM1Value) => {
    try {
      const userDocRef = doc(db, 'users', currentUserId);
      await updateDoc(userDocRef, { M1: increment(-1) });
      console.log('M1 updated successfully');
    } catch (error) {
      console.error('Error updating M1:', error);
    }
  };
  const decreaseM1 = () => {
    setM1(prevM1 => {
      const newM1 = prevM1 - 1;
      decreaseM1InFirestore(newM1).then(() => {
        // code to be executed after the update is complete
      }).catch(error => {
        console.error('Error updating M1:', error);
      });
      return newM1;
    });

  };

  const updateM1InFirestore = async (newM1Value) => {
  try {
    const userDocRef = doc(db, 'users', currentUserId);
    await updateDoc(userDocRef, { M1: increment(1) });
    console.log('M1 updated successfully');
  } catch (error) {
    console.error('Error updating M1:', error);
  }
};

  

  const incrementMn = () => {
    setMn(prevMn => {
      const newMn = prevMn + 1;
      updateMnInFirestore(newMn).then(() => {
        // code to be executed after the update is complete
      }).catch(error => {
        console.error('Error updating Mn:', error);
      });
      return newMn;
    });
  };
  
  const playbball = () => {
    setTimeout(() => {
      if (bballref.current) {
        bballref.current.play();
       // setbball(bballf); // or setbball(bballp)
      }
    }, 500);
  };
  
  const playwrongbball = () => {
   // setbball(bballf);
    setTimeout(() => {
      if (bballref.current) {
        bballref.current.play();
       // setbball(bballf); // or setbball(bballp)
      }
    }, 500);
  };
  const updateMnInFirestore = async (newMnValue) => {
    try {
      const userDocRef = doc(db, 'users', currentUserId);
      await updateDoc(userDocRef, { Mn: increment(1) });
      console.log('Mn updated successfully');
    } catch (error) {
      console.error('Error updating Mn:', error);
    }
  };
  


  const handleWrongMove = () => {
    setbball(bballf);
    playbball();
  };
  
  const handleWrongMove2 = () => {
    setbball(bballf);
    playbball();
  };
  const handleCorrectMove = () => {
    setbball(bballp);
    playbball();
  };
  const handleCorrectMove2 = () => {
    setbball(bballp);
    playbball();
  };

  const playsound = () => {
    setTimeout(() => {
     if(audioref.current){
       audioref.current.play();
     }
    }, 700);
   
 
   };
  
  useEffect(() => {
    if(doubleclicked){
      
      setTimeout(() => {
        const firstClone = clones[0];
        const secondclone = clones[1];
  
        // firstClone.style.backgroundColor = 'yellow';
          //const firstClone2 = clones2[0];
        // secondclone.style.backgroundColor = 'red';
        // firstClone.style.top = "-20px";
         gsap.to(firstClone, { y: -70, duration: 1.5 });

      }, 1000);
       


    }
   
  }, [clones]); // Run this effect when the clones array changes
  
  useEffect(() => {
    const one = oneRef.current;
    const equal = equalRef.current;
    const five = fiveRef.current;
    const extra = extraref.current;

    const minus = minusRef.current;
    const x = xRef.current;
    const plus = plusRef.current;
    const fivePosition = five.getBoundingClientRect().right;
    const equalPosition = equal.getBoundingClientRect().left;

    if (variableValue === 1) {
      setShowVariableValue(false);
    }

    const draggableOneInstance = Draggable.create(one, {
      type: "x",
      edgeResistance: 0.65,
     
      onDragEnd: function() {
        const onePosition = this.target.getBoundingClientRect().right;

        if (onePosition > fivePosition) {
          sethinters(hinter2);
        //  gsap.to(five, { backgroundColor: 'yellow', duration: 1 });
        //  gsap.to(five, { backgroundColor: 'white', duration: 1,delay:5 });


           
        setresponsesound(correctsound);
        playsound();
        
          setTimeout(() => {
            cloneit();
          }, 1200);
          incrementM1();
          incrementuM1();
          setAns(sub);
          handleCorrectMove();
          gsap.to(upperanimref.current, { opacity: 100, duration: 0.5 });
          const place1 = extra.offsetLeft - one.offsetLeft;
          gsap.to(one, { x: place1, duration: 1.5 });
          gsap.to(one, { color: 'black', duration: 1 });
          gsap.to(one, { backgroundColor: 'white', duration: 1 });
         // gsap.to(five, { color: 'white', duration: 2 });
        //  gsap.to(five, { backgroundColor: 'black', duration: 2 });
        //  gsap.to(minus, { color: 'white', duration: 2 });
         // gsap.to(minus, { backgroundColor: 'black', duration: 2 });
          gsap.to(x, { x: 90, duration: 1.5 });
          gsap.to(minus, { opacity: 100, duration: 1, delay: 0.3 });
          gsap.to(plus, { opacity: 0, duration: 0.5, delay: 0.2 });

          gsap.to(one, { opacity: 0, duration: 0.5, delay: 2 });
          gsap.to(minus, { opacity: 0, duration: 0.5, delay: 2 });
        //  gsap.to(five, { color: 'black', duration: 1, delay: 3 });
        //  gsap.to(five, { backgroundColor: 'white', duration: 1, delay: 3 });
          if(variableValue===1){
finished='true';

          }
          setTimeout(() => {
            
            if (sign2 === '+') {
              const newValue = +fiveValue + +oneValue; // Convert strings to numbers
              fiveRef.current.textContent = newValue;
              
            } else if (sign2 === '-') {
              const newValue = +fiveValue - +oneValue; // Convert strings to numbers
              fiveRef.current.textContent = newValue;
             // setCompleted(true);

            }
          }, 2300);
        } else {
          setresponsesound(wrongsound);
          playsound();
          handleWrongMove2();
          incrementMn();
          incrementuMn();
          setAns(wrong);
          setbball(bballf);
          playbball();
          const placeorigin = one.offsetLeft - one.offsetLeft;
          gsap.to(one, { x: placeorigin, duration: 0.5 });
          gsap.to(upperanimref.current, { opacity: 100, duration: 0.5 });
          gsap.to(upperanimref.current, { opacity: 0, duration: 0.5, delay: 1.5 });
        }
        
        const handleDoubleClick = () => {
          setdoubleclicked(true);
        sethinters(hint1);
          setresponsesound(correctsound);
          playsound();
        
          setTimeout(() => {
           // cloneit2();
          }, 1000);
        
          if (cloneref.current) {
            gsap.to(cloneref.current, { backgroundColor: 'blue', duration: 0.5 });
            console.log('clone active');
          }
        
          incrementM1();
          incrementuM1();
          if (onePosition > fivePosition) {
            if (variableValue > 1) {
              handleCorrectMove2();
              setbball(bballp);
              setAns(divide);
              setlow(congs);
              gsap.to(x, { backgroundColor: "grey", duration: 0.5 });
              gsap.to(x, { color: "black", duration: 0.5, delay: 0.5 });
              console.log("double clicked");
        
              const xValueElement = document.createElement("div");
              xValueElement.textContent = variableValue;
              xValueElement.style.padding = "5px";
              xValueElement.style.color = "#585757";
              xValueElement.style.fontSize = "20px";
              xValueElement.style.zIndex = "5";
              xValueElement.style.border = "2px solid #000";
              xValueElement.style.backgroundColor = "white";

              // let cardBody = document.querySelector("#cont3").parentNode;

              xValueElement.style.cursor = "move";
              if (isMobile) {
                xValueElement.style.marginLeft = "50px";
                xValueElement.style.position = `absolute`;
                xValueElement.style.left = `+25px`;

                const cont2 = document.querySelector('#cont2');
              
                cont2.appendChild(xValueElement);

              } else {
                xValueElement.style.position = `absolute`;

                xValueElement.style.left = `${x.getBoundingClientRect().left + 15}px`;
                xValueElement.style.top = `420px`;
                document.body.appendChild(xValueElement);

              }
        
        
              const draggableXValueInstance = Draggable.create(xValueElement, {
                type: "x",
                edgeResistance: 0.65,
                onDragEnd: function () {
                  const xValue = parseFloat(this.target.textContent);
                  const xValuePosition = this.target.getBoundingClientRect().left;
                  const newone = fiveRef.current.textContent;
        
                  if (xValuePosition > equalPosition) {
                    setdoubleclicked(false);
                    setresponsesound(correctsound);
                    playsound();
                    setTimeout(() => {
                    //  cloneit();
                    }, 1000);
                    gsap.to(x, { backgroundColor: "white", duration: 0.5 });
                    gsap.to(x, { color: "black", duration: 0.5, delay: 0.5 });
                    gsap.to(five, { backgroundColor: "white", duration: 0.5 });
                    gsap.to(five, { color: "black", duration: 0.5, delay: 0.5 });
                    gsap.to(xValueElement, { x: 80, duration: 1 });
                    handleCorrectMove();
                    setTimeout(() => {
                      sendData();
                    }, 2000);
        
                    setTimeout(() => {
                      const new2 = newone / xValue;
                      fiveRef.current.textContent = new2;
                      gsap.to(xValueElement, { opacity: 0, duration: 0.3, ease: "power2.inOut" });
                    }, 2000);
        
                    setTimeout(() => {
                      if (lowref.current) {
                        lowref.current.play();
                      }
                    }, 2500);
        
                    setTimeout(() => {
                      setShowVariableValue(false);
                    }, 2500);
        
                    setTimeout(() => {
                      criscross();
                    }, 300);
                    setTimeout(() => {
                      criscross2();
                    }, 1000);
        
                    this.disable();
                  } else {
                    setresponsesound(wrongsound);
                    playsound();
                    handleWrongMove2();
                    incrementMn();
                    setAns(wrong);
                    setbball(bballf);
                    playbball();
                  }
                },
              });
            }
          } else {
            incrementMn();
            incrementuMn();
          }
        };
        
        x.addEventListener("dblclick", handleDoubleClick);

        return () => {
          x.removeEventListener("dblclick", handleDoubleClick);
        };
      },
    });

    const draggablexInstance = Draggable.create(x, {
      type: "x",
      edgeResistance: 0.65,
     
      onDragEnd: function() {
        const onePosition = this.target.getBoundingClientRect().right;

     
          const placeorigin2 = x.offsetLeft - x.offsetLeft;
          gsap.to(x, { x: placeorigin2+93, duration: 0.5 });
       //   gsap.to(upperanimref.current, { opacity: 100, duration: 0.5 });
        //  gsap.to(upperanimref.current, { opacity: 0, duration: 0.5, delay: 1.5 });
        setAns(wrong);
       // setbball(bballf);
       if(bball===bballf){
        playwrongbball();
        setTimeout(() => {
          setbball(bballf2);
        }, 3000);
console.log('first fail');
       }
       else{
       // setbball(bballf);
        setTimeout(() => {
          playwrongbball();
        }, 500);
        setTimeout(() => {
          setbball(bballf);
        }, 3000);
        console.log('second fail');

       }

     
      },
    });

    const draggablefiveInstance = Draggable.create(five, {
      type: "x",
      edgeResistance: 0.65,
     
      onDragEnd: function() {
        const onePosition = this.target.getBoundingClientRect().right;

     
          const placeorigin3 = five.offsetLeft - five.offsetLeft;
          gsap.to(five, { x: placeorigin3, duration: 0.5 });
       //   gsap.to(upperanimref.current, { opacity: 100, duration: 0.5 });
        //  gsap.to(upperanimref.current, { opacity: 0, duration: 0.5, delay: 1.5 });
      
        if(bball===bballf){
          playwrongbball();
          setTimeout(() => {
            setbball(bballf2);
          }, 3000);
  console.log('first fail');
         }
         else{
         // setbball(bballf);
          setTimeout(() => {
            playwrongbball();
          }, 500);
          setTimeout(() => {
            setbball(bballf);
          }, 3000);
          console.log('second fail');
  
         }     
      },
    });


  }, [bball]);

  useEffect(()=> {
    if(sign1==='-'){
      setsign1color('red');
  
    }
    else{
      setsign1color('blue');
  
    }
  },[sign1color]);
  useEffect(()=> {
    if(sign2==='-'){
      setsign2color('red');
  
    }
    else{
      setsign2color('blue');
  
    }
  },[sign2color]);
  useEffect(() => {
    setbball(bballf); // or setbball(bballp)
  }, [bballref]);
  
   useEffect(() => {
  

    if(variableValue<2){
      setShowVariableValue(false);
    }

   }, []);


   

  const crossposition = {
    position: 'absolute',
    width: '80px',
    height: '80px',
    bottom: '30px',
    left: '200px',
    zIndex: 2
  };

  const crossposition2 = {
    position: 'absolute',
    width: '80px',
    height: '80px',
    bottom: '50px',
    left: '200px',
    zIndex: 2
  };

  const numbersposition = {
    padding: "10px",
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    border: "1px solid #000",
    userSelect: 'none',
    margin: "5px",
    backgroundColor: twotilecolor,
    color:twocolor,
  };

  const criscross = () => {
    if (crossesref.current) {
      crossesref.current.play();
    }
  };

  const criscross2 = () => {
    if (crosses2ref.current) {
      crosses2ref.current.play();
    }
  };

  const criscross3 = () => {
    if (crosses3ref.current) {
      crosses3ref.current.play();
    }
  };
  useEffect(() => {
    console.log('Updated clones:', clones);
  }, [clones]);
  
  

  const cloneit = () => {
    console.log("cloning");
    let addclone = document.getElementById("cont1");
    let mainone = document.getElementById("main");

    let clone = addclone.cloneNode(true);
    clone.id = 'theclone';
    clone.style.position = "fixed";
    if(isMobile){
      clone.style.bottom = `+100px`; // update top position
      clone.style.left='18px';

    }
    else{
      clone.style.top = `${cloneTopPosition}px`; // update top position
      clone.style.left='1010px';

    }
   // setCloneTopPosition(prevPosition => prevPosition ); // increment for the next clone

    // Access the previous clone and adjust its position
    
   
    // Prepend the clone to the main container

    //adding to card
    if (isMobile) {
      // Insert the clone in the same card holding cont1
      let cardBody = document.querySelector("#cont-1").parentNode;
      cardBody.insertBefore(clone, addclone); // Insert clone before the original cont1
    }
    else{
      mainone.insertBefore(clone, mainone.firstChild);

    }
    // Update the clones state
    setClones([...clones, clone]);
}
  
  const cloneit2 = () => {
    console.log("cloning");
    let addclone = document.getElementById("cont1");
    let mainone = document.getElementById("main");
  
    // Create a new clone
    let clone = addclone.cloneNode(true);
    clone.id = 'theclone';
    clone.style.position = "fixed";
     if(isMobile){
      clone.style.bottom = `+70px`; // update top position
      clone.style.left='18px';

    }
    else{
      clone.style.top = `${cloneTopPosition}px`; // update top position
      clone.style.left='1010px';

    }
    if (isMobile) {
   
    setClones(prevClones => {
      const updatedClones = [...prevClones];
      updatedClones[1] = clone; // Always set the clone at index 0
      return updatedClones;
    });
  
    let cardBody = document.querySelector("#cont1").parentNode;
    cardBody.insertBefore(clone, addclone); // Insert clone before the original cont1


  }
    // Update the cloneTopPosition for the next clone
  
    // Prepend the clone to the main container
    mainone.insertBefore(clone, mainone.firstChild);
  
    
  
    // Update the counter
    setCounter(prevCounter => prevCounter + 1);
  
    // Log clones to the console
  };
  
 
const sendData = () => {
  const data = true;
  sendDataToParent(data);
};
useEffect(() => {
  window.addEventListener('resize', handleResize);
  return () => {
      window.removeEventListener('resize', handleResize);
  };
}, []);
const handleResize = () => {
  setIsMobile(window.innerWidth <= 768);
};



return (
  <div>
    {isMobile ? (
      <div id='main' >
      <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', marginBottom: '10vh' }}>
      <Row className="justify-content-center flex-wrap" >
            <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
            <Card style={{ width: 'auto' ,height:'300px'}}>
            <Card.Body className="text-center">
                <div style={{height:"200px"}}>

                  <Player
                    ref={bballref}
                    autoplay={false}
                    loop={false}
                    keepLastFrame={true}
                    hover={false}
                    src={bball}
                    aspectRatio="3:4"
                    style={{ width: '250px', height: '350px' }}
                    />
                                  </div>

                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={4} lg={5} className="mb-4 d-flex justify-content-center">
              <Card style={{ width: '380px',height:'220px',backgroundColor:' rgba(16, 143, 255, 0.4)' }}>
                <Card.Body >
                  <div style={{display:'flex',flexDirection:'column',}}>
                  <div id='cont-1' style={{padding:'10px',height:'38px',width:'40px',marginLeft:'50px'}}>

</div>
                  <div id='cont1' style={{ display: "flex",fontSize: "18px", padding: '30px',userSelect: 'none' }}>
                    <div ref={xRef} style={{ ...numbersposition }}>
                      {showVariableValue && (
                        <span style={{ userSelect: 'none' }}>{variableValue}</span>
                      )}
                      x
                    </div>
                    <div ref={plusRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', color: 'blue', userSelect: 'none', margin: "5px" }}>
                      {sign1}
                    </div>
                    <div ref={oneRef} style={{ ...numbersposition }}>
                      {oneValue}
                    </div>
                    <div ref={equalRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', color: 'blue', userSelect: 'none', margin: "5px" }}>
                      =
                    </div>
                    <div>
                      <Player style={{ ...crossposition }} ref={crossesref} autoplay={false} loop={false} src={crosses} />
                    </div>
                    <div>
                      <Player style={{ ...crossposition2 }} ref={crosses2ref} autoplay={false} loop={false} src={crosses2} />
                    </div>
                    <div ref={fiveRef} style={{ ...numbersposition }}>
                      {fiveValue}
                    </div>
                    <div ref={minusRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', userSelect: 'none', color: sign2color, margin: "5px", opacity: 0 }}>
                      {sign2}
                    </div>
                    <div ref={extraref} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', border: "1px solid #black", userSelect: 'none', color: 'black', margin: "5px", opacity: 0 }}>
                      5
                    </div>
                  </div>
                  <div id='cont2' style={{position:'absolute',top:'150px',left:'80px',width:'100px',height:'20px'}}>

                  </div>
                  </div>
                

                </Card.Body>
              </Card>
            </Col>
            <Col xs={10} md={4} lg={5}  >
    <Card style={{ width: 'auto',height:'80px',marginBottom:"260px",marginRight:'50px' }}>
      <Card.Body >  
        <div style={{display:"flex"}}>

            <div onClick={enablehint} style={{height:'50px',width:"80px"}}>
              
        <Player ref={hintref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={hint} style={{width:'100px',height:'50px'}}  
 />
        </div> 
        {showhint && (
<div style={{height:'50px',width:"50px",marginBottom:"100px"}} >
<Player ref={hinterref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={hinters} style={{width:'120px',height:'50px'}} 
 />
</div>
)}
        </div>

      </Card.Body>
    </Card>
            </Col>

          </Row>
        </Container>
      </div>
    ): (
        <div id= 'main' className="practicearea">
      
      <div id= 'cont1' className="values" style={{ left:'1000px', position: 'fixed', display: "flex", fontSize: "30px", alignItems: 'center', padding: '30px', userSelect: 'none' }}>
        <div ref={xRef} style={{ ...numbersposition }}>
          {showVariableValue && (
            <span style={{ userSelect: 'none' }}>{variableValue}</span>
          )}
          x</div>
        <div ref={plusRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', color: 'blue', userSelect: 'none', margin: "5px" }}>
          {sign1}</div>
        <div ref={oneRef} style={{ ...numbersposition }}>
          {oneValue}</div>
        <div ref={equalRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', color: 'blue', userSelect: 'none', margin: "5px" }}>
          =</div>
        <div>
          <Player style={{ ...crossposition }} ref={crossesref} autoplay={false} loop={false} src={crosses} />
        </div>
        <div>
          <Player style={{ ...crossposition2 }} ref={crosses2ref} autoplay={false} loop={false} src={crosses2} />
        </div>
        <div  ref={fiveRef} style={{ ...numbersposition }}>
          {fiveValue}
        </div>
        <div ref={minusRef} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', userSelect: 'none', color: sign2color, margin: "5px", opacity: 0 }}>
          {sign2}</div>
        <div ref={extraref} style={{ padding: "10px", fontFamily: 'Helvetica', fontWeight: 'bold', border: "1px solid #black", userSelect: 'none', color: 'black', margin: "5px", opacity: 0 }}>
          5</div>
      </div>
      <div style={{scale:'0.7',position:'fixed',left:'170px',top:'-156px'}}>
      <Player ref={bballref} autoplay={false} loop={false} keepLastFrame={true} src={bball}   // or setbball(bballp)
 />

      </div>

      <div style={{zIndex:5,scale:'0.3',position:'fixed',left:'750px',top:'365px'}}>
        <div onClick={enablehint}>
        <Player ref={hintref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={hint}   // or setbball(bballp)
 />
        </div>
            {showhint && (
              <div style={{position:'fixed',fontFamily:'helvetica',fontSize:'100px',left:'550px',bottom:'40px',color:'black', scale:'3.5' // Added CSS property to prevent wrapping
            }}>
               
               <Player ref={hinterref} autoplay={false} loop={false} keepLastFrame={true} hover={true} src={hinters}   // or setbball(bballp)
 />
                 </div>
            )}

      </div>



    </div>
      )}
  
  <div>
 <Player>
 <audio ref={audioref} src={responsesound} type="audio/mp3" loop={false} />

 </Player>
</div>


    </div>
  



  );
};
export default Component10;
