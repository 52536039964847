import React, { useEffect, useRef, useState } from "react";
import ShadowComponent from './ShadowComponent';
import { gsap } from "gsap";
import falling from '../jsons/fallingoff.json';
import passedto from '../jsons/passedonto.json';
import dbc from '../jsons/dbcit.json';
import Lottie from "react-lottie-player";
import stage2 from '../jsons/stagediv.json';
import stage2b from '../jsons/stagediv2.json';
import stage2c from '../jsons/stagediv3.json';
import stage2d from '../jsons/stagediv4.json';
import stage2e from '../jsons/stagediv5.json';
import stage2f from '../jsons/stagediv6.json';
import { Draggable } from "gsap/all";
gsap.registerPlugin(Draggable);

const Quadraticeqn = () => {
    
    const charref = useRef(null);
    const fourref = useRef(null);
    const sevenref = useRef(null);
    const deg2ref = useRef(null);
    const vvaref = useRef(null);
    
    const operator2ref = useRef(null);
    const operator3ref = useRef(null);

    const voneref = useRef(null);
    const vv = useRef(null);
    const vv2 = useRef(null);

    const vtworef = useRef(null);
    const power = useRef(null);
    const operatorref = useRef(null);
    
    const variablessecond = useRef(null);
    const thirdvarref = useRef(null);

    const containerRef = useRef(null);
    const [split, setSplit] = useState(false);
    const [char, setchar] = useState(falling);
    const [ison, setison] = useState(false);

    const [stage, setstage] = useState(1);

    const [variable1, setvariable1] = useState(2);
    const [variable2, setvariable2] = useState(8);
    const [variable3, setvariable3] = useState(1);
    const [variable4, setvariable4] = useState(4);
    const [lastClickedButton, setLastClickedButton] = useState(null);
    const [showShadow, setShowShadow] = useState(false);
    const [elementsToDuplicate, setElementsToDuplicate] = useState([]);
    const [cloneTopPosition, setCloneTopPosition] = useState(410); // Initial top position for the first clone
    const [clones, setClones] = useState([]); // State to store clones
    
    const [isdeg2on, setisdeg2on] = useState(false); // State to store clones
    const [isthirdvaron, setisthirdvaron] = useState(false); // State to store clones
    const [isvtworefon, setisvtworefon] = useState(false); // State to store clones
    const [isfourrefvaron, setisfourrefvaron] = useState(false); // State to store clones
    const [common, setcommon] = useState(false);

    const [operator1, setoperator1] = useState('+');
    const [operator2, setoperator2] = useState('-');
    const [operator3, setoperator3] = useState('-');
    
    const splitreffs = useRef(null);

    useEffect(() => {
        if (split) {
            gsap.to(splitreffs.current, { color: 'black', duration: 1, delay: 0.5, ease: "power1.inOut" });
        }
    }, [split,vvaref,voneref]);

    const doubleclick = () => {
        setstage(2);
        setSplit(true);
        gsap.to(splitreffs.current, {color:'black' ,duration: 1,delay:2,ease:"power1.inOut"});
        setchar(passedto);
      //  playchar();

        cloneit();
        setTimeout(() => {
            const elements = [];
            containerRef.current.childNodes.forEach(child => {
                if (child.nodeType === 1) { // Node.ELEMENT_NODE
                    const clonedElement = child.cloneNode(true);
                    const computedStyles = window.getComputedStyle(child);
                    elements.push({ element: clonedElement, styles: computedStyles });
                }
            });
            setElementsToDuplicate(elements);
            setShowShadow(true);
        }, 500);
    };
    
    const selectit = () => {
        gsap.to(splitreffs.current, { backgroundColor: 'green', duration:1, delay:1 });
    }
    const splitter3 = () => {
console.log('splitter3')
        if(common){
            cloneit();
           gsap.to(vv2.current, { opacity: '0', duration:1, delay:1 });
            gsap.to(operator2ref.current, { x: -50, duration:1, delay:1 });
            gsap.to(deg2ref.current, { x: +80, duration:1, delay:1 });

            
        }

    }

   
    const cloneit = () => {
        let addclone = document.getElementById("cont1");
        let mainone = document.getElementById("main");
    
        let clone = addclone.cloneNode(true);
        clone.id = 'theclone';
        clone.style.position = "fixed";
        clone.style.left = '400px';
    
        // Calculate the top position for the new clone
        const newTopPosition =  cloneTopPosition ;
        clone.style.top = `${newTopPosition}px`;
    
        // Prepend the clone to the main container
        mainone.insertBefore(clone, mainone.firstChild);
    
        // Update the positions of all previous clones
        for (let i = 0; i < clones.length; i++) {
            clones[i].style.top = `${parseInt(clones[i].style.top) + 70}px`;
        }
    
        // Update the clones state
        setClones([...clones, clone]);
    }
    
    
    const quadposition = {
        height:'50px',
        padding:'4px',
        display: 'flex',
        cursor: 'default',
        userSelect: 'none'
    };
       
    const handleVonerefClick = () => {
        if (lastClickedButton === 'vtworef') {
            splitter();
        }
        setLastClickedButton('voneref');
    };

    const handleVtworefClick = () => {
        if (lastClickedButton === 'voneref') {
            splitter();
        }
        setLastClickedButton('vtworef');
    };

    const handle2ndshiftClick1 = () => {
        if (lastClickedButton === 'fourref') {
            splitter2();
        }
        setLastClickedButton('thirdvarref');
    };
    const handle2ndshiftClick2 = () => {
        if (lastClickedButton === 'thirdvarref') {
            splitter2();
        }
        setLastClickedButton('fourref');
    };
    
    const splitter = () => {
        setchar(stage2b);
      //  playchar();
        // Extract the values of voneref and vtworef
        const vonerefValue = voneref.current.innerText;
        const vtworefValue = vtworef.current.innerText;
        
        console.log('splitter');
        // Create new div elements and add them to the container
        const holderdiv = document.createElement('div');
        holderdiv.style.display = 'flex';
        holderdiv.style.flexDirection = 'row';
        holderdiv.style.margin = '30px';
        holderdiv.style.marginRight = '130px';


        containerRef.current.appendChild(holderdiv);

        const newDivs = [];
        for (let i = 0; i < 3; i++) {
            const newDiv = document.createElement('div');
            // newDiv.style.fontColor = 'grey';
            newDiv.style.padding = '4px';
            newDiv.style.display = 'flex';
            newDiv.style.cursor = 'default';
            newDiv.style.userSelect = 'none';
            newDiv.style.display = 'flex';
            newDiv.style.margin = '10px';
            newDiv.style.fontSize = '20px';
            newDiv.style.color = 'black';
            newDiv.style.width = '30px';
           

           // newDiv.style.marginRight = '130px';

            //newDiv.style.position = 'absolute';
           // newDiv.style.left = '230px';

            const divisor = (newDiv) => {
                newDiv.onclick = () => {
                    if(i === 0) {
                        setstage(4);
                        const dividedvar = variable2/variable1;
                        const together = 'v'+ operator1+ dividedvar;
                        setchar(stage2c);
                    //    playchar();
                        cloneit();
                        newDiv.style.backgroundColor = '#5FB6BB';
                        //here
                        (gsap.to(deg2ref.current, {color:'blue' ,duration: 1,ease:"power1.inOut"}));

                        (gsap.to(power.current, {opacity:0 ,duration: 1,ease:"power1.inOut"}));
                        operatorref.current.textContent='(';

                        (gsap.to(operatorref.current, {fontWeight:'bolder',fontSize:'40px' ,duration: 1,ease:"power1.inOut"}));
                        (gsap.to(operatorref.current, {color:'blue',duration: 0,}));
                        (gsap.to(operatorref.current, {y:'+20',duration: 0,}));
                        setvariable2(together);

                        (gsap.to(vtworef.current, {color:'blue',duration: 0,}));

                        //perfom a calculation
                        vv.current.textContent=')';
                        (gsap.to(vv.current, {fontWeight:'bolder',fontSize:'40px' ,duration: 1,ease:"power1.inOut"}));
                        (gsap.to(vv.current, {color:'blue',duration: 0,}));
                        (gsap.to(vv.current, {y:'+20',duration: 0,}));
                        
                        console.log('powerout');
                        setTimeout(() => {
                            (gsap.to(newDivs, {opacity:0 ,duration: 1,ease:"power1.inOut"}));

                        }, 1000);


                    } else if(i===1){
                        (gsap.to(vvaref.current, {opacity:0 ,duration: 1,ease:"power1.inOut"}));
                        (gsap.to(power.current, {opacity:0 ,duration: 1,ease:"power1.inOut"}));
                    }
                }
            }

            if (i === 0) {
                const ds = voneref.current.textContent;
                const sd = vvaref.current.textContent;
                newDiv.style.border = '3px solid grey';
                newDiv.style.backgroundColor = 'white';
                newDiv.style.color = 'grey';
                newDiv.textContent = ds+sd;
                divisor(newDiv); // Call the divisor function here
                holderdiv.appendChild(newDiv);
            } else if (i === 1) {
                newDiv.innerText = vonerefValue;
                newDiv.style.border = '3px solid grey';
                newDiv.style.backgroundColor = 'white';
                newDiv.style.color = 'grey';
                divisor(newDiv); // Call the divisor function here
                holderdiv.appendChild(newDiv);
            } else {
                newDiv.innerText = "divisors";
                newDiv.style.color = 'black';
                holderdiv.appendChild(newDiv);
            }
            newDivs.push(newDiv);
        }
    };
    const splitter2 = () => {
        // Extract the values of voneref and vtworef
      
        setstage(5);
        setchar(stage2e);
      //  playchar();
        console.log('splitter2');
        // Create new div elements and add them to the container
       
        const divisor2 = (newDiv2) => {
            newDiv2.onclick = () => {
               
                setstage(5);
                setchar(stage2f);
             //   playchar();
                   cloneit();

              
                    operator2ref.current.textContent='-1';
                    fourref.current.textContent=')';
                    thirdvarref.current.textContent='(';
                    operator3ref.current.textContent='v' + operator1+variable4;

             setcommon(true);

                    gsap.to(operator3ref.current, {fontWeight:'bold',fontSize:'30px' ,duration: 0.4,ease:"power1.inOut"});
                    gsap.to(operator3ref.current, {color:'blue' ,duration: 0.4,ease:"power1.inOut"});
                    gsap.to(operator3ref.current, {y:20 ,duration: 0.4,ease:"power1.inOut"});

                    gsap.to(thirdvarref.current, {fontWeight:'bold',fontSize:'30px' ,duration: 0.4,ease:"power1.inOut"});
                    gsap.to(thirdvarref.current, {color:'blue' ,duration: 0.4,ease:"power1.inOut"});
                    gsap.to(thirdvarref.current, {y:20 ,duration: 0.4,ease:"power1.inOut"});

                    gsap.to(fourref.current, {fontWeight:'bold',fontSize:'30px' ,duration: 0.4,ease:"power1.inOut"});
                    gsap.to(fourref.current, {color:'blue' ,duration: 0.4,ease:"power1.inOut"});
                    gsap.to(fourref.current, {y:20 ,duration: 0.4,ease:"power1.inOut"});

                    gsap.to(operator2ref.current, {fontWeight:'bold',fontSize:'30px' ,duration: 0.4,ease:"power1.inOut"});
                    gsap.to(operator2ref.current, {color:'blue' ,duration: 0.4,ease:"power1.inOut"});
                    gsap.to(operator2ref.current, {y:17 ,duration: 0.4,ease:"power1.inOut"});

                    setTimeout(() => {
                        gsap.to(holderdiv2, { opacity: '0', duration:1, });

                    }, 1000);
                
               
                
            }
        }
        const holderdiv2 = document.createElement('div');
        containerRef.current.appendChild(holderdiv2);

            const newDiv2 = document.createElement('div');
            const newDiv3 = document.createElement('div');

            newDiv2.style.padding = '10px';
            newDiv2.style.display = 'flex';
            newDiv2.style.cursor = 'default';
            newDiv2.style.userSelect = 'none';
            newDiv2.style.display = 'flex';
            newDiv2.style.margin = '10px';
            newDiv2.style.fontSize = '20px';
            newDiv2.style.color = 'red';
            newDiv2.style.height = '30px';
            newDiv2.style.width = '30px';
            newDiv2.style.border = '3px solid grey';
            newDiv2.style.left = '800px';

            newDiv2.textContent = '-1';
            holderdiv2.appendChild(newDiv2);

            newDiv2.style.position = 'fixed';
            newDiv3.textContent =  'divisor';
            newDiv3.style.fontSize = '25px';
            newDiv3.style.fontWeight = 'bold';
            newDiv3.style.left = '900px';
            newDiv3.style.top = '370px';
            newDiv3.style.position = 'fixed';

            //holderdiv2.appendChild(newDiv3);

            divisor2(newDiv2);

          

          
        
    };    
    return (
        <div id= 'main' className="button-container">
            <div id= 'cont1' ref={containerRef} style={{ display: 'flex', position:'fixed',top:'338px',left:'400px'}}>
                <div ref={deg2ref}
                    onClick={() => { 
                        if(stage===1){
                            if(ison){
                                charref.current.stop();
                                charref.current.play();
        
                               // playchar();
                               // setison(true);
                            }
                            else{
                                setchar(falling);
                             //   playchar();
                                setison(true);
        
                            }
                        }
                        else if (stage===2){

                            if(ison){
                                charref.current.stop();
                                charref.current.play();
                               // playchar();
                               // setison(true);
                            }
                            else{
                                setchar(stage2);
                             //   playchar();
                                setison(true);
                                setstage(3);

                            }
                        }
                       
                        //charset here
                      
                        if(isdeg2on){
                            gsap.to(deg2ref.current, {color:'black' ,duration: 0.4,ease:"power1.inOut"});
                            setisdeg2on(false);
                            gsap.to(deg2ref.current, {scale:'1' ,duration: 0.4,ease:"power1.inOut"});

                        } else{
                            gsap.to(deg2ref.current, {color:'red' ,duration: 0.4,ease:"power1.inOut"});
                            setisdeg2on(true);
                            gsap.to(deg2ref.current, {scale:'1.2' ,duration: 0.4,ease:"power1.inOut"});

                        }

                      //  gsap.to(deg2ref.current, {color:'red' ,duration: 1,ease:"power1.inOut"});
                        handleVonerefClick();
                        console.log('vone');
                        setTimeout(() => {
                            gsap.to(splitreffs.current, {color:'black' ,duration: 1,ease:"power1.inOut"});
                        }, 1000);
                    }} style={{...quadposition }} className="button" >
                    <div ref={voneref}><h1>{variable1}</h1></div>
                    <div ref={vvaref}><h1>v</h1></div>
                    <div ref={power}><h4>2</h4></div>
                </div>
                <div style={{padding:'10px'}} ref={operatorref}><h1>{operator1}</h1></div>
                {split ? (
                    <div ref={splitreffs} className="split-buttons" style={{ ...quadposition }}>
                        {/* Render each child element individually */}
                        <div style={{...quadposition}}>
                            <div style={{display:'flex'}} ref={vtworef} onClick={() => {
                                console.log('vtwo');

                                if(stage===2){
                                    if(ison){
                                        charref.current.stop();
                                        charref.current.play();
                                       // playchar();
                                       // setison(true);
                                    }
                                    else{
                                        setchar(stage2);
                                      //  playchar();
                                        setison(true);
                                        setstage(3);
        
                                    }
                                }
                                else if(stage===3){

                                    if(ison){
                                        charref.current.stop();
                                        charref.current.play();
                                       // playchar();
                                       // setison(true);
                                    }
                                    else{
                                        console.log('pressed');
                                        setchar(stage2b);
                                      //  playchar();
                                        setison(true);
                                        setstage(4);
        
                                    }

                                }
                               // gsap.to(vtworef.current, {color:' red' ,duration: 1,ease:"power1.inOut"}); 
                                if(isvtworefon){
                                    gsap.to(vtworef.current, {color:'black' ,duration: 0.4,ease:"power1.inOut"});
                                    setisvtworefon(false);
                                    gsap.to(vtworef.current, {scale:'1' ,duration: 0.4,ease:"power1.inOut"});

                                } else{
                                    gsap.to(vtworef.current, {color:'red' ,duration: 0.4,ease:"power1.inOut"});
                                    setisvtworefon(true);
                                    gsap.to(vtworef.current, {scale:'1.2' ,duration: 0.4,ease:"power1.inOut"});

                                }
                               handleVtworefClick();

                                }}>
                                <h1></h1>
                                <div ref={vv2}><h1>{variable2}</h1></div>

                                <div  ref={vv}><h1>v</h1></div>

                            </div>
                            <div>
                                <div ref={operator2ref} style={{margin:'10px'}}>
                                    <h1>{operator2}</h1>
                                </div>
                                <div  ref={variablessecond} style={{visibility:'hidden',...quadposition}}>
                                    {variable3}
                                </div>
                            </div>
                            <div ref={thirdvarref} onClick={() => { 
                                handle2ndshiftClick1();
                                if(isthirdvaron){
                                    gsap.to(thirdvarref.current, {color:'black' ,duration: 0.4,ease:"power1.inOut"});
                                    setisthirdvaron(false);
                                    gsap.to(thirdvarref.current, {scale:'1' ,duration: 0.4,ease:"power1.inOut"});

                                } else{
                                    gsap.to(thirdvarref.current, {color:'red' ,duration: 0.4,ease:"power1.inOut"});
                                    setisthirdvaron(true);
                                    gsap.to(thirdvarref.current, {scale:'1.2' ,duration: 0.4,ease:"power1.inOut"});

                                }
                                 }}  >
                                <h1>v</h1>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div ref={sevenref} onClick={() => { 


                        setchar(dbc);
                    //    playchar();
                        gsap.to(sevenref.current, {color:'red' ,duration: 0.4,ease:"power1.inOut"}); }}style={{ ...quadposition }} className="button" onDoubleClick={doubleclick}>
                        <div><h1>7</h1></div>
                        <h1>v</h1>
                    </div>
                )}
                <div ref={operator3ref} onClick={splitter3} style={{padding:'10px',userselect:'none'  }}>
                    <h1>{operator3}</h1></div>
                <div ref={fourref} onClick={() => { 
                    if(stage===4){
                        setchar(stage2d);
                      //  playchar();

                    }
                    else{
                        if(ison){
                            charref.current.stop();
                            charref.current.play();
    
                           // playchar();
                           // setison(true);
                        }
                        else{
                            setchar(falling);
                          //  playchar();
                            setison(true);
    
                        }

                    }
                    
                
                    handle2ndshiftClick2();
                    if(isfourrefvaron){
                        gsap.to(fourref.current, {color:'black' ,duration: 0.4,ease:"power1.inOut"});
                        setisfourrefvaron(false);
                        gsap.to(fourref.current, {scale:'1' ,duration: 0.4,ease:"power1.inOut"});

                    } else{
                        gsap.to(fourref.current, {color:'red' ,duration: 0.4,ease:"power1.inOut"});
                        setisfourrefvaron(true);
                        gsap.to(fourref.current, {scale:'1.2' ,duration: 0.4,ease:"power1.inOut"});

                    }                    
                    }}style={{padding:'10px',userSelect:'none',cursor:'default'  }} className="button" >
                    <h1>{variable4}</h1></div>
                <div style={{padding:'10px',userSelect:'none'}}><h1>=</h1></div>
                <div style={{padding:'10px',userSelect:'none'}}><h1>0</h1></div>
            </div>
            <div style={{scale:3, position:'fixed',top:'-5px'}}>
          
          
            </div>
        </div>
    );
};

export default Quadraticeqn;
