import React, { useEffect, useRef, useState } from "react";
import { Card, Dropdown, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ProgressBar from '../LoadingPages/ProgressBar';
import gsap from 'gsap';
import PhysicsHeader from "../PhysicsHeader";

const ChemistryTopics = () => {
    const [topics, setTopics] = useState([
        {
            title: '01 Atomic Structure & chemical Bonding',
            progress: 50,
            availability:0,
            description:'  Atoms and their composition',
            subtopics: [
              {
                title: ' Atomic Structure',
                progress: 100,
                availability:1,
                description:' Equilibrium is about balance that results in rest of an object.',
                activities: [' Electron Arrangement', 'Atomic number and Mass number'],
            },
                {
                    title: ' Chemical bonding',
                    description:' Equilibrium is about balance that results in rest of an object.',
                    progress: 40,
                    availability:0,
                    activities: ['A03 Forces & Moments', '04 Newtons laws', 'A05 Momentum','06 Energy','A07 Work',' A08 Power'],
                },
                {
                    title: ' Shapes of molecules',
                    description:'Equilibrium is about balance that results in rest of an object',
                    progress: 0,
                    availability:0,
                    activities: ['A09 Motion','10 Distance','11 Displacement','A12 speed','13 Linear Motion', 'A14 Non-linear motion',''],
                },
            ],
        },
        {
            title: ' 02 Quantitative Chemistry',
            description:' Study of how energy flows through materials',
            progress: 20,
            availability:0,
            subtopics: [
                {
                    title: 'Amount of Substance',
                    description:'Equilibrium is about balance that results in rest of an object',
                    progress: 0,
                    availability:0,
                    activities: ['A15 Coulombs law', 'A16 Electric field Intensity'],
                },
                {
                    title: ' Chemical Calculations',
                    description:'Equilibrium is about balance that results in rest of an object',
                    progress: 0,
                    availability:0,
                    activities: ['A17 Series', 'A18 Parallel','A19 combination Circuits'],
                },
             
            ],
        },{
          title: ' 03 Chemical Changes',
          progress: 0,
          availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
          subtopics: [
              {
                  title: ' Chemical Reactions',
                  progress: 0,
                  availability:0,
                  activities: ['A23 Laws of reflection', 'A24 Total Internal Reflection'],
              },
              {
                  title: ' Acids and bases',
                  progress: 0,
                  availability:0,
                  activities: ['A25 Types of Lenses', 'A26 Lens equations','A27 optical Instruments'],
              },
              {
                title: ' Oxidation & Reduction',
                progress: 0,
                availability:0,
                activities: ['A25 Types of Lenses', 'A26 Lens equations','A27 optical Instruments'],
            },
          ],
      },
      {
        title: ' 04 Periodicity and Chemical Properties',
        progress: 20,
        availability:0,
        description:'Equilibrium is about balance that results in rest of an object',
        subtopics: [
            {
                title: 'Periodic Table',
                description:'Equilibrium is about balance that results in rest of an object',
                progress: 0,
                availability:0,
                activities: ['A28 Mechanical', 'A29 Electromagnetic','A30 Longitudinal','A31 transverse'],
            },
            {
                title: 'Group II (Alkaline earth metals)',
                description:'Equilibrium is about balance that results in rest of an object',
                progress: 0,
                availability:0,
                activities: ['A32 Speed', 'A33Frequency','A34 Wavelength', 'A35 Intensity'],
            },
            {
              title: 'Group VII (Halogens)',
              description:'Equilibrium is about balance that results in rest of an object',
              progress: 0,
              availability:0,
              activities: ['A36 Principle of superposition', 'A37 interferance Patterns'],
          },
        ],
    }, 
{
  title: ' 05 Organic Chemistry',
  progress: 20,
  availability:0,
  description:'Equilibrium is about balance that results in rest of an object',
  subtopics: [
      {
          title: 'Introduction',
          progress: 0,
          description:'Equilibrium is about balance that results in rest of an object',
          availability:0,
          activities: ['A37 Conduction', 'A38 Convection', 'A39 Radiation'],
      },
      {
          title: 'Functional Groups',
          progress: 0,
          availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
          activities: ['A40 Temperature', 'A41 Heat Capacity','A42 Specific Heat Capacity'],
      },
   
  ],
},  
{
  title: ' 06 Energy and Chemical Reactions',
  progress: 20,
  availability:0,
  subtopics: [
      {
          title: 'Energy Changes',
          progress: 0,
          availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
          activities: ['A47 Magnetic Field Lines', 'A48 Magnetic Flux'],
      },
      {
          title: 'Chemical Kinetics',
          progress: 0,
          availability:0,
          description:'Equilibrium is about balance that results in rest of an object',
          activities: ['A49 Faradays Law', 'A50 Lens Law'],
      },
    
  ],
},
    
    ]);

    const [editingIndex, setEditingIndex] = useState(null);
    const [editingSubtopic, setEditingSubtopic] = useState({ topicIndex: null, subtopicIndex: null });
    const [newTitle, setNewTitle] = useState('');
    const progressValue = 50;
    const progressValue2 = 20;
    const progressValue3 = 10;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isRevealed, setIsRevealed] = useState(false);
    const [revealedDiv2, setRevealedDiv2] = useState(false);
    const [revealedDiv1, setRevealedDiv1] = useState(false);
    const [isDiv1Expanded, setIsDiv1Expanded] = useState(false);
    const [isDiv2Expanded, setIsDiv2Expanded] = useState(false);
    const [isDiv2Clicked, setDiv2Clicked] = useState(false);
    const [viewMode, setViewMode] = useState('topics'); // 'topics', 'subtopics', 'activities'
    const [currentTopic, setCurrentTopic] = useState('01 Atomic Structure & chemical Bonding');
    const [currentSubtopic, setCurrentSubtopic] = useState(' ');
    const [currentdesc, setcurrentdesc] = useState('Atoms and their composition ');
    const [currentProgress, setcurrentProgress] = useState(progressValue);

    const [showsubtopic, setshowsubtopic] = useState(false);

    const handleToggleSubtopics = (index, topicTitle,topicDescription,topicprogress) => {
      setCurrentTopic(topicTitle);
      setCurrentSubtopic(''); // Reset subtopic when a new topic is selected
      setcurrentdesc(topicDescription);
      setcurrentProgress(topicprogress);
      setshowsubtopic(false);

      const subtopicElement = document.getElementById(`subtopics-${index}`);
      const isOpen = subtopicElement.style.height !== '0px';
  
      gsap.to(subtopicElement, {
          height: isOpen ? 0 : 'auto',
          opacity: isOpen ? 0 : 1,
          duration: 0.3,
      });
  };
  
  const handleToggleActivities = (topicIndex, subIndex, subtopicTitle,subtopicdescription,subtopicprogress) => {
    setshowsubtopic(true);
    setCurrentSubtopic(subtopicTitle);
    setcurrentdesc(subtopicdescription);
setcurrentProgress(subtopicprogress);
    const activityElement = document.getElementById(`activities-${topicIndex}-${subIndex}`);
    const isOpen = activityElement.style.height !== '0px';

    gsap.to(activityElement, {
        height: isOpen ? 0 : 'auto',
        opacity: isOpen ? 0 : 1,
        duration: 0.3,
    });
};

const updateTopicProgress = (topicIndex, newProgress) => {
  setTopics(prevTopics => {
    // Create a copy of the topics array
    const updatedTopics = [...prevTopics];
    
    // Update the progress for the specific topic
    updatedTopics[topicIndex] = {
      ...updatedTopics[topicIndex], // Copy the existing topic
      progress: newProgress          // Set the new progress value
    };
    
    // Return the updated array to update state
    return updatedTopics;
  });
};

    const handleSelectViewMode = (mode) => {
      setViewMode(mode);
    };
  
    // Render functions based on viewMode
    const renderTopics = () => (
      topics.map((topic, index) => (
        <div key={`topic-${index}`}>
          {/* Topic Div */}
          <div
            style={{
              display: 'flex',
              border: '1px solid #ccc',
              padding: '10px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              marginBottom: '10px',
              fontWeight: 'bold',
              backgroundColor: topic.availability === 0 ? '#FFFFFF' : '#DDEFFD',  // Check for availability and set color
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => handleToggleSubtopics(index, topic.title,topic.description,topic.progress)}
          >
            <span>{topic.title}</span>
            
            <ProgressBar progress={topic.progress} label="Progress:" />
            </div>
    
          {/* Subtopics */}
          <div
            id={`subtopics-${index}`}
            style={{
              height: 0,
              overflow: 'hidden',
              opacity: 0,
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '20px',
              fontSize: '0.9em',
            }}
          >
            {topic.subtopics.map((subtopic, subIndex) => (
              <div key={`subtopic-${index}-${subIndex}`}>
                {/* Subtopic Div */}
                <div
                  style={{
                    display: 'flex',
                    border: '0.5px solid #ccc',
                    padding: '8px',
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                    backgroundColor: subtopic.availability === 0 ? '#FFFFFF' : '#FBF6CD',  // Check for availability and set color                    marginBottom: '5px',
                    cursor: 'pointer',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => handleToggleActivities(index, subIndex, subtopic.title,subtopic.description,subtopic.progress)}
                >
                
                    <span>{subtopic.title}</span>
                
                  
                </div>
    
                {/* Activities */}
                <div
                  id={`activities-${index}-${subIndex}`}
                  style={{
                    height: 0,
                    overflow: 'hidden',
                    opacity: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '40px',
                    fontSize: '0.8em',
                  }}
                >
                  {subtopic.activities.map((activity, activityIndex) => (
                    <div
                      key={`activity-${index}-${subIndex}-${activityIndex}`}
                      style={{ marginBottom: '5px', padding: '5px 0' }}
                    >
                       {activityIndex === 0 ? (
                      <Link to="/periodictable" style={{ textDecoration: 'none', color: 'inherit' }}>
                         {activity}
                       </Link>                    ) : activityIndex === 1 ? (
                        <Link to="/periodictable" style={{ textDecoration: 'none', color: 'inherit' }}>
                         {activity}
                       </Link>                    ) : (
                      <span>{activity}</span>
                    )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))
    );
    
    const renderSubtopics = () => (
      topics.map((topic, index) => (
        <div key={`topic-${index}`}>
          {topic.subtopics.map((subtopic, subIndex) => (
            <div key={`subtopic-${index}-${subIndex}`}>
              {/* Subtopic Div */}
              <div
                style={{
                  display: 'flex',
                  border: '0.5px solid #ccc',
                  padding: '8px',
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#FBF6CD',
                  marginBottom: '5px',
                  cursor: 'pointer',
                  justifyContent: 'space-between',
                }}
                onClick={() => handleToggleActivities(index, subIndex, subtopic.title)}
              >
                <span>{subtopic.title}</span>
              </div>
    
              {/* Activities */}
              <div
                id={`activities-${index}-${subIndex}`}
                style={{
                  height: 0,
                  overflow: 'hidden',
                  opacity: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '40px',
                  fontSize: '0.8em',
                }}
              >
                {subtopic.activities.map((activity, activityIndex) => (
                  <div
                    key={`activity-${index}-${subIndex}-${activityIndex}`}
                    style={{ marginBottom: '5px', padding: '5px 0' }}
                  >
                    {activityIndex === 0 ? (
                      <Link to="/stemblocs" style={{ textDecoration: 'none', color: 'inherit' }}>
                         {activity}
                       </Link>                    ) : activityIndex === 1 ? (
                        <Link to="/stemblocs" style={{ textDecoration: 'none', color: 'inherit' }}>
                         {activity}
                       </Link>                    ) : (
                      <span>{activity}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))
    );
    
    const renderActivities = () => (
      topics.flatMap((topic, index) => 
        topic.subtopics.flatMap((subtopic, subIndex) => 
          subtopic.activities.map((activity, activityIndex) => (
            <div key={`activity-${index}-${subIndex}-${activityIndex}`} style={{ marginBottom: '5px', padding: '5px 0' }}>
              <Link to={`/stemblocs`} style={{ textDecoration: 'none', color: 'blue' }}>
                {activity}
              </Link>
            </div>
          ))
        )
      )
    );
    
    
    
   

    return (
        <>
            {isMobile ? (
                <div>
                    <div style={{ userSelect: 'none' }}>
                        {/* Fixed Header Card */}
                      
                      <PhysicsHeader />


                        <div style={{ position: 'fixed',marginBottom:'100px',  right: '150px', zIndex: 30 }}>
              <Dropdown  style={{top:'280px'}} onSelect={handleSelectViewMode}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  View Mode
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="topics">Topics</Dropdown.Item>
                  <Dropdown.Item eventKey="subtopics">Subtopics</Dropdown.Item>
                  <Dropdown.Item eventKey="activities">Activities</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

                        <div className="fixed-card" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '100px' }}>
                            {/* Fixed Card on top */}
                            <Card style={{
                                width: '85%',
                                position: 'fixed', top: '12%',
                                maxWidth: '400px',
                                marginLeft: '30px',
                                marginBottom: '20px',
                                paddingBottom:'10px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                border: '2px solid #000',
                                zIndex:5
                            }}>
                                <Card.Header style={{ backgroundColor: '#f8f9fa' }}>
                                <h6>Current Progress</h6>
</Card.Header>
                                  <Card.Body>
        <h1> Topic: {currentTopic}</h1>
        <h4 style={{fontWeight:'normal'}}>
          {showsubtopic && (
           <span>
           SubTopic:
           </span> 
            
            )}
          {currentSubtopic || ' '}</h4>
        <h6 style={{fontWeight:'normal', color:'#3B3B3B'}}>
          <span style={{fontWeight:'bold', color:'#1D1D1D'}}>
          Summary: 
          </span>
          <span style={{padding:'5px'}}>
          {currentdesc}
            </span>
         </h6>        <ProgressBar progress={currentProgress} label="Progress:" />
        <Button variant="primary">
  {currentTopic.includes('01 Atomic Structure & chemical Bonding') ? (
    <Link to="/periodictable" style={{ color: 'white', textDecoration: 'none' }}>
            Start

    </Link>
  ) : currentTopic.includes('Dynamics') ? (
    <Link to="/activity" style={{ color: 'white', textDecoration: 'none' }}>
      Start
    </Link>
  ) : (
    <span>Start.</span> // Fallback for other conditions
  )}
</Button>
    </Card.Body>
                            </Card>

                            {/* Scrolling Card on the Right */}
                            <Card style={{
                                width: '85%',
                                position:'relative',
                                marginTop:'300px',
                                maxWidth: '700px',
                                marginLeft: '30px',
                                padding: '20px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                border: '2px solid #000',
                            }}>
                              <Card.Body>
  <h4>Contents</h4>

  {/* Three Boxes for Subtopics */}
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
    
    {/* Box 1: Dynamic Content Based on viewMode */}
    <div style={{ width: '50px', fontWeight: 'bold' }}></div>
    <div style={{ flex: 1 }}>
      {/* Render content based on viewMode */}
      {viewMode === 'topics' && renderTopics()}
      {viewMode === 'subtopics' && renderSubtopics()}
      {viewMode === 'activities' && renderActivities()}
    </div>

  
  </div>
</Card.Body>

                            </Card>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div style={{ userSelect: 'none' }}>
                        {/* Fixed Header Card */}
                        <PhysicsHeader />

                        <div style={{ position: 'fixed', top: '50px', right: '150px', zIndex: 1001 }}>
              <Dropdown onSelect={handleSelectViewMode}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  View Mode
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={renderTopics} eventKey="topics">Topics</Dropdown.Item>
                  <Dropdown.Item onClick={renderSubtopics} eventKey="subtopics">Subtopics</Dropdown.Item>
                  <Dropdown.Item onClick={renderActivities} eventKey="activities">Activities</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
                        <div style={{ display: 'flex', position: 'relative', marginTop: '100px' }}>
                            {/* Fixed Card on the Left */}
                            <Card style={{
                                width: '500px',
                                height: '300px',
                                position: 'fixed',
                                top: '100px',
                                left: '120px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                border: '2px solid #000',
                                zIndex: 1000,
                            }}>
                                <Card.Body>
        <h6>Current Progress</h6>
        <h1 style={{fontWeight:'bolder'}}> Topic: {currentTopic}</h1>
        <h4 style={{fontWeight:'normal'}}>
          {showsubtopic && (
           <span>
           SubTopic:
           </span> 
            
            )}
          {currentSubtopic || ' '}</h4>        <h6 style={{fontWeight:'normal', color:'#3B3B3B'}}>
          <span style={{fontWeight:'bold', color:'#1D1D1D'}}>
          Summary: 
          </span>
          <span style={{padding:'5px'}}>
          {currentdesc}
            </span>
         </h6>

        <ProgressBar progress={currentProgress} label="Progress:" />
        <Button variant="primary">
  {currentTopic.includes('01 Atomic Structure & chemical Bonding') ? (
    <Link to="/periodictable" style={{ color: 'white', textDecoration: 'none' }}>
      Start
    </Link>
  ) : currentTopic.includes('Dynamics') ? (
    <Link to="/introduction" style={{ color: 'white', textDecoration: 'none' }}>
        Start

    </Link>
  ) : (
    <span>Start...</span> // Fallback for other conditions
  )}
</Button>
    </Card.Body>
                            </Card>

                            {/* Scrolling Card on the Right */}
                            <div style={{ marginLeft: '640px' }}>
                                <Card style={{
                                    height: '1500px',
                                    width: '700px',
                                    padding: '20px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    border: '2px solid #000',
                                }}>
                                 <Card.Body>
  <h4>Topics</h4>

  {/* Three Boxes for Subtopics */}
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
    
    {/* Box 1: Dynamic Content Based on viewMode */}
    <div style={{ width: '50px', fontWeight: 'bold' }}></div>
    <div style={{ flex: 1 }}>
      {/* Render content based on viewMode */}
      {viewMode === 'topics' && renderTopics()}
      {viewMode === 'subtopics' && renderSubtopics()}
      {viewMode === 'activities' && renderActivities()}
    </div>

    

   
  </div>
</Card.Body>

                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ChemistryTopics;
