import { gsap, selector } from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";
import anequation from '../jsons/anequation.json';
import variable from '../jsons/variables.json';
import constants from '../jsons/constants.json';
import task1 from '../jsons/task1.json';
import congs from '../jsons/congs.json';
import wrong from '../jsons/wrongmove.json';
import sub from '../jsons/subtraction.json';
import crosses from '../jsons/crossesb.json';
import crosses2 from '../jsons/crossesb.json';
import crosses3 from '../jsons/crosses1.json';
import welldone from '../jsons/welldone.json';
import division from '../jsons/Division.json';
import swipeinstruction from '../jsons/swipeinstruction.json';
import { Draggable } from "gsap/all";
import tryagain from '../jsons/wrongmove.json';
import doubleclick from '../jsons/dbclick.json';
import drag from '../jsons/draghere.json';


import '../MathLearning.css';
import instruction from '../jsons/doubleclickinstruction.json';
gsap.registerPlugin(Draggable);


const Unit32 = ({value1,value2}) => {
  
    const crossesref = useRef(crosses);
    const crosses2ref = useRef(crosses2);
    const apointer = useRef(null);

    const oneref = useRef(null);
    const tworef = useRef(null);
    const plusRef = useRef(null);
    const xref = useRef(null);
    const extraref = useRef(null);
    const m2ref = useRef(null);
    const value1ref = useRef(null);
    const value2ref = useRef(null);
    
    const instructionref = useRef(null);
    const doubleclickref = useRef(null);

    const assignRef = useRef(null);
    const negateref = useRef(null);
    const extrarightref = useRef(null);  
    const bottomanimref = useRef(anequation);
    const upperanimref = useRef(task1);
    const [bottomanimation, setbottomanimation] = useState(instruction);
    const [playbottomanimation, setplaybottomanimation] = useState(true);
    const [playupperanimation, setplayupperanimation] = useState(false);
    const [upperanimations, setupperanimations] = useState(task1);
    const [showNum1, setShowNum1] = useState(true); // State to track the visibility of the minus box
    const [twoanswered, settwoanswered] = useState(false);
    const [pointertype, setpointertype] = useState(doubleclick);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [showdivide, setshowdivide] = useState(false);
  const [showmultiply, setshowmultiply] = useState(false);
  const [cross, setcross] = useState(false); // State to track the visibility of the minus box
  const [draggableElements, setDraggableElements] = useState([]);
  const [showthird, setthird] = useState(false); // State to track the visibility of the minus box
  const [iswelldone, setiswelldone] = useState(false); // State to track the visibility of the minus box
  const [color1, setcolor1] = useState('4E5154'); // State to track the visibility of the minus box
  const [color2, setcolor2] = useState('4E5154'); // State to track the visibility of the minus box
  const [colorop, setcolorop] = useState('4E5154'); // State to track the visibility of the minus box
  const [colorx, setcolorx] = useState('4E5154'); // State to track the visibility of the minus box
  const [looping, setlooping] = useState(false); // State to track the visibility of the minus box

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  


    useEffect(() => {
      const one = oneref.current;
      const two = tworef.current;
      const x = xref.current;
      const plus = plusRef.current;
      const extra = extrarightref.current;
      const neg = negateref.current;

      

      },[]);

      const handleddClick = () => {
        console.log("doubleclicked");
      setbottomanimation(swipeinstruction);
      setpointertype(drag);
      gsap.to(apointer.current, { y: +30,x:-15, duration: 1 });
      const place1 = oneref.current.offsetLeft;
      const place2 = tworef.current.offsetLeft;
      const allocation = place2 - place1;
    

        const newValueElement = document.createElement("div");
                newValueElement.textContent = value1; // Set the text content to the value of 'x'
                newValueElement.style.position = "fixed";
                newValueElement.className = "dropdown";
                newValueElement.style.padding = "10px";
                newValueElement.style.color = "#585757";
                newValueElement.style.fontSize = "30px";
                newValueElement.style.fontFamily = "Helvetica";

                newValueElement.style.zindex = "7";

                newValueElement.style.left = `${oneref.current.getBoundingClientRect().left + 10}px`; // Position it below the 'x' element
                newValueElement.style.cursor = "default";
              //  document.body.appendChild(newValueElement); // Append it to the body
              const referenceElement = document.querySelector('#thisid'); // Replace with your specific div's selector
      
      // Insert the new div after the reference element
      if (referenceElement && referenceElement.nextSibling) {
        document.body.insertBefore(newValueElement, referenceElement.nextSibling);
      } else if (referenceElement) {
        // If referenceElement is the last child
        document.body.appendChild(newValueElement);
      } else {
        // If referenceElement is not found, append to body as default
        document.body.appendChild(newValueElement);
      }
      const draggableXValueInstance = Draggable.create(newValueElement, {
        type: "x",
        edgeResistance: 0.65,
        onDragEnd: function () {
            const xValue = parseFloat(this.target.textContent); // Get the value of the dragged element
            const xValuePosition = this.target.getBoundingClientRect().left;
            const equal = assignRef.current;
      
            const equalPosition = equal.getBoundingClientRect().left;
      
            if (xValuePosition > equalPosition) {
                 gsap.to(newValueElement, { x: 152, duration: 1 });
                 setcross(true);
                 gsap.to(apointer.current, { opacity: 0, duration: 1 });
                 
                 gsap.to(bottomanimref.current, { opacity: 100, duration: 1,delay:2});

                 setTimeout(() => {
                   const new2 = value2 / xValue; // Perform division using the value of the dragged element
                   tworef.current.textContent = new2; 
                   gsap.to(newValueElement, { opacity:  0, duration: 0.3, ease: "power2.inOut" });
              
                   setTimeout(() => {
                    settwoanswered(true);
                  }, 300);
                 }, 3000);
               



                 setTimeout(() => {
                
                  gsap.to(bottomanimref.current, { opacity:  0, duration: 0.3, ease: "power2.inOut" });

                 }, 500);
                 gsap.to(upperanimref.current, { opacity:  0, duration: 0.3, ease: "power2.inOut",delay:5 });


                 setTimeout(() => {
                  gsap.to(bottomanimref.current, { opacity:  100, duration: 0.3, ease: "power2.inOut" });

                  setbottomanimation(congs);

                 }, 6000);
               
                 setTimeout(() => {
                  setShowNum1(false);
                }, 500);
      
                  setTimeout(() => {
                   criscross();
                 }, 400);
      
      
                  setTimeout(() => {
                    criscross2();
                  }, 600);
                
          
                 // Update the text content of the '5' element
                 this.disable(); // Disable dragging after the condition is met
            }
            else{
              setbottomanimation(tryagain);

              setTimeout(() => {
                setbottomanimation(swipeinstruction);

                
              }, 1500);
            }
        },
      });
      
      };

 const trainingelements = {
    display: "flex",
    position: "relative",
    alignItems: 'center',
    justifyContent: 'center',
 
    zindex:2,
    alignItems: "center",

  };

  const trainingelements2 = {
    display: "flex",
    position: "relative",
    marginLeft:"380px",
    zindex:2,
    alignItems: "center",
  };

  const crossposition = {
    position: 'absolute', // Make sure to include the 'position' property
    width: '50px',
    height: '50px',
    bottom: '90px',
    left: '150px',
    marginBottom:"18px",

    zIndex: 2
  };
  const crossposition3 = {
    position: 'absolute', // Make sure to include the 'position' property
    width: '100px',
    height: '100px',
    bottom: '155px',
    left: '0px',
    marginBottom:"5px",
    zIndex: 2
  };
  const crossposition2 = {
    position: 'absolute', // Make sure to include the 'position' property
    width: '60px',
    height: '60px',
    bottom: '184px',
    left: '150px',
    marginTop:"15px",

    zIndex: 2
  };
  const criscross = () => {
    if (crossesref.current) {
      crossesref.current.play();
  }  };
  
  const criscross2 = () => {
    if (crosses2ref.current) {
      crosses2ref.current.play();
  }  };
 

    return(
        <div style={{position:'fixed', left:'400px',color:'#0390D8',top:'100px' }}>
        

            <div ref={upperanimref} className="upperanimation" style={{opacity:'0'}}>
            <Player ref={upperanimref} autoplay={true} loop={false} keepLastFrame={true} src={upperanimations} />

            </div>
            {isMobile? (
              <div className="workarea" style={{position:'fixed',top:'165px'}} >
             <div  id={"test2"} onDoubleClick={handleddClick} ref={oneref} style={{position:"relative", padding: "10px", fontSize: '30px', userSelect: 'none',backgroundColor:'white', margin: "10px",zindex:4,cursor:"default"}}>
                  {showNum1 && (
                      <span >{value1}</span>
                  )}  p
              </div>   
              <div className="crossposition">
         <Player  ref={crossesref} autoplay={false} loop={false} src={crosses} speed={0.3}   />

         </div> 
         <div className="crossposition2">
         <Player  ref={crosses2ref} autoplay={false} loop={false} src={crosses2} speed={0.3}    />

         </div>
           
         

                   <div  ref={assignRef} style={{ padding: "10px", fontSize: '30px', backgroundColor:'white',color: colorop, userSelect: 'none', margin: "15px" }}>=</div>
                   <div ref={tworef} style={{ padding: "10px", fontSize: '30px',backgroundColor:'white', color: color2, userSelect: 'none',marginRight:'20px', margin: "15px" }}>{value2}</div>
                   

               </div>
            ): (

              <div className="workarea" style={{position:'fixed',top:'168px',}} >
             <div  id={"test2"} onDoubleClick={handleddClick} ref={oneref} style={{position:"relative", padding: "10px", fontSize: '30px', userSelect: 'none',backgroundColor:'white', margin: "10px",zindex:4,cursor:"default"}}>
                  {showNum1 && (
                      <span >{value1}</span>
                  )}  p
              </div>   
              <div className="crossposition">
         <Player  ref={crossesref} autoplay={false} loop={false} src={crosses} speed={0.3}   />

         </div> 
         <div className="crossposition2">
         <Player  ref={crosses2ref} autoplay={false} loop={false} src={crosses2} speed={0.3}    />

         </div>
           
         

                   <div  ref={assignRef} style={{ padding: "10px", fontSize: '30px', backgroundColor:'white',color: colorop, userSelect: 'none', margin: "15px" }}>=</div>
                   <div ref={tworef} style={{ padding: "10px", fontSize: '30px',backgroundColor:'white', color: color2, userSelect: 'none',marginRight:'20px', margin: "15px" }}>{value2}</div>
                   

               </div>
            )}
             
               {playbottomanimation && (
 <div ref={bottomanimref} className="loweranimation" style={{opacity:'0',position:'fixed',left:'700px'}} >
 <Player ref={bottomanimref} autoplay={true} loop={false} keepLastFrame={true}  src={congs} />
</div>
               )}
              
        </div>
    );

};
export default Unit32;