import React, { useEffect, useRef, useState } from "react";
import lottie from "lottie-web";
import watertest from '../jsons/watertest.json';
import launch2 from '../jsons/launchballpiston2.json';
import { Player } from '@lottiefiles/react-lottie-player';

const Launch = () => {
  const rptref = useRef(null); // This is not used in the current implementation
  const launchref = useRef(null);

  const lottieInstance = useRef(null);
  const [sliderValue, setSliderValue] = useState(20);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentFrame, setCurrentFrame] = useState(0);

  // Function to update animation data


 
  return (
    <div>
      <div ref={launchref} style={{ position: 'fixed', top: "300px", left: '600px', height: '300px', width: '300px' }}>
        {/* The animation will be rendered here */}
      </div>
      <div style={{ position: 'fixed', top: '550px', left: '600px' }}>
        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue}
        />
      </div>
      <div style={{ position: 'fixed', top: '600px', left: '400px' }}>
        Current time: {currentTime} seconds
      </div>
      <div style={{ position: 'fixed', top: '625px', left: '400px' }}>
        Current frame: {currentFrame}
      </div>
      <div>
      <Player  ref={launchref} autoplay={true} loop={true} src={launch2} intermission={3}     />

      </div>
    </div>
  );
};

export default Launch;
