// src/GameRoom.js
import React, { useEffect, useState } from 'react';
import { db } from './Firebase';
import { collection, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './Firebase';

const GameRoom = () => {
  const [leftScore, setLeftScore] = useState(0);
  const [rightScore, setRightScore] = useState(0);
  const [gameState, setGameState] = useState(null);
  const [user] = useAuthState(auth);

  const roomId = 'game-room-1'; // Fixed room ID for now, you can make this dynamic

  useEffect(() => {
    const roomRef = doc(collection(db, 'gameRooms'), roomId);

    // Listen to game state changes in real-time
    const unsubscribe = onSnapshot(roomRef, (snapshot) => {
      if (snapshot.exists()) {
        setGameState(snapshot.data());
      }
    });

    return () => unsubscribe();
  }, [roomId]);

  const handleLeftClick = async () => {
    if (user && gameState) {
      const newScore = gameState.leftScore + 1;
      const roomRef = doc(collection(db, 'gameRooms'), roomId);
      await setDoc(roomRef, { leftScore: newScore }, { merge: true });
    }
  };

  const handleRightClick = async () => {
    if (user && gameState) {
      const newScore = gameState.rightScore + 1;
      const roomRef = doc(collection(db, 'gameRooms'), roomId);
      await setDoc(roomRef, { rightScore: newScore }, { merge: true });
    }
  };

  return (
    <div>
      <h1>Game Room</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '400px' }}>
        <div>
          <h2>Left Player: {gameState ? gameState.leftScore : 0}</h2>
          <button onClick={handleLeftClick} disabled={gameState?.leftPlayer !== user?.uid}>Left Button</button>
        </div>
        <div>
          <h2>Right Player: {gameState ? gameState.rightScore : 0}</h2>
          <button onClick={handleRightClick} disabled={gameState?.rightPlayer !== user?.uid}>Right Button</button>
        </div>
      </div>
    </div>
  );
};

export default GameRoom;
