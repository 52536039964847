import { gsap, selector } from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";
import anequation from '../jsons/anequation.json';
import variable from '../jsons/variables.json';
import constants from '../jsons/constants.json';
import task1 from '../jsons/task1.json';
import congs from '../jsons/congs.json';
import wrong from '../jsons/wrongmove.json';
import '../MathLearning.css';


const Unit1 = ({value1,value2}) =>{
    const xRef = useRef(null);
    const thex = useRef(null);
    const plusRef = useRef(null);
    const ppRef = useRef(null);
    const m1ref = useRef(null);
    const m2ref = useRef(null);
    const value1ref = useRef(null);
    const value2ref = useRef(null);
    const assignRef = useRef(null);
    const negateref = useRef(null);
    const bottomanimref = useRef(anequation);
    const upperanimref = useRef(task1);
    const [bottomanimation, setbottomanimation] = useState(anequation);
    const [playbottomanimation, setplaybottomanimation] = useState(false);
    const [playupperanimation, setplayupperanimation] = useState(false);

    const [upperanimations, setupperanimations] = useState(task1);

  const [showdivide, setshowdivide] = useState(false);
  const [showmultiply, setshowmultiply] = useState(false);
  const [cross, setcross] = useState(false); // State to track the visibility of the minus box
  const [draggableElements, setDraggableElements] = useState([]);
  const [showthird, setthird] = useState(false); // State to track the visibility of the minus box
  const [iswelldone, setiswelldone] = useState(false); // State to track the visibility of the minus box
  const [color1, setcolor1] = useState('4E5154'); // State to track the visibility of the minus box
  const [color2, setcolor2] = useState('4E5154'); // State to track the visibility of the minus box
  const [colorop, setcolorop] = useState('4E5154'); // State to track the visibility of the minus box
  const [colorx, setcolorx] = useState('4E5154'); // State to track the visibility of the minus box
  const [looping, setlooping] = useState(false); // State to track the visibility of the minus box

  

    useEffect(() => {
        const valueone = value1ref.current;
        const valuetwo = value2ref.current;
        const neg = negateref.current;
        const p = ppRef.current;
        const ass = assignRef.current;
        const tx = thex.current;

        //play equation animation
          setTimeout(() => {

           setplaybottomanimation(true);
           gsap.to(tx, { backgroundColor:'#000083', duration:1});
           gsap.to(tx, { color:'white', duration:1, });
           gsap.to(valueone, { backgroundColor:'#000083', duration:1});
           gsap.to(valueone, { color:'white', duration:1, });
           gsap.to(valuetwo, { backgroundColor:'#000083', duration:1});
           gsap.to(valuetwo, { color:'white', duration:1, });
           gsap.to(neg, { backgroundColor:'#000083', duration:1});
           gsap.to(neg, { color:'white', duration:1, });
           gsap.to(ass, { backgroundColor:'#000083', duration:1});
           gsap.to(ass, { color:'white', duration:1, });
           gsap.to(p, { backgroundColor:'#000083', duration:1});
           gsap.to(p, { color:'white', duration:1, });


          }, 1000);

          //revert colors
      
         

          //play variable animation
          setTimeout(() => {

            setbottomanimation(variable);
            gsap.to(tx, { backgroundColor:'#6E46EC', duration:1});
            gsap.to(tx, { color:'white', duration:1, });
            gsap.to(tx, { scale:2, duration:1.5, });

          }, 4600);

          //scale down variable
          gsap.to(tx, { scale:1, duration:1, delay:7,repeat: 0});
          gsap.to(tx, { color:'black', duration:1, delay:7,repeat: 0});
          gsap.to(tx, { backgroundColor:'white', duration:1,delay:7});

          //play constant animation
          setTimeout(() => {

            setbottomanimation(constants);
            gsap.to(valueone, { scale:2, duration:1.5});
            gsap.to(valueone, { backgroundColor:'#4E4EFF', duration:1});
            gsap.to(valueone, { color:'white', duration:1});

            gsap.to(valuetwo, { backgroundColor:'#4E4EFF', duration:1});
            gsap.to(valuetwo, { scale:2, duration:1.5});
            gsap.to(valuetwo, { color:'white', duration:1});
            
          }, 8000);
          
          //scale down constants

            gsap.to(valueone, { scale:1, duration:1,delay:10});
            gsap.to(valueone, { backgroundColor:'white', duration:1,delay:10});
            gsap.to(valueone, { color:'black', duration:1,delay:10});

            gsap.to(valuetwo, { backgroundColor:'white', duration:1,delay:10});
            gsap.to(valuetwo, { scale:1, duration:1.5,delay:10});
            gsap.to(valuetwo, { color:'black', duration:1,delay:10});

          //hide constants
        

         
          
          setTimeout(() => {
            setplaybottomanimation(false);
          }, 11000);

    },[]);

 const trainingelements = {
    display: "flex",
    position: "relative",
    alignItems: 'center',
    justifyContent: 'center',
 
    zindex:2,
    alignItems: "center",

  };

  const trainingelements2 = {
    display: "flex",
    position: "relative",
    marginLeft:"380px",
    zindex:2,
    alignItems: "center",
  };
    return(
        <div>
            <div className="upperanimation">
            <Player ref={upperanimref} autoplay={false} loop={false} keepLastFrame={true} src={upperanimations} />

            </div>
             <div className="workarea" >
                   <div className='test2' ref={thex} style={{ padding: "10px", fontSize: '30px', color: colorx, userSelect: 'none', margin: "30px" }}>x</div>
                   <div className='test2' ref={ppRef} style={{ padding: "10px", fontSize: '30px', color: colorop, userSelect: 'none', margin: "30px" }}>+</div>
                   <div className='test2' ref={value1ref} style={{ padding: "10px", fontSize: '30px', color: color1, userSelect: 'none',borderRadius:'5px', margin: "30px" }}>{value1}</div>
                   <div className='test2' ref={assignRef} style={{ padding: "10px", fontSize: '30px', color: colorop, userSelect: 'none', margin: "30px" }}>=</div>
                   <div className='test2' ref={value2ref} style={{ padding: "10px", fontSize: '30px', color: color2, userSelect: 'none', margin: "30px" }}>{value2}</div>
                   <div className='test2' ref={negateref} style={{ padding: "10px", fontSize: '30px', userSelect: 'none', color: colorop, margin: "30px", opacity: 0 }}>-</div>
               </div>
               {playbottomanimation && (
 <div className="loweranimation" >
 <Player ref={bottomanimref} autoplay={true} loop={false} keepLastFrame={true} src={bottomanimation} />
</div>
               )}
              
        </div>
    );

};
export default Unit1;