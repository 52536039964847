import React, { useState, useEffect } from "react";
import { Card, Button } from 'react-bootstrap';
import DistanceTracker2 from "./PracticeComponents/DistanceTracker2";
const Timer = () => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showSpeed, setShowSpeed] = useState(false);
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    let timerInterval;
    if (isRunning) {
      timerInterval = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [isRunning]);

  // Function to format time to HH:MM:SS
  const formatTime = (seconds) => {
    const hrs = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const secs = String(seconds % 60).padStart(2, '0');
    return `${hrs}h:${mins}m:${secs}s`;
  };

  const handleStartPause = () => {
    setIsRunning(!isRunning);
{isRunning? setShowSpeed(true) : setShowSpeed(false)}
  };

  const handleReset = () => {
    setTime(0);
    setIsRunning(false);
    setShowSpeed(false);
    setDistance(0);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  const calculateSpeed = () => {
    if (time === 0) return 0;
    const speedInSeconds = distance / time;
    return Math.round(speedInSeconds * 3600); // Convert to km/h and round
  };

  return (
    <>
    {isMobile? (
 <div>
      
   
      <Card 
  style={{
    width: '300px', // Adjust width as needed
    height: '400px', // Adjust height as needed
    position: 'absolute', 
    top: '100%',
    left: '50%',
    zIndex: 10, // Ensure it's on top
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional: Add slight opacity for visibility
    padding: '20px',
    borderRadius: '10px'
  }}
>      <Card.Header as="h5" style={{height:'100px',fontSize:'15px',fontWeight:'bold'}} className="text-center">Distance / Time</Card.Header>

   <Card.Body style={{height:'500px'}}>
     <h2 style={{ textAlign: 'center' }}>{formatTime(time)}</h2>
   <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

     <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
       <Button onClick={handleStartPause}>
         {isRunning ? 'Pause' : 'Start'}
       </Button>
       <Button onClick={handleReset}>Reset</Button>
     </div>
     <div style={{fontSize:'10px',position:'fixed',width:'300px',marginTop:'300px'}}>
       <DistanceTracker2 onDistanceUpdate={setDistance} />
     </div>
     {showSpeed && (
       <div style={{marginTop: '10px', textAlign: 'center'}}>
         Speed: {calculateSpeed()} km/h
       </div>
     )}
     </div>

   </Card.Body>
 </Card>
 </div>
    ):(

 
    <div>
      
   
    <Card style={{position:'absolute',left:'1100px',top:'100px', width: '200px', height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <Card.Header as="h5" style={{fontSize:'15px',fontWeight:'bold'}} className="text-center">Distance / Time</Card.Header>

      <Card.Body>
        <h2 style={{ textAlign: 'center' }}>{formatTime(time)}</h2>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
          <Button onClick={handleStartPause}>
            {isRunning ? 'Pause' : 'Start'}
          </Button>
          <Button onClick={handleReset}>Reset</Button>
        </div>
        
        {showSpeed && (
          <div style={{marginTop: '10px', textAlign: 'center'}}>
            Speed: {calculateSpeed()} km/h
          </div>
        )}
        </div>

      </Card.Body>
    </Card>
    </div>

)}
       </>
 
  );
};

export default Timer;
