import React, { useEffect, useRef, useState } from "react";
import lottie from "lottie-web";
import boytag from './jsons/boytag.json';
import watertest from './jsons/p2.json';
import leftwin from './jsons/leftwin.json';
import rightwin from './jsons/rightwin.json';
import wallpaper from './jsons/wallpaper.mp3';
import { Player } from '@lottiefiles/react-lottie-player';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { db, doc, onSnapshot,updateDoc } from './Firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Multiplayer = () => {
  const rptref = useRef(null);
  const leftref = useRef(null);
  const [resttime, setRestTime] = useState(5);
  const [isEnabled, setIsEnabled] = useState(true);
  const [timeTaken, setTimeTaken] = useState(3);
  const audioref = useRef(null);
  const lottieInstance = useRef(null);
  const [sliderValue, setSliderValue] = useState(50);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [is100, setIs100] = useState(false);
  const [is0, setIs0] = useState(false);
  const [leftValue, setLeftValue] = useState(20);
  const [rightValue, setRightValue] = useState(0);
  const [winHolder, setWinHolder] = useState(leftwin);
  const [activePlayer, setActivePlayer] = useState('left');
  const [gameState, setGameState] = useState(null);
  const [isPlayer1, setIsPlayer1] = useState(false);
  const [isPlayer2, setIsPlayer2] = useState(false);
  const { user } = getAuth(); // Custom hook to get user info


  useEffect(() => {
    if (!user) return;

    const gameRef = doc(db, 'games', 'game1');
    const unsubscribe = onSnapshot(gameRef, (doc) => {
      setGameState(doc.data());
      const players = doc.data().players;
      setIsPlayer1(players[0] === user.uid);
      setIsPlayer2(players[1] === user.uid);
    });

    return () => unsubscribe();
  }, [user]);
  const handleTokenCollection = (side) => {
    if (!gameState) return;

    // Update game state based on the player side
    const newGameState = { ...gameState };
    if (side === 'right') {
      // Player 1 actions
    } else {
      // Player 2 actions
    }

    // Update Firestore
    const gameRef = doc(db, 'games', 'game1');
    updateDoc(gameRef, {
      gameState: newGameState,
    });
  };

  useEffect(() => {
    const playAudio = () => {
      if (audioref.current) {
        audioref.current.play().catch((error) => {
          console.error("Audio play interrupted:", error);
        });
      }
    };

    document.addEventListener("click", playAudio, { once: true });

    return () => {
      document.removeEventListener("click", playAudio);
    };
  }, []);

  const updateAnimationData = (newValue) => {
    if (!rptref.current) return; // Early exit if ref is not set

    const updatedData = JSON.parse(JSON.stringify(watertest)); // Clone watertest data
    updatedData.layers.forEach((layer) => {
      if (layer.ef) {
        layer.ef.forEach((effect) => {
          if (effect.nm === "Slider Control") {
            const sliderEffect = effect.ef.find(e => e.nm === "Slider");
            if (sliderEffect) {
              sliderEffect.v.k = newValue;
            }
          }
        });
      }
    });

    if (lottieInstance.current) {
      lottieInstance.current.destroy();
    }
    lottieInstance.current = lottie.loadAnimation({
      container: rptref.current,
      animationData: updatedData,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    });

    if (newValue > 0 && lottieInstance.current) {
      lottieInstance.current.goToAndPlay(currentFrame, true);
    }
  };

  const handleSliderChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setSliderValue(newValue);
    updateAnimationData(newValue);
  };

  const addLeft = () => {
    if (leftValue > 0) {
      setLeftValue(leftValue - 1);
      setSliderValue(prev => prev + 1);
      updateAnimationData(sliderValue + 1);
      setActivePlayer('right'); // Switch to right player
    }
  };

  const addRight = () => {
    setSliderValue(prev => prev - 1);
    updateAnimationData(sliderValue - 1);
    setActivePlayer('left'); // Switch to left player
  };

  useEffect(() => {
    if (rptref.current) {
      rptref.current.style.height = '300px';
      rptref.current.style.width = '800px';
      rptref.current.style.top = '100px';

      lottieInstance.current = lottie.loadAnimation({
        container: rptref.current,
        animationData: watertest,
        renderer: 'svg',
        loop: false,
        autoplay: false,
      });

      return () => {
        if (lottieInstance.current) {
          lottieInstance.current.destroy();
        }
      };
    }
  }, []);

  useEffect(() => {
    if (leftref.current) {
      leftref.current.style.height = '300px';
      leftref.current.style.width = '800px';
      leftref.current.style.top = '100px';

      if (winHolder) {
        lottieInstance.current = lottie.loadAnimation({
          container: leftref.current,
          animationData: winHolder,
          renderer: 'svg',
          loop: false,
          autoplay: true,
        });
      }
    }

    if (sliderValue >= 95) {
      setWinHolder(leftwin);
      setIs100(true);
      setIs0(false);
    } else if (sliderValue <= 5) {
      setWinHolder(rightwin);
      setIs0(true);
      setIs100(false);
    } else {
      setIs0(false);
      setIs100(false);
    }
  }, [sliderValue, winHolder]);

  useEffect(() => {
    if (sliderValue > 3 && sliderValue < 95) {
      const timerId = setTimeout(() => {
        setSliderValue(prev => prev - 1);
        updateAnimationData(sliderValue - 1);
      }, 300);
      return () => clearTimeout(timerId);
    }
  }, [sliderValue]);

  useEffect(() => {
    if (!isEnabled) {
      const timerId = setTimeout(() => {
        setTimeTaken(prevTime => prevTime - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeTaken, isEnabled]);

  useEffect(() => {
    if (leftValue < 1) {
      setIsEnabled(false);
      setTimeout(() => {
        setIsEnabled(true);
        setLeftValue(20);
        setTimeTaken(3);
      }, 3000);
    }
  }, [leftValue]);

  const handleAudioLoad3 = () => {
    if (audioref.current) {
      audioref.current.play().catch((error) => {
        console.error("Audio play interrupted:", error);
      });
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Row className="justify-content-center w-100">
        <Col xs={12} md={10} lg={8} className="mb-4">
          <Card style={{ width: '100%', minHeight: '80vh', textAlign: 'center' }}>
            <Card.Header style={{ backgroundColor: '#E0DBCF' }}>
              <h3>Tag of War</h3>
            </Card.Header>
            <Card.Body>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', userSelect: 'none' }}>
                {is100 && (
                  <div ref={leftref} style={{ height: '400px', width: '800px', userSelect: 'none' }}>
                    {/* The leftwin animation will be rendered here */}
                  </div>
                )}
                {is0 && (
                  <div ref={leftref} style={{ height: '400px', width: '800px', userSelect: 'none' }}>
                    {/* The rightwin animation will be rendered here */}
                  </div>
                )}
                {!is100 && !is0 && (
                  <div ref={rptref} style={{ height: '400px', width: '800px', userSelect: 'none' }}>
                    {/* The watertest animation will be rendered here */}
                    <Player>
                      <source src={watertest} />
                    </Player>
                  </div>
                )}
              </div>
              <div className="mt-3">
              {isPlayer1 && (

                <Button
                  variant="primary"
                  onClick={addLeft}
                  disabled={activePlayer !== 'left'}
                  className="mx-2"
                >
                
                  Left
                </Button>
                   )}
                         {isPlayer2 && (

                <Button
                  variant="secondary"
                  onClick={addRight}
                  disabled={activePlayer !== 'right'}
                  className="mx-2"
                >
                  Right
                </Button>
                  )}
              </div>
              <div className="mt-3">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={sliderValue}
                  onChange={handleSliderChange}
                  className="w-100"
                />
                <p>Slider Value: {sliderValue}</p>
              </div>
              <audio ref={audioref} src={wallpaper} onLoadedData={handleAudioLoad3} />
            </Card.Body>
            <Card.Footer>
              <p>Time Remaining: {timeTaken}s</p>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Multiplayer;
